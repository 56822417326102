import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/style.css'; 
import useGameLogic from './hooks/useRotatingDiamondGame';
import GameControls from './components/GameControls';
import ScoreDisplay from './components/ScoreDisplay';
import GameTimerDisplay from './components/GameTimerDisplay';
import GameOverModal from './components/GameOverModal';
import NavigationButtons from './components/NavigationButtons';
import { API_URLS } from '../../../config/config';

const RotatingDiamondGame = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [userRole, setUserRole] = useState(null); 
  const location = useLocation();
  const { stage, exercise } = location.state || {};
  const token = localStorage.getItem('userToken');

  // Kullanıcı verilerini ve rolünü getirme
  useEffect(() => {
    const fetchUserData = async () => {
      if (token) {
        try {
          // Öğrenci verilerini getir
          const studentResponse = await fetch(`${API_URLS.STUDENT_URL}/me`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
          if (studentResponse.ok) {
            const studentData = await studentResponse.json();
            setUserData(studentData);
            setUserRole('student');
            return;
          }
        } catch (error) {
          console.error('Öğrenci verisi alınırken hata oluştu:', error);
        }

        try {
          // Öğretmen verilerini getir
          const teacherResponse = await fetch(`${API_URLS.TEACHER_URL}/me`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
          if (teacherResponse.ok) {
            const teacherData = await teacherResponse.json();
            setUserData(teacherData);
            setUserRole('teacher');
            return;
          }
        } catch (error) {
          console.error('Öğretmen verisi alınırken hata oluştu:', error);
        }

        // Eğer her ikisi de başarısız olursa
        setUserData(null);
        setUserRole(null);
      }
    };

    fetchUserData();
  }, [token]);

  const {
    canvasRef,
    isPlaying,
    setIsPlaying,
    speed,
    setSpeed,
    score,
    gameTime,
    initialGameTime, // Yeni eklenen
    drawDiamond,
    resetGame,
    isGameOver,
    setIsGameOver,
  } = useGameLogic(userData);

  const updateProgress = async () => {
    if (!userData || !userRole) return;
    try {
      if (userData) {
        const apiUrl =
          userRole === 'student'
            ? `${API_URLS.STUDENT_URL}akillikurs/progress/update-game`
            : `${API_URLS.TEACHER_URL}akillikurs/progress/update-game`;

        await axios.post(
          apiUrl,
          {
            stageNumber: stage?.number || 1,
            gameName: exercise?.gameName || 'Harf Döngüsü Oyunu',
            score: score,
            time: (userData.status === 'inactive' ? 15 : 60) - gameTime,
            completed: true,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        console.log('İlerleme başarıyla güncellendi.');
      }
    } catch (error) {
      console.error('İlerleme güncellenirken hata oluştu:', error);
    }
  };

  const onGameOver = () => {
    setIsPlaying(false);
    setIsGameOver(true);
    updateProgress();
  };

  useEffect(() => {
    if (isPlaying) {
      const interval = setInterval(() => {
        drawDiamond();
      }, Math.max(1000 / speed, 100));
      return () => clearInterval(interval);
    }
  }, [isPlaying, speed, drawDiamond]);

  useEffect(() => {
    if (gameTime === 0 && isPlaying) {
      onGameOver();
    }
  }, [gameTime, isPlaying]);

  const [showIntroduction, setShowIntroduction] = useState(true);

  const startGame = () => {
    setShowIntroduction(false);
    setIsPlaying(true);
  };

  const handleReturnToPreviousStage = () => {
    if (stage) {
      navigate(userRole === 'student' ? '/ogrenci-dashboard' : '/ogretmen-dashboard', {
        state: {
          selectedMenu: 'ExerciseList',
          selectedStage: stage,
        },
      });
    } else {
      alert('Aşama bilgisi bulunmamaktadır.');
    }
  };

  return (
    <>
      {showIntroduction ? (
        <div className="container-fluid min-vh-100 d-flex flex-column justify-content-center align-items-center bg-light introduction-bg">
          <div className="row justify-content-center mb-4 w-100">
            <div className="col-12 col-md-10 col-lg-8">
              <div className="card shadow border-0 rounded-lg text-center p-5 introduction-card">
                <div className="card-body">
                  <NavigationButtons handleReturnToPreviousStage={handleReturnToPreviousStage} />
                  <h1 className="display-4 mb-4 fw-bold text-primary">Harf Döngüsü</h1>
                  <p className="lead mb-4 text-secondary">
                    Bu oyunda amaç, ekrandaki döngü yapan harfleri takip etmektir.
                  </p>
                  <h3 className="mb-3 text-info">Nasıl Oynanır?</h3>
                  <ul className="text-start d-inline-block fs-6 text-dark">
                    <li className="mb-2">
                      <strong>1. Harfleri Takip Edin:</strong> Döngü yapan harfleri gözlerinizle izleyin.
                    </li>
                    <li className="mb-2">
                      <strong>2. Hız ve Dikkat:</strong> Harflerin hızını artırarak daha dikkatli olun.
                    </li>
                    <li>
                      <strong>3. Zorluk Seviyesi:</strong> Oyunun hızını artırarak zorluğu yükseltin.
                    </li>
                  </ul>
                  <button
                    className="btn btn-success mt-4 px-4 py-2 start-button"
                    onClick={startGame}
                  >
                    <FontAwesomeIcon icon={faArrowLeft} className="me-2" /> Oyuna Başla
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container-fluid min-vh-100 d-flex flex-column justify-content-center align-items-center bg-light game-bg">
          <div className="row g-0 justify-content-center w-100">
            <div className="col-12 col-md-10 col-lg-8">
              <div className="card shadow mt-4 game-card">
                <div className="card-body">
                  {/* Üst Kontroller */}
                  <div className="row mb-3">
                    <div className="col-6">
                      <GameTimerDisplay 
                        gameTime={gameTime} 
                        initialGameTime={initialGameTime} // Yeni eklenen
                      />
                    </div>
                    <div className="col-6 text-end">
                      {userData && <ScoreDisplay score={score} />}
                    </div>
                  </div>

                  {/* Oyun Alanı */}
                  <div className="harf-dongusu-game-container mb-3">
                    <canvas
                      ref={canvasRef}
                      width="800"
                      height="600"
                      className="game-canvas"
                    />
                  </div>

                  {/* Alt Kontroller */}
                  <div className="row">
                    <div className="col-12 d-flex justify-content-center">
                      <GameControls
                        speed={speed}
                        setSpeed={setSpeed}
                        isPlaying={isPlaying}
                        setIsPlaying={setIsPlaying}
                        resetGame={resetGame}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isGameOver && <GameOverModal score={score} onRestart={resetGame} userData={userData} />}
    </>
  );
};

export default RotatingDiamondGame;
