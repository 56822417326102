import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCrosshairs } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

export const CANVAS_WIDTH = 900;
export const CANVAS_HEIGHT = 600;
export const COLORS = ['#E91E63', '#9C27B0', '#2196F3', '#4CAF50', '#FFC107'];

export const Ball = ({ x, y, color, number, size, isTarget }) => (
  <div style={{
    position: 'absolute',
    left: `${(x / CANVAS_WIDTH) * 100}%`,
    top: `${(y / CANVAS_HEIGHT) * 100}%`,
    width: `${(size / CANVAS_WIDTH) * 100}%`,
    height: `${(size / CANVAS_HEIGHT) * 100}%`,
    borderRadius: '50%',
    backgroundColor: color,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontSize: `${size / 3}px`,
    fontWeight: 'bold',
    border: isTarget ? '3px solid gold' : '2px solid black',
    boxShadow: isTarget ? '0 0 10px gold' : 'none',
    transition: 'all 0.3s ease'
  }}>
    {number}
  </div>
);

export const Crosshair = ({ x, y }) => (
  <div style={{
    position: 'absolute',
    left: `${(x / CANVAS_WIDTH) * 100}%`,
    top: `${(y / CANVAS_HEIGHT) * 100}%`,
    transform: 'translate(-50%, -50%)',
    pointerEvents: 'none'
  }}>
    <FontAwesomeIcon icon={faCrosshairs} size="2x" color="red" />
  </div>
);

export const MoveBalls = (entities, { time, events, dispatch }) => {
  if (!entities || !entities.score) return entities;

  const updatedEntities = { ...entities };
  let score = entities.score.value;

  const now = time.current; 

  Object.keys(updatedEntities).forEach(key => {
    if (updatedEntities[key].type === 'ball') {
      if (now - updatedEntities[key].lastMoved > updatedEntities[key].moveInterval) {
        updatedEntities[key] = {
          ...updatedEntities[key],
          x: Math.random() * (CANVAS_WIDTH - updatedEntities[key].size),
          y: Math.random() * (CANVAS_HEIGHT - updatedEntities[key].size),
          lastMoved: now 
        };
      }
    }
  });

  return updatedEntities;
};
