// src/dashboard/okul/styles/SchoolDashboardStyles.js

import { styled } from '@mui/material/styles';
import { AppBar, Drawer, Avatar, Card } from '@mui/material';

const drawerWidth = 240;

// Ana İçerik Alanı
export const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    marginLeft: open ? drawerWidth : 0,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginTop: '64px',
    overflow: 'auto',
    height: `calc(100vh - 64px)`,
    backgroundColor: '#e3f2fd', // Açık mavi arka plan
  })
);

// Uygulama Çubuğu
export const AppBarStyled = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  background: 'linear-gradient(to right, #2196f3, #0d47a1)', // Mavi gradient
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  zIndex: theme.zIndex.drawer + 1,
  ...(open && {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  }),
}));

// Çekmece (Drawer)
export const DrawerStyled = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    background: 'linear-gradient(to bottom, #1565c0, #0d47a1)', // Koyu mavi gradient
    color: '#fff',
  },
}));

// Liste Öğesi
export const ListItemStyled = styled('div')(({ theme, selected }) => ({
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)', // Hafif beyaz hover
    transition: 'background-color 0.3s ease',
  },
  backgroundColor: selected ? 'rgba(255, 255, 255, 0.2)' : 'inherit',
}));

// Liste Metni
export const ListItemTextStyled = styled('span')({
  color: '#fff',
});

// İç içe Liste Öğesi
export const NestedListItem = styled('div')(({ theme }) => ({
  paddingLeft: theme.spacing(4),
}));

// Profil Kartı
export const ProfileCard = styled(Card)(({ theme }) => ({
  background: 'linear-gradient(135deg, #2196f3 0%, #64b5f6 100%)', // Mavi gradient
  borderRadius: '15px',
  padding: theme.spacing(4),
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)',
  marginTop: theme.spacing(8),
  textAlign: 'center',
  color: '#fff',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 8px 30px rgba(0, 0, 0, 0.3)',
  },
}));

// Avatar Stili
export const AvatarStyled = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(15),
  height: theme.spacing(15),
  margin: '0 auto',
  marginBottom: theme.spacing(2),
  backgroundColor: '#0d47a1', // Koyu mavi
  border: '4px solid #fff',
  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
}));
