// src/pages/ogretmen/payment/PaymentHistory.js

import React, { useEffect, useState } from 'react';
import { API_URLS } from '../../../config/config';
import {Typography, Card, CardContent, Grid, Paper,} from '@mui/material';
import { styled } from '@mui/system';

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: '#f8f9fc',
  }));

function PaymentHistory() {
  const [payments, setPayments] = useState([]);

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const response = await fetch(API_URLS.TEACHER_URL + 'payments', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        if (response.ok) {
          setPayments(data);
        } else {
          console.error('Ödeme kayıtları alınamadı:', data.message);
        }
      } catch (error) {
        console.error('Ödeme kayıtları alınırken bir hata oluştu:', error);
      }
    };

    fetchPayments();
  }, []);

  return (
    <Card>
    <CardContent>
      <Typography variant="h5" gutterBottom>
        Satın Aldığınız Paketler
      </Typography>
      {payments.map((payment) => (
        <StyledPaper key={payment._id}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Paket Süresi:</strong> {payment.paketSuresi} Ay
              </Typography>
              <Typography variant="body1">
                <strong>Adet:</strong> {payment.adet}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Toplam Tutar:</strong> {payment.toplamTutar} TL
              </Typography>
              <Typography variant="body1">
                <strong>Satın Alma Tarihi:</strong> {new Date(payment.odemeTarihi).toLocaleDateString()}
              </Typography>
            </Grid>
          </Grid>
        </StyledPaper>
      ))}
    </CardContent>
  </Card>
  );
}

export default PaymentHistory;
