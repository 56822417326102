import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faRedo } from '@fortawesome/free-solid-svg-icons';

const GameDisplay = ({
  isPlaying,
  speed,
  paragraphsList,
  selectedParagraphIndex,
  displayedWords,
  updateWords,
  handlePlayPause,
  handleReset,
  message,
  gameOver,
}) => {
  useEffect(() => {
    let interval;
    if (isPlaying) {
      const intervalDuration = 1000 / speed; // Hıza göre interval süresi
      interval = setInterval(updateWords, intervalDuration);
    }
    return () => clearInterval(interval);
  }, [isPlaying, updateWords, speed]);

  return (
    <>
      <div className="row mb-3">
        <div className="col-12 text-end">
          <button className="btn btn-primary me-2" onClick={handlePlayPause} disabled={gameOver}>
            <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} /> {isPlaying ? 'Duraklat' : 'Başlat'}
          </button>
          <button className="btn btn-secondary" onClick={handleReset}>
            <FontAwesomeIcon icon={faRedo} /> Yeniden Başlat
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          {!paragraphsList[selectedParagraphIndex] ? (
            <div className="alert alert-warning" role="alert">
              Paragraf bulunamadı. Lütfen farklı bir paragraf veya zorluk seviyesi seçin.
            </div>
          ) : (
            <div className="kayanyazi-three-game-container">
              <div className="kayanyazi-three-word-display">
                {displayedWords.map((word, index) => (
                  <span key={index} className="animated-word">
                    {word}&nbsp;
                  </span>
                ))}
              </div>
            </div>
          )}
          {message && (
            <div className="alert alert-info text-center mt-3">
              {message}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default GameDisplay;
