import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faRedo } from '@fortawesome/free-solid-svg-icons';

const Controls = ({
  isPlaying,
  handlePlayPause,
  handleReset,
  timeRemaining,
  difficulty,
  handleDifficultyChange,
  selectedParagraphIndex,
  handleParagraphChange,
  paragraphsList,
}) => {
  const formatTime = (seconds) => {
    if (seconds < 0) seconds = 0;
    const minutes = Math.floor(seconds / 60)
      .toString()
      .padStart(2, '0');
    const remainingSeconds = (seconds % 60).toString().padStart(2, '0');
    return `${minutes}:${remainingSeconds}`;
  };

  return (
    <>
      <div className="row mb-3 justify-content-center">
        <div className="col-12 col-md-8 text-center">
          <button
            className={`btn btn-${isPlaying ? 'warning' : 'success'} me-2`}
            onClick={handlePlayPause}
            style={{ fontSize: '1rem', fontWeight: '600' }}
          >
            <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />{' '}
            {isPlaying ? 'Duraklat' : 'Başlat'}
          </button>
          <button
            className="btn btn-secondary"
            onClick={handleReset}
            style={{ fontSize: '1rem', fontWeight: '600' }}
          >
            <FontAwesomeIcon icon={faRedo} /> Yeniden Başlat
          </button>
        </div>
      </div>

      <div className="row mb-3 justify-content-center">
        <div className="col-12 col-md-8 text-center">
          <div className="d-inline-block px-4 py-2 bg-light border rounded">
            <label
              htmlFor="timeRemaining"
              className="me-2 fw-bold"
              style={{ fontSize: '1.25rem' }}
            >
              Kalan Süre:
            </label>
            <span
              className="fw-bold text-danger"
              style={{ fontSize: '1.5rem' }}
            >
              {formatTime(timeRemaining)}
            </span>
          </div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-12 col-md-6 mb-2 mb-md-0">
          <label
            htmlFor="difficultySelect"
            className="form-label"
            style={{ fontSize: '1.25rem', fontWeight: 'bold' }}
          >
            Zorluk Seç:
          </label>
          <select
            className="form-select"
            id="difficultySelect"
            value={difficulty}
            onChange={handleDifficultyChange}
            style={{ fontSize: '1.1rem', fontWeight: '500' }}
          >
            <option value="easy">Kolay</option>
            <option value="medium">Orta</option>
            <option value="hard">Zor</option>
          </select>
        </div>
        <div className="col-12 col-md-6">
          <label
            htmlFor="paragraphSelect"
            className="form-label"
            style={{ fontSize: '1.25rem', fontWeight: 'bold' }}
          >
            Paragraf Seç:
          </label>
          <select
            className="form-select"
            id="paragraphSelect"
            value={selectedParagraphIndex}
            onChange={handleParagraphChange}
            style={{ fontSize: '1.1rem', fontWeight: '500' }}
          >
            {paragraphsList.map((paragraph, index) => (
              <option key={index} value={index}>
                {paragraph.title}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
};

export default Controls;
