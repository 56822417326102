// gameLogic.js

import { useRef, useEffect, useState, useCallback } from 'react';

export const useGameLogic = (userData, onGameOver) => {
  const canvasRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [position, setPosition] = useState(0);
  const [speed, setSpeed] = useState(1); // Başlangıç hızını 5 olarak ayarladık
  const [orientation, setOrientation] = useState('horizontal');
  const [canvasSize, setCanvasSize] = useState({ width: 800, height: 600 });
  const amplitude = orientation === 'horizontal' ? 200 : 400;
  const wavelength = 200;
  const [score, setScore] = useState(0);

  // Kullanıcının durumuna göre oyun süresini ayarla
  const [timeRemaining, setTimeRemaining] = useState(0); // Başlangıçta 0

  useEffect(() => {
    if (userData) {
      setTimeRemaining(userData.status === 'inactive' ? 15 : 60);
    } else {
      setTimeRemaining(15); // Kullanıcı verisi yoksa varsayılan 15 saniye
    }
  }, [userData]);

  const [difficulty, setDifficulty] = useState('seciniz'); // Başlangıç zorluğunu 'easy' olarak ayarladık
  const [showModal, setShowModal] = useState(false);

  const updateCanvasSize = useCallback(() => {
    const container = document.querySelector('.game-container');
    if (container) {
      const width = container.clientWidth;
      const height = width * 0.75; // 4:3 en boy oranı
      setCanvasSize({ width, height });
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', updateCanvasSize);
    updateCanvasSize();
    return () => window.removeEventListener('resize', updateCanvasSize);
  }, [updateCanvasSize]);

  const draw = useCallback(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    const { width, height } = canvasSize;

    ctx.clearRect(0, 0, width, height);
    ctx.fillStyle = '#F0EAD6'; // Göz dostu arka plan rengi
    ctx.fillRect(0, 0, width, height);

    ctx.beginPath();
    ctx.lineWidth = 3;
    ctx.strokeStyle = '#4A4A4A';

    if (orientation === 'horizontal') {
      ctx.moveTo(0, height / 2);
      for (let x = 0; x < width; x += 1) {
        ctx.lineTo(
          x,
          height / 2 + Math.sin((x / wavelength) * 2 * Math.PI) * amplitude
        );
      }
    } else {
      const shift = (width - amplitude) / 2;
      const startX = shift + amplitude / 2;
      for (let y = 0; y < height; y++) {
        const x =
          startX + amplitude * Math.sin((y / wavelength) * 2 * Math.PI);
        ctx.lineTo(x, y);
      }
    }
    ctx.stroke();

    // Topu çiz
    const posY =
      orientation === 'horizontal'
        ? height / 2 +
          Math.sin((position / wavelength) * 2 * Math.PI) * amplitude
        : position;
    const posX =
      orientation === 'horizontal'
        ? position
        : (width - amplitude) / 2 +
          amplitude / 2 +
          amplitude * Math.sin((position / wavelength) * 2 * Math.PI);

    ctx.beginPath();
    ctx.arc(posX, posY, 20, 0, 2 * Math.PI);
    ctx.fillStyle = 'red';
    ctx.fill();
  }, [canvasSize, orientation, position, amplitude, wavelength]);

  useEffect(() => {
    let animationFrameId;
    let lastTime = 0;
    const fps = 60;
    const interval = 1000 / fps;

    const gameLoop = (currentTime) => {
      animationFrameId = requestAnimationFrame(gameLoop);

      if (currentTime - lastTime >= interval) {
        if (isPlaying) {
          setPosition((prevPosition) => {
            const maxPosition =
              orientation === 'horizontal'
                ? canvasSize.width
                : canvasSize.height;
            return (prevPosition + speed) % maxPosition;
          });
          setScore((prevScore) => prevScore + speed / 100); // Skor artışı
        }
        draw();
        lastTime = currentTime;
      }
    };

    gameLoop();

    return () => cancelAnimationFrame(animationFrameId);
  }, [isPlaying, speed, orientation, canvasSize, draw]);

  useEffect(() => {
    let timer;
    if (isPlaying && timeRemaining > 0) {
      timer = setInterval(() => {
        setTimeRemaining((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeRemaining === 0 && isPlaying) {
      setIsPlaying(false);
      setShowModal(true);
      if (onGameOver) {
        onGameOver(); // Oyun bittiğinde onGameOver fonksiyonunu çağırıyoruz
      }
    }
    return () => clearInterval(timer);
  }, [isPlaying, timeRemaining, onGameOver]);

  const resetGame = () => {
    setIsPlaying(false);
    setScore(0);
    setTimeRemaining(userData ? (userData.status === 'inactive' ? 15 : 60) : 15); // Süreyi başlangıç değerine ayarlıyoruz
    setPosition(0);
    setShowModal(false);
  };



  
  const handleDifficultyChange = (event) => {
    const newDifficulty = event.target.value;
    setDifficulty(newDifficulty);
    switch (newDifficulty) {
      case 'easy':
        setSpeed(2);
        break;
      case 'normal':
        setSpeed(5);
        break;
      case 'hard':
        setSpeed(8);
        break;
      default:
        setSpeed(2);
    }
  };

  return {
    canvasRef,
    isPlaying,
    setIsPlaying,
    position,
    setPosition,
    speed,
    setSpeed,
    orientation,
    setOrientation,
    canvasSize,
    setCanvasSize,
    amplitude,
    wavelength,
    score,
    setScore,
    timeRemaining,
    setTimeRemaining,
    difficulty,
    setDifficulty,
    updateCanvasSize,
    draw,
    resetGame,
    handleDifficultyChange,
    showModal,
    setShowModal,
  };
};
