// GameControls.js

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faRedo, faMinus, faPlus, faHome, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import '../styles/style.css'; // Stil dosyanızı kullanmaya devam ediyoruz

export default function GameControls({ speed, setSpeed, isPlaying, setIsPlaying, resetGame }) {
  const navigate = useNavigate();

  const handleDecreaseSpeed = () => {
    setSpeed(Math.max(speed - 0.1, 0.1));
  };

  const handleIncreaseSpeed = () => {
    setSpeed(speed + 0.1);
  };

  const goToHome = () => {
    navigate('/'); // Ana sayfaya yönlendirme
  };

  const goBack = () => {
    navigate(-1); // Bir önceki sayfaya geri dön
  };

  return (
    <div className="game-controls-container">
      {/* Sol Köşe: Ev ve Geri Dön İkonları */}
      <div className="home-icon-container d-flex align-items-center">
        <button className="btn btn-outline-primary btn-sm home-button me-2" onClick={goToHome}>
          <FontAwesomeIcon icon={faHome} />
        </button>
        <button className="btn btn-outline-primary btn-sm back-button" onClick={goBack}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
      </div>

      {/* Orta Bölüm: Hız Ayarları */}
      <div className="speed-controls">
        <button
          className="btn btn-outline-secondary btn-sm speed-button"
          onClick={handleDecreaseSpeed}
          disabled={speed <= 0.1}
        >
          <FontAwesomeIcon icon={faMinus} />
        </button>
        <span className="speed-label">Hız: {speed.toFixed(1)}</span>
        <button
          className="btn btn-outline-secondary btn-sm speed-button"
          onClick={handleIncreaseSpeed}
        >
          <FontAwesomeIcon icon={faPlus} />
        </button>
      </div>

      {/* Sağ Köşe: Başlat/Duraklat ve Yeniden Başlat Butonları */}
      <div className="play-reset-controls">
        <button className="btn btn-primary me-2 custom-button play-button" onClick={() => setIsPlaying(!isPlaying)}>
          <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />{' '}
          {isPlaying ? 'Duraklat' : 'Başlat'}
        </button>
        <button className="btn btn-secondary custom-button reset-button" onClick={resetGame}>
          <FontAwesomeIcon icon={faRedo} /> Yeniden Başlat
        </button>
      </div>
    </div>
  );
}
