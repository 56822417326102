import { useState, useEffect, useRef } from 'react';

const alphabet = 'ABCÇDEFGĞHIİJKLMNOÖPRSŞTUÜVYZ';

export const useLetterGridGame = (userData) => {
  const [letters, setLetters] = useState([]);
  const [targetLetters, setTargetLetters] = useState([]);
  const [message, setMessage] = useState('');
  const [score, setScore] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [clickedLetters, setClickedLetters] = useState([]);
  const [difficulty, setDifficulty] = useState('easy');
  const [gridSize, setGridSize] = useState(64); // Default to 8x8 grid

  // Yeni state değişkenleri
  const [targetLetterCounts, setTargetLetterCounts] = useState({});
  const [foundLetterCounts, setFoundLetterCounts] = useState({});

  // Kullanıcının durumuna göre oyun süresini ayarla
  const initialTime = !userData || userData.status === 'inactive' ? 15 : 60;
  const [timeRemaining, setTimeRemaining] = useState(initialTime);

  // Süreyi initialTime'a göre güncelle
  useEffect(() => {
    setTimeRemaining(initialTime);
  }, [initialTime]);

  // Oyun başlangıcında ve zorluk seviyesi değiştiğinde oyunu başlat
  useEffect(() => {
    if (isPlaying) {
      initializeGame();
    }
  }, [difficulty]);

  useEffect(() => {
    let timer;
    if (isPlaying && timeRemaining > 0) {
      timer = setTimeout(() => setTimeRemaining(timeRemaining - 1), 1000);
    } else if (timeRemaining === 0) {
      setIsPlaying(false);
      setMessage('Süre doldu!');
    }
    return () => clearTimeout(timer);
  }, [isPlaying, timeRemaining]);

  const initializeGame = () => {
    // Zorluk seviyesine göre grid boyutu ve hedef harf sayısı ayarla
    let size, targetCount, targetLetterFrequency;
    switch (difficulty) {
      case 'easy':
        size = 64; // 8x8 grid
        targetCount = 1;
        targetLetterFrequency = 0.1; // Hedef harflerin ızgaradaki oranı
        break;
      case 'medium':
        size = 100; // 10x10 grid
        targetCount = 2;
        targetLetterFrequency = 0.1;
        break;
      case 'hard':
        size = 144; // 12x12 grid
        targetCount = 3;
        targetLetterFrequency = 0.1;
        break;
      default:
        size = 64;
        targetCount = 1;
        targetLetterFrequency = 0.1;
    }
    setGridSize(size);

    // Hedef harfleri oluştur
    const targets = [];
    for (let i = 0; i < targetCount; i++) {
      targets.push(alphabet[Math.floor(Math.random() * alphabet.length)]);
    }
    setTargetLetters(targets);

    // Hedef harflerin sayısını takip etmek için nesne oluştur
    const targetCounts = {};
    targets.forEach((letter) => {
      targetCounts[letter] = 0;
    });

    // Izgaradaki harfleri oluştur ve hedef harfleri rastgele yerlere yerleştir
    const newLetters = [];
    for (let i = 0; i < size; i++) {
      let randomLetter;
      // Hedef harfleri belirli bir sıklıkta yerleştir
      if (Math.random() < targetLetterFrequency) {
        randomLetter = targets[Math.floor(Math.random() * targetCount)];
        targetCounts[randomLetter] += 1;
      } else {
        randomLetter = alphabet[Math.floor(Math.random() * alphabet.length)];
      }
      newLetters.push(randomLetter);
    }

    setLetters(newLetters);
    setClickedLetters([]);
    setMessage('');
    setTargetLetterCounts(targetCounts);
    setFoundLetterCounts({});
    // Skoru sıfırlamıyoruz, böylece skor birikmeye devam edecek
    // Süreyi de sıfırlamıyoruz, mevcut süre üzerinden devam ediyoruz
  };

  const handlePlayPause = () => {
    if (!isPlaying) {
      setMessage('');
      initializeGame(); // Oyun başlarken harfleri oluşturuyoruz
    }
    setIsPlaying(!isPlaying);
  };

  const handleReset = () => {
    setIsPlaying(false);
    setScore(0); // Skoru sıfırlıyoruz çünkü oyun tamamen resetleniyor
    setTimeRemaining(initialTime); // Süreyi de başlangıç değerine alıyoruz
    initializeGame();
  };

  const handleLetterClick = (letter, index) => {
    if (!isPlaying || clickedLetters.includes(index)) return;

    setClickedLetters([...clickedLetters, index]);

    if (targetLetters.includes(letter)) {
      // Bulunan hedef harfin sayısını artır
      setFoundLetterCounts((prevCounts) => {
        const newCounts = { ...prevCounts };
        if (newCounts[letter]) {
          newCounts[letter] += 1;
        } else {
          newCounts[letter] = 1;
        }
        return newCounts;
      });

      setScore(score + 1);
      setMessage('Doğru!');

      // Tüm hedef harflerin tüm kopyaları bulundu mu kontrol et
      const allFound = targetLetters.every(
        (target) => foundLetterCounts[target] + 1 >= targetLetterCounts[target]
      );

      if (allFound) {
        setMessage('Tüm harfleri buldunuz! Yeni harflere geçiliyor...');
        // Eğer süre varsa yeni harflerle oyuna devam et
        if (timeRemaining > 0) {
          setTimeout(() => {
            initializeGame();
          }, 1000);
        } else {
          setIsPlaying(false);
        }
      }
    } else {
      setMessage('Yanlış!');
    }
  };

  const handleDifficultyChange = (e) => {
    setDifficulty(e.target.value);
    handleReset(); 
  };

  return {
    letters,
    targetLetters,
    message,
    timeRemaining,
    score,
    isPlaying,
    clickedLetters,
    difficulty,
    gridSize,
    handlePlayPause,
    handleReset,
    handleLetterClick,
    handleDifficultyChange,
  };
};
