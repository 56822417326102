import React from 'react';
import { GameEngine } from 'react-game-engine';
import { Ball, Crosshair, MoveBalls } from './GameComponents';

const GameEngineComponent = ({ entities, isPlaying, handleEvent }) => {
  return (
    <div className="row">
      <div className="col-12">
        <div className="nesne-game-container">
        <GameEngine
        style={{
          width: '100%',
          height: '0',
          paddingBottom: '66.67%',
          position: 'relative',
          backgroundColor: '#F0EAD6'
        }}
  systems={[MoveBalls]} // Sistemi doğru şekilde geçir
  entities={entities} 
  running={isPlaying} // isPlaying durumuna göre oyunun çalışması
  onEvent={handleEvent} // Event handler
>

            {Object.keys(entities).map(key => {
              if (entities[key].type === 'ball') {
                return <Ball key={key} {...entities[key]} />;
              }
              return null;
            })}
            {entities.crosshair && <Crosshair {...entities.crosshair} />}
          </GameEngine>
        </div>
      </div>
    </div>
  );
};

export default GameEngineComponent;
