// src/components/admin/balances/BalanceList.js

import React, { useEffect, useState } from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Avatar, // Avatar'ı ekledik
} from '@mui/material';
import { API_URLS } from '../../../config/config';
import { styled } from '@mui/system';
import {
  Person as PersonIcon,
  Business as BusinessIcon,
  School as SchoolIcon,
} from '@mui/icons-material';
import ResultDialog from '../../../pages/components/Auth/Dialog';
import useFetchUserData from '../../../hooks/admin/useFetchUserData';

// Styled bileşenler
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
}));

const FilterContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(4),
  padding: theme.spacing(2),
  backgroundColor: '#f5f5f5',
  borderRadius: theme.shape.borderRadius,
}));

const BalanceList = () => {
  const [balances, setBalances] = useState([]);
  const [filteredBalances, setFilteredBalances] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Filtreleme durum değişkenleri
  const [userTypeFilter, setUserTypeFilter] = useState('');
  const [startDateFilter, setStartDateFilter] = useState(null);
  const [endDateFilter, setEndDateFilter] = useState(null);

  // Yeni eklenen searchTerm state'i
  const [searchTerm, setSearchTerm] = useState('');

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');
  const [dialogSeverity, setDialogSeverity] = useState('info');

  // Kullanıcı verilerini çekmek için hook
  const [userData, setUserData] = useState(null);
  useFetchUserData(setUserData);

  useEffect(() => {
    const fetchBalances = async () => {
      try {
        const response = await fetch(`${API_URLS.ADMIN_URL}bakiye/balance-adjustments`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          },
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Bakiyeler alınamadı.');
        }

        const data = await response.json();
        setBalances(data);
        setFilteredBalances(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBalances();
  }, []);

  // Kullanıcı isimlerini almak için yardımcı fonksiyon
  const getUserName = (balance) => {
    if (!balance.userId) return 'Bilinmiyor';
    switch (balance.userType) {
      case 'Ogretmen':
        return `${balance.userId.ad || ''} ${balance.userId.soyad || ''}`;
      case 'Ogrenci':
        return `${balance.userId.ad || ''} ${balance.userId.soyad || ''}`;
      case 'Institution':
        return balance.userId.kurum_adi || '';
      case 'School':
        return balance.userId.okul_adi || '';
      default:
        return 'Bilinmiyor';
    }
  };

  // Kullanıcı türlerini Türkçeye çeviren yardımcı fonksiyon
const getUserTypeLabel = (userType) => {
  switch (userType) {
    case 'Ogretmen':
      return 'Öğretmen';
    case 'Ogrenci':
      return 'Öğrenci';
    case 'Institution':
      return 'Kurumsal';
    case 'School':
      return 'Okul';
    default:
      return 'Bilinmiyor';
  }
};

  // Kullanıcı türüne göre ikon seçimi
  const getUserIcon = (userType) => {
    if (userType === 'Ogretmen' || userType === 'Ogrenci') {
      return <PersonIcon />;
    } else if (userType === 'Institution') {
      return <BusinessIcon />;
    } else if (userType === 'School') {
      return <SchoolIcon />;
    } else {
      return <PersonIcon />;
    }
  };

  // Filtreleme fonksiyonu
  useEffect(() => {
    const lowercasedFilter = searchTerm.toLowerCase();
    const filteredData = balances.filter((item) => {
      const userName = getUserName(item) || '';
      const userType = item.userType || '';
      const userIdStr = item.userId?._id?.toString() || '';

      // Kullanıcı tipi filtresi
      if (userTypeFilter && userType !== userTypeFilter) {
        return false;
      }

      // Tarih aralığı filtresi
      const balanceDate = new Date(item.date);
      if (startDateFilter && balanceDate < startDateFilter) {
        return false;
      }
      if (endDateFilter && balanceDate > endDateFilter) {
        return false;
      }

      // Arama filtresi
      if (searchTerm) {
        if (
          !userName.toLowerCase().includes(lowercasedFilter) &&
          !userIdStr.includes(lowercasedFilter) &&
          !userType.toLowerCase().includes(lowercasedFilter)
        ) {
          return false;
        }
      }

      return true;
    });
    setFilteredBalances(filteredData);
  }, [userTypeFilter, startDateFilter, endDateFilter, searchTerm, balances]);

  // Yardımcı fonksiyonlar
  const getUserBalance = (balance) => {
    const user = balance.userId;
    return user && user.bakiye !== undefined ? `${user.bakiye} TL` : 'Bilinmiyor';
  };

  return (
    <>
      <Typography variant="h5" gutterBottom sx={{ padding: 2 }}>
        Bakiye Değişiklikleri
      </Typography>

      {/* Filtreleme Alanı */}
      <FilterContainer>
        <Grid container spacing={2} alignItems="center">
          {/* Kullanıcı Türü Filtreleme */}
          <Grid item xs={12} sm={3}>
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel>Kullanıcı Türü</InputLabel>
              <Select
                value={userTypeFilter}
                onChange={(e) => setUserTypeFilter(e.target.value)}
                label="Kullanıcı Türü"
              >
                <MenuItem value="">
                  <em>Tümü</em>
                </MenuItem>
                <MenuItem value="Ogretmen">Öğretmen</MenuItem>
                <MenuItem value="Ogrenci">Öğrenci</MenuItem>
                <MenuItem value="Institution">Kurumsal</MenuItem>
                <MenuItem value="School">Okul</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Başlangıç Tarihi Filtreleme */}
          <Grid item xs={12} sm={3}>
            <TextField
              label="Başlangıç Tarihi"
              type="date"
              variant="outlined"
              size="small"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              value={startDateFilter ? startDateFilter.toISOString().split('T')[0] : ''}
              onChange={(e) => setStartDateFilter(e.target.value ? new Date(e.target.value) : null)}
            />
          </Grid>

          {/* Bitiş Tarihi Filtreleme */}
          <Grid item xs={12} sm={3}>
            <TextField
              label="Bitiş Tarihi"
              type="date"
              variant="outlined"
              size="small"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              value={endDateFilter ? endDateFilter.toISOString().split('T')[0] : ''}
              onChange={(e) => setEndDateFilter(e.target.value ? new Date(e.target.value) : null)}
            />
          </Grid>

          {/* Yeni eklenen Arama Kutusu */}
          <Grid item xs={12} sm={3}>
            <TextField
              label="Ara"
              variant="outlined"
              size="small"
              fullWidth
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Grid>
        </Grid>
      </FilterContainer>

      {/* Yükleniyor Göstergesi */}
      {loading ? (
        <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '200px' }}>
          <CircularProgress />
        </Grid>
      ) : error ? (
        <Typography color="error" variant="h6">
          {error}
        </Typography>
      ) : (
        /* Bakiye Listesi */
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>Kullanıcı</StyledTableCell>
                <StyledTableCell>Kullanıcı Türü</StyledTableCell>
                <StyledTableCell>Bakiye</StyledTableCell>
                <StyledTableCell>Değişim Miktarı</StyledTableCell>
                <StyledTableCell>Tarih</StyledTableCell>
                <StyledTableCell>Değiştiren Yönetici</StyledTableCell>
                <StyledTableCell>Gerekçe</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredBalances.map((balance) => (
                <TableRow key={balance._id}>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <Avatar sx={{ bgcolor: '#3f51b5', marginRight: 1 }}>
                        {getUserIcon(balance.userType)}
                      </Avatar>
                      <Typography>{getUserName(balance)}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell>{getUserTypeLabel(balance.userType)}</TableCell>
                  <TableCell>{getUserBalance(balance)}</TableCell>
                  <TableCell>
                    {balance.amountChanged > 0 ? (
                      <Typography color="success.main">+{balance.amountChanged} TRY</Typography>
                    ) : (
                      <Typography color="error.main">{balance.amountChanged} TRY</Typography>
                    )}
                  </TableCell>
                  <TableCell>{new Date(balance.date).toLocaleDateString('tr-TR')}</TableCell>
                  <TableCell>{balance.changedBy?.fullname || 'Bilinmiyor'}</TableCell>
                  <TableCell>{balance.reason || 'Belirtilmemiş'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Dialog */}
      <ResultDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        title={dialogSeverity === 'error' ? 'Hata Oluştu' : 'İşlem Başarılı'}
        message={dialogContent}
        severity={dialogSeverity}
      />
    </>
  );
};

export default BalanceList;
