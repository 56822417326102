import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faRedo } from '@fortawesome/free-solid-svg-icons';

const GameControls = ({ isPlaying, handlePlayPause, handleReset }) => {
  return (
    <div className="row mb-3 justify-content-center">
      <div className="col-12 col-md-8 text-center">
        <button className="btn btn-primary me-2" onClick={handlePlayPause}>
          <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} /> {isPlaying ? 'Duraklat' : 'Başlat'}
        </button>
        <button className="btn btn-secondary" onClick={handleReset}>
          <FontAwesomeIcon icon={faRedo} /> Yeniden Başlat
        </button>
      </div>
    </div>
  );
};

export default GameControls;
