import React from 'react';
import '../../../assets/home/MesafeliSatisSozlesmesi.css';
import Header from '../Header';
import Footer from '../Footer';

const MesafeliSatisSozlesmesi = () => {
  return (
    <div>
      <Header />
    <div className="sozlesme-container">
      <h1 className="title">MESAFELİ SATIŞ SÖZLEŞMESİ</h1>

      <section className="section">
        <h2>1. Taraflar</h2>
        <div className="taraflar">
          <div className="taraf">
            <h3>Satıcı:</h3>
            <ul>
              <li><strong>Firma Adı:</strong> Okumaks</li>
              <li><strong>Adres:</strong> Yavuz Selim Mah. 560 Cadde No:2 Eryaman Etimesgut, Ankara</li>
              <li><strong>Telefon:</strong> 0530 303 35 39</li>
              <li><strong>E-posta:</strong> [Firma E-posta Adresi]</li>
              <li><strong>Mersis No:</strong> [Mersis Numarası]</li>
            </ul>
          </div>
          <div className="taraf">
            <h3>Alıcı:</h3>
            <ul>
              <li><strong>Ad Soyad:</strong> [Alıcı Adı Soyadı]</li>
              <li><strong>Adres:</strong> [Alıcı Adresi]</li>
              <li><strong>Telefon:</strong> [Alıcı Telefonu]</li>
              <li><strong>E-posta:</strong> [Alıcı E-posta Adresi]</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="section">
        <h2>2. Sözleşmenin Konusu</h2>
        <p>
          İşbu sözleşme, Satıcı'nın elektronik ortamda sunduğu ürün veya hizmetlerin Alıcı tarafından satın alınması ve satışı ile ilgili olarak tarafların hak ve yükümlülüklerini düzenler.
        </p>
      </section>

      <section className="section">
        <h2>3. Ürün/Hizmet Bilgileri</h2>
        <ul>
          <li><strong>Ürün/Hizmet Adı:</strong> [Ürün veya Hizmetin Adı]</li>
          <li><strong>Açıklama:</strong> [Ürün veya Hizmetin Detaylı Açıklaması]</li>
          <li><strong>Birim Fiyatı:</strong> [Ürün Birim Fiyatı]</li>
          <li><strong>Adet:</strong> [Satın Alınacak Adet]</li>
          <li><strong>Toplam Tutar:</strong> [Toplam Tutar]</li>
        </ul>
      </section>

      <section className="section">
        <h2>4. Ödeme Şartları</h2>
        <p>
          Alıcı, siparişini onayladıktan sonra aşağıdaki ödeme yöntemlerinden birini kullanarak ödemeyi gerçekleştirecektir:
        </p>
        <ul>
          <li>Kredi/Banka Kartı</li>
          <li>Havale/EFT</li>
          <li>Kapıda Ödeme</li>
        </ul>
      </section>

      <section className="section">
        <h2>5. Teslimat Şartları</h2>
        <ul>
          <li><strong>Teslimat Süresi:</strong> [Teslimat Süresi]</li>
          <li><strong>Teslimat Adresi:</strong> Alıcının belirttiği adres</li>
          <li><strong>Kargo Ücreti:</strong> [Kargo Ücreti Bilgisi]</li>
        </ul>
      </section>

      <section className="section">
        <h2>6. Cayma Hakkı</h2>
        <p>
          Alıcı, ürünü teslim aldığı tarihten itibaren 14 gün içinde herhangi bir gerekçe göstermeksizin sözleşmeden cayma hakkına sahiptir. Cayma talebi, yazılı olarak veya elektronik ortamda Satıcı'ya bildirilmelidir.
        </p>
      </section>

      <section className="section">
        <h2>7. İade ve Değişim Şartları</h2>
        <ul>
          <li><strong>İade Şartları:</strong> İade edilecek ürünün kullanılmamış, paketinin açılmamış ve fatura ile birlikte iade edilmesi gerekmektedir.</li>
          <li><strong>Değişim Şartları:</strong> Üründe üretim hatası veya yanlış gönderim durumunda ücretsiz değişim yapılacaktır.</li>
        </ul>
      </section>

      <section className="section">
        <h2>8. Garanti Şartları</h2>
        <p>
          Satılan ürünler, teslim tarihinden itibaren [Garanti Süresi] ay garanti kapsamındadır. Garanti kapsamında oluşan arızalarda ücretsiz onarım veya değişim sağlanacaktır.
        </p>
      </section>

      <section className="section">
        <h2>9. Sözleşmenin Feshi</h2>
        <p>
          Taraflardan herhangi biri, diğer tarafın sözleşme şartlarına aykırı davranması durumunda yazılı bildirimde bulunarak sözleşmeyi feshedebilir.
        </p>
      </section>

      <section className="section">
        <h2>10. Uyuşmazlıkların Çözümü</h2>
        <p>
          İşbu sözleşmeden doğacak uyuşmazlıklarda İstanbul (Anadolu) Adliyesi Mahkemeleri ve İcra Daireleri yetkilidir. Taraflar arabuluculuk yolunu tercih edebilirler.
        </p>
      </section>

      <section className="section">
        <h2>11. Yürürlük ve Değişiklikler</h2>
        <p>
          Bu sözleşme, Alıcı tarafından sipariş onayı verildiği anda yürürlüğe girer. Satıcı, sözleşme hükümlerini güncelleme hakkını saklı tutar. Güncellemeler, elektronik ortamda ilan edildiği tarihten itibaren geçerli olur.
        </p>
      </section>

      <section className="section">
        <h2>12. Gizlilik Politikası</h2>
        <p>
          Satıcı, Alıcı'nın kişisel verilerini gizlilik politikası kapsamında koruyacaktır. <a href="/gizliliksozlesmesi"target="_blank" rel="noopener noreferrer">Gizlilik Politikası</a>
        </p>
      </section>

      <section className="section">
        <h2>13. İletişim Bilgileri</h2>
        <p>
          Herhangi bir soru, talep veya şikayet için aşağıdaki iletişim kanallarını kullanabilirsiniz:
        </p>
        <ul>
          <li><strong>E-posta:</strong> [Firma E-posta Adresi]</li>
          <li><strong>Telefon:</strong> 0530 303 35 39</li>
          <li><strong>Adres:</strong> Yavuz Selim Mah. 560 Cadde No:2 Eryaman Etimesgut, Ankara</li>
        </ul>
      </section>

      <section className="section">
        <h2>14. Yürürlük Tarihi</h2>
        <p>
          Bu sözleşme, [Tarih] tarihinde yürürlüğe girmiştir.
        </p>
      </section>
    </div>
    <Footer />
    </div>
  );
};

export default MesafeliSatisSozlesmesi;
