import React from 'react';
import GameTimer from '../components/GameTimer'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faRedo } from '@fortawesome/free-solid-svg-icons';

const GameControls = ({
  isPlaying,
  gameTime,
  speed,
  setSpeed,
  numberOfBalls,
  setNumberOfBalls,
  difficulty,
  handleDifficultyChange,
  setIsPlaying,
  resetGame,
}) => {
  return (
    <div>
      {/* Oyun Zamanlayıcısı ve Zorluk Seçimi */}
      <div className="row mb-3 align-items-center">
        {/* Oyun Zamanlayıcısı */}
        <div className="col-12 col-md-4 mb-3 mb-md-0">
          <GameTimer time={gameTime} /> 
        </div>

        {/* Zorluk Seviyesi Seçimi */}
        <div className="col-12 col-md-4 mb-3 mb-md-0">
          <div className="input-group">
            <label className="input-group-text" htmlFor="difficultySelect">
              Zorluk
            </label>
            <select
              className="form-select"
              id="difficultySelect"
              value={difficulty}
              onChange={(e) => handleDifficultyChange(e.target.value)}
            >
              <option value="easy">Kolay</option>
              <option value="normal">Normal</option>
              <option value="hard">Zor</option>
            </select>
          </div>
        </div>

        {/* Oyun Kontrol Butonları */}
        <div className="col-12 col-md-4 text-center">
          <div className="btn-group" role="group" aria-label="Oyun Kontrolleri">
            <button
              className={`btn btn-${isPlaying ? 'warning' : 'success'}`}
              onClick={() => setIsPlaying(!isPlaying)}
            >
              <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />{' '}
              {isPlaying ? 'Duraklat' : 'Başlat'}
            </button>
            <button className="btn btn-secondary" onClick={resetGame}>
              <FontAwesomeIcon icon={faRedo} /> Yeniden Başlat
            </button>
          </div>
        </div>
      </div>

      {/* Hız ve Top Sayısı Kontrolleri */}
      <div className="row mb-3 align-items-center">
        {/* Hız Kontrolü */}
        <div className="col-12 col-md-6 mb-3 mb-md-0">
          <div className="card p-2">
            <label htmlFor="speedRange" className="form-label">
              Hız: {speed}
            </label>
            <input
              type="range"
              className="form-range"
              id="speedRange"
              value={speed}
              onChange={(e) => setSpeed(Math.max(1, parseInt(e.target.value)))}
              min="1"
              max="20"
            />
          </div>
        </div>

        {/* Top Sayısı Kontrolü */}
        <div className="col-12 col-md-6">
          <div className="card p-2">
            <label htmlFor="numberOfBalls" className="form-label">
              Top Sayısı: {numberOfBalls}
            </label>
            <input
              type="range"
              className="form-range"
              id="numberOfBalls"
              value={numberOfBalls}
              onChange={(e) => setNumberOfBalls(Math.max(1, Math.min(10, parseInt(e.target.value))))}
              min="1"
              max="10"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameControls;
