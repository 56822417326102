// src/pages/ogrenci/oyunlar/components/Message.js

import React from 'react';

const Message = ({ message, timeRemaining }) => {
  return (
    message && (
      <div className="row mt-3">
        <div className="col-12 text-center">
          <div className={`alert ${timeRemaining === 0 ? 'alert-danger' : 'alert-info'}`} role="alert">
            {message}
          </div>
        </div>
      </div>
    )
  );
};

export default Message;
