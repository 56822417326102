// components/PaymentForm.js

import React, { useState, useEffect } from 'react';
import {Typography, Button, Select, MenuItem, InputLabel, FormControl, Card, CardContent, Grid,} from '@mui/material';
import { API_URLS } from '../../../config/config';

function PaymentForm({ userType, userId }) {
  const [paketSuresi, setPaketSuresi] = useState(3);
  const [toplamTutar, setToplamTutar] = useState(0);
  const [message, setMessage] = useState('');

  // Paket fiyatları
   const prices = {
    3: 750,
    6: 1250,
    12: 2000,
  }; 

  useEffect(() => {
    // Toplam tutarı paket süresine göre ayarla
    setToplamTutar(prices[paketSuresi]);
  }, [paketSuresi]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Ödeme isteği gönder
    try {
      const response = await fetch(API_URLS.STUDENT_PAYMENT_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userType,
          paketSuresi,
          userId,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        // paymentPageUrl'yi al ve kullanıcıyı yönlendir
        window.location.href = data.paymentPageUrl;
       // setMessage('Ödeme başarılı. Hesabınız aktif hale getirildi.');
       //console.log(data);
       
      } else {
        setMessage('Ödeme başlatılamadı: ' + data.message);
      }
    } catch (error) {
      console.error('Ödeme işlemi sırasında bir hata oluştu', error);
      setMessage('Ödeme işlemi sırasında bir hata oluştu.');
    }
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Hesabınızı Aktif Et
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="paket-suresi-label">Paket Süresi</InputLabel>
                <Select
                  labelId="paket-suresi-label"
                  value={paketSuresi}
                  onChange={(e) => setPaketSuresi(e.target.value)}
                  label="Paket Süresi"
                >
                  <MenuItem value={3}>3 Aylık - 750 TL</MenuItem>
                  <MenuItem value={6}>6 Aylık - 1250 TL</MenuItem>
                  <MenuItem value={12}>12 Aylık - 2000 TL</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">Toplam Tutar: {toplamTutar} TL</Typography>
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary" fullWidth>
                Ödeme Yap ve Hesabı Aktif Et
              </Button>
            </Grid>
          </Grid>
        </form>
        {message && (
          <Typography variant="body1" color="error" sx={{ mt: 2 }}>
            {message}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}

export default PaymentForm;
