import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const GameOverModal = ({ displayedWords, enteredWords, onRestart, userData }) => {
  const navigate = useNavigate();

  // Utility function to normalize words
  const normalizeWord = (word) =>
    word
      .toLowerCase()
      .trim()
      .replace(/[^\p{L}\p{N}\s]/gu, ''); // Remove punctuation

  // Normalize both displayed and entered words
  const normalizedDisplayedWords = displayedWords.map(normalizeWord);
  const normalizedEnteredWords = enteredWords.map(normalizeWord);

  // Create sets for efficient lookup
  const displayedWordsSet = new Set(normalizedDisplayedWords);

  // Calculate correct and incorrect words
  const correctWords = normalizedEnteredWords.filter((word) =>
    displayedWordsSet.has(word)
  );
  const incorrectWords = normalizedEnteredWords.filter(
    (word) => !displayedWordsSet.has(word)
  );

  // Giriş yap butonuna tıklandığında login sayfasına yönlendirme fonksiyonu
  const handleLoginRedirect = () => {
    navigate('/login');
  };

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <h2>Oyun Bitti!</h2>

        {/* Kullanıcı giriş yapmışsa doğru ve yanlış kelimeleri göster */}
        {userData ? (
          <>
            <p>Doğru Kelimeler: {correctWords.length}</p>
            <p>Yanlış Kelimeler: {incorrectWords.length}</p>

            <h3>Girdiğiniz Kelimeler:</h3>
            <ul style={{ textAlign: 'left' }}>
              {enteredWords.map((word, index) => (
                <li key={index}>{word}</li>
              ))}
            </ul>

            <button className="btn btn-primary" onClick={onRestart}>
              Yeniden Başlat
            </button>
            <button className="btn btn-primary" onClick={() => navigate(-1)}>
              <FontAwesomeIcon icon={faArrowLeft} /> Geri Dön
            </button>
          </>
        ) : (
          <>
            <p>Skoru ve girdiğiniz kelimeleri görmek için lütfen giriş yapınız.</p>
            <button className="btn btn-secondary" onClick={handleLoginRedirect}>
              Giriş Yapınız
            </button>
            <button className="btn btn-primary" onClick={() => navigate(-1)}>
              <FontAwesomeIcon icon={faArrowLeft} /> Geri Dön
            </button>
          </>
        )}
      </div>

      {/* Stil tanımlamaları */}
      <style jsx>{`
        .modal-backdrop {
          position: fixed;
          top: 0;
          left: 0;
          width: 100vw;
          height: 100vh;
          background: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
        }

        .modal-content {
          background: white;
          padding: 2rem;
          border-radius: 8px;
          text-align: center;
          max-width: 600px;
          width: 100%;
        }

        .btn {
          margin-top: 1rem;
          padding: 0.5rem 2rem;
          font-size: 1rem;
          border: none;
          border-radius: 5px;
          cursor: pointer;
        }

        .btn-primary {
          background-color: #007bff;
          color: white;
        }

        .btn-primary:hover {
          background-color: #0056b3;
        }

        .btn-secondary {
          background-color: #28a745;
          color: white;
        }

        .btn-secondary:hover {
          background-color: #218838;
        }
      `}</style>
    </div>
  );
};

export default GameOverModal;
