import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const GameOverModal = ({ score, onRestart, userData }) => {
  const navigate = useNavigate();

  // Giriş yap butonuna tıklandığında login sayfasına yönlendirme fonksiyonu
  const handleLoginRedirect = () => {
    navigate('/login');
  };

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <h2 className="text-white">Oyun Bitti!</h2>

        {/* Giriş yapıldıysa skoru göster, yapılmadıysa giriş yapma uyarısı ve buton */}
        {userData ? (
          <>
            <p className="text-white">Toplam Skorunuz: {Math.floor(score)}</p>
            <button className="btn btn-primary mt-3" onClick={onRestart}>
              Yeniden Başlat
            </button>
            <button className="btn btn-primary" onClick={() => navigate(-1)}>
              <FontAwesomeIcon icon={faArrowLeft} /> Geri Dön
            </button>
          </>
        ) : (
          <>
            <p className="text-white">Skoru görmek için giriş yapmalısınız.</p>
            <button className="btn btn-secondary mt-3" onClick={handleLoginRedirect}>
              Giriş Yapınız
            </button>
            <button className="btn btn-primary" onClick={() => navigate(-1)}>
              <FontAwesomeIcon icon={faArrowLeft} /> Geri Dön
            </button>
          </>
        )}
      </div>

      {/* Stil tanımlamaları */}
      <style jsx>{`
        .modal-backdrop {
          position: fixed;
          top: 0;
          left: 0;
          width: 100vw;
          height: 100vh;
          background: rgba(0, 0, 0, 0.7);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
        }

        .modal-content {
          background: #2c3e50;
          color: #ecf0f1;
          padding: 2rem;
          border-radius: 8px;
          text-align: center;
          max-width: 400px;
          width: 100%;
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
        }

        .modal-content h2 {
          margin-bottom: 1rem;
        }

        .modal-content p {
          font-size: 1.2rem;
        }

        .btn {
          margin-top: 1rem;
          padding: 0.5rem 2rem;
          font-size: 1rem;
          border: none;
          border-radius: 5px;
          cursor: pointer;
        }

        .btn-primary {
          background-color: #e74c3c;
          color: white;
        }

        .btn-primary:hover {
          background-color: #c0392b;
        }

        .btn-secondary {
          background-color: #3498db;
          color: white;
        }

        .btn-secondary:hover {
          background-color: #2980b9;
        }
      `}</style>
    </div>
  );
};

export default GameOverModal;
