// HeaderBanner.js
import React, { useState } from 'react';
import { Box, Card, InputBase, Paper, IconButton, ThemeProvider, createTheme } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const theme = createTheme({
  palette: {
    primary: {
      main: '#00796B',
    },
    secondary: {
      main: '#4DB6AC',
    },
    background: {
      default: '#ede7f6',
      paper: '#fff',
    },
  
  },
});

const HeaderBanner = () => {
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearch = () => {
    console.log("Arama sorgusu: ", searchQuery);
  };

  return (
    <ThemeProvider theme={theme}  >
 <Box
      sx={{
        width: '100%',
        height: '500px',
        position: 'relative', // Pseudo-element için relative konumlandırma
        overflow: 'hidden', // Bulanıklaştırılmış arka planın taşmasını engellemek için
        boxShadow: '-3px 8px 23px -2px rgba(0,0,0,0.75)',
     
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundImage: 'url("/images/header.webp")',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center -100px',
          filter: 'blur(3px)', // Bulanıklık miktarını ayarlayın
          zIndex: -1, // İçeriğin arkasında kalmasını sağlar
        },
      }}
    >
        <Card
          sx={{
            position: 'absolute',
            top: '40%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '50%',
            width: '80%',
            bgcolor: 'rgba(150, 134, 134, 0.3)',
            backdropFilter: 'blur(3px)',
            borderRadius: '64px',
            padding: '26px',
            boxShadow: '0 3px 5px 2px rgba(0, 0, 0, 0.3)',
            textAlign: 'center',
          }}

        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              padding: theme.spacing(2),
            }}
          >
            <Paper
              component="form"
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: 500,
                borderRadius: '20px',
                boxShadow: '0 3px 5px 2px rgba(0, 0, 0, 0.3)',
                bgcolor: 'rgba(97, 97, 97, 0.9)',
              }}
            >
              <InputBase
                sx={{
                  ml: 1,
                  flex: 1,
                  color: 'white',
                  '& .MuiInputBase-input': {
                    color: 'white',
                  }
                }}
                placeholder="Arama yap..."
                inputProps={{ 'aria-label': 'search' }}
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <IconButton
                type="submit"
                sx={{ p: '10px', color: 'white' }}
                aria-label="search"
                onClick={handleSearch}
              >
                <SearchIcon />
              </IconButton>
            </Paper>
          </Box>
        </Card>
      </Box>
    </ThemeProvider>
  );
};

export default HeaderBanner;
