import React, { useState, useEffect } from 'react';
import {Container, Typography, Card, CardContent, CardActionArea, Grid, Chip, Box, CircularProgress, Paper, Stack, } from '@mui/material';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import TimerIcon from '@mui/icons-material/Timer';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import Header from '../home/components/Header';
import Footer from '../home/components/Footer';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_URLS } from '../config/config';
import useFetchStudentDataForHome from '../hooks/ogrenci/useFetchStudentDataForHome';

function Testler() {
  const [userData, setUserData] = useState(null);
  useFetchStudentDataForHome(setUserData);
  const [difficulty, setDifficulty] = useState('easy');
  const [books, setBooks] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBooks = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem('userToken');
        const response = await axios.get(`${API_URLS.TESTLER_URL}test/${difficulty}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setBooks(response.data);
      } catch (error) {
        console.error('Kitaplar çekilirken bir hata oluştu:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBooks();
  }, [difficulty]);

  const handleDifficultyChange = (newDifficulty) => {
    setDifficulty(newDifficulty);
  };

  const truncateContent = (content, maxLength) => {
    return content.length > maxLength ? `${content.substring(0, maxLength)}...` : content;
  };

  const handleBookClick = (bookId) => {
    navigate(`/testsayfasi/${bookId}`);
  };

  const difficultyData = {
    easy: {
      color: 'linear-gradient(45deg, #43cea2, #185a9d)', // Yeşil-mavi gradyan
      label: 'Kolay',
      icon: <LightbulbIcon sx={{ fontSize: 60, color: '#fff' }} />,
    },
    medium: {
      color: 'linear-gradient(45deg, #ff9966, #ff5e62)', // Turuncu-kırmızı gradyan
      label: 'Orta',
      icon: <TimerIcon sx={{ fontSize: 60, color: '#fff' }} />,
    },
    hard: {
      color: 'linear-gradient(45deg, #8e2de2, #4a00e0)', // Mor gradyan
      label: 'Zor',
      icon: <FitnessCenterIcon sx={{ fontSize: 60, color: '#fff' }} />,
    },
  };

  return (
    <div
      style={{
        backgroundImage: 'url(/images/motivational-bg.jpg)', // Eğitimle ilgili motive edici bir görsel
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh',
      }}
    >
      <Header />
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4, minHeight: '80vh' }}>
        <Typography
          variant="h3"
          align="center"
          gutterBottom
          sx={{
            fontWeight: 'bold',
            color: '#ffffff',
            mb: 4,
            textShadow: '3px 3px 6px rgba(0,0,0,0.6)',
          }}
        >
          Testler
        </Typography>

        <Grid container spacing={3} mb={4} justifyContent="center">
          {['easy', 'medium', 'hard'].map((level) => (
            <Grid item xs={12} sm={4} key={level}>
              <Paper
                elevation={10}
                sx={{
                  padding: 2,
                  textAlign: 'center',
                  background: difficultyData[level].color,
                  cursor: 'pointer',
                  borderRadius: '15px',
                  transform: difficulty === level ? 'scale(1.05)' : 'none',
                  transition: 'transform 0.3s',
                  boxShadow:
                    difficulty === level
                      ? '0 0 20px rgba(255,255,255,0.7)'
                      : '0 0 10px rgba(0,0,0,0.3)',
                }}
                onClick={() => handleDifficultyChange(level)}
              >
                {difficultyData[level].icon}
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 'bold',
                    color: '#fff',
                    mt: 1,
                    textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
                  }}
                >
                  {difficultyData[level].label}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>

        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
            <CircularProgress color="inherit" />
          </Box>
        ) : (
          <Grid container spacing={4}>
            {books.map((book) => (
              <Grid item xs={12} sm={6} md={4} key={book._id}>
                <Card
                  elevation={8}
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '20px',
                    backgroundColor: 'rgba(255,255,255,0.9)',
                    backdropFilter: 'blur(10px)',
                    transition: 'transform 0.3s',
                    '&:hover': {
                      transform: 'scale(1.05)',
                      boxShadow: '0 0 20px rgba(255,255,255,0.7)',
                    },
                  }}
                >
                  <CardActionArea onClick={() => handleBookClick(book._id)} sx={{ flexGrow: 1 }}>
                    <Box
                      sx={{
                        height: 140,
                        background: difficultyData[book.difficulty].color,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: '#fff',
                        borderTopLeftRadius: '20px',
                        borderTopRightRadius: '20px',
                        boxShadow: 'inset 0 -5px 10px rgba(0,0,0,0.3)',
                      }}
                    >
                      <Typography
                        variant="h5"
                        sx={{
                          fontWeight: 'bold',
                          textAlign: 'center',
                          textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
                        }}
                      >
                        {book.title}
                      </Typography>
                    </Box>
                    <CardContent>
                      <Typography variant="body2" color="textPrimary">
                        {truncateContent(book.content, 100)}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardContent sx={{ pt: 0 }}>
                    <Stack direction="row" spacing={1}>
                      <Chip
                        label={difficultyData[book.difficulty].label}
                        sx={{
                          background: difficultyData[book.difficulty].color,
                          color: '#fff',
                          fontWeight: 'bold',
                          boxShadow: '0 0 10px rgba(0,0,0,0.3)',
                        }}
                      />
                      <Chip
                        label={`Test Sayısı: ${book.testCount || 0}`}
                        sx={{
                          backgroundColor: '#ffeb3b',
                          color: '#000',
                          fontWeight: 'bold',
                          boxShadow: '0 0 10px rgba(0,0,0,0.3)',
                        }}
                      />
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </Container>
      <Footer />

      {/* Stil Tanımlamaları */}
      <style jsx>{`
        @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500;700&display=swap');

        body {
          font-family: 'Poppins', sans-serif;
        }

        ::-webkit-scrollbar {
          width: 8px;
        }

        ::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 4px;
        }

        ::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
      `}</style>
    </div>
  );
}

export default Testler;
