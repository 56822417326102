import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, TextField, Button, Typography, Container, CircularProgress, IconButton, InputAdornment } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Header from '../../../home/components/Header';
import Footer from '../../../home/components/Footer';
import ResultDialog from '../../components/Auth/Dialog';
import { API_URLS } from '../../../config/config';

function SchoolLoginForm() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');
  const [dialogSeverity, setDialogSeverity] = useState('error');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
  
    try {
      const response = await fetch( API_URLS.SCHOOL_URL +'login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });
  
      const data = await response.json();
      setLoading(false);
  
      if (response.ok) {
        localStorage.setItem('userToken', data.token);
        navigate('/okul-dashboard');
      } else {
        setDialogSeverity('error');
        setDialogContent(data.message || 'Giriş başarısız. Lütfen bilgilerinizi kontrol ediniz.');
        setDialogOpen(true);
      }
    } catch (error) {
      setLoading(false);
      setDialogSeverity('error');
      setDialogContent('Giriş sırasında bir hata oluştu: ' + error.message);
      setDialogOpen(true);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      <Header />
      <div className='bg-main'>
        <Container maxWidth="sm" sx={{
          display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh',
          '.MuiCard-root': {
            background: 'linear-gradient(145deg, rgba(105,105,105,0.8) 0%, rgba(220,20,60,0.8) 100%)', 
            backdropFilter: 'blur(8px)', 
          }
        }}>
          <Card sx={{
            minWidth: 275, backgroundColor: 'rgba(255, 255, 255, 0.75)', 
            backdropFilter: 'blur(8px)', 
            borderRadius: '16px',
            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
          }}>
            <CardContent sx={{
              display: 'flex', flexDirection: 'column', alignItems: 'center', 
            }}>
              <Typography variant="h5" component="div" sx={{ marginBottom: 2, color: '#fff', fontWeight: 'bold', textShadow: '2px 2px 4px rgba(0,0,0,0.5)', letterSpacing: '0.05rem', fontSize: '1.5rem' }}>
                Okul Giriş Yap
              </Typography>

              <form onSubmit={handleSubmit} style={{ width: '100%' }}> 
                <TextField
                  fullWidth
                  margin="normal"
                  label="Email"
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  sx={{ input: { color: 'white' }, label: { color: 'white' }, '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: 'white' }, '&:hover fieldset': { borderColor: 'lightgray' }, '&.Mui-focused fieldset': { borderColor: 'white' }, } }}
                />

                <TextField
                  fullWidth
                  margin="normal"
                  label="Şifre"
                  type={showPassword ? 'text' : 'password'}
                  variant="outlined"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  sx={{ input: { color: 'white' }, label: { color: 'white' }, '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: 'white' }, '&:hover fieldset': { borderColor: 'lightgray' }, '&.Mui-focused fieldset': { borderColor: 'white' }, } }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2, py: 1.5 }}> 
                  Giriş
                </Button>
                <Button
                  fullWidth
                  sx={{
                    mt: 1,
                    py: 1,
                    color: 'white', 
                    backgroundColor: 'transparent', 
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    },
                  }}
                  onClick={() => navigate('/kurumsal/forgot-password')}
                >
                  Parolamı Unuttum 
                </Button>

              </form>
            </CardContent>
          </Card>
        </Container>
      </div>

      {loading && <CircularProgress />}
      <ResultDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        title={dialogSeverity === 'error' ? "Giriş Başarısız" : "Giriş Başarılı"}
        message={dialogContent}
        severity={dialogSeverity}
      />
      <Footer />
    </div>
  );
}

export default SchoolLoginForm;
