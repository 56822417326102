// src/pages/ogrenci/oyunlar/components/GameStatus.js

import React from 'react';

const GameStatus = ({ timeRemaining, score, difficulty, handleDifficultyChange }) => {
  return (
    <>
      <div className="row mb-3 justify-content-center">
        <div className="col-12 col-md-4 text-center">
          <div className="d-inline-block px-4 py-2 bg-light border rounded">
            <label htmlFor="timeRemaining" className="me-2 fw-bold">
              Kalan Süre:
            </label>
            <span className="fw-bold text-danger" style={{ fontSize: '1.5rem' }}>
              {timeRemaining} sn
            </span>
          </div>
        </div>
        <div className="col-12 col-md-4 text-center">
          <div className="d-inline-block px-4 py-2 bg-light border rounded">
            <label htmlFor="score" className="me-2 fw-bold">
              Skor:
            </label>
            <span className="fw-bold text-primary" style={{ fontSize: '1.5rem' }}>
              {score}
            </span>
          </div>
        </div>
        <div className="col-12 col-md-4">
          <label htmlFor="difficultySelect" className="form-label">Zorluk Seviyesi:</label>
          <select
            className="form-select"
            id="difficultySelect"
            value={difficulty}
            onChange={handleDifficultyChange}
          >
            <option value="easy">Kolay</option>
            <option value="medium">Orta</option>
            <option value="hard">Zor</option>
          </select>
        </div>
      </div>
    </>
  );
};

export default GameStatus;
