// NesneTakipOyunu.js

import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import GameHeader from './components/GameHeader';
import GameControls from './components/GameControls';
import GameEngineComponent from './components/GameEngineComponent';
import useGameLogic from './hooks/useGameLogic';
import GameOverModal from './components/GameOverModal';
import { API_URLS } from '../../../config/config';

const NesneTakipOyunu = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [userRole, setUserRole] = useState(null); // Kullanıcı rolü eklendi
  const location = useLocation();
  const { stage, exercise } = location.state || {};
  const token = localStorage.getItem('userToken');

  // Kullanıcı verilerini ve rolünü getirme
  useEffect(() => {
    const fetchUserData = async () => {
      if (token) {
        try {
          // Öğrenci verilerini getir
          const studentResponse = await fetch(`${API_URLS.STUDENT_URL}/me`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
          if (studentResponse.ok) {
            const studentData = await studentResponse.json();
            setUserData(studentData);
            setUserRole('student');
            return;
          }
        } catch (error) {
          console.error('Öğrenci verisi alınırken hata oluştu:', error);
        }

        try {
          // Öğretmen verilerini getir
          const teacherResponse = await fetch(`${API_URLS.TEACHER_URL}/me`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
          if (teacherResponse.ok) {
            const teacherData = await teacherResponse.json();
            setUserData(teacherData);
            setUserRole('teacher');
            return;
          }
        } catch (error) {
          console.error('Öğretmen verisi alınırken hata oluştu:', error);
        }

        // Eğer her ikisi de başarısız olursa
        setUserData(null);
        setUserRole(null);
      }
    };

    fetchUserData();
  }, [token]);
 
  const {
    isPlaying,
    setIsPlaying,
    speed,
    setSpeed,
    numberOfBalls,
    setNumberOfBalls,
    entities,
    score,
    setScore,
    gameTime,
    setGameTime,
    difficulty,
    handleDifficultyChange,
    resetGame,
    handleEvent,
    showModal,
    setShowModal,
  } = useGameLogic(userData);

  const [showIntroduction, setShowIntroduction] = useState(true);

  // Oyuna başlama fonksiyonu
  const startGame = () => {
    setShowIntroduction(false);
    setIsPlaying(true);
  };

  // Oyun bittiğinde çağrılacak fonksiyon
  const onGameOver = () => {
    setIsPlaying(false);
    setShowModal(true);
    if (userData) {
      updateProgress();
    }
  };

  const updateProgress = async () => {
    if (!userData || !userRole) return; // Kullanıcı giriş yapmamışsa ilerlemeyi güncelleme
    try {
      const apiUrl =
        userRole === 'student'
          ? `${API_URLS.STUDENT_URL}akillikurs/progress/update-game`
          : `${API_URLS.TEACHER_URL}akillikurs/progress/update-game`;

      await axios.post(
        apiUrl,
        {
          stageNumber: stage?.number || 1,
          gameName: exercise?.gameName || 'Nesne Takip Oyunu',
          score: score,
          time: (userData.status === 'inactive' ? 15 : 60) - gameTime,
          completed: true,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
    } catch (error) {
      console.error('İlerleme güncellenirken hata oluştu:', error);
    }
  };

  useEffect(() => {
    if (gameTime <= 0 && isPlaying) {
      onGameOver();
    }
  }, [gameTime, isPlaying]);

  const handleReturnToPreviousStage = () => {
    if (stage) {
      navigate(
        userRole === 'student' ? '/ogrenci-dashboard' : '/ogretmen-dashboard',
        {
          state: {
            selectedMenu: 'ExerciseList',
            selectedStage: stage,
          },
        }
      );
    } else {
      alert('Aşama bilgisi bulunmamaktadır.');
    }
  };

  return (
    <>
      {/* Giriş ekranı */}
      {showIntroduction && (
        <div className="container-fluid vh-50 d-flex flex-column justify-content-center align-items-center bg-light">
          <div className="row justify-content-center mb-4">
            <div className="col-12 col-md-8 col-lg-6">
              <div className="card shadow border-0 rounded-lg text-center p-4">
                <div className="card-body">
                  <GameHeader
                    handleReturnToPreviousStage={handleReturnToPreviousStage}
                  />
                  <h1 className="display-5 mb-4 fw-bold text-dark">
                    Nesne Takip Oyunu
                  </h1>
                  <p className="lead mb-4 text-muted">
                    Bu oyunda amacınız, ekranda rastgele hareket eden topları
                    gözlerinizle takip etmektir. Toplar hızlıca hareket ederken
                    doğru olanı takip ettiğinizden emin olun ve süreniz dolmadan
                    ne kadar iyi takip edebildiğinizi test edin.
                  </p>
                  <h3 className="mb-4 text-secondary">Nasıl Oynanır?</h3>
                  <ul className="text-start d-inline-block fs-6 text-dark">
                    <li className="mb-2">
                      <strong>1. Topları Takip Edin:</strong> Hedef topu
                      gözünüzle izleyin.
                    </li>
                    <li className="mb-2">
                      <strong>2. Zaman Dolmadan:</strong> Takip ettiğiniz topun
                      pozisyonunu aklınızda tutun.
                    </li>
                    <li>
                      <strong>3. Zorluk Seviyesi:</strong> Hız ve top sayısını
                      artırarak zorluğu artırın.
                    </li>
                  </ul>
                  <button
                    className="btn btn-primary mt-4 px-4 py-2"
                    onClick={startGame}
                    style={{ fontSize: '1rem', fontWeight: '600' }}
                  >
                    Oyuna Başla
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Oyun ekranı */}
      {!showIntroduction && (
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-6">
              <div className="card shadow border-0 rounded-lg text-center p-4">
                <div className="card-body">
                  <GameHeader
                    handleReturnToPreviousStage={handleReturnToPreviousStage}
                  />
                  <GameControls
                    isPlaying={isPlaying}
                    gameTime={gameTime}
                    speed={speed}
                    setSpeed={setSpeed}
                    numberOfBalls={numberOfBalls}
                    setNumberOfBalls={setNumberOfBalls}
                    difficulty={difficulty}
                    handleDifficultyChange={handleDifficultyChange}
                    setIsPlaying={setIsPlaying}
                    resetGame={resetGame}
                  />
                  <GameEngineComponent
                    entities={entities}
                    isPlaying={isPlaying}
                    handleEvent={handleEvent}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showModal && (
        <GameOverModal
          score={score}
          onRestart={resetGame}
          userData={userData}
        />
      )}

      {/* Stil tanımlamaları */}
      <style jsx>{`
        .container-fluid {
          background-color: #f0f8ff;
        }
        .card {
          background-color: white;
          border-radius: 12px;
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        }
        .btn-primary {
          background-color: #28a745;
          border: none;
          transition: background-color 0.3s ease;
        }
        .btn-primary:hover {
          background-color: #218838;
        }
        @media (max-width: 768px) {
          .card-body {
            padding: 1rem;
          }
          .btn-group {
            flex-wrap: wrap;
            justify-content: center;
          }
          .form-range {
            width: 100%;
          }
          .nesne-game-container {
            width: 100%;
            transform: scale(0.9);
            transform-origin: top center;
          }
        }
      `}</style>
    </>
  );
};

export default NesneTakipOyunu;
