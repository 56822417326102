import React from 'react';

const GameDisplay = ({
  paragraph,
  getParagraphWithBlanks,
  options,
  selectedWord,
  userSelections, // Burada ekledik
  handleWordSelect,
  handleBlankClick,
}) => {
  return (
    <>
      <div className="row mt-3">
        <div className="col-12">
          <h5 style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>Seçenekler:</h5>
          <div className="options-container sticky-options">
            {options.map((word, index) => (
              <button
                key={index}
                className={`btn ${
                  selectedWord === word ? 'btn-primary' : 'btn-outline-primary'
                } m-1`}
                onClick={() => handleWordSelect(word)}
                style={{ fontSize: '1.25rem', fontWeight: 'bold' }}
                disabled={
                  // Eğer kelime doğru şekilde yerleştirilmişse seçenekten kaldır
                  Object.values(userSelections.filledWords).some(
                    (filled) => filled.word === word && filled.isCorrect
                  )
                }
              >
                {word}
              </button>
            ))}
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-12">
          <div className="kelime-bulma-tamamlam-game-container">
            <p>{paragraph ? getParagraphWithBlanks() : 'Paragraf yükleniyor...'}</p>
          </div>
        </div>
      </div>

      {/* Stil tanımlamaları */}
      <style jsx>{`
        .options-container {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
          justify-content: center;
        }

        .sticky-options {
          position: sticky;
          top: 0;
          background-color: white;
          z-index: 10;
          padding: 10px 0;
        }

        .blank-button {
          background-color: #ffff99;
          padding: 10px 15px;
          border: none;
          border-bottom: 2px solid #cccc00;
          cursor: pointer;
          margin: 0 2px;
          min-width: 60px;
          text-align: center;
          color: #000000;
          font-weight: bold;
          font-size: 1.4rem;
          white-space: normal;
          word-break: break-word;
          height: auto;
          line-height: 1.2;
          border-radius: 4px;
        }

        .blank-button:hover {
          background-color: #ffeb3b;
          color: #000;
        }

        .blank-button.incorrect {
          background-color: #ffcdd2;
          color: #c62828;
        }

        .blank-button.correct {
          background-color: #c8e6c9;
          color: #2e7d32;
          cursor: default;
        }
      `}</style>
    </>
  );
};

export default GameDisplay;
