import React from 'react';
import { Route, Routes } from 'react-router-dom';
import TestSayfasi from '../pages/Tests/TestSayfasi';
import SeviyeTespitSinavi from '../pages/Tests/SeviyeTespitSinav';


function TestRouter() {
    return (
    <Routes>
    <Route path="/testsayfasi/:bookId" element={<TestSayfasi />} />
    <Route path="/seviye-tespit-sinav" element={<SeviyeTespitSinavi />} />    
    </Routes>
    );

}
  
export default TestRouter;