// home header

import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  Box,
  Menu,
  MenuItem,
  Avatar,
} from '@mui/material';
import {
  Menu as MenuIcon,
  Home as HomeIcon,
  Speed as SpeedIcon,
  FitnessCenter as FitnessCenterIcon,
  Assignment as AssignmentIcon,
  TrendingUp as TrendingUpIcon,
  Login as LoginIcon,
  PersonAdd as PersonAddIcon,
  AccountCircle as AccountCircleIcon,
  Settings as SettingsIcon,
  Close as CloseIcon,
  Logout as LogoutIcon,
  School as SchoolIcon, // Yeni İkon for Akıllı Kurs
} from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import { API_URLS } from '../../config/config';
import useFetchStudentDataForHome from '../../hooks/ogrenci/useFetchStudentDataForHome';

// Yeni Logo İçe Aktarma
import Logo from '../../assets/images/logo/120.png';

// Styled Components
const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: 'linear-gradient(135deg, #0A192F 0%, #203A43 25%, #2C5364 50%, #47688E 75%, #6F9AB8 100%)',
  color: '#FFFFFF',
  transition: 'background 0.5s',
  backdropFilter: 'blur(10px)', // Eğer isterseniz benzer bir efekt ekleyebilirsiniz
}));

const AnimatedIconButton = styled(IconButton)(({ theme }) => ({
  color: '#FFFFFF',
  '&:hover': {
    color: '#FFD700',
    transform: 'scale(1.1)',
    transition: 'transform 0.3s, color 0.3s',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: '#FFFFFF',
  fontWeight: 'bold',
  margin: theme.spacing(1),
  fontFamily: 'Roboto, sans-serif',
  '&:hover': {
    color: '#FFD700',
    transform: 'scale(1.05)',
    transition: 'transform 0.3s, color 0.3s',
  },
}));

// Logo İçin Styled Component (Boyutları Artırıldı)
const LogoImage = styled('img')(({ theme }) => ({
  width: '200px', // Genişliği artırdık
  height: 'auto',
  [theme.breakpoints.down('sm')]: {
    width: '150px', // Mobil cihazlar için biraz daha küçültme
  },
  [theme.breakpoints.down('xs')]: {
    width: '120px', // Çok küçük ekranlar için
  },
}));

// Logo Bölgesi için Yeni Styled Component
const LogoContainer = styled(Box)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.1)', // Açık bir arka plan rengi (yarı saydam beyaz)
  padding: theme.spacing(1, 2),
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  // Gölge ekleyerek logo bölgesini biraz daha belirgin hale getirebilirsiniz
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
}));

function Header() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [userData, setUserData] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  useFetchStudentDataForHome(setUserData);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      await fetch(API_URLS.STUDENT_URL + 'logout', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'Content-Type': 'application/json',
        },
      });
      localStorage.removeItem('userToken');
      navigate('/ogrenci-giris');
    } catch (error) {
      console.error('Çıkış işlemi sırasında bir hata oluştu', error);
    }
  };

  // Function to navigate to the Akıllı Kurs page
  const navigateToAkilliKurs = () => {
    navigate('/ogrenci-dashboard', { state: { selectedMenu: 'AkilliKurs' } });
  };

  return (
    <StyledAppBar position="sticky">
      <Toolbar>
        {isMobile && (
          <AnimatedIconButton edge="start" aria-label="menu" onClick={handleDrawerToggle}>
            <MenuIcon />
          </AnimatedIconButton>
        )}
        {/* Logo Ekleme */}
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: isMobile ? 'center' : 'flex-start',
            alignItems: 'center',
          }}
        >
          <LogoContainer>
            <Link to="/home">
              <LogoImage src={Logo} alt="Okumaks Logo" />
            </Link>
          </LogoContainer>
        </Box>
        {!isMobile ? (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <StyledButton component={Link} to="/home" startIcon={<HomeIcon />}>
              Anasayfa
            </StyledButton>
            <StyledButton component={Link} to="/hizlioku" startIcon={<SpeedIcon />}>
              Hızlı Oku
            </StyledButton>
            <StyledButton component={Link} to="/egsersizler" startIcon={<FitnessCenterIcon />}>
              Egzersizler
            </StyledButton>
            <StyledButton component={Link} to="/testler" startIcon={<AssignmentIcon />}>
              Testler
            </StyledButton>
            <StyledButton component={Link} to="/seviye-tespit-sinav" startIcon={<TrendingUpIcon />}>
              Hız Testi
            </StyledButton>
            {userData && (
              <StyledButton onClick={navigateToAkilliKurs} startIcon={<SchoolIcon />}>
                Akıllı Kurs
              </StyledButton>
            )}
            {!userData ? (
              <>
                <StyledButton component={Link} to="/login" startIcon={<LoginIcon />}>
                  Giriş Yap
                </StyledButton>
                <StyledButton component={Link} to="/register" startIcon={<PersonAddIcon />}>
                  Kayıt Ol
                </StyledButton>
              </>
            ) : (
              <AnimatedIconButton onClick={handleMenuOpen}>
                <AccountCircleIcon sx={{ fontSize: 40 }} />
              </AnimatedIconButton>
            )}
          </Box>
        ) : (
          <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerToggle}>
            <Box sx={{ width: 250 }}>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <Avatar sx={{ bgcolor: 'transparent' }}>
                      <CloseIcon onClick={handleDrawerToggle} />
                    </Avatar>
                  </ListItemIcon>
                </ListItem>
                <ListItem button component={Link} to="/home" onClick={handleDrawerToggle}>
                  <ListItemIcon>
                    <HomeIcon />
                  </ListItemIcon>
                  <ListItemText primary="Anasayfa" />
                </ListItem>
                <ListItem button component={Link} to="/hizlioku" onClick={handleDrawerToggle}>
                  <ListItemIcon>
                    <SpeedIcon /> {/* Yeni İkon */}
                  </ListItemIcon>
                  <ListItemText primary="Hızlı Oku" />
                </ListItem>
                <ListItem button component={Link} to="/egsersizler" onClick={handleDrawerToggle}>
                  <ListItemIcon>
                    <FitnessCenterIcon /> {/* Yeni İkon */}
                  </ListItemIcon>
                  <ListItemText primary="Egzersizler" />
                </ListItem>
                <ListItem button component={Link} to="/testler" onClick={handleDrawerToggle}>
                  <ListItemIcon>
                    <AssignmentIcon /> {/* Yeni İkon */}
                  </ListItemIcon>
                  <ListItemText primary="Testler" />
                </ListItem>
                <ListItem button component={Link} to="/seviye-tespit-sinav" onClick={handleDrawerToggle}>
                  <ListItemIcon>
                    <TrendingUpIcon /> {/* Yeni İkon */}
                  </ListItemIcon>
                  <ListItemText primary="Hız Testi" />
                </ListItem>
                {userData && (
                  <ListItem button onClick={() => { navigateToAkilliKurs(); handleDrawerToggle(); }}>
                    <ListItemIcon>
                      <SchoolIcon />
                    </ListItemIcon>
                    <ListItemText primary="Akıllı Kurs" />
                  </ListItem>
                )}
                {!userData ? (
                  <>
                    <ListItem button component={Link} to="/login" onClick={handleDrawerToggle}>
                      <ListItemIcon>
                        <LoginIcon />
                      </ListItemIcon>
                      <ListItemText primary="Giriş Yap" />
                    </ListItem>
                    <ListItem button component={Link} to="/register" onClick={handleDrawerToggle}>
                      <ListItemIcon>
                        <PersonAddIcon />
                      </ListItemIcon>
                      <ListItemText primary="Kayıt Ol" />
                    </ListItem>
                  </>
                ) : (
                  <>
                    <ListItem button component={Link} to="/ogrenci-dashboard" onClick={handleDrawerToggle}>
                      <ListItemIcon>
                        <AccountCircleIcon /> {/* Yeni İkon */}
                      </ListItemIcon>
                      <ListItemText primary="Profilim/Ayarlar" />
                    </ListItem>
                    <ListItem button onClick={handleLogout}>
                      <ListItemIcon>
                        <LogoutIcon />
                      </ListItemIcon>
                      <ListItemText primary="Çıkış Yap" />
                    </ListItem>
                  </>
                )}
              </List>
            </Box>
          </Drawer>
        )}
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
          <MenuItem component={Link} to="/ogrenci-dashboard" onClick={handleMenuClose}>
            Profilim/Ayarlar
          </MenuItem>
          <MenuItem onClick={handleLogout}>Çıkış Yap</MenuItem>
        </Menu>
      </Toolbar>
    </StyledAppBar>
  );
}

export default Header;
