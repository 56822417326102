// src/pages/ogrenci/oyunlar/components/TimerDisplay.js

import React from 'react';

const TimerDisplay = ({ timeRemaining }) => {
  const formatTime = (seconds) => {
    if (seconds < 0) seconds = 0;
    const minutes = Math.floor(seconds / 60).toString().padStart(2, '0');
    const remainingSeconds = (seconds % 60).toString().padStart(2, '0');
    return `${minutes}:${remainingSeconds}`;
  };

  return (
    <div className="timer-display">
      <div className="d-inline-block px-4 py-2 bg-light border rounded">
        <label className="me-2 fw-bold">Kalan Süre:</label>
        <span className="fw-bold text-danger" style={{ fontSize: '1.5rem' }}>
          {formatTime(timeRemaining)}
        </span>
      </div>
    </div>
  );
};

export default TimerDisplay;
