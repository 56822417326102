import React from 'react';
import { Route, Routes } from 'react-router-dom';
import NesneTakip from '../pages/Egzersizler/NesneTakipOyunu/GameSetup';
import ZigzagTracker from '../pages/Egzersizler/ZigzagTakipOyunu/GameSetup';
import RotatingDiamondGame from '../pages/Egzersizler/RotatingDiamondGame/GameSetup';
import HangiSayiGordun from '../pages/Egzersizler/HangiSayiGordun/GameSetup';
import HangiHarfiGordun from '../pages/Egzersizler/HangiHarfiGordun/GameSetup';


function EgzersizRouter() {
    return (
   
      <Routes>      

          <Route path="/nesne-takip" element={<NesneTakip />} />
          <Route path="/zigzag" element={<ZigzagTracker />} />  
          <Route path="/harf-dongusu" element={<RotatingDiamondGame />} />
          <Route path="/sayi-tahmin-et" element={<HangiSayiGordun />} />
          <Route path="/gordugun-harfi-tahmin-et" element={<HangiHarfiGordun />} />          
      
      </Routes>
    );
  }
  
  export default EgzersizRouter;