import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../home/containers/Home';
import MainLogin from '../home/containers/MainLogin';
import MainRegister from '../home/containers/MainRegister';
import HizliOku from '../pages/HizliOku';
import Egzersiz from '../pages/Egzersiz';
import Testler from '../pages/Testler';
import AboutUs from '../home/components/sirketimiz/AboutUs';
import ContactUs from '../home/components/sirketimiz/ContactUs';
import PrivacyPolicy from '../home/components/sirketimiz/PrivacyPolicy';
import CancellationRefundForm from '../home/components/sirketimiz/CancellationRefundForm';
import MesafeliSatisSozlesmesi from '../home/components/sirketimiz/MesafeliSatisSozlesmesi';
import TermsOfUse from '../home/components/sirketimiz/TermsOfUse';


function HomeRouter() {
    return (
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/login" element={<MainLogin />} />
          <Route path="/register" element={<MainRegister />} />
          <Route path="/hizlioku" element={<HizliOku />} />
          <Route path="/egsersizler" element={<Egzersiz />} />
          <Route path="/testler" element={<Testler />} />         
          <Route path="/hakkimizda" element={<AboutUs />} />         
          <Route path="/iletisim" element={<ContactUs />} />         
          <Route path="/gizliliksozlesmesi" element={<PrivacyPolicy />} />         
          <Route path="/iptalveiadekosullari" element={<CancellationRefundForm />} />         
          <Route path="/mesafelisatissozlesmesi" element={<MesafeliSatisSozlesmesi />} />         
          <Route path="/termofuse" element={<TermsOfUse />} />    
      
      </Routes>
    );
  }
  
  export default HomeRouter;