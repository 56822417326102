import React, { useState } from 'react';
import { TextField,  Button, FormControl, InputLabel, Select, MenuItem, Typography, Paper,  Radio,  RadioGroup,  FormControlLabel,  Grid, Box} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import ReactQuill from 'react-quill'; // ReactQuill importu
import 'react-quill/dist/quill.snow.css'; // ReactQuill stil dosyası
import useFetchUserData from '../../../../hooks/okul/useFetchUserData';
import { API_URLS } from '../../../../config/config';
import ResultDialog from '../../../../pages/components/Auth/Dialog';

function BookUploadForm() {
    const [userData, setUserData] = useState(null);
    const [bookTitle, setBookTitle] = useState('');
    const [difficulty, setDifficulty] = useState('');
    const [file, setFile] = useState(null);
    const [content, setContent] = useState('');
    const [uploadType, setUploadType] = useState('file');
    const [dialogContent, setDialogContent] = useState('');
    const [dialogSeverity, setDialogSeverity] = useState('info');
    const [eklemeBasariliDialogOpen, setEklemeBasariliDialogOpen] = useState(false);

    useFetchUserData(setUserData);

    const handleTitleChange = (event) => {
        setBookTitle(event.target.value);
    };

    const handleDifficultyChange = (event) => {
        setDifficulty(event.target.value);
    };

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleContentChange = (value) => {
        setContent(value);
    };

    const handleUploadTypeChange = (event) => {
        setUploadType(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        let missingFields = [];

        if (!bookTitle) missingFields.push('Kitap Adı');
        if (!difficulty) missingFields.push('Zorluk Seviyesi');
        if (uploadType === 'file' ? !file : !content) missingFields.push('İçerik');
        if (!userData?._id) missingFields.push('Admin ID');

        if (missingFields.length > 0) {
            setDialogContent(`Lütfen ${missingFields.join(', ')} alanlarını doldurunuz.`);
            setDialogSeverity('error');
            setEklemeBasariliDialogOpen(true);
            return;
        }

        const headers = {};
        let bodyData;

        if (uploadType === 'file') {
            const formData = new FormData();
            formData.append('title', bookTitle);
            formData.append('difficulty', difficulty);
            formData.append('adminId', userData?._id);
            formData.append('file', file);
            bodyData = formData;

        } else {
            // For text content, send as JSON
            headers['Content-Type'] = 'application/json';
            bodyData = JSON.stringify({
                title: bookTitle,
                difficulty: difficulty,
                content: content,
                okulId: userData?._id
            });
        }

        try {
            const response = await fetch(`${API_URLS.SCHOOL_URL}kutuphane`, {
                method: 'POST',
                headers: headers,
                body: bodyData,
            });

            const responseData = await response.json();
            if (response.ok) {
                setDialogSeverity('success');
                setDialogContent('Yeni Kitap başarıyla eklendi.');
                setEklemeBasariliDialogOpen(true);
                setBookTitle('');
                setDifficulty('');
                setContent('');
                setFile(null);
            } else {
                throw new Error(responseData.message || 'Kitap eklenirken bir hata oluştu');
            }
        } catch (err) {
            setDialogSeverity('error');
            setDialogContent(err.message);
            setEklemeBasariliDialogOpen(true);
        }
    };

    return (
        <Paper elevation={3} sx={{ padding: 4, maxWidth: 800, margin: 'auto', mt: 5 }}>
            <Typography variant="h4" component="h1" gutterBottom align="center">
                Kitap Ekle
            </Typography>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="title"
                            label="Kitap Adı"
                            name="title"
                            value={bookTitle}
                            onChange={handleTitleChange}
                            autoFocus
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant="outlined" fullWidth required>
                            <InputLabel id="difficulty-label">Zorluk Seviyesi</InputLabel>
                            <Select
                                labelId="difficulty-label"
                                id="difficulty"
                                value={difficulty}
                                label="Zorluk Seviyesi"
                                onChange={handleDifficultyChange}
                            >
                                <MenuItem value="easy">Kolay</MenuItem>
                                <MenuItem value="medium">Orta</MenuItem>
                                <MenuItem value="hard">Zor</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl component="fieldset" fullWidth>
                            <RadioGroup row aria-label="uploadType" name="uploadType" value={uploadType} onChange={handleUploadTypeChange}>
                                <FormControlLabel value="file" control={<Radio color="primary" />} label="Dosya Yükle" />
                                <FormControlLabel value="text" control={<Radio color="primary" />} label="Metin Gir" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    {uploadType === 'file' ? (
                        <Grid item xs={12}>
                            <Box display="flex" alignItems="center">
                                <input
                                    accept=".txt, .docx"
                                    style={{ display: 'none' }}
                                    id="file-upload"
                                    type="file"
                                    onChange={handleFileChange}
                                />
                                <label htmlFor="file-upload">
                                    <Button variant="contained" color="primary" component="span">
                                        Dosya Yükle
                                    </Button>
                                </label>
                                {file && (
                                    <Typography variant="body2" sx={{ ml: 2 }}>
                                        {file.name}
                                    </Typography>
                                )}
                            </Box>
                        </Grid>
                    ) : (
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <ReactQuill
                                    theme="snow"
                                    value={content}
                                    onChange={handleContentChange}
                                    placeholder="Metin içeriğinizi buraya yazın..."
                                    modules={{
                                        toolbar: [
                                            [{ 'header': [1, 2, 3, false] }],
                                            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                            ['link', 'image'],
                                            ['clean']
                                        ],
                                    }}
                                    formats={[
                                        'header',
                                        'bold', 'italic', 'underline', 'strike', 'blockquote',
                                        'list', 'bullet',
                                        'link', 'image'
                                    ]}
                                    style={{ height: '200px', marginBottom: '50px' }}
                                />
                            </FormControl>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            size="large"
                            sx={{ py: 1.5 }}
                            endIcon={<SendIcon />} // İsteğe bağlı ikon ekleme
                        >
                            Kitap Ekle
                        </Button>
                    </Grid>
                </Grid>
            </form>

            <ResultDialog
                open={eklemeBasariliDialogOpen}
                onClose={() => setEklemeBasariliDialogOpen(false)}
                title={dialogSeverity === 'error' ? "Hata Oluştu" : "İşlem Başarılı"}
                message={dialogContent}
                severity={dialogSeverity}
            />
        </Paper>
    );
}

export default BookUploadForm;
