import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { useKelimeTamamlamaOyunu } from './hooks/useKelimeTamamlamaOyunu';
import Header from './components/Header';
import Controls from './components/Controls';
import GameDisplay from './components/GameDisplay';
import Message from './components/Message';
import Description from './components/Description';
import GameOverModal from './components/GameOverModal';
import './styles/style.css';
import axios from 'axios';
import { API_URLS } from '../../../config/config';

const KelimeTamamlamaOyunu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { stage, exercise } = location.state || {};

  const token = localStorage.getItem('userToken');

  const [userData, setUserData] = useState(null);
  const [userRole, setUserRole] = useState(null);

  const [showInstructions, setShowInstructions] = useState(true);
  const [showGameOverModal, setShowGameOverModal] = useState(false);

  // Kullanıcı verilerini ve rolünü getirme
  useEffect(() => {
    const fetchUserData = async () => {
      if (token) {
        try {
          // Öğrenci verilerini getir
          const studentResponse = await fetch(`${API_URLS.STUDENT_URL}/me`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
          if (studentResponse.ok) {
            const studentData = await studentResponse.json();
            setUserData(studentData);
            setUserRole('student');
            return;
          }
        } catch (error) {
          console.error('Öğrenci verisi alınırken hata oluştu:', error);
        }

        try {
          // Öğretmen verilerini getir
          const teacherResponse = await fetch(`${API_URLS.TEACHER_URL}/me`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
          if (teacherResponse.ok) {
            const teacherData = await teacherResponse.json();
            setUserData(teacherData);
            setUserRole('teacher');
            return;
          }
        } catch (error) {
          console.error('Öğretmen verisi alınırken hata oluştu:', error);
        }

        // Eğer her ikisi de başarısız olursa
        setUserData(null);
        setUserRole(null);
      }
    };

    fetchUserData();
  }, [token]);

  const {
    difficulty,
    paragraphsList,
    selectedParagraphIndex,
    paragraph,
    options,
    userSelections,
    isPlaying,   
    score,
    message,
    handleWordSelect,
    handleBlankClick,
    handleDifficultyChange,
    handleParagraphChange,
    getParagraphWithBlanks,
    timeRemaining,
    handlePlayPause,
    handleReset,
 
  } = useKelimeTamamlamaOyunu(userData);

  const startGame = () => {
    setShowInstructions(false);
   // handlePlayPause();
  };

  const updateProgress = async () => {
    try {
      if (userData && userRole) {
        const apiUrl =
          userRole === 'student'
            ? `${API_URLS.STUDENT_URL}akillikurs/progress/update-game`
            : `${API_URLS.TEACHER_URL}akillikurs/progress/update-game`;

        await axios.post(
          apiUrl,
          {
            stageNumber: stage?.number || 1,
            gameName: exercise?.gameName || 'Kelime Tamamlama Oyunu',
            score: score,
            time: (userData.status === 'inactive' ? 15 : 60) - timeRemaining,
            completed: true,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        console.log('İlerleme başarıyla güncellendi.');
      }
    } catch (error) {
      console.error('İlerleme güncellenirken hata oluştu:', error);
    }
  };

  const onGameOver = () => {
    setShowGameOverModal(true);
    updateProgress();
  };

  const handleRestart = () => {
    setShowGameOverModal(false);
    handleReset();
  };

  // Süre dolduğunda oyunu bitir
  useEffect(() => {
    if (!isPlaying && (timeRemaining === 0 || message === 'Tebrikler! Tüm kelimeleri doldurdunuz.') && !showGameOverModal) {
      onGameOver();
    }
  }, [isPlaying, timeRemaining, message, showGameOverModal]);

  const handleReturnToPreviousStage = () => {
    if (stage) {
      navigate(
        userRole === 'student' ? '/ogrenci-dashboard' : '/ogretmen-dashboard',
        {
          state: {
            selectedMenu: 'ExerciseList',
            selectedStage: stage,
          },
        }
      );
    } else {
      alert('Aşama bilgisi bulunmamaktadır.');
    }
  };



  return (
    <div className="container-fluid px-0" style={{ backgroundColor: '#f0f8ff' }}>
      {showInstructions ? (
        <div className="container-fluid vh-50 d-flex flex-column justify-content-center align-items-center bg-light">
          <div className="row justify-content-center mb-4">
            <div className="col-12 col-md-8 col-lg-6">
              <div className="card shadow border-0 rounded-lg text-center p-4">
                <div className="card-body">
                  <Description />
                  <button
                    className="btn btn-primary mt-4 px-4 py-2"
                    onClick={startGame}
                    style={{ fontSize: '1rem', fontWeight: '600' }}
                  >
                    Oyuna Başla
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="row g-0 justify-content-center">
          <div className="col-12 col-md-10 col-lg-8">
            <div className="card shadow-lg mt-4">
              <div className="card-body">
                <Header handleReturnToPreviousStage={handleReturnToPreviousStage} />
                <Controls
                  isPlaying={isPlaying}
                  handlePlayPause={handlePlayPause}
                  handleReset={handleReset}
                  timeRemaining={timeRemaining}
                  difficulty={difficulty}
                  handleDifficultyChange={handleDifficultyChange}
                  selectedParagraphIndex={selectedParagraphIndex}
                  handleParagraphChange={handleParagraphChange}
                  paragraphsList={paragraphsList}
                />
              <GameDisplay
                paragraph={paragraph}
                getParagraphWithBlanks={getParagraphWithBlanks}
                options={options}
                selectedWord={userSelections.selectedWord}
                userSelections={userSelections} // Burada ekledik
                handleWordSelect={handleWordSelect}
                handleBlankClick={handleBlankClick}
              />
                <Message message={message} timeRemaining={timeRemaining} />
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Oyun bittiğinde modalı göster */}
      {showGameOverModal && (
       <GameOverModal
       score={score}
       onRestart={handleRestart}
       userData={userData}
     />
      )}
    </div>
  );
};

export default KelimeTamamlamaOyunu;
