import React, { useEffect, useState } from 'react';
import { API_URLS } from '../../../config/config';
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import {
  Card,
  CardContent,
  Typography,
  Tabs,
  Tab,
  Grid,
  Box,
} from '@mui/material';

const ProgressReport = ({ userData }) => {
  const [progressData, setProgressData] = useState(null);
  const [initialTestResult, setInitialTestResult] = useState(null);
  const [selectedStage, setSelectedStage] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('userToken');
        const [progressResponse, initialTestResponse] = await Promise.all([
          fetch(`${API_URLS.STUDENT_URL}akillikurs/progress`, {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }),
          fetch(`${API_URLS.STUDENT_URL}akillikurs/test/initial-test`, {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }),
        ]);

        const progressData = await progressResponse.json();
        setProgressData(progressData.progress);

        if (initialTestResponse.ok) {
          const initialTestData = await initialTestResponse.json();
          setInitialTestResult(initialTestData.testResult);
        } else {
          setInitialTestResult(null);
        }

      } catch (error) {
        console.error('Veriler alınırken bir hata oluştu:', error);
      }
    };

    fetchData();
  }, []);

  if (!progressData) {
    return (
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <Typography variant="h6">İlerleme verileri yükleniyor...</Typography>
      </Box>
    );
  }

  const stages = progressData.stages;

  const prepareChartData = (stageIndex) => {
    const chartData = [];

    const stage = stages[stageIndex];

    if (stage) {
      stage.games.forEach((game) => {
        chartData.push({
          gameName: game.gameName,
          score: game.score || 0,
          time: game.time || 0,
          wordCount: game.wordCount || 0,
          completedAt: game.completedAt
            ? new Date(game.completedAt).toLocaleDateString()
            : 'Tamamlanmadı',
        });
      });
    }

    return chartData;
  };

  const chartData = prepareChartData(selectedStage);

  const handleTabChange = (event, newValue) => {
    setSelectedStage(newValue);
  };

  const prepareOverallChartData = () => {
    const overallChartData = [];

    stages.forEach((stage) => {
      const stageData = {
        stageNumber: `Aşama ${stage.stageNumber}`,
        totalScore: 0,
        totalTime: 0,
        totalWordCount: 0,
      };

      stage.games.forEach((game) => {
        stageData.totalScore += game.score || 0;
        stageData.totalTime += game.time || 0;
        stageData.totalWordCount += game.wordCount || 0;
      });

      overallChartData.push(stageData);
    });

    return overallChartData;
  };

  const overallChartData = prepareOverallChartData();

  // Anlama oranı verilerini hazırlama
  const prepareComprehensionData = () => {
    const data = [];

    // Başlangıç testi için
    if (initialTestResult) {
      data.push({
        name: 'Başlangıç Testi',
        comprehensionRate: 0, // Başlangıç testinde anlama oranı yok
      });
    }

    stages.forEach((stage) => {
      const comprehensionRate =
        stage.testResult && stage.testResult.totalQuestions > 0
          ? (stage.testResult.correctAnswers / stage.testResult.totalQuestions) * 100
          : null;
      data.push({
        name: `Aşama ${stage.stageNumber}`,
        comprehensionRate: comprehensionRate ? parseFloat(comprehensionRate.toFixed(2)) : 0,
      });
    });

    return data;
  };

  const comprehensionData = prepareComprehensionData();

  const formatTime = (totalSeconds) => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes} dakika ${seconds} saniye`;
  };

  return (
    <>
      {/* Anlama Oranı Bölümü */}
      <Box
        sx={{
          padding: { xs: 2, md: 4 },
          backgroundColor: '#fff3e0',
          color: '#000',
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          align="center"
          sx={{
            marginBottom: 4,
            fontWeight: 'bold',
          }}
        >
          Anlama Oranı
        </Typography>

        {/* Anlama Oranı Grafiği */}
        <Card
          sx={{
            boxShadow: 6,
            borderRadius: 3,
            backgroundImage: 'linear-gradient(to right, #f3e5f5, #e1bee7)',
            color: '#000',
            marginBottom: 4,
          }}
        >
          <CardContent>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ fontWeight: 'bold', color: '#000' }}
            >
              Anlama Oranı Grafiği
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart
                data={comprehensionData}
                margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
              >
                <CartesianGrid strokeDasharray="3 3" stroke="#000" />
                <XAxis dataKey="name" stroke="#000" />
                <YAxis stroke="#000" domain={[0, 100]} />
                <Tooltip
                  formatter={(value, name) => [`${value}%`, 'Anlama Oranı']}
                  contentStyle={{
                    backgroundColor: '#f3e5f5',
                    color: '#000',
                    borderRadius: '10px',
                  }}
                />
                <Legend wrapperStyle={{ color: '#000' }} />
                <Line
                  type="monotone"
                  dataKey="comprehensionRate"
                  name="Anlama Oranı (%)"
                  stroke="#8e24aa"
                  strokeWidth={3}
                  activeDot={{ r: 8 }}
                />
              </LineChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>

        {/* Başlangıç Test Sonucu */}
        {initialTestResult ? (
          <Box sx={{ marginBottom: 4 }}>
            <Typography variant="h6" gutterBottom>
              Başlangıç Test Sonucu:
            </Typography>
            <Typography variant="body1">
              Okuduğunuz Kelime Sayısı: {initialTestResult.wordCount}
            </Typography>
            <Typography variant="body1">
              Toplam Süre: {formatTime(initialTestResult.totalTime)}
            </Typography>
            <Typography variant="body1">
              Okuma Hızınız: {initialTestResult.readingSpeed} kelime/dakika
            </Typography>
          </Box>
        ) : (
          <Typography variant="body1">
            Başlangıç test sonucu bulunamadı.
          </Typography>
        )}

        {/* Aşama Test Sonuçları */}
        {stages.map((stage, index) => {
          const comprehensionRate =
            stage.testResult && stage.testResult.totalQuestions > 0
              ? ((stage.testResult.correctAnswers / stage.testResult.totalQuestions) * 100).toFixed(2)
              : null;
          return (
            <Box key={index} sx={{ marginBottom: 4 }}>
              <Typography variant="h6" gutterBottom>
                Aşama {stage.stageNumber} Test Sonucu:
              </Typography>
              {stage.testResult ? (
                <>
                  <Typography variant="body1">
                    Okuduğunuz Kelime Sayısı: {stage.testResult.wordCount}
                  </Typography>
                  <Typography variant="body1">
                    Toplam Süre: {formatTime(stage.testResult.totalTime)}
                  </Typography>
                  <Typography variant="body1">
                    Okuma Hızınız: {stage.testResult.readingSpeed} kelime/dakika
                  </Typography>
                  {comprehensionRate !== null ? (
                    <Typography variant="body1">
                      Anlama Oranı: {comprehensionRate}%
                    </Typography>
                  ) : (
                    <Typography variant="body1">
                      Anlama Oranı hesaplanamadı.
                    </Typography>
                  )}
                </>
              ) : (
                <Typography variant="body1">
                  Test sonucu bulunamadı.
                </Typography>
              )}
            </Box>
          );
        })}
      </Box>

      {/* İlerleme Raporu Bölümü */}
      <Box
        sx={{
          padding: { xs: 2, md: 4 },
          backgroundImage: 'linear-gradient(to right, #00c6ff, #0072ff)',
          minHeight: '100vh',
          color: '#fff',
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          align="center"
          sx={{
            marginBottom: 4,
            fontWeight: 'bold',
            textShadow: '2px 2px #000',
          }}
        >
          İlerleme Raporu
        </Typography>

        {/* Genel Grafikler */}
        <Grid container spacing={4} sx={{ marginBottom: 4 }}>
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                boxShadow: 6,
                borderRadius: 3,
                backgroundImage: 'linear-gradient(to right, #ff7e5f, #feb47b)',
                color: '#fff',
              }}
            >
              <CardContent>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ fontWeight: 'bold', color: '#fff' }}
                >
                  Aşamaların Toplam Skorları
                </Typography>
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart
                    data={overallChartData}
                    margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" stroke="#fff" />
                    <XAxis dataKey="stageNumber" stroke="#fff" />
                    <YAxis stroke="#fff" />
                    <Tooltip
                      formatter={(value, name) => [value, 'Toplam Skor']}
                      contentStyle={{
                        backgroundColor: '#ff7e5f',
                        color: '#fff',
                        borderRadius: '10px',
                      }}
                    />
                    <Legend wrapperStyle={{ color: '#fff' }} />
                    <Bar dataKey="totalScore" name="Toplam Skor" fill="#ffd700" barSize={50} />
                  </BarChart>
                </ResponsiveContainer>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card
              sx={{
                boxShadow: 6,
                borderRadius: 3,
                backgroundImage: 'linear-gradient(to right, #8360c3, #2ebf91)',
                color: '#fff',
              }}
            >
              <CardContent>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ fontWeight: 'bold', color: '#fff' }}
                >
                  Aşamaların Toplam Süreleri
                </Typography>
                <ResponsiveContainer width="100%" height={300}>
                  <LineChart
                    data={overallChartData}
                    margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" stroke="#fff" />
                    <XAxis dataKey="stageNumber" stroke="#fff" />
                    <YAxis stroke="#fff" />
                    <Tooltip
                      formatter={(value, name) => [`${value} saniye`, 'Toplam Süre']}
                      contentStyle={{
                        backgroundColor: '#8360c3',
                        color: '#fff',
                        borderRadius: '10px',
                      }}
                    />
                    <Legend wrapperStyle={{ color: '#fff' }} />
                    <Line
                      type="monotone"
                      dataKey="totalTime"
                      name="Toplam Süre (saniye)"
                      stroke="#ffd700"
                      strokeWidth={3}
                      activeDot={{ r: 8 }}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </CardContent>
            </Card>
          </Grid>

          {overallChartData.some((data) => data.totalWordCount > 0) && (
            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  boxShadow: 6,
                  borderRadius: 3,
                  backgroundImage: 'linear-gradient(to right, #ff512f, #dd2476)',
                  color: '#fff',
                }}
              >
                <CardContent>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ fontWeight: 'bold', color: '#fff' }}
                  >
                    Aşamaların Toplam Kelime Sayıları
                  </Typography>
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart
                      data={overallChartData}
                      margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" stroke="#fff" />
                      <XAxis dataKey="stageNumber" stroke="#fff" />
                      <YAxis stroke="#fff" />
                      <Tooltip
                        formatter={(value, name) => [value, 'Toplam Kelime Sayısı']}
                        contentStyle={{
                          backgroundColor: '#dd2476',
                          color: '#fff',
                          borderRadius: '10px',
                        }}
                      />
                      <Legend wrapperStyle={{ color: '#fff' }} />
                      <Bar dataKey="totalWordCount" name="Toplam Kelime Sayısı" fill="#00ff00" barSize={50} />
                    </BarChart>
                  </ResponsiveContainer>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>

        {/* Aşamaların detaylı verileri için sekmeler */}
        <Box
          sx={{
            width: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
            borderRadius: 3,
            padding: 2,
            marginBottom: 4,
          }}
        >
          <Tabs
            value={selectedStage}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            indicatorColor="secondary"
            textColor="inherit"
            sx={{
              '& .MuiTab-root': {
                fontWeight: 'bold',
                color: '#fff',
              },
              '& .Mui-selected': {
                color: '#ffd700 !important',
              },
            }}
          >
            {stages.map((stage, index) => (
              <Tab key={index} label={`Aşama ${stage.stageNumber}`} />
            ))}
          </Tabs>
        </Box>

        {chartData.length === 0 ? (
          <Box sx={{ marginTop: 4, textAlign: 'center' }}>
            <Typography variant="h6" color="#fff">
              Henüz tamamlanmış bir oyun bulunmamaktadır.
            </Typography>
          </Box>
        ) : (
          <Grid container spacing={4} sx={{ marginTop: 4 }}>
            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  boxShadow: 6,
                  borderRadius: 3,
                  backgroundImage: 'linear-gradient(to right, #ff9966, #ff5e62)',
                  color: '#fff',
                }}
              >
                <CardContent>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ fontWeight: 'bold', color: '#fff' }}
                  >
                    Skor Grafiği
                  </Typography>
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart
                      data={chartData}
                      margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" stroke="#fff" />
                      <XAxis dataKey="gameName" stroke="#fff" />
                      <YAxis stroke="#fff" />
                      <Tooltip
                        formatter={(value, name) => [value, 'Skor']}
                        contentStyle={{
                          backgroundColor: '#ff5e62',
                          color: '#fff',
                          borderRadius: '10px',
                        }}
                      />
                      <Legend wrapperStyle={{ color: '#fff' }} />
                      <Bar dataKey="score" name="Skor" fill="#00e676" barSize={50} />
                    </BarChart>
                  </ResponsiveContainer>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  boxShadow: 6,
                  borderRadius: 3,
                  backgroundImage: 'linear-gradient(to right, #24c6dc, #514a9d)',
                  color: '#fff',
                }}
              >
                <CardContent>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ fontWeight: 'bold', color: '#fff' }}
                  >
                    Süre Grafiği
                  </Typography>
                  <ResponsiveContainer width="100%" height={300}>
                    <LineChart
                      data={chartData}
                      margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" stroke="#fff" />
                      <XAxis dataKey="gameName" stroke="#fff" />
                      <YAxis stroke="#fff" />
                      <Tooltip
                        formatter={(value, name) => [`${value} saniye`, 'Süre']}
                        contentStyle={{
                          backgroundColor: '#514a9d',
                          color: '#fff',
                          borderRadius: '10px',
                        }}
                      />
                      <Legend wrapperStyle={{ color: '#fff' }} />
                      <Line
                        type="monotone"
                        dataKey="time"
                        name="Süre (saniye)"
                        stroke="#ffeb3b"
                        strokeWidth={3}
                        activeDot={{ r: 8 }}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </CardContent>
              </Card>
            </Grid>

            {chartData.some((data) => data.wordCount > 0) && (
              <Grid item xs={12} md={6}>
                <Card
                  sx={{
                    boxShadow: 6,
                    borderRadius: 3,
                    backgroundImage: 'linear-gradient(to right, #11998e, #38ef7d)',
                    color: '#fff',
                  }}
                >
                  <CardContent>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{ fontWeight: 'bold', color: '#fff' }}
                    >
                      Kelime Sayısı Grafiği
                    </Typography>
                    <ResponsiveContainer width="100%" height={300}>
                      <BarChart
                        data={chartData}
                        margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
                      >
                        <CartesianGrid strokeDasharray="3 3" stroke="#fff" />
                        <XAxis dataKey="gameName" stroke="#fff" />
                        <YAxis stroke="#fff" />
                        <Tooltip
                          formatter={(value, name) => [value, 'Kelime Sayısı']}
                          contentStyle={{
                            backgroundColor: '#38ef7d',
                            color: '#fff',
                            borderRadius: '10px',
                          }}
                        />
                        <Legend wrapperStyle={{ color: '#fff' }} />
                        <Bar dataKey="wordCount" name="Kelime Sayısı" fill="#ff9100" barSize={50} />
                      </BarChart>
                    </ResponsiveContainer>
                  </CardContent>
                </Card>
              </Grid>
            )}
          </Grid>
        )}
      </Box>
    </>
  );
};

export default ProgressReport;
