import React, { useState, useEffect } from 'react';
import { 
    IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
    Paper, Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions, 
    FormControl, InputLabel, Select, MenuItem 
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';
import { API_URLS } from '../../../../config/config';
import useFetchUserData from '../../../../hooks/kurum/useFetchUserData';
import ResultDialog from '../../../../pages/components/Auth/Dialog';

const Tests = () => {
    const [tests, setTests] = useState([]);
    const [books, setBooks] = useState([]); // Kitapları depolamak için state
    const [userData, setUserData] = useState(null);
    const [editedTest, setEditedTest] = useState({
        _id: '',
        question: '',
        options: [],
        correctAnswer: '',
        explanation: '',
        bookTitle: '',
    });
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
    const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
    const [newTest, setNewTest] = useState({
        question: '',
        options: ['', ''],
        correctAnswer: '',
        explanation: '',
        bookId: '',
    });
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState('');
    const [dialogSeverity, setDialogSeverity] = useState('info');

    // Test oluşturma formu state'leri
    const [testSorusu, setTestSorusu] = useState('');
    const [testSiklari, setTestSiklari] = useState(['', '']);
    const [dogruCevap, setDogruCevap] = useState('');
    const [testAciklamasi, setTestAciklamasi] = useState('');
    const [secilenKitapId, setSecilenKitapId] = useState('');

    useFetchUserData(setUserData);

    const showDialog = (message, severity = 'info') => {
        setDialogContent(message);
        setDialogSeverity(severity);
        setDialogOpen(true);
    };

    // Kitapları çekmek için fonksiyon
    const fetchBooks = async () => {
        if (!userData || !userData._id) {
            console.log("Kullanıcı bilgisi yüklenemedi veya kullanıcı ID'si bulunamadı.");
            return;
        }

        const userId = userData._id;
        console.log("Kullanıcı ID: " + userId);

        try {
            const response = await fetch(`${API_URLS.KURUMSAL_URL}kutuphane/${userId}`);
            const data = await response.json();
            if (response.ok) {
                setBooks(data);
            } else {
                console.error('Kitapları yüklerken bir sorun oluştu: ' + data.message);
                showDialog('Kitapları yüklerken bir sorun oluştu: ' + data.message, 'error');
            }
        } catch (error) {
            console.error('Fetch işlemi sırasında hata oluştu:', error);
            showDialog('Kitapları yüklerken bir hata oluştu. Lütfen daha sonra tekrar deneyin.', 'error');
        }
    };

    // Testleri çekmek için fonksiyon
    const fetchTests = async () => {
        if (!userData || !userData._id) {
            console.log("Kullanıcı bilgisi yüklenemedi veya kullanıcı ID'si bulunamadı.");    
            return; 
        }
    
        const userId = userData._id;  
        console.log("Kullanıcı ID: " + userId);
    
        try {
            const response = await fetch(`${API_URLS.KURUMSAL_URL}kutuphane/tests/${userId}`);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setTests(data);
        } catch (error) {
            console.error('Testleri yüklerken bir sorun oluştu:', error);
            showDialog('Testler yüklenirken bir hata oluştu. Lütfen daha sonra tekrar deneyin.', 'error');
        }
    };

    useEffect(() => {
        fetchTests();
        fetchBooks(); // Kitapları çek
    }, [userData]);

    // Test Düzenleme Fonksiyonları
    const handleEditTest = (testId) => {
        const selectedTest = tests.find((test) => test._id === testId);
        if (selectedTest) {
            setEditedTest({
                _id: selectedTest._id,
                question: selectedTest.question,
                options: selectedTest.options,
                correctAnswer: selectedTest.correctAnswer,
                explanation: selectedTest.explanation,
                bookTitle: selectedTest.bookTitle,
            });
            setIsEditDialogOpen(true);
        } else {
            console.error('Seçilen test bulunamadı.');
            showDialog('Seçilen test bulunamadı.', 'error');
        }
    };

    const handleTestChange = (e) => {
        const { name, value } = e.target;
        setEditedTest(prevTest => ({
            ...prevTest,
            [name]: value
        }));
    };

    const handleUpdate = async () => {
        try {
            if (!editedTest.question || editedTest.options.length < 2 || editedTest.correctAnswer === '') {
                showDialog('Lütfen tüm gerekli alanları doldurun ve en az 2 şık ekleyin.', 'error');
                return;
            }

            if (editedTest.options.some(option => option.trim() === '')) {
                showDialog('Boş şıklar olamaz. Lütfen tüm şıkları doldurun.', 'error');
                return;
            }

            const correctAnswerIndex = parseInt(editedTest.correctAnswer);

            const updatedTest = {
                ...editedTest,
                correctAnswer: correctAnswerIndex
            };

            const response = await fetch(`${API_URLS.KURUMSAL_URL}kutuphane/tests/${editedTest._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedTest),
            });
        
            const data = await response.json();
            if (!response.ok) throw new Error(data.message || 'Bir hata oluştu');
        
            setTests(prevTests => prevTests.map(test => 
                test._id === editedTest._id ? {...test, ...data} : test
            ));
    
            setIsEditDialogOpen(false);
            showDialog('Test başarıyla güncellendi.', 'success');
        } catch (err) {
            console.error('Test güncelleme hatası:', err);
            showDialog(`Test güncellenirken bir hata oluştu: ${err.message}`, 'error');
        }
    };

    const handleDeleteTest = async (testId) => {
        if (window.confirm('Bu testi silmek istediğinizden emin misiniz? Bu işlem geri alınamaz.')) {
            try {
                const response = await fetch(`${API_URLS.KURUMSAL_URL}kutuphane/tests/${testId}`, {
                    method: 'DELETE',
                });
                if (response.ok) {
                    const data = await response.json();
                    console.log(data.message);
                    setTests(prevTests => prevTests.filter(test => test._id !== testId));
                    showDialog('Test başarıyla silindi.', 'success');
                } else {
                    const errorData = await response.json();
                    showDialog('Test silme işlemi sırasında bir sorun oluştu: ' + errorData.message, 'error');
                }
            } catch (error) {
                console.error('Fetch işlemi sırasında hata oluştu:', error);
                showDialog('Test silinirken bir hata oluştu.', 'error');
            }
        }
    };

    // Şık ekleme ve silme fonksiyonları
    const handleSikEkle = () => {
        if (testSiklari.length < 5) {
            setTestSiklari([...testSiklari, '']);
        }
    };

    const handleSikSil = (index) => {
        const yeniSiklar = [...testSiklari];
        yeniSiklar.splice(index, 1);
        setTestSiklari(yeniSiklar);
    };

    // Test Oluşturma Fonksiyonu
    const handleTestFormKapat = () => {
        setIsAddDialogOpen(false);
        setTestSorusu('');
        setTestSiklari(['', '']);
        setDogruCevap('');
        setTestAciklamasi('');
        setSecilenKitapId('');
    };

    const handleTestFormSubmit = async (e) => {
        e.preventDefault();

        if (testSiklari.length < 2 || testSiklari.length > 5) {
            showDialog('En az 2, en fazla 5 seçenek girilmelidir.', 'error');
            return;
        }

        if (dogruCevap === '' || dogruCevap < 0 || dogruCevap >= testSiklari.length) {
            showDialog('Geçerli bir doğru cevap seçin.', 'error');
            return;
        }

        if (!secilenKitapId) {
            showDialog('Lütfen bir kitap seçin.', 'error');
            return;
        }

        try {
            const response = await fetch(`${API_URLS.KURUMSAL_URL}kutuphane/tests`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    bookId: secilenKitapId,
                    question: testSorusu,
                    options: testSiklari,
                    correctAnswer: testSiklari[dogruCevap], // Doğru cevabın kendisini gönder
                    explanation: testAciklamasi,
                }),
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.message || 'Bir hata oluştu');
            }

            showDialog('Test başarıyla oluşturuldu.', 'success');
            handleTestFormKapat();
            fetchTests(); // Test listesini yenile
        } catch (err) {
            console.error(err);
            showDialog(`Hata: ${err.message}`, 'error');
        }
    };

    // Edit Form Dialog
    const renderEditFormDialog = () => (
        <Dialog open={isEditDialogOpen} onClose={() => setIsEditDialogOpen(false)} maxWidth="md" fullWidth>
            <DialogTitle>Test Düzenle</DialogTitle>
            <DialogContent>
                <TextField
                    fullWidth
                    label="Soru"
                    name="question"
                    value={editedTest.question}
                    onChange={handleTestChange}
                    margin="normal"
                />
                {editedTest.options.map((option, index) => (
                    <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                            fullWidth
                            label={`Şık ${index + 1}`}
                            value={option}
                            onChange={(e) => {
                                const newOptions = [...editedTest.options];
                                newOptions[index] = e.target.value;
                                setEditedTest({...editedTest, options: newOptions});
                            }}
                            margin="normal"
                        />
                        <IconButton onClick={() => handleSikSil(index)}>
                            <DeleteIcon />
                        </IconButton>
                    </div>
                ))}
                <Button
                    onClick={handleSikEkle}
                    color="primary"
                    variant="contained"
                    style={{ marginTop: '10px' }}
                    disabled={editedTest.options.length >= 5}
                >
                    Şık Ekle
                </Button>
                <FormControl fullWidth margin="normal">
                    <InputLabel>Doğru Cevap</InputLabel>
                    <Select
                        name="correctAnswer"
                        value={editedTest.correctAnswer}
                        onChange={handleTestChange}
                        displayEmpty
                    >
                        <MenuItem value="" disabled>Doğru cevabı seçin</MenuItem>
                        {editedTest.options.map((option, index) => (
                            <MenuItem key={index} value={index.toString()}>
                                {String.fromCharCode(65 + index)}. {option}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField
                    fullWidth
                    label="Açıklama"
                    name="explanation"
                    value={editedTest.explanation}
                    onChange={handleTestChange}
                    margin="normal"
                    multiline
                    rows={4}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setIsEditDialogOpen(false)}>İptal</Button>
                <Button onClick={handleUpdate} color="primary" variant="contained">
                    Güncelle
                </Button>
            </DialogActions>
        </Dialog>
    );

    // Add Test Form Dialog
    const renderAddFormDialog = () => (
        <Dialog open={isAddDialogOpen} onClose={handleTestFormKapat} maxWidth="md" fullWidth>
            <DialogTitle>Yeni Test Ekle</DialogTitle>
            <DialogContent>
                <form onSubmit={handleTestFormSubmit}>
                    <TextField
                        fullWidth
                        label="Soru"
                        value={testSorusu}
                        onChange={(e) => setTestSorusu(e.target.value)}
                        margin="normal"
                        required
                    />
                    {testSiklari.map((sik, index) => (
                        <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                            <TextField
                                fullWidth
                                label={`Şık ${index + 1}`}
                                value={sik}
                                onChange={(e) => {
                                    const yeniSiklar = [...testSiklari];
                                    yeniSiklar[index] = e.target.value;
                                    setTestSiklari(yeniSiklar);
                                }}
                                margin="normal"
                                required
                            />
                            <IconButton onClick={() => handleSikSil(index)}>
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    ))}
                    <Button
                        onClick={handleSikEkle}
                        color="primary"
                        variant="contained"
                        style={{ marginTop: '10px' }}
                        disabled={testSiklari.length >= 5}
                    >
                        Şık Ekle
                    </Button>
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Doğru Cevap</InputLabel>
                        <Select
                            value={dogruCevap}
                            onChange={(e) => setDogruCevap(e.target.value)}
                            displayEmpty
                            required
                        >
                            <MenuItem value="" disabled>Doğru cevabı seçin</MenuItem>
                            {testSiklari.map((_, index) => (
                                <MenuItem key={index} value={index}>
                                    {String.fromCharCode(65 + index)}. {testSiklari[index] || `Şık ${index + 1}`}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Kitap Seçin</InputLabel>
                        <Select
                            value={secilenKitapId}
                            onChange={(e) => setSecilenKitapId(e.target.value)}
                            displayEmpty
                            required
                        >
                            <MenuItem value="" disabled>Kitap seçin</MenuItem>
                            {books.map((book) => (
                                <MenuItem key={book._id} value={book._id}>
                                    {book.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        fullWidth
                        label="Açıklama"
                        value={testAciklamasi}
                        onChange={(e) => setTestAciklamasi(e.target.value)}
                        margin="normal"
                        multiline
                        rows={4}
                    />
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleTestFormKapat}>İptal</Button>
                <Button onClick={handleTestFormSubmit} color="primary" variant="contained" type="submit">
                    Kaydet
                </Button>
            </DialogActions>
        </Dialog>
    );

    return (
        <TableContainer component={Paper} style={{ marginTop: '20px' }}>
            <Button
                startIcon={<RefreshIcon />}
                onClick={fetchTests} 
                variant="contained"
                color="primary"
                style={{ marginBottom: '10px', marginRight: '10px' }}
            >
                Yenile
            </Button>
            <Button
                startIcon={<AddIcon />}
                onClick={() => setIsAddDialogOpen(true)}
                variant="contained"
                color="secondary"
                style={{ marginBottom: '10px' }}
            >
                Yeni Test Ekle
            </Button>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Kitap Adı</TableCell>
                        <TableCell>Soru</TableCell>
                        <TableCell>Şıklar</TableCell>
                        <TableCell>Doğru Cevap</TableCell>
                        <TableCell>İşlem</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tests.map((test) => (
                        <TableRow key={test._id}>
                            <TableCell>{test.bookTitle || 'Bilinmiyor'}</TableCell>
                            <TableCell>{test.question}</TableCell>
                            <TableCell>
                                {test.options && test.options.map((option, index) => (
                                    <div key={index}>
                                        {String.fromCharCode(65 + index)}. {option}
                                    </div>
                                ))}
                            </TableCell>
                            <TableCell>
                                {test.options && test.correctAnswer !== undefined && test.correctAnswer >= 0 && test.correctAnswer < test.options.length
                                    ? `${String.fromCharCode(65 + test.correctAnswer)}. ${test.options[test.correctAnswer]}`
                                    : 'Geçersiz'}
                            </TableCell>
                            <TableCell>
                                <IconButton onClick={() => handleEditTest(test._id)}>
                                    <EditIcon />
                                </IconButton>
                                <IconButton onClick={() => handleDeleteTest(test._id)}>
                                    <DeleteIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {renderEditFormDialog()}
            {renderAddFormDialog()}
            <ResultDialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                title={dialogSeverity === 'error' ? 'Hata' : 'Bilgi'}
                message={dialogContent}
                severity={dialogSeverity}
            />
        </TableContainer>
    );
};

export default Tests;
