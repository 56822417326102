import React from 'react';

const GameControls = ({
  difficulty,
  paragraphsList,
  selectedParagraphIndex,
  speed,
  handleDifficultyChange,
  handleParagraphChange,
  handleSpeedChange,
}) => {
  return (
    <div className="row mb-3">
      <div className="col-12 col-md-4 mb-2 mb-md-0">
        <label
          htmlFor="difficultySelect"
          className="form-label"
          style={{ fontSize: '1.5rem', fontWeight: 'bold' }}
        >
          Zorluk Seç:
        </label>
        <select
          className="form-select"
          id="difficultySelect"
          value={difficulty}
          onChange={handleDifficultyChange}
          style={{ fontSize: '1.25rem' }}
        >
          <option value="easy">Kolay</option>
          <option value="medium">Orta</option>
          <option value="hard">Zor</option>
        </select>
      </div>
      <div className="col-12 col-md-4 mb-2 mb-md-0">
        <label
          htmlFor="paragraphSelect"
          className="form-label"
          style={{ fontSize: '1.5rem', fontWeight: 'bold' }}
        >
          Paragraf Seç:
        </label>
        <select
          className="form-select"
          id="paragraphSelect"
          value={selectedParagraphIndex}
          onChange={handleParagraphChange}
          style={{ fontSize: '1.25rem' }}
        >
          {paragraphsList.map((paragraph, index) => (
            <option key={index} value={index}>
              {paragraph.title}
            </option>
          ))}
        </select>
      </div>
      <div className="col-12 col-md-4 mb-2 mb-md-0">
        <label
          htmlFor="speed"
          className="form-label"
          style={{ fontSize: '1.5rem', fontWeight: 'bold' }}
        >
          Hız:
        </label>
        <div className="d-flex align-items-center">
          <input
            type="range"
            id="speed"
            min="1"
            max="5"
            step="0.5"
            value={speed}
            onChange={handleSpeedChange}
            className="form-range me-2"
          />
          <span style={{ fontSize: '1.25rem', fontWeight: 'bold' }}>{speed}</span>
        </div>
      </div>
    </div>
  );
};

export default GameControls;
