import React from 'react';

const GameTimer = ({ timeLeft, isPlaying }) => {
  const formatTime = (seconds) => {
    if (seconds < 0) seconds = 0;
    const minutes = Math.floor(seconds / 60).toString().padStart(2, '0');
    const remainingSeconds = (seconds % 60).toString().padStart(2, '0');
    return `${minutes}:${remainingSeconds}`;
  };

  return (
    <div className="game-timer my-3">
      <h4>Kalan Süre: {isPlaying ? formatTime(timeLeft) : 'Oyun duraklatıldı'}</h4>
    </div>
  );
};

export default GameTimer;
