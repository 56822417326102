import React, { useState, useEffect } from 'react';
import {
  IconButton, Button, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Grid, TextField, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, InputLabel, Select, MenuItem, Chip
} from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { API_URLS } from '../../../config/config';
import ResultDialog from '../../../pages/components/Auth/Dialog';
import useFetchUserData from '../../../hooks/admin/useFetchUserData';

const Kurumlar = () => {
  // Mevcut state değişkenleriniz
  const [sehirListesi, setSehirListesi] = useState([]);
  const [secilenSehirId, setSecilenSehirId] = useState('');
  const [secilenIlceId, setSecilenIlceId] = useState('');
  const [userData, setUserData] = useState(null);
  const [shouldFetch, setShouldFetch] = useState(true);
  const [kurumListesi, setKurumListesi] = useState([]);
  const [kurumAdi, setKurumAdi] = useState('');
  const [email, setEmail] = useState('');
  const [sifre, setSifre] = useState('');
  const [secilenKurumTurleri, setSecilenKurumTurleri] = useState([]);
  const [telefon, setTelefon] = useState('');
  const [telefonHata, setTelefonHata] = useState(false);
  const [durum, setDurum] = useState('inactive');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');
  const [dialogSeverity, setDialogSeverity] = useState('info');
  const [eklemeBasariliDialogOpen, setEklemeBasariliDialogOpen] = useState(false);
  const [silmeBasariliDialogOpen, setSilmeBasariliDialogOpen] = useState(false);
  const [duzenlemeDialogAcik, setDuzenlemeDialogAcik] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [balanceDialogOpen, setBalanceDialogOpen] = useState(false);
  const [selectedKurum, setSelectedKurum] = useState(null);
  const [balanceAmount, setBalanceAmount] = useState(0);
  const [balanceReason, setBalanceReason] = useState('');
  
  // Yeni filtre durum değişkenleri
  const [filterSehirId, setFilterSehirId] = useState('');
  const [filterIlceId, setFilterIlceId] = useState('');
  const [filterKurumTurleri, setFilterKurumTurleri] = useState([]);
  const [filterDurum, setFilterDurum] = useState('');

  const [duzenlenenKurum, setDuzenlenenKurum] = useState({
    kurumAdi: '',
    email: '',
    telefon: '',
    sehirId: '',
    ilceId: '',
    kurumTuru: [],
    ilceler: [],
    status: '',
    password: '',
  });

  useFetchUserData(setUserData);

  // Filtrelenmiş kurumlar
  const filteredKurumlar = kurumListesi.filter((kurum) => {
    const matchesSearch = searchTerm
      ? kurum.kurum_adi.toLowerCase().includes(searchTerm.toLowerCase())
      : true;
    
    const matchesSehir = filterSehirId
      ? kurum.sehir === filterSehirId
      : true;
    
    const matchesIlce = filterIlceId
      ? kurum.ilce === filterIlceId
      : true;
    
    const matchesKurumTuru = filterKurumTurleri.length > 0
      ? filterKurumTurleri.every(tur => kurum.kurum_turu.includes(tur))
      : true;
    
    const matchesDurum = filterDurum
      ? kurum.status === filterDurum
      : true;
    
    return matchesSearch && matchesSehir && matchesIlce && matchesKurumTuru && matchesDurum;
  });

  const fetchIlceler = async (sehirId, seciliIlceId) => {
    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}sehirler/ilceler/${sehirId}`);
      if (!response.ok) throw new Error('İlçeler yüklenirken bir hata oluştu.');
      const data = await response.json();
      const ilceler = data.ilceler ? data.ilceler : [];
      setDuzenlenenKurum(prev => ({
        ...prev,
        ilceler: ilceler,
        ilceId: seciliIlceId && ilceler.some(ilce => ilce._id === seciliIlceId) ? seciliIlceId : prev.ilceId
      }));
    } catch (error) {
      console.error('İlçeleri yüklerken bir hata oluştu:', error);
    }
  };

  useEffect(() => {
    const fetchSehirler = async () => {
      if (!shouldFetch) return;
      try {
        const response = await fetch(API_URLS.ADMIN_URL + "sehirler");
        if (!response.ok) {
          throw new Error('Veri çekme işlemi başarısız');
        }
        const sehirler = await response.json();
        setSehirListesi(sehirler);
      } catch (err) {
        console.error("Şehirler yüklenirken bir hata oluştu:", err.message);
      } finally {
        setShouldFetch(false);
      }
    };

    const fetchKurumlar = async () => {
      try {
        const response = await fetch(API_URLS.ADMIN_URL + "kurumsal");
        if (!response.ok) throw new Error('Kurumlar yüklenirken bir hata oluştu.');
        const data = await response.json();

        const enhancedData = data.map(kurum => {
          const sehir = sehirListesi.find(s => s._id === kurum.sehir);
          const ilce = sehir?.ilceler.find(i => i._id === kurum.ilce);

          return {
            ...kurum,
            sehirAdi: sehir ? sehir.sehir_adi : 'Bulunamadı',
            ilceAdi: ilce ? ilce.ilce_adi : 'Bulunamadı',
            status: kurum.status || 'inactive',
            bakiye: typeof kurum.bakiye === 'number' ? kurum.bakiye : 0,
          };
        });

        setKurumListesi(enhancedData);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchKurumlar();
    fetchSehirler();
  }, [shouldFetch, sehirListesi]);

  const handleTelefonChange = (event) => {
    const telefonRegex = /^0(\d{3}) (\d{3}) (\d{4})$/;
    let { value } = event.target;
    const numbers = value.replace(/\D/g, '');
    value = numbers.substring(0, 4) + (numbers.length > 4 ? ' ' : '') + numbers.substring(4, 7) + (numbers.length > 7 ? ' ' : '') + numbers.substring(7, 11);
    setTelefonHata(!telefonRegex.test(value) && value !== '');
    setTelefon(value);
  };

  const kurumTurleri = [
    { id: 'ilkokul', adi: 'İlkokul' },
    { id: 'ortaokul', adi: 'Ortaokul' },
    { id: 'lise', adi: 'Lise' },
    { id: 'kurs', adi: 'Kurs' }
  ];
 
  const handleEkle = async () => {
    let eksikAlanlar = [];
    if (!kurumAdi) eksikAlanlar.push('Kurum Adı');
    if (!email) eksikAlanlar.push('Email');
    if (!sifre) eksikAlanlar.push('Şifre');
    if (!telefon) eksikAlanlar.push('Telefon');
    if (!secilenSehirId) eksikAlanlar.push('Şehir');
    if (!secilenIlceId) eksikAlanlar.push('İlçe');
    if (secilenKurumTurleri.length === 0) eksikAlanlar.push('Kurum Türleri');

    if (eksikAlanlar.length > 0) {
      setDialogSeverity('error');
      setDialogContent(`Lütfen ${eksikAlanlar.join(', ')} alanlarını doldurunuz.`);
      setEklemeBasariliDialogOpen(true);
      return;
    }

    if (telefonHata) {
      setDialogSeverity('error');
      setDialogContent('Lütfen geçerli bir telefon numarası giriniz.');
      setEklemeBasariliDialogOpen(true);
      return;
    }

    try {
      const response = await fetch(API_URLS.ADMIN_URL + 'kurumsal', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          kurum_adi: kurumAdi,
          email: email,
          password: sifre,
          password_open: sifre,
          telefon: telefon,
          sehir: secilenSehirId,
          ilce: secilenIlceId,
          kurum_turu: secilenKurumTurleri,
          adminId: userData?._id,
          status: durum,
        }),
      });

      const responseData = await response.json();

      if (response.ok) {
        const yeniKurum = {
          ...responseData,
          sehirAdi: sehirListesi.find(sehir => sehir._id === secilenSehirId)?.sehir_adi || '',
          ilceAdi: sehirListesi.find(sehir => sehir._id === secilenSehirId)?.ilceler.find(ilce => ilce._id === secilenIlceId)?.ilce_adi || '',
          status: durum,
        };

        setKurumListesi([...kurumListesi, yeniKurum]);

        setDialogSeverity('success');
        setDialogContent('Yeni kurum başarıyla eklendi.');
        setEklemeBasariliDialogOpen(true);

        setKurumAdi('');
        setEmail('');
        setSifre('');
        setTelefon('');
        setSecilenSehirId('');
        setSecilenIlceId('');
        setSecilenKurumTurleri([]);
        setDurum('inactive');
      } else {
        throw new Error(responseData.message || 'Bir hata oluştu');
      }
    } catch (err) {
      setDialogSeverity('error');
      setDialogContent(err.message);
      setEklemeBasariliDialogOpen(true);
    }
  };

  const handleEdit = (kurumId) => {
    const seciliKurum = kurumListesi.find(kurum => kurum._id === kurumId);
    if (seciliKurum) {
      const sehirId = seciliKurum.sehir;

      setDuzenlenenKurum(prev => ({
        ...prev,
        _id: seciliKurum._id,
        kurumAdi: seciliKurum.kurum_adi,
        email: seciliKurum.email,
        telefon: seciliKurum.telefon,
        sehirId: sehirId,
        ilceId: seciliKurum.ilce,
        kurumTuru: seciliKurum.kurum_turu,
        status: seciliKurum.status,
      }));
      fetchIlceler(sehirId);
      setDuzenlemeDialogAcik(true);
    } else {
      console.error('Seçilen kurum bulunamadı.');
    }
  };

  const handleGuncelle = async () => {
    let eksikAlanlar = [];
    if (!duzenlenenKurum.kurumAdi) eksikAlanlar.push('Kurum Adı');
    if (!duzenlenenKurum.email) eksikAlanlar.push('Email');
    if (!duzenlenenKurum.telefon) eksikAlanlar.push('Telefon');
    if (!duzenlenenKurum.sehirId) eksikAlanlar.push('Şehir');
    if (!duzenlenenKurum.ilceId) eksikAlanlar.push('İlçe');
    if (duzenlenenKurum.kurumTuru.length === 0) eksikAlanlar.push('Kurum Türleri');
  
    if (eksikAlanlar.length > 0) {
      setDialogSeverity('error');
      setDialogContent(`Lütfen ${eksikAlanlar.join(', ')} alanlarını doldurunuz.`);
      setDialogOpen(true);
      return;
    }
  
    try {
      // Güncelleme verilerini oluştur
      const updateData = {
        kurum_adi: duzenlenenKurum.kurumAdi,
        email: duzenlenenKurum.email,
        telefon: duzenlenenKurum.telefon,
        sehir: duzenlenenKurum.sehirId,
        ilce: duzenlenenKurum.ilceId,
        kurum_turu: duzenlenenKurum.kurumTuru,
        status: duzenlenenKurum.status,
      };
  
      // Şifre alanı doluysa ekle
      if (duzenlenenKurum.password.trim() !== '') {
        updateData.password = duzenlenenKurum.password;
        updateData.password_open = duzenlenenKurum.password;
      }
  
      const response = await fetch(`${API_URLS.ADMIN_URL}kurumsal/${duzenlenenKurum._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updateData),
      });
  
      const data = await response.json();
      if (response.ok) {
        const existingKurum = kurumListesi.find(k => k._id === duzenlenenKurum._id);

        const guncellenmisKurum = {
          ...existingKurum, 
          ...duzenlenenKurum,
          sehirAdi: sehirListesi.find(sehir => sehir._id === duzenlenenKurum.sehirId)?.sehir_adi || '',
          ilceAdi: sehirListesi.find(sehir => sehir._id === duzenlenenKurum.sehirId)?.ilceler.find(ilce => ilce._id === duzenlenenKurum.ilceId)?.ilce_adi || '',
        };
  
        setKurumListesi(kurumListesi.map(kurum => kurum._id === duzenlenenKurum._id ? guncellenmisKurum : kurum));
        setDialogSeverity('success');
        setDialogContent('Kurum başarıyla güncellendi.');
        setDialogOpen(true);
        setDuzenlemeDialogAcik(false);
      } else {
        throw new Error(data.message || 'Bir hata oluştu');
      }
    } catch (err) {
      setDialogSeverity('error');
      setDialogContent(err.message);
      setDialogOpen(true);
    }
  };

  const handleDelete = async (kurumId) => {
    const onay = window.confirm('Bu kurumu silmek istediğinize emin misiniz?');
    if (!onay) return;

    try {
      const response = await fetch(API_URLS.ADMIN_URL + `kurumsal/${kurumId}`, {
        method: 'DELETE',
      });

      const data = await response.json();

      if (!response.ok) {
        setDialogSeverity('error');
        setDialogContent(data.message || 'Kurum silinirken bir hata oluştu.');
        setSilmeBasariliDialogOpen(true);
      } else {
        setKurumListesi(prevList => prevList.filter(kurum => kurum._id !== kurumId));
        setDialogSeverity('success');
        setDialogContent(`${data.message}`);
        setSilmeBasariliDialogOpen(true);
      }
    } catch (err) {
      setDialogSeverity('error');
      setDialogContent('Hata: ' + err.message);
      setSilmeBasariliDialogOpen(true);
    }
  };

  const handleStatusChange = async (kurumId, currentStatus) => {
    const newStatus = currentStatus === 'active' ? 'inactive' : 'active';
  
    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}kurumsal/${kurumId}/status`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status: newStatus }),
      });
  
      if (!response.ok) throw new Error('Durum güncellenirken bir hata oluştu.');
  
      setKurumListesi(prevList =>
        prevList.map(kurum =>
          kurum._id === kurumId ? { ...kurum, status: newStatus } : kurum
        )
      );
  
      setDialogSeverity('success');
      setDialogContent('Durum başarıyla güncellendi.');
      setDialogOpen(true);
    } catch (error) {
      setDialogSeverity('error');
      setDialogContent(error.message);
      setDialogOpen(true);
    }
  };

  const handleAdjustBalance = (kurum) => {
    setSelectedKurum(kurum);
    setBalanceDialogOpen(true);
  };

  const handleBalanceSubmit = async () => {
    if (!balanceReason) {
      setDialogSeverity('error');
      setDialogContent('Lütfen bir neden giriniz.');
      setDialogOpen(true);
      return;
    }
  
    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}bakiye/balance-adjustment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('userToken')}`, 
        },
        body: JSON.stringify({
          userType: 'Institution',
          userId: selectedKurum._id,
          amountChanged: balanceAmount,
          reason: balanceReason,
        }),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        // Bakiyeyi güncelle
        setKurumListesi(prevList =>
          prevList.map(kurum =>
            kurum._id === selectedKurum._id ? { ...kurum, bakiye: balanceAmount } : kurum
          )
        );
  
        setDialogSeverity('success');
        setDialogContent('Bakiye başarıyla güncellendi.');
        setDialogOpen(true);
        setBalanceDialogOpen(false);
        setBalanceAmount(0);
        setBalanceReason('');
      } else {
        throw new Error(data.message || 'Bakiye güncellenirken bir hata oluştu.');
      }
    } catch (error) {
      setDialogSeverity('error');
      setDialogContent(error.message);
      setDialogOpen(true);
    }
  };

  const renderDuzenlemeFormuDialog = () => (
    <Dialog open={duzenlemeDialogAcik} onClose={() => setDuzenlemeDialogAcik(false)}>
      <DialogTitle>Kurum Düzenle</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="kurumAdi"
          label="Kurum Adı"
          type="text"
          fullWidth
          variant="outlined"
          value={duzenlenenKurum.kurumAdi}
          onChange={(e) => setDuzenlenenKurum({ ...duzenlenenKurum, kurumAdi: e.target.value })}
        />
        <TextField
          margin="dense"
          id="email"
          label="Email"
          type="email"
          fullWidth
          variant="outlined"
          value={duzenlenenKurum.email}
          onChange={(e) => setDuzenlenenKurum({ ...duzenlenenKurum, email: e.target.value })}
        />
        <TextField
          margin="dense"
          id="telefon"
          label="Telefon"
          type="tel"
          fullWidth
          variant="outlined"
          value={duzenlenenKurum.telefon}
          onChange={(e) => setDuzenlenenKurum({ ...duzenlenenKurum, telefon: e.target.value })}
        />
        <FormControl fullWidth margin="dense">
          <InputLabel id="sehir-select-label-duzenle">Şehir</InputLabel>
          <Select
            labelId="sehir-select-label-duzenle"
            id="sehir-select-duzenle"
            value={duzenlenenKurum.sehirId}
            onChange={(e) => {
              setDuzenlenenKurum({ ...duzenlenenKurum, sehirId: e.target.value, ilceId: '' });
              fetchIlceler(e.target.value);
            }}
          >
            <MenuItem value="">
              <em>Şehir Seçiniz</em>
            </MenuItem>
            {sehirListesi.map((sehir) => (
              <MenuItem key={sehir._id} value={sehir._id}>
                {sehir.sehir_adi}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {duzenlenenKurum.sehirId && (
          <FormControl fullWidth margin="dense">
            <InputLabel id="ilce-select-label-duzenle">İlçe</InputLabel>
            <Select
              labelId="ilce-select-label-duzenle"
              id="ilce-select-duzenle"
              value={duzenlenenKurum.ilceId}
              onChange={(e) => setDuzenlenenKurum({ ...duzenlenenKurum, ilceId: e.target.value })}
            >
              <MenuItem value="">
                <em>İlçe Seçiniz</em>
              </MenuItem>
              {duzenlenenKurum.ilceler?.map((ilce) => (
                <MenuItem key={ilce._id} value={ilce._id}>
                  {ilce.ilce_adi}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <FormControl fullWidth margin="dense">
          <InputLabel id="kurum-turu-label-duzenle">Kurum Türü</InputLabel>
          <Select
            labelId="kurum-turu-label-duzenle"
            id="kurum-turu-select-duzenle"
            multiple
            value={duzenlenenKurum.kurumTuru ? duzenlenenKurum.kurumTuru : []}
            onChange={(event) => setDuzenlenenKurum({ ...duzenlenenKurum, kurumTuru: event.target.value })}
            renderValue={(selected) => selected.join(', ')}
          >
            {kurumTurleri.map((tur) => (
              <MenuItem key={tur.id} value={tur.adi}>
                {tur.adi}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel id="status-select-label-duzenle">Durum</InputLabel>
          <Select
            labelId="status-select-label-duzenle"
            id="status-select-duzenle"
            value={duzenlenenKurum.status}
            onChange={(e) => setDuzenlenenKurum({ ...duzenlenenKurum, status: e.target.value })}
          >
            <MenuItem value="active">Aktif</MenuItem>
            <MenuItem value="inactive">Pasif</MenuItem>
          </Select>
        </FormControl>

        {/* Şifre Güncelleme Alanı */}
        <TextField
          fullWidth
          label="Yeni Şifre (isteğe bağlı)"
          type="password"
          value={duzenlenenKurum.password}
          onChange={(e) => setDuzenlenenKurum({ ...duzenlenenKurum, password: e.target.value })}
          margin="normal"
          helperText="Şifreyi güncellemek istiyorsanız buraya yeni şifreyi giriniz."
        />

      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDuzenlemeDialogAcik(false)}>İptal</Button>
        <Button onClick={handleGuncelle}>Güncelle</Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Paper style={{ padding: 20 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <Typography variant="h5" component="h3" marginBottom={2}>
            Bayi Kayıt Formu
          </Typography>
          <FormControl fullWidth margin="normal">
            <InputLabel id="sehir-select-label">Şehir</InputLabel>
            <Select
              labelId="sehir-select-label"
              value={secilenSehirId}
              onChange={(e) => setSecilenSehirId(e.target.value)}
              displayEmpty
              renderValue={(selected) => {
                if (selected === "") {
                  return <em>Şehir Seçiniz</em>;
                }
                const secilenSehir = sehirListesi.find(sehir => sehir._id === selected);
                return secilenSehir ? secilenSehir.sehir_adi : <em>Şehir Seçiniz</em>;
              }}
            >
              <MenuItem disabled value="">
                <em>Şehir Seçiniz</em>
              </MenuItem>
              {sehirListesi.map((sehir) => (
                <MenuItem key={sehir._id} value={sehir._id}>
                  {sehir.sehir_adi}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {secilenSehirId && (
            <FormControl fullWidth margin="normal">
              <InputLabel id="ilce-select-label">İlçe</InputLabel>
              <Select
                labelId="ilce-select-label"
                id="ilce-select"
                value={secilenIlceId}
                onChange={(e) => setSecilenIlceId(e.target.value)}
                displayEmpty
                renderValue={(selected) => {
                  if (!selected) {
                    return <em>İlçe Seçiniz</em>;
                  }
                  const secilenIlce = sehirListesi.find(sehir => sehir._id === secilenSehirId)?.ilceler.find(ilce => ilce._id === selected);
                  return secilenIlce ? secilenIlce.ilce_adi : <em>İlçe Seçiniz</em>;
                }}
              >
                <MenuItem disabled value="">
                  <em>İlçe Seçiniz</em>
                </MenuItem>
                {sehirListesi.find(sehir => sehir._id === secilenSehirId)?.ilceler.map((ilce) => (
                  <MenuItem key={ilce._id} value={ilce._id}>
                    {ilce.ilce_adi}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <FormControl fullWidth margin="normal">
            <InputLabel id="kurum-turu-label">Kurum Türü</InputLabel>
            <Select
              labelId="kurum-turu-label"
              id="kurum-turu-select"
              multiple
              value={secilenKurumTurleri}
              onChange={(e) => setSecilenKurumTurleri(e.target.value)}
              renderValue={(selected) => selected.join(', ')}
            >
              {kurumTurleri.map((tur) => (
                <MenuItem key={tur.id} value={tur.adi}>
                  {tur.adi}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField fullWidth label="Kurum Adı" value={kurumAdi} onChange={(e) => setKurumAdi(e.target.value)} margin="normal" />
          <TextField
            fullWidth
            label="Telefon"
            value={telefon}
            onChange={handleTelefonChange}
            error={telefonHata}
            helperText={telefonHata ? 'Telefon numarası 05XX XXX XXXX formatında olmalıdır.' : ''}
            margin="normal"
          />
          <TextField fullWidth label="Email" value={email} onChange={(e) => setEmail(e.target.value)} margin="normal" />
          <TextField fullWidth label="Şifre" value={sifre} onChange={(e) => setSifre(e.target.value)} margin="normal" type="password" />
          <FormControl fullWidth margin="normal">
            <InputLabel id="status-select-label">Durum</InputLabel>
            <Select
              labelId="status-select-label"
              id="status-select"
              value={durum}
              onChange={(e) => setDurum(e.target.value)}
            >
              <MenuItem value="active">Aktif</MenuItem>
              <MenuItem value="inactive">Pasif</MenuItem>
            </Select>
          </FormControl>
          <Button onClick={handleEkle} variant="contained" color="primary">
            Ekle
          </Button>
        </Grid>
        <Grid item xs={12} md={9}>
          <Typography variant="h5" component="h3" marginBottom={2}>
            Bayi Listesi
          </Typography>
          
          {/* Filtreleme Kontrolleri */}
          <Grid container spacing={2} marginBottom={2}>
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth>
                <InputLabel id="filter-sehir-label">Şehir</InputLabel>
                <Select
                  labelId="filter-sehir-label"
                  value={filterSehirId}
                  onChange={(e) => {
                    setFilterSehirId(e.target.value);
                    setFilterIlceId(''); // Şehir değiştiğinde İlçe filtresini sıfırla
                  }}
                  label="Şehir"
                >
                  <MenuItem value="">
                    <em>Tümü</em>
                  </MenuItem>
                  {sehirListesi.map((sehir) => (
                    <MenuItem key={sehir._id} value={sehir._id}>
                      {sehir.sehir_adi}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            
            {filterSehirId && (
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <InputLabel id="filter-ilce-label">İlçe</InputLabel>
                  <Select
                    labelId="filter-ilce-label"
                    value={filterIlceId}
                    onChange={(e) => setFilterIlceId(e.target.value)}
                    label="İlçe"
                  >
                    <MenuItem value="">
                      <em>Tümü</em>
                    </MenuItem>
                    {sehirListesi.find(sehir => sehir._id === filterSehirId)?.ilceler.map((ilce) => (
                      <MenuItem key={ilce._id} value={ilce._id}>
                        {ilce.ilce_adi}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth>
                <InputLabel id="filter-kurum-turu-label">Kurum Türü</InputLabel>
                <Select
                  labelId="filter-kurum-turu-label"
                  multiple
                  value={filterKurumTurleri}
                  onChange={(e) => setFilterKurumTurleri(e.target.value)}
                  renderValue={(selected) => selected.join(', ')}
                  label="Kurum Türü"
                >
                  {kurumTurleri.map((tur) => (
                    <MenuItem key={tur.id} value={tur.adi}>
                      {tur.adi}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth>
                <InputLabel id="filter-durum-label">Durum</InputLabel>
                <Select
                  labelId="filter-durum-label"
                  value={filterDurum}
                  onChange={(e) => setFilterDurum(e.target.value)}
                  label="Durum"
                >
                  <MenuItem value="">
                    <em>Tümü</em>
                  </MenuItem>
                  <MenuItem value="active">Aktif</MenuItem>
                  <MenuItem value="inactive">Pasif</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12} sm={12}>
              <Button variant="outlined" color="primary" onClick={() => {
                setFilterSehirId('');
                setFilterIlceId('');
                setFilterKurumTurleri([]);
                setFilterDurum('');
              }}>
                Filtreleri Sıfırla
              </Button>
            </Grid>
          </Grid>
          
          {/* Arama Girdisi */}
          <TextField
            label="Kurum Ara"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            fullWidth
            margin="normal"
          />
          
          {/* Kurum Listesi Tablosu */}
          <TableContainer component={Paper}>
            <Table aria-label="okul listesi">
              <TableHead>
                <TableRow>
                  <TableCell>Ekleyen</TableCell>
                  <TableCell>Kurum Adı</TableCell>
                  <TableCell>Şifre</TableCell>
                  <TableCell align="right">Email</TableCell>
                  <TableCell align="right">Telefon</TableCell>
                  <TableCell align="right">Şehir</TableCell>
                  <TableCell align="right">İlçe</TableCell>
                  <TableCell align="right">Kurum Türü</TableCell>
                  <TableCell align="right">Bakiye (TL)</TableCell>
                  <TableCell align="right">Durum</TableCell>
                  <TableCell align="right">İşlem</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredKurumlar.map((kurum) => (
                  <TableRow key={kurum._id}>
                    <TableCell component="th" scope="row"> {kurum.adminAdi} </TableCell>
                    <TableCell>{kurum.kurum_adi}</TableCell>
                    <TableCell>{kurum.password_open}</TableCell>
                    <TableCell align="right">{kurum.email}</TableCell>
                    <TableCell align="right">{kurum.telefon}</TableCell>
                    <TableCell align="right">{kurum.sehirAdi}</TableCell>
                    <TableCell align="right">{kurum.ilceAdi}</TableCell>
                    <TableCell align="right">
                      {kurum.kurum_turu ? kurum.kurum_turu.join(', ') : ''}
                    </TableCell>
                    <TableCell align="right">
                      {kurum.bakiye !== undefined && kurum.bakiye !== null ? kurum.bakiye : '0'}
                    </TableCell>
                    <TableCell align="right">
                      <Chip
                        label={kurum.status === 'active' ? 'Aktif' : 'Pasif'}
                        color={kurum.status === 'active' ? 'success' : 'error'}
                        onClick={() => handleStatusChange(kurum._id, kurum.status)}
                        sx={{
                          cursor: 'pointer',
                          '&:hover': {
                            backgroundColor: 'lightgray',
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        onClick={() => handleAdjustBalance(kurum)}
                        variant="contained"
                        color="success"
                        size="small"
                        startIcon={<AttachMoneyIcon />}
                        sx={{
                          '&:hover': {
                            backgroundColor: 'green',
                          },
                          boxShadow: 'none',
                        }}
                      >
                        <AttachMoneyIcon />
                      </IconButton>
                      <IconButton onClick={() => handleEdit(kurum._id)} color="primary">
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => handleDelete(kurum._id)} color="secondary">
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      
      {/* Dialoglar */}
      <>
        <Dialog open={balanceDialogOpen} onClose={() => setBalanceDialogOpen(false)}>
          <DialogTitle>Bakiye Güncelle</DialogTitle>
          <DialogContent>
            <Typography>Kurum: {selectedKurum?.kurum_adi}</Typography>
            <TextField
              margin="dense"
              label="Miktar"
              type="number"
              fullWidth
              value={balanceAmount}
              onChange={(e) => setBalanceAmount(Number(e.target.value))}
            />
            <TextField
              margin="dense"
              label="Neden"
              type="text"
              fullWidth
              value={balanceReason}
              onChange={(e) => setBalanceReason(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setBalanceDialogOpen(false)}>İptal</Button>
            <Button onClick={handleBalanceSubmit}>Güncelle</Button>
          </DialogActions>
        </Dialog>
        <ResultDialog
          open={eklemeBasariliDialogOpen}
          onClose={() => setEklemeBasariliDialogOpen(false)}
          title={dialogSeverity === 'error' ? "Hata Oluştu" : "İşlem Başarılı"}
          message={dialogContent}
          severity={dialogSeverity}
        />
        <ResultDialog
          open={silmeBasariliDialogOpen}
          onClose={() => setSilmeBasariliDialogOpen(false)}
          title={dialogSeverity === 'error' ? "Hata Oluştu" : "Silme İşlemi Başarılı"}
          message={dialogContent}
          severity={dialogSeverity}
        />
        <ResultDialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          title={dialogSeverity === 'error' ? "Hata Oluştu" : "İşlem Sonucu"}
          message={dialogContent}
          severity={dialogSeverity}
        />
        {renderDuzenlemeFormuDialog()}
      </>
    </Paper>
  );
};

export default Kurumlar;
