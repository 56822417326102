import React from 'react';

const MessageAlert = ({ message }) => {
  return (
    message && (
      <div className="row mt-3">
        <div className="col-12 text-center">
          <div className={`alert ${message === 'Doğru!' ? 'alert-success' : 'alert-warning'}`} role="alert">
            {message}
          </div>
        </div>
      </div>
    )
  );
};

export default MessageAlert;
