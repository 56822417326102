import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography, Paper, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, FormControl, InputLabel, Select, MenuItem, OutlinedInput, Chip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { API_URLS } from '../../../config/config';
import ResultDialog from '../../../pages/components/Auth/Dialog';

const RoleAndPermissionManagement = () => {
  const [roles, setRoles] = useState([]);
  const [newRoleName, setNewRoleName] = useState('');
  const [roleDialogOpen, setRoleDialogOpen] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [newPermissionName, setNewPermissionName] = useState('');
  const [permissionDialogOpen, setPermissionDialogOpen] = useState(false);
  const [newPermissionDescription, setNewPermissionDescription] = useState('');
  const [resultDialogOpen, setResultDialogOpen] = useState(false);
  const [resultDialogMessage, setResultDialogMessage] = useState('');
  const [resultDialogSeverity, setResultDialogSeverity] = useState('info');
  const [selectedPermissions, setSelectedPermissions] = useState([]); 
  const [editPermissionDialogOpen, setEditPermissionDialogOpen] = useState(false);
  const [editingPermissionId, setEditingPermissionId] = useState(null);
  const [editRoleDialogOpen, setEditRoleDialogOpen] = useState(false);
  const [editingRoleId, setEditingRoleId] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const [resourceDialogOpen, setResourceDialogOpen] = useState(false);
  const [newResourceName, setNewResourceName] = useState('');
  const [newResourceEndpoints, setNewResourceEndpoints] = useState('');
  const [newResourceMethods, setNewResourceMethods] = useState('');
  const [resources, setResources] = useState([]); 
  const [editResourceDialogOpen, setEditResourceDialogOpen] = useState(false);
  const [editingResource, setEditingResource] = useState({id: '', name: '', endpoints: '', methods: ''});
  const [accessControls, setAccessControls] = useState([]);
  const [accessControlDialogOpen, setAccessControlDialogOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState('');
  const [selectedResources, setSelectedResources] = useState([]);
  const [editAccessControlDialogOpen, setEditAccessControlDialogOpen] = useState(false);
  const [editingAccessControlId, setEditingAccessControlId] = useState(null);
  const [editingAccessControlRole, setEditingAccessControlRole] = useState('');
  const [editingAccessControlResources, setEditingAccessControlResources] = useState([]);

  


const triggerRefetch = () => setRefetch(!refetch);
  
   const showResultDialog = (message, severity) => {
    setResultDialogMessage(message);
    setResultDialogSeverity(severity);
    setResultDialogOpen(true);
  };

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await fetch(`${API_URLS.ADMIN_URL}roles`);
        if (!response.ok) {
          throw new Error('Roller yüklenirken bir hata oluştu');
        }
        const data = await response.json();
        setRoles(data.roles); 
      } catch (error) {
        console.error(error);
      }
    };
  
    const fetchPermissions = async () => {
      try {
        const response = await fetch(`${API_URLS.ADMIN_URL}roles/permissions`);
        if (!response.ok) {
          throw new Error('Yetkiler yüklenirken bir hata oluştu');
        }
        const data = await response.json();
        setPermissions(data.permissions); 
      } catch (error) {
        console.error(error);
      }
    };
    const fetchResources = async () => {
      try {
        const response = await fetch(`${API_URLS.ADMIN_URL}roles/resources`); 
        if (!response.ok) {
          throw new Error('Kaynaklar yüklenirken bir hata oluştu');
        }
        const data = await response.json();
        setResources(data.resources);
      } catch (error) {
        console.error(error);
      
      }
    };

    const fetchAccessControls = async () => {
      try {
        const response = await fetch(`${API_URLS.ADMIN_URL}roles/access-controls`);
        const data = await response.json();
        setAccessControls(data.accessControls);
      } catch (error) {
        console.error("Erişim kontrolleri yüklenirken bir hata oluştu", error);
      }
    };    
  
    fetchRoles(); 
    fetchPermissions(); 
    fetchResources();
    fetchAccessControls();
    
  }, [refetch]);

  //Rol bölümü
  const handleAddRole = async () => {
    const roleData = {
      name: newRoleName,
      permissions: selectedPermissions, 
    };

    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}roles`, { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(roleData),
      });

      if (!response.ok) {
        throw new Error('Rol oluşturulurken bir hata oluştu');
      }

      const responseData = await response.json();
      setRoles(prevRoles => [...prevRoles, responseData.role]);
      setNewRoleName('');
      setSelectedPermissions([]);
      setRoleDialogOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditRoleClick = (role) => {
    setNewRoleName(role.name);
    setSelectedPermissions(role.permissions.map(permission => permission._id));
    setEditingRoleId(role._id);
    setEditRoleDialogOpen(true);
  };
  
const handleUpdateRole = async () => {
  if (!newRoleName.trim()) {
    showResultDialog("Rol adı boş bırakılamaz", "error");
    return;
  }

  try {
    const response = await fetch(`${API_URLS.ADMIN_URL}roles/${editingRoleId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: newRoleName,
        permissions: selectedPermissions,
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Rol güncellenirken bir hata oluştu");
    }

    const updatedRole = await response.json();
    setRoles(prevRoles => prevRoles.map(role => role._id === editingRoleId ? updatedRole.role : role));
    setEditRoleDialogOpen(false);
    showResultDialog("Rol başarıyla güncellendi.", "success");
    triggerRefetch();
  } catch (error) {
    console.error(error);
    showResultDialog(error.message, "error");
  }
};

const handleDeleteRole = async (roleId) => {
  const isConfirmed = window.confirm('Bu rolü silmek istediğinize emin misiniz?');
  if (!isConfirmed) {
    return;
  }

  try {

    if (!roleId) {
      throw new Error('Geçersiz rol ID');
    }

    const response = await fetch(`${API_URLS.ADMIN_URL}roles/${roleId}`, {
      method: 'DELETE',
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Rol silinirken bir hata oluştu.');
    }

    // Silme işlemi başarılıysa, UI'daki rol listesini güncelle
    setRoles(prevRoles => prevRoles.filter(role => role._id !== roleId)); // .id yerine ._id kullanıldığına dikkat edin

    showResultDialog('Rol başarıyla silindi.', 'success');
  } catch (error) {
    console.error(error);
    showResultDialog(error.message, 'error');
  }
};

// Yetki bölümü
const handleAddPermission = async () => {
  if (!newPermissionName.trim()) {
    setResultDialogMessage("Yetki adı boş bırakılamaz");
    setResultDialogSeverity("error");
    setResultDialogOpen(true);
    return;
  }

  try {
    const response = await fetch(`${API_URLS.ADMIN_URL}roles/permissions`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        name: newPermissionName, 
        description: newPermissionDescription 
      }) 
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Yetki eklenirken bir hata oluştu");
    }

    const addedPermission = await response.json();
    setPermissions(permissions.concat(addedPermission.permission)); 
    setNewPermissionName(''); 
    setNewPermissionDescription(''); 
    setPermissionDialogOpen(false); 
    showResultDialog(`Yetki başarıyla eklendi.`, "success");

  } catch (error) {
    showResultDialog(error.message, "error");
  }
};

const handleEditPermissionClick = (permission) => {
  setNewPermissionName(permission.name);
  setNewPermissionDescription(permission.description || '');
  setEditingPermissionId(permission._id);
  setEditPermissionDialogOpen(true);
};

const handleUpdatePermission = async () => {
  if (!newPermissionName.trim()) {
    setResultDialogMessage("Yetki adı boş bırakılamaz");
    setResultDialogSeverity("error");
    setResultDialogOpen(true);
    return;
  }

  try {
    const response = await fetch(`${API_URLS.ADMIN_URL}roles/permissions/${editingPermissionId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: newPermissionName,
        description: newPermissionDescription,
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Yetki güncellenirken bir hata oluştu");
    }

    const updatedPermission = await response.json();
    setPermissions(permissions.map((perm) => perm._id === editingPermissionId ? updatedPermission.permission : perm));
    setEditPermissionDialogOpen(false);
    triggerRefetch();
  } catch (error) {
    console.error(error);
    showResultDialog(error.message, "error");
  }
};

const handleDeletePermission = async (permissionId) => {
    const isConfirmed = window.confirm('Bu yetkiyi silmek istediğinize emin misiniz?');
    if (!isConfirmed) {
      return; 
    }
  
    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}roles/permissions/${permissionId}`, {
        method: 'DELETE',
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Yetki silinirken bir hata oluştu.');
      }
  
      setPermissions(prevPermissions => prevPermissions.filter(permission => permission._id !== permissionId));  
      triggerRefetch();
      
    } catch (error) {
      console.error(error);
      alert('Yetki silinirken bir hata oluştu: ' + error.message); 
    }
  };

  const handlePermissionChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedPermissions(
    typeof value === 'string' ? value.split(',') : value,
    );
  };  

  //resource kısmı
  const handleAddResource = async () => {
    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}roles/resources`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: newResourceName,
          endpoints: newResourceEndpoints.split(',').map(endpoint => endpoint.trim()), 
          methods: newResourceMethods.split(',').map(method => method.trim()),
        }),
      });
  
      if (!response.ok) {
        throw new Error('Kaynak eklenirken bir hata oluştu');
      }
  
      const addedResource = await response.json();
      setResources(prevResources => [...prevResources, addedResource.resource]); // State güncelleme
      // Formu temizle
      setNewResourceName('');
      setNewResourceEndpoints('');
      setNewResourceMethods('');
      setResourceDialogOpen(false); // Diyalogu kapat
      showResultDialog('Kaynak başarıyla eklendi.', 'success');
    } catch (error) {
      console.error(error);
      showResultDialog(error.message, 'error');
    }
  };

  const handleEditResourceClick = (resource) => {
    setEditingResource({
      id: resource._id,
      name: resource.name,
      endpoints: resource.endpoints.join(", "), // Array'i string'e çevir
      methods: resource.methods.join(", "), // Array'i string'e çevir
    });
    setEditResourceDialogOpen(true);
  };
  
  const handleUpdateResource = async () => {
    const { id, name, endpoints, methods } = editingResource;
    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}roles/resources/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name,
          endpoints: endpoints.split(',').map(endpoint => endpoint.trim()), 
          methods: methods.split(',').map(method => method.trim()), 
        }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Kaynak güncellenirken bir hata oluştu");
      }
  
      // Güncelleme başarılı, UI'ı güncelle veya bir bildirim göster
      setEditResourceDialogOpen(false);
      triggerRefetch(); // Listeyi yeniden fetch etmek için
    } catch (error) {
      console.error(error);
      showResultDialog(error.message, "error");
    }
  };
  
  const handleDeleteResource = async (resourceId) => {
    const isConfirmed = window.confirm('Bu kaynağı silmek istediğinize emin misiniz?');
    if (!isConfirmed) {
      return;
    }

    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}roles/resources/${resourceId}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Kaynak silinirken bir hata oluştu.');
      }     
      setResources(prevResources => prevResources.filter(resource => resource._id !== resourceId)); 
      showResultDialog('Kaynak başarıyla silindi.', 'success');
    } catch (error) {
      console.error(error);  
      showResultDialog(error.message, 'error');
    }
  };

  const handleAddAccessControl = async () => {
    if (!selectedRole || selectedResources.length === 0) {
      showResultDialog("Rol ve en az bir kaynak seçilmelidir.", "error");
      return;
    }
  
    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}roles/access-controls`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          role: selectedRole,
          resources: selectedResources,
        }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Erişim kontrolü eklenirken bir hata oluştu");
      }
  
      const addedAccessControl = await response.json();
      setAccessControls(prevAccessControls => [...prevAccessControls, addedAccessControl.accessControl]);
      showResultDialog("Erişim kontrolü başarıyla eklendi.", "success");
      // Ekleme işleminden sonra dialog'u kapat ve seçimleri temizle
      setAccessControlDialogOpen(false);
      setSelectedRole('');
      setSelectedResources([]);
    } catch (error) {
      console.error(error);
      showResultDialog(error.message, "error");
    }
  };

  const handleEditAccessControlClick = (accessControl) => {
    setEditingAccessControlId(accessControl._id);
    setEditingAccessControlRole(accessControl.role?._id);
    setEditingAccessControlResources(accessControl.resources.map(resource => resource._id));
    setEditAccessControlDialogOpen(true);
  };

const handleUpdateAccessControl = async () => {
    try {
      // API'ye gönderilecek veri
      const updateData = {
        role: editingAccessControlRole,
        resources: editingAccessControlResources,
      };
  
      const response = await fetch(`${API_URLS.ADMIN_URL}roles/access-controls/${editingAccessControlId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updateData),
      });
  
      if (!response.ok) {
     
        const errorData = await response.json();
        throw new Error(errorData.message || 'Erişim kontrolü güncellenirken bir hata oluştu.');
      }
  
      // Yanıttan gelen veriyi kullanarak UI güncellemeleri yap
      const updatedAccessControl = await response.json();
    
      alert('Erişim kontrolü başarıyla güncellendi.'); // Kullanıcıya bilgi ver
      setEditAccessControlDialogOpen(false); // Diyalog penceresini kapat
      triggerRefetch(); // Listeyi yeniden yükle
    } catch (error) {
      console.error(error);
      alert(error.message); // Hata mesajını göster
    }
  };

  
  const handleDeleteAccessControl = async (accessControlId) => {
    const isConfirmed = window.confirm('Bu erişim kontrolünü silmek istediğinize emin misiniz?');
    if (!isConfirmed) {
      return;
    }
  
    try {
      // API'den erişim kontrolünü silmek için DELETE isteği gönder
      const response = await fetch(`${API_URLS.ADMIN_URL}roles/access-controls/${accessControlId}`, {
        method: 'DELETE',
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Erişim kontrolü silinirken bir hata oluştu.');
      }
  
      setAccessControls(prevAccessControls => prevAccessControls.filter(accessControl => accessControl._id !== accessControlId));
      showResultDialog('Erişim kontrolü başarıyla silindi.', 'success');
    } catch (error) {
      console.error(error);
      showResultDialog(error.message, 'error');
    }
  };
  

  
  return (
    <Paper style={{ margin: 20, padding: 20 }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="h6">Roller</Typography>
          <Button startIcon={<AddIcon />} variant="contained" color="primary" onClick={() => setRoleDialogOpen(true)}>Rol Ekle</Button>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                <TableCell>Rol Adı</TableCell>
                <TableCell>Yetkiler</TableCell>
                <TableCell>Erişim Yetkileri</TableCell>
                  <TableCell align="right">İşlemler</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {roles.map((role) => (
                <TableRow key={role._id}>
                  <TableCell>{role.name}</TableCell>
                  <TableCell>
                    {role.permissions.map((permission) => (
                      <Chip key={permission._id} label={permission.name} style={{ margin: 2 }} />
                    ))}
                  </TableCell>               
                  <TableCell>
                    {role.accessControl.map((access) => (
                      <Chip key={access._id} label={`Access: ${access.resources.map((res) => res.name).join(', ')}`} style={{ margin: 2 }} />
                    ))}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton color="primary" onClick={() => handleEditRoleClick(role)}><EditIcon /></IconButton>
                    <IconButton color="secondary" onClick={() => handleDeleteRole(role._id)}><DeleteIcon /></IconButton>
                  </TableCell>
                </TableRow>
              ))}


              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="h6">Yetkiler</Typography>
          <Button startIcon={<AddIcon />} variant="contained" color="secondary" onClick={() => setPermissionDialogOpen(true)}>Yetki Ekle</Button>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                <TableCell>Yetki Adı</TableCell>
                <TableCell>Açıklama</TableCell>
                  <TableCell align="right">İşlemler</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {permissions.map((permission) => (
                <TableRow key={permission._id}>
                  <TableCell>{permission.name}</TableCell>
                  <TableCell>{permission.description}</TableCell>
                  <TableCell align="right">
                    <IconButton color="primary" onClick={() => handleEditPermissionClick(permission)}><EditIcon /></IconButton>
                    <IconButton color="secondary" onClick={() => handleDeletePermission(permission._id)}><DeleteIcon /></IconButton>
                  </TableCell>
                </TableRow>
              ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
  
      <Grid container spacing={2}>
      <Grid item xs={6}>
      <Typography variant="h6">Kaynaklar</Typography>
      <Button
        startIcon={<AddIcon />}
        variant="contained"
        color="primary"
        onClick={() => setResourceDialogOpen(true)}
      >
    Kaynak Ekle
  </Button>
  <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Kaynak Adı</TableCell>
              <TableCell>Endpoint'ler</TableCell>
              <TableCell>Metodlar</TableCell>
              <TableCell align="right">İşlemler</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {resources.map((resource) => (
          <TableRow key={resource._id}>
            <TableCell>{resource.name}</TableCell>
            <TableCell>{resource.endpoints.join(", ")}</TableCell>
            <TableCell>{resource.methods.join(", ")}</TableCell>
            <TableCell align="right">
              <IconButton color="primary" onClick={() => handleEditResourceClick(resource)}><EditIcon /></IconButton>
              <IconButton color="secondary" onClick={() => handleDeleteResource(resource._id)}><DeleteIcon /></IconButton>
            </TableCell>
          </TableRow>
        ))}
          </TableBody>
        </Table>
      </TableContainer>
</Grid>


<Grid item xs={6}>
  <Typography variant="h6" style={{ margin: '20px 0' }}>Erişim Kontrolleri</Typography>
  <Button startIcon={<AddIcon />} variant="contained" color="primary" onClick={() => setAccessControlDialogOpen(true)} style={{ marginBottom: '20px' }}>
    Erişim Kontrolü Ekle
  </Button>
  <TableContainer component={Paper}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Rol</TableCell>
          <TableCell>Kaynaklar</TableCell>
          <TableCell align="right">İşlemler</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
    
  {accessControls?.map((accessControl) => (
    <TableRow key={accessControl._id}>
      <TableCell>{accessControl.role?.name || 'Loading...'}</TableCell>
      <TableCell>
        {accessControl.resources?.map((resource) => (
          <Chip key={resource._id} label={resource.name} style={{ margin: '5px' }} />
        )) || 'No Resources'}
      </TableCell>
      <TableCell align="right">
      <IconButton color="primary" onClick={() => handleEditAccessControlClick(accessControl)}><EditIcon /></IconButton>

        <IconButton color="secondary" onClick={() => handleDeleteAccessControl(accessControl._id)}><DeleteIcon /></IconButton>
      </TableCell>
    </TableRow>
  ))}


      </TableBody>
    </Table>
  </TableContainer>
</Grid>

</Grid>

{/* Rol Bölümü */}   
<Dialog open={roleDialogOpen} onClose={() => setRoleDialogOpen(false)}>
  <DialogTitle>Yeni Rol Ekle</DialogTitle>
  <DialogContent>
    <TextField
      autoFocus
      margin="dense"
      label="Rol Adı"
      type="text"
      fullWidth
      value={newRoleName}
      onChange={(e) => setNewRoleName(e.target.value)}
    />
    <FormControl fullWidth margin="normal">
      <InputLabel>Yetkiler</InputLabel>
      <Select
        multiple
        value={selectedPermissions} 
        onChange={handlePermissionChange} 
        input={<OutlinedInput label="Yetkiler" />}
        renderValue={(selected) => (
          // Seçili yetkileri göster
          <div>
            {selected.map((permissionId) => (
              <Chip key={permissionId} label={permissions.find(p => p._id === permissionId)?.name} />
            ))}
          </div>
        )}
      >
        {permissions.map((permission) => (
          <MenuItem key={permission._id} value={permission._id}>
            {permission.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setRoleDialogOpen(false)}>İptal</Button>
    <Button onClick={handleAddRole}>Ekle</Button>
  </DialogActions>
</Dialog>

<Dialog open={editRoleDialogOpen} onClose={() => setEditRoleDialogOpen(false)}>
  <DialogTitle>Rol Düzenle</DialogTitle>
  <DialogContent>
    <TextField
      autoFocus
      margin="dense"
      label="Rol Adı"
      type="text"
      fullWidth
      value={newRoleName}
      onChange={(e) => setNewRoleName(e.target.value)}
    />
    <FormControl fullWidth margin="normal">
      <InputLabel>Yetkiler</InputLabel>
      <Select
        multiple
        value={selectedPermissions}
        onChange={handlePermissionChange}
        input={<OutlinedInput label="Yetkiler" />}
        renderValue={(selected) => selected.map((permissionId) => {
          const permission = permissions.find(p => p._id === permissionId);
          return permission ? <Chip key={permissionId} label={permission.name} /> : null;
        })}
      >
        {permissions.map((permission) => (
          <MenuItem key={permission._id} value={permission._id}>
            {permission.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setEditRoleDialogOpen(false)}>İptal</Button>
    <Button onClick={handleUpdateRole}>Güncelle</Button>
  </DialogActions>
</Dialog>

 {/* Yetki Bölümü */}
<Dialog open={permissionDialogOpen} onClose={() => setPermissionDialogOpen(false)}>
  <DialogTitle>Yeni Yetki Ekle</DialogTitle>
  <DialogContent>
    <TextField
      autoFocus
      margin="dense"
      label="Yetki Adı"
      type="text"
      fullWidth
      value={newPermissionName}
      onChange={(e) => setNewPermissionName(e.target.value)}
    />
    <TextField
      margin="dense"
      label="Açıklama"
      type="text"
      fullWidth
      multiline
      rows={4}
      value={newPermissionDescription} // Bu state'i tanımlamanız gerekecek
      onChange={(e) => setNewPermissionDescription(e.target.value)} // Bu handler fonksiyonunu tanımlamanız gerekecek
    />
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setPermissionDialogOpen(false)}>İptal</Button>
    <Button onClick={handleAddPermission}>Ekle</Button>
  </DialogActions>
</Dialog>
<Dialog open={editPermissionDialogOpen} onClose={() => setEditPermissionDialogOpen(false)}>
  <DialogTitle>Yetki Düzenle</DialogTitle>
  <DialogContent>
    <TextField
      autoFocus
      margin="dense"
      label="Yetki Adı"
      type="text"
      fullWidth
      value={newPermissionName}
      onChange={(e) => setNewPermissionName(e.target.value)}
    />
    <TextField
      margin="dense"
      label="Açıklama"
      type="text"
      fullWidth
      multiline
      rows={4}
      value={newPermissionDescription}
      onChange={(e) => setNewPermissionDescription(e.target.value)}
    />
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setEditPermissionDialogOpen(false)}>İptal</Button>
    <Button onClick={handleUpdatePermission}>Güncelle</Button>
  </DialogActions>
</Dialog>

 {/* Resource Bölümü */}
<Dialog open={resourceDialogOpen} onClose={() => setResourceDialogOpen(false)}>
  <DialogTitle>Yeni Kaynak Ekle</DialogTitle>
  <DialogContent>
    <TextField
      autoFocus
      margin="dense"
      label="Kaynak Adı"
      type="text"
      fullWidth
      value={newResourceName}
      onChange={(e) => setNewResourceName(e.target.value)}
    />
    <TextField
      margin="dense"
      label="Endpoint'ler"
      type="text"
      fullWidth
      helperText="Virgülle ayrılmış endpoint'ler (örn: /users,/users/:id)"
      value={newResourceEndpoints}
      onChange={(e) => setNewResourceEndpoints(e.target.value)}
    />
    <TextField
      margin="dense"
      label="Metodlar"
      type="text"
      fullWidth
      helperText="Virgülle ayrılmış HTTP metodları (örn: GET,POST,DELETE)"
      value={newResourceMethods}
      onChange={(e) => setNewResourceMethods(e.target.value)}
    />
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setResourceDialogOpen(false)}>İptal</Button>
    <Button onClick={handleAddResource}>Ekle</Button>
  </DialogActions>
</Dialog>

<Dialog open={editResourceDialogOpen} onClose={() => setEditResourceDialogOpen(false)}>
    <DialogTitle>Kaynak Düzenle</DialogTitle>
    <DialogContent>
      <TextField
        autoFocus
        margin="dense"
        label="Kaynak Adı"
        type="text"
        fullWidth
        value={editingResource.name}
        onChange={e => setEditingResource({...editingResource, name: e.target.value})}
      />
      <TextField
        margin="dense"
        label="Endpoint'ler"
        type="text"
        fullWidth
        value={editingResource.endpoints}
        onChange={e => setEditingResource({...editingResource, endpoints: e.target.value})}
        helperText="Virgülle ayrılmış endpoint'ler (örn: /users,/users/:id)"
      />
      <TextField
        margin="dense"
        label="Metodlar"
        type="text"
        fullWidth
        value={editingResource.methods}
        onChange={e => setEditingResource({...editingResource, methods: e.target.value})}
        helperText="Virgülle ayrılmış HTTP metodları (örn: GET,POST,DELETE)"
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={() => setEditResourceDialogOpen(false)}>İptal</Button>
      <Button onClick={handleUpdateResource}>Güncelle</Button>
    </DialogActions>
  </Dialog>

 {/* Access Bölümü */}
  <Dialog open={accessControlDialogOpen} onClose={() => setAccessControlDialogOpen(false)}>
  <DialogTitle>Yeni Erişim Kontrolü Ekle</DialogTitle>
  <DialogContent>
    <FormControl fullWidth margin="normal">
      <InputLabel>Rol</InputLabel>
      <Select
        value={selectedRole}
        onChange={(e) => setSelectedRole(e.target.value)}
      >
        {roles.map((role) => (
          <MenuItem key={role._id} value={role._id}>{role.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
    <FormControl fullWidth margin="normal">
      <InputLabel>Kaynaklar</InputLabel>
      <Select
        multiple
        value={selectedResources}
        onChange={(e) => setSelectedResources(e.target.value)}
        renderValue={(selected) => (
          <div>
            {selected.map((resourceId) => {
              const resource = resources.find(r => r._id === resourceId);
              return <Chip key={resourceId} label={resource ? resource.name : ''} />;
            })}
          </div>
        )}
      >
        {resources.map((resource) => (
          <MenuItem key={resource._id} value={resource._id}>{resource.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setAccessControlDialogOpen(false)}>İptal</Button>
    <Button onClick={handleAddAccessControl}>Ekle</Button>
  </DialogActions>
</Dialog>

<Dialog open={editAccessControlDialogOpen} onClose={() => setEditAccessControlDialogOpen(false)}>
  <DialogTitle>Erişim Kontrolü Düzenle</DialogTitle>
  <DialogContent>
    <FormControl fullWidth margin="normal">
      <InputLabel>Rol</InputLabel>
      <Select
        value={editingAccessControlRole}
        onChange={(e) => setEditingAccessControlRole(e.target.value)}
      >
        {roles.map((role) => (
          <MenuItem key={role._id} value={role._id}>{role.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
    <FormControl fullWidth margin="normal">
      <InputLabel>Kaynaklar</InputLabel>
      <Select
        multiple
        value={editingAccessControlResources}
        onChange={(e) => setEditingAccessControlResources(e.target.value)}
        renderValue={(selected) => (
          <div>
            {selected.map((resourceId) => {
              const resource = resources.find(r => r._id === resourceId);
              return resource ? <Chip key={resourceId} label={resource.name} /> : null;
            })}
          </div>
        )}
      >
        {resources.map((resource) => (
          <MenuItem key={resource._id} value={resource._id}>{resource.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setEditAccessControlDialogOpen(false)}>İptal</Button>
    <Button onClick={() => handleUpdateAccessControl()}>Güncelle</Button>
  </DialogActions>
</Dialog>



<ResultDialog
      open={resultDialogOpen}
      onClose={() => setResultDialogOpen(false)}
      title={"İşlem Sonucu"}
      message={resultDialogMessage}
      severity={resultDialogSeverity}
    />
    </Paper>
  );
};

export default RoleAndPermissionManagement;
