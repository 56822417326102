// components/GameTimer.js
import React, { useEffect } from 'react';

const GameTimer = ({ isPlaying, timeRemaining, setTimeRemaining }) => {

  useEffect(() => {
    let interval;
    if (isPlaying) {
      interval = setInterval(() => {
        // Her saniyede bir yapılacak işlemler buraya
        setTimeRemaining((prevTime) => Math.max(prevTime - 1, 0)); // Süreyi her saniye azaltıyoruz
      }, 1000);
    } else {
      clearInterval(interval);
    }
  
    return () => clearInterval(interval); // Bileşen unmount olduğunda veya isPlaying değiştiğinde temizler
  }, [isPlaying]); // isPlaying değiştiğinde bu efekt yeniden çalışacak
  
  const formatTime = (seconds) => {
    if (seconds < 0) seconds = 0;
    const minutes = Math.floor(seconds / 60).toString().padStart(2, '0');
    const remainingSeconds = (seconds % 60).toString().padStart(2, '0');
    return `${minutes}:${remainingSeconds}`;
  };

  return (
    <div className="game-timer">
      <div className="timer-display">
        Kalan Süre: {formatTime(timeRemaining)}
      </div>
      {/* Stil tanımlamaları */}
      <style jsx>{`
        .game-timer {
          font-size: 1.5rem;
          font-weight: bold;
          color: #dc3545;
          background-color: #f8f9fa;
          padding: 10px;
          border-radius: 8px;
        }
      `}</style>
    </div>
  );
};

export default GameTimer;
