// PackagePromotion.js
import React from 'react';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  Box,
  useTheme,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Link } from 'react-router-dom'; // Link bileşenini içe aktarın

function PackagePromotion() {
  const theme = useTheme();

  const packages = [
    {
      duration: 3,
      price: 750,
      features: ['21 Günlük Hızlı Okuma Eğitimi', '3 Ay Boyunca Aktif Hesap'],
      bgColor: 'linear-gradient(135deg, #FF9A9E 0%, #FAD0C4 100%)',
    },
    {
      duration: 6,
      price: 1250,
      features: ['21 Günlük Hızlı Okuma Eğitimi', '6 Ay Boyunca Aktif Hesap'],
      bgColor: 'linear-gradient(135deg, #A18CD1 0%, #FBC2EB 100%)',
    },
    {
      duration: 12,
      price: 2000,
      features: ['21 Günlük Hızlı Okuma Eğitimi', '12 Ay Boyunca Aktif Hesap'],
      bgColor: 'linear-gradient(135deg, #FDCB6E 0%, #F8EFBA 100%)',
    },
  ];

  return (
    <Box sx={{ py: 8, backgroundColor: '#f0f2f5' }}>
      <Container maxWidth="lg">
        <Typography
          variant="h3"
          align="center"
          gutterBottom
          sx={{ fontWeight: 'bold', mb: 4, color: '#333' }}
        >
          Paketlerimiz
        </Typography>
        <Grid container spacing={4}>
          {packages.map((pkg) => (
            <Grid item xs={12} sm={6} md={4} key={pkg.duration}>
              <Card
                elevation={6}
                sx={{
                  borderRadius: 3,
                  background: pkg.bgColor,
                  color: '#fff',
                  transition: 'transform 0.3s, box-shadow 0.3s', // Transition ekleyin
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: '-3px 8px 23px -2px rgba(0,0,0,0.75)', // İstenen box-shadow
                  },
                }}
              >
                <CardContent>
                  <Typography
                    variant="h4"
                    align="center"
                    gutterBottom
                    sx={{
                      fontWeight: 'bold',
                      color: '#fff',
                      textShadow: '1px 1px 2px rgba(0,0,0,0.3)',
                    }}
                  >
                    {pkg.duration} Ay
                  </Typography>
                  <Typography
                    variant="h5"
                    align="center"
                    gutterBottom
                    sx={{
                      fontWeight: 'bold',
                      color: '#fff',
                      textShadow: '1px 1px 2px rgba(0,0,0,0.3)',
                    }}
                  >
                    {pkg.price} TL
                  </Typography>
                  <Box sx={{ mt: 2 }}>
                    {pkg.features.map((feature, index) => (
                      <Box
                        key={index}
                        sx={{ display: 'flex', alignItems: 'center', mb: 1 }}
                      >
                        <CheckCircleIcon
                          sx={{ color: '#fff', mr: 1, textShadow: '1px 1px 2px rgba(0,0,0,0.3)' }}
                        />
                        <Typography
                          variant="body1"
                          sx={{ color: '#fff', textShadow: '1px 1px 2px rgba(0,0,0,0.3)' }}
                        >
                          {feature}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </CardContent>
                <CardActions>
                  <Button
                    component={Link} // Link bileşenini kullan
                    to="/ogrenci-kayit" // Yönlendirme yapılacak yol
                    variant="contained"
                    color="secondary"
                    fullWidth
                    sx={{
                      borderRadius: 0,
                      fontWeight: 'bold',
                      py: 1.5,
                      background: 'rgba(0,0,0,0.2)',
                      '&:hover': {
                        background: 'rgba(0,0,0,0.3)',
                      },
                    }}
                  >
                    Satın Al
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default PackagePromotion;
