import axios from 'axios';
import { API_URLS } from '../../../../config/config';

export const fetchParagraphs = async (difficulty) => {
  const token = localStorage.getItem('userToken');
  try {
    const url = `${API_URLS.HIZLIOKUMA_URL}kelimebulmavesecme/${difficulty}`;
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching paragraphs:', error);
    throw new Error('Paragraflar yüklenirken bir hata oluştu.');
  }
};
