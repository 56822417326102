import React from 'react';

const Description = () => {
  return (
    <div className="row mt-3">
      <div className="col-12">
        <h5>Oyun Açıklaması ve İpuçları:</h5>
        <ul>
          <li>Oyunu başlatmak için "Başlat" butonuna basın.</li>
          <li>Size verilen paragrafta eksik kelimeleri tamamlayın.</li>
          <li>Doğru kelimeleri seçtiğinizde puan kazanırsınız.</li>
          <li>Yanlış kelimeleri seçtiğinizde puan kaybedersiniz.</li>
          <li>Oyun süresi 60 saniyedir.</li>
          <li>Oyun süresi dolduğunda veya tüm kelimeleri tamamladığınızda oyun sona erer ve doğru paragrafı görürsünüz.</li>
        </ul>
      </div>
    </div>
  );
};

export default Description;
