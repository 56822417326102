import React from 'react';
import {Box, Container, Grid, Link, Typography, IconButton,} from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import PaymentLogos from '../../assets/images/logo/logo_iyzico.png'; // Yolu ihtiyaca göre ayarlayın
import { styled } from '@mui/system';
import { motion } from 'framer-motion';

// Styled Components
const FooterContainer = styled(Box)(({ theme }) => ({
  background: 'linear-gradient(135deg, #0A192F 0%, #203A43 25%, #2C5364 50%, #47688E 75%, #6F9AB8 100%)',
  backdropFilter: 'blur(10px)',
  backgroundSize: '200% 200%',
  boxShadow: '0px -4px 10px rgba(0, 0, 0, 0.5)',
  borderRadius: '20px 20px 0 0',
  position: 'relative',
  padding: theme.spacing(6, 0),
  color: '#FFFFFF',
  transition: 'background 0.5s ease',
  '&:hover': {
    background: 'linear-gradient(135deg, #6F9AB8 0%, #47688E 25%, #2C5364 50%, #203A43 75%, #0A192F 100%)',
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4, 0),
  },
}));

const FooterLink = styled(Link)(({ theme }) => ({
  color: '#FFFFFF',
  textDecoration: 'none',
  display: 'block',
  marginBottom: theme.spacing(1),
  transition: 'color 0.3s',
  '&:hover': {
    color: theme.palette.secondary.main,
  },
}));

const SocialIconButton = styled(motion(IconButton))(({ theme }) => ({
  color: '#FFFFFF',
  marginRight: theme.spacing(1),
  transition: 'color 0.3s',
  '&:hover': {
    color: theme.palette.secondary.main,
  },
}));

const PaymentLogo = styled(motion.img)({
  width: 'auto',
  height: '40px',
});

const Footer = () => {
  return (
    <FooterContainer component="footer">
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          {/* Şirketimiz Bölümü */}
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
              Şirketimiz
            </Typography>
            <FooterLink href="/hakkimizda">Hakkımızda</FooterLink>
            <FooterLink href="/iletisim">İletişim</FooterLink>
          </Grid>

          {/* Topluluk Bölümü */}
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
              Topluluk
            </Typography>
            <FooterLink href="/termofuse">Kullanım Koşulları</FooterLink>
            <FooterLink href="/gizliliksozlesmesi">Gizlilik Politikası</FooterLink>
            <FooterLink href="/mesafelisatissozlesmesi">Mesafeli Satış Sözleşmesi</FooterLink>
            <FooterLink href="/iptalveiadekosullari">İptal ve İade Koşulları</FooterLink>
          </Grid>

          {/* Sosyal Medya Bölümü */}
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
              Sosyal Medya
            </Typography>
            <Box>
              <SocialIconButton
                href="https://www.instagram.com/oku.maks/profilecard/?igsh=d2NwNXkxcGk1MWJs"
                target="_blank"
                aria-label="Instagram"
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.9 }}
              >
                <InstagramIcon />
              </SocialIconButton>
              <SocialIconButton
                href="https://facebook.com"
                target="_blank"
                aria-label="Facebook"
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.9 }}
              >
                <FacebookIcon />
              </SocialIconButton>
              <SocialIconButton
                href="https://twitter.com"
                target="_blank"
                aria-label="Twitter"
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.9 }}
              >
                <TwitterIcon />
              </SocialIconButton>
            </Box>
          </Grid>
        </Grid>

        {/* Ödeme Logoları */}
        <Box mt={4} display="flex" justifyContent="center">
          <PaymentLogo
            src={PaymentLogos}
            alt="Payment Logos"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          />
        </Box>

        {/* Telif Hakkı */}
        <Box mt={4}>
          <Typography variant="body2" align="center">
            © {new Date().getFullYear()} HizliOkumaveAnlama.Com Tüm hakları saklıdır.
          </Typography>
        </Box>
      </Container>
    </FooterContainer>
  );
};

export default Footer;
