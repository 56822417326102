import { useState, useEffect, useCallback } from 'react';
import { CANVAS_WIDTH, CANVAS_HEIGHT, COLORS } from '../components/GameComponents';

const useGameLogic = (userData) => { 
  const [isPlaying, setIsPlaying] = useState(false);
  const [speed, setSpeed] = useState(6);
  const [numberOfBalls, setNumberOfBalls] = useState(5);
  const [entities, setEntities] = useState({});
  const [score, setScore] = useState(0);
  const [gameTime, setGameTime] = useState(userData ? (userData.status === 'inactive' ? 15 : 60) : 15);
  const [difficulty, setDifficulty] = useState('normal');
  const [showModal, setShowModal] = useState(false);
  
  const scoreMultiplier = difficulty === 'easy' ? 1 : difficulty === 'normal' ? 2 : 3;

  // Topları oluşturma
  const createBalls = useCallback(() => {
    const newEntities = { score: { value: 0, type: 'score' } };
    for (let i = 0; i < numberOfBalls; i++) {
      const size = Math.random() * 30 + 30;
      newEntities[`ball${i}`] = {
        type: 'ball',
        x: Math.random() * (CANVAS_WIDTH - size),
        y: Math.random() * (CANVAS_HEIGHT - size),
        color: COLORS[Math.floor(Math.random() * COLORS.length)],
        number: Math.floor(Math.random() * 15) + 1,
        size: size,
        moveInterval: 2000 - speed * 100,
        lastMoved: 0,
        isTarget: i === 0,
        points: Math.floor(100 / size),
      };
    }
    return newEntities;
  }, [numberOfBalls, speed]);

  // Oyunu başlatma veya sıfırlama
  const resetGame = useCallback(() => {
    setIsPlaying(false);
    setScore(0);
    setGameTime(userData ? (userData.status === 'inactive' ? 15 : 60) : 15);
    setEntities(createBalls());
    setShowModal(false); 
  }, [createBalls, userData]);

  // userData yüklendiğinde gameTime'ı ayarla
  useEffect(() => {
    setGameTime(userData ? (userData.status === 'inactive' ? 15 : 60) : 15);
  }, [userData]);

  useEffect(() => {
    if (isPlaying) {
      setEntities(createBalls());

      const moveInterval = setInterval(() => {
        setEntities((prevEntities) => {
          const now = Date.now();
          const updatedEntities = { ...prevEntities };

          Object.keys(updatedEntities).forEach((key) => {
            if (updatedEntities[key].type === 'ball') {
              if (now - updatedEntities[key].lastMoved > updatedEntities[key].moveInterval) {
                updatedEntities[key] = {
                  ...updatedEntities[key],
                  x: Math.random() * (CANVAS_WIDTH - updatedEntities[key].size),
                  y: Math.random() * (CANVAS_HEIGHT - updatedEntities[key].size),
                  lastMoved: now,
                };
              }
            }
          });

          return updatedEntities;
        });
      }, 1000 / speed);

      return () => clearInterval(moveInterval);
    }
  }, [isPlaying, speed, createBalls]);

  // Oyun süresi ilerledikçe skoru artır ve zamanı kontrol et
  useEffect(() => {
    let timer;
    if (isPlaying && gameTime > 0) {
      timer = setInterval(() => {
        setGameTime((prevTime) => prevTime - 1);
        setScore((prevScore) => prevScore + scoreMultiplier);
      }, 1000);
    } else if (gameTime <= 0) {
      setIsPlaying(false);
      setShowModal(true); 
    }
    return () => clearInterval(timer);
  }, [isPlaying, gameTime, scoreMultiplier]);

  // Event handler
  const handleEvent = useCallback((e) => {
    if (e.type === 'click') {
      setEntities((prevEntities) => ({
        ...prevEntities,
        crosshair: { x: e.x, y: e.y },
      }));
    }
  }, []);

  // Zorluk değişince oyunu sıfırla
  const handleDifficultyChange = useCallback((newDifficulty) => {
    setDifficulty(newDifficulty);

    switch (newDifficulty) {
      case 'easy':
        setSpeed(3);
        setNumberOfBalls(3);
        break;
      case 'normal':
        setSpeed(6);
        setNumberOfBalls(5);
        break;
      case 'hard':
        setSpeed(10);
        setNumberOfBalls(7);
        break;
      default:
        setSpeed(6);
        setNumberOfBalls(5);
    }

    resetGame();
  }, [resetGame]);

  return {
    isPlaying,
    setIsPlaying,
    speed,
    setSpeed,
    numberOfBalls,
    setNumberOfBalls,
    entities,
    score,
    setScore,
    gameTime,
    setGameTime,
    difficulty,
    handleDifficultyChange,
    resetGame,
    handleEvent,
    showModal, 
    setShowModal,
    userData,  
  };
};

export default useGameLogic;
