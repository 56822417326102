import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PaymentSuccess from '../pages/components/Payments/PaymentSuccess';
import PaymentFailed from '../pages/components/Payments/PaymentFailed';

function PaymentRouter() {
    return (
   
        <Routes>
       
          <Route path="/payment-success" element={<PaymentSuccess />} />
          <Route path="/payment-failed" element={<PaymentFailed />} />
       
        </Routes>
      
    );
  }
  
  export default PaymentRouter;