// src/components/PrivacyPolicy.js

import React from 'react';
import { Typography, Container, Card, CardContent, Box } from '@mui/material';
import Header from '../Header';
import Footer from '../Footer';


function PrivacyPolicy() {
  return (
    <div>
    <Header />
    <Container maxWidth="md" sx={{ mt: 5, mb: 5 }}>
      <Card>
        <CardContent>
          <Typography variant="h4" align="center" gutterBottom>
            Gizlilik Sözleşmesi
          </Typography>

          <Typography variant="body1" paragraph>
            Hızlı Okuma ve Anlama, internet sitesi{' '}
            <a href="http://hizliokumaanlama.com/" target="_blank" rel="noopener noreferrer">
              http://hizliokumaanlama.com/
            </a>{' '}
            ziyaret eden ziyaretçilerimizin paylaşmış olduğu bilgilerin gizliliğini sağlamayı ilke olarak kabul etmiştir.
            Bu nedenle işbu “Gizlilik Politikası” sizlerin hangi bilgilerinin, hangi yollarla ve hukuka uygun hangi
            amaç çerçevesinde Hızlı Okuma ve Anlama tarafından işlendiğini, bu bilgilerin hukuka uygun olarak ve
            vermiş olduğunuz izin kapsamında hangi üçüncü kişiler ile paylaşıldığını ve Hızlı Okuma ve Anlama tarafından
            ne şekilde korunduğunu açıklayarak sizi bilgilendirmek amacı ile oluşturulmuştur. Hızlı Okuma ve Anlama
            tarafından sizlere her türlü kanaldan sunulan tüm hizmetler kısaca “Hizmet” olarak anılacaktır.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            Gizlilik Politikasının Kapsamı
          </Typography>
          <Typography variant="body1" paragraph>
            Sunulan hizmetin bir parçası olarak Hızlı Okuma ve Anlama, hizmet alan sizlere ilişkin bilgileri işbu
            Gizlilik Sözleşmesi kapsamında elde edebilir ve aktarabilir. Bu tür bilgi aktarımları, üçüncü kişiler
            tarafından belirlenen şartlara ve üçüncü kişiler ile mevcut sözleşmelere ve yürürlükteki mevcut yasal
            mevzuata uygun olarak yapılır.
          </Typography>
          <Typography variant="body1" paragraph>
            Bu Gizlilik Politikası, bilgi aktarımı yapılan üçüncü kişilerin gizlilik uygulamalarını yansıtmamaktadır ve
            onların gizlilik politikalarından veya uygulamalarından Hızlı Okuma ve Anlama ait internet siteleri ve
            platformlar tarafından toplanılan bilgiler, üçüncü taraflarca Hızlı Okuma ve Anlama internet sitesindeki
            bağlantılar üzerinden toplanan bilgiler veya Hızlı Okuma ve Anlama ait bu web sitemizin sponsor olduğu
            ve/veya katıldığı üçüncü taraf internet siteleri üzerindeki başlıklar, kampanyalar ve diğer reklam veya
            tanıtımları kapsamamaktadır. Üçüncü tarafların kendi internet siteleri yoluyla topladığı, sakladığı ve
            kullandığı kişisel verilere yönelik yapılan işlemlerden Hızlı Okuma ve Anlama sorumlu değildir.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            Toplanan Veriler
          </Typography>
          <Typography variant="body1" paragraph>
            Hızlı Okuma ve Anlama tarafından, verilen Hizmetler kapsamında birtakım kişisel verileriniz bizimle
            paylaşmayı seçtiyseniz veritabanımıza işlenmektedir. Bu kişisel veriler şunları içerebilir:
          </Typography>
          <Box component="ul" sx={{ pl: 4 }}>
            <li>Ad-soyad, T.C. Kimlik Numarası, uyruk bilgisi, anne adı, baba adı, doğum yeri, doğum tarihi, cinsiyet</li>
            <li>Vergi numarası, SGK numarası, imza bilgisi, fotoğraf, taşıt plakası</li>
            <li>Telefon numarası, adres, e-posta adresi, faks numarası, IP adresi, sosyal medya hesapları, konum bilgileri</li>
            <li>
              Ürün veya hizmet satın almanızla ilgili bilgiler, ödeme bilgileri, sitemizde görüntülediğiniz sayfalar,
              sitemizi mobil cihazınız ile ziyaret etmeniz halinde mobil cihazınızı tanıtan veriler
            </li>
            <li>
              Tarafımıza vermeyi açıkça ve yazılı olarak tercih ettiğiniz ve onayladığınız veya üçüncü kişilerden bu açık
              onayınız ile elde edebileceğimiz diğer her tür bilgiye tarafımızdan erişilebilir ve bu bilgiler işlenebilir.
            </li>
          </Box>

          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            Çerez Kullanımı
          </Typography>
          <Typography variant="body1" paragraph>
            Hızlı Okuma ve Anlama, yukarıda anılan kişisel verilerden bazılarını teknik bir iletişim dosyasını
            (Çerez-Cookie) kullanarak elde edebilir. Bahsi geçen teknik iletişim dosyaları, ana bellekte saklanmak üzere
            bir internet sitesinin kullanıcının tarayıcısına (browser) gönderdiği küçük metin dosyalarıdır. Teknik
            iletişim dosyası bir internet sitesi hakkında durum ve tercihleri saklayarak İnternet’in kullanımını
            kolaylaştırır.
          </Typography>
          <Typography variant="body1" paragraph>
            Teknik iletişim dosyası, internet sitesini kaç kişinin kullandığını, bir kişinin internet sitesini hangi
            amaçla, kaç kez ziyaret ettiğini ve ne kadar kaldıkları hakkında istatistiksel bilgileri elde etmeye ve
            kullanıcılar için özel tasarlanmış kullanıcı sayfalarından dinamik olarak reklam ve içerik üretilmesine
            yardımcı olur. Teknik iletişim dosyası, ana bellekte veya e-postanızdan veri veya başkaca herhangi bir
            kişisel veri almak için tasarlanmamıştır. Tarayıcıların pek çoğu başta teknik iletişim dosyasını kabul eder
            biçimde tasarlanmıştır ancak kullanıcılar dilerse teknik iletişim dosyasının gelmemesi veya teknik iletişim
            dosyasının gönderildiğinde ikaz verilmesini sağlayacak biçimde ayarları değiştirebilirler.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            Bilgi Güncelleme ve Değişiklik
          </Typography>
          <Typography variant="body1" paragraph>
            Hızlı Okuma ve Anlama, gizlilik ve veri koruma prensiplerini güncel tutmak ve ilgili mevzuata uygun hale
            getirmek için işbu Gizlilik Politikası’nın içeriğini dilediği zaman değiştirebilir. Değişen Gizlilik
            Politikası Hızlı Okuma ve Anlama internet sitesinde ilan edilecektir. Gizlilik Politikası’nın güncel haline{' '}
            <a href="http://hizliokumaanlama.com/" target="_blank" rel="noopener noreferrer">
              http://hizliokumaanlama.com/
            </a>{' '}
            adresinden sürekli olarak ulaşmanız mümkündür.
          </Typography>
          <Typography variant="body1" paragraph>
            İşbu Gizlilik Politikası’nın değiştirilmesinden sonra Hızlı Okuma ve Anlama hizmet ve/veya uygulamalarını
            kullanmaya devam etmeniz halinde yapılan değişiklikleri kabul ettiğiniz varsayılır. Hızlı Okuma ve Anlama
            değişiklik yaptığı Gizlilik Politikası hükümleri internet sitesinde yayınlandığı tarihte yürürlük kazanır.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            Sorumlu Adli Makamlar
          </Typography>
          <Typography variant="body1" paragraph>
            Hızlı Okuma ve Anlama ile ilgili tüm adli süreçlerde Ankara Adliyesi sorumludur.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            Gizlilik Politikası Hakkında
          </Typography>
          <Typography variant="body1" paragraph>
            <a href="http://hizliokumaanlama.com/" target="_blank" rel="noopener noreferrer">
              http://hizliokumaanlama.com/
            </a>{' '}
            gizlilik politikasını dilediği zaman değiştirebilir. Siteyi kullandığınız veya etkileşime geçtiğiniz zaman
            güncel gizlilik politikalarını da kabul etmiş sayılırsınız. Bu gizlilik sözleşmesi Turksem.com gizlilik
            sözleşme oluşturucu tarafından hazır bir şekilde oluşturulmuştur.{' '}
            <a href="http://hizliokumaanlama.com/" target="_blank" rel="noopener noreferrer">
              http://hizliokumaanlama.com/
            </a>{' '}
            dilediği zaman bu sözleşmeye yeni maddeler ekleyebilir veya mevcut maddeleri sözleşme içerisinden
            kaldırabilir.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            İletişim Bilgileri
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Firma Adresi:</strong> Yavuz Selim Mah. 560 Cadde No:2 Eryaman Etimesgut, Ankara
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Yetkili Kişi Adı:</strong> Zeynep YİĞİT
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Firma Telefon Numarası:</strong> +90 530 303 35 39
          </Typography>

          <Typography variant="body1" paragraph sx={{ mt: 4 }}>
            Bu sözleşme, Hızlı Okuma ve Anlama ile ziyaretçileri arasında yapılan gizlilik politikasını içermektedir.
          </Typography>
        </CardContent>
      </Card>
    </Container>
    <Footer />
    </div>
  );
}

export default PrivacyPolicy;
