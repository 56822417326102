import { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URLS } from '../../../../config/config';

export const useKelimeTamamlamaOyunu = (userData) => {
  const [difficulty, setDifficulty] = useState('easy');
  const [paragraphsList, setParagraphsList] = useState([]);
  const [selectedParagraphIndex, setSelectedParagraphIndex] = useState(0);
  const [paragraph, setParagraph] = useState('');
  const [missingWordsIndexes, setMissingWordsIndexes] = useState([]);
  const [options, setOptions] = useState([]);
  const [userSelections, setUserSelections] = useState({
    selectedWord: null,
    filledWords: {},
  });
  const [isPlaying, setIsPlaying] = useState(false);
  
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [score, setScore] = useState(0);
  const [message, setMessage] = useState('');
  const token = localStorage.getItem('userToken');

  const getInitialTime = () => {
    return !userData || userData.status === 'inactive' ? 15 : 60;
  };

  useEffect(() => {
    setTimeRemaining(getInitialTime());
  }, [userData]);

  useEffect(() => {
    const fetchParagraphs = async () => {
      try {
        const url = `${API_URLS.HIZLIOKUMA_URL}kelimetamamlama/${difficulty}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        setParagraphsList(response.data);
      } catch (error) {
        console.error('Paragraflar yüklenirken hata oluştu:', error);
      }
    };

    fetchParagraphs();
  }, [difficulty, token]);

  useEffect(() => {
    if (paragraphsList.length > 0) {
      handleReset();
    }
  }, [paragraphsList, selectedParagraphIndex]);

  useEffect(() => {
    if (isPlaying && timeRemaining > 0) {
      const timer = setTimeout(() => {
        setTimeRemaining((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearTimeout(timer);
    } else if (timeRemaining === 0) {
      setIsPlaying(false);
      setMessage('Zaman doldu!');
    }
  }, [isPlaying, timeRemaining]);

  const handleWordSelect = (word) => {
    if (!isPlaying) return;
    setUserSelections({ ...userSelections, selectedWord: word });
  };

  const handleBlankClick = (wordIndex, selectedWord) => {
    if (!isPlaying || !selectedWord) return;

    const wordsArray = paragraph.split(' ');

    const correctWord = wordsArray[wordIndex]
      ?.replace(/[^\p{L}\p{N}_]+/gu, '');

    if (!correctWord) return;

    setUserSelections((prevState) => ({
      ...prevState,
      filledWords: {
        ...prevState.filledWords,
        [wordIndex]: {
          word: selectedWord,
          isCorrect: selectedWord.trim().toLowerCase() === correctWord.toLowerCase(),
        },
      },
      selectedWord: null,
    }));

    if (
      selectedWord.trim().toLowerCase() ===
      correctWord.toLowerCase()
    ) {
      setScore((prevScore) => prevScore + 1);
    }

    // Tüm kelimeler doldurulduğunda kontrol et
    const totalFilled = Object.keys(userSelections.filledWords).length + 1; // +1 çünkü setState asenkron
    if (totalFilled >= missingWordsIndexes.length) {
      setIsPlaying(false);
      setMessage('Tebrikler! Tüm kelimeleri doldurdunuz.');
    }
  };

  // Tüm kelimeler doğruysa oyunu bitir ve skor ekranını göster
  useEffect(() => {
    if (!isPlaying && message === 'Tebrikler! Tüm kelimeleri doldurdunuz.') {
      // Burada herhangi bir ek işlem yapabilirsiniz
      // Örneğin skor ekranını göstermek için bir fonksiyon çağırabilirsiniz
    }
  }, [isPlaying, message]);

  const handlePlayPause = () => {
    if (isPlaying) {
      setMessage('Oyun duraklatıldı.');
    } else {
      setMessage('');
    }
    setIsPlaying(!isPlaying);
  };

  const handleReset = () => {
    setIsPlaying(false);
    setUserSelections({ selectedWord: null, filledWords: {} });
    setTimeRemaining(getInitialTime());
    setScore(0);
    setMessage('');

    const newParagraph = paragraphsList[selectedParagraphIndex];
    if (newParagraph && typeof newParagraph.content === 'string') {
      setParagraph(newParagraph.content);

      const wordsArray = newParagraph.content.split(' ');

      const missingIndexesSet = new Set();
      while (missingIndexesSet.size < 5 && missingIndexesSet.size < wordsArray.length) {
        const randomIndex = Math.floor(Math.random() * wordsArray.length);
        missingIndexesSet.add(randomIndex);
      }
      const missingIndexes = Array.from(missingIndexesSet);
      setMissingWordsIndexes(missingIndexes);

      const missingWords = missingIndexes.map((index) =>
        wordsArray[index].replace(/[^\p{L}\p{N}_]+/gu, '')
      );

      const allOptionsSet = new Set([
        ...missingWords,
        ...wordsArray
          .map((word) => word.replace(/[^\p{L}\p{N}_]+/gu, ''))
          .slice(0, 10),
      ]);
      setOptions(Array.from(allOptionsSet).sort(() => 0.5 - Math.random()));
    }
  };

  const handleDifficultyChange = (e) => {
    setDifficulty(e.target.value);
    setSelectedParagraphIndex(0);
    // handleReset(); // Bunu kaldırdık çünkü paragraphsList henüz yüklenmemiş olabilir
  };

  const handleParagraphChange = (e) => {
    setSelectedParagraphIndex(Number(e.target.value));
    // handleReset(); // Bunu da kaldırdık
  };

  const getParagraphWithBlanks = () => {
    const wordsArray = paragraph.split(' ');
    return wordsArray.map((word, index) => {
      if (missingWordsIndexes.includes(index)) {
        const blankIndex = missingWordsIndexes.indexOf(index);
        const blankNumber = blankIndex + 1;
        const userFilled = userSelections.filledWords[index];
        const correctWord = word.replace(/[^\p{L}\p{N}_]+/gu, '');
        const isIncorrect =
          userFilled &&
          !userFilled.isCorrect;
        const isCorrect =
          userFilled &&
          userFilled.isCorrect;
        return (
          <button
            key={index}
            className={`blank-button ${isIncorrect ? 'incorrect' : ''} ${isCorrect ? 'correct' : ''}`}
            onClick={() => handleBlankClick(index, userSelections.selectedWord)}
            disabled={isCorrect} // Doğruysa buton devre dışı
          >
            {userFilled ? userFilled.word : `(${blankNumber}) _____`}
          </button>
        );
      } else {
        return <span key={index}>{word} </span>;
      }
    });
  };

  return {
    difficulty,
    setDifficulty,
    paragraphsList,
    selectedParagraphIndex,
    setSelectedParagraphIndex,
    paragraph,
    options,
    userSelections,
    isPlaying,
    timeRemaining,
    score,
    message,
    handleWordSelect,
    handleBlankClick,
    handlePlayPause,
    handleReset,
    handleDifficultyChange,
    handleParagraphChange,
    getParagraphWithBlanks,
  };
};
