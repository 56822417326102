// TachistoscopicReadingGame.js

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faRedo, faHome, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate, useLocation } from 'react-router-dom';
import useTachistoscopicGameLogic from './hooks/useTachistoscopicGameLogic';
import GameOverModal from './components/GameOverModal';
import axios from 'axios';
import { API_URLS } from '../../../config/config';

const TachistoscopicReadingGame = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { stage, exercise } = location.state || {};
  const [showInstructions, setShowInstructions] = useState(true);
  const [userData, setUserData] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const token = localStorage.getItem('userToken');

  // Kullanıcı verilerini ve rolünü getirme
  useEffect(() => {
    const fetchUserData = async () => {
      if (token) {
        try {
          // Öğrenci verilerini getir
          const studentResponse = await fetch(`${API_URLS.STUDENT_URL}/me`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
          if (studentResponse.ok) {
            const studentData = await studentResponse.json();
            setUserData(studentData);
            setUserRole('student');
            return;
          }
        } catch (error) {
          console.error('Öğrenci verisi alınırken hata oluştu:', error);
        }

        try {
          // Öğretmen verilerini getir
          const teacherResponse = await fetch(`${API_URLS.TEACHER_URL}/me`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
          if (teacherResponse.ok) {
            const teacherData = await teacherResponse.json();
            setUserData(teacherData);
            setUserRole('teacher');
            return;
          }
        } catch (error) {
          console.error('Öğretmen verisi alınırken hata oluştu:', error);
        }

        // Eğer her ikisi de başarısız olursa
        setUserData(null);
        setUserRole(null);
      }
    };

    fetchUserData();
  }, [token]);

  const {
    difficulty,
    currentWord,
    showWord,
    input,
    displayedWords,
    enteredWords,
    showingTime,
    writingTime,
    isPlaying,
    isFinished,
    phase,
    speed,
    handlePlayPause,
    handleReset,
    handleSubmit,
    handleInputChange,
    handleKeyPress,
    handleDifficultyChange,
    handleSpeedChange,
    getSpeedInSeconds,
    setIsFinished,
  } = useTachistoscopicGameLogic();

  const startGame = () => {
    setShowInstructions(false);
    // Oyun içeride kullanıcı tarafından başlatılacak
  };

  const handleReturnToPreviousStage = () => {
    if (stage) {
      navigate(
        userRole === 'student' ? '/ogrenci-dashboard' : '/ogretmen-dashboard',
        {
          state: {
            selectedMenu: 'ExerciseList',
            selectedStage: stage,
          },
        }
      );
    } else {
      alert('Aşama bilgisi bulunmamaktadır.');
    }
  };

  // Oyun bittiğinde ilerlemeyi güncelle
  useEffect(() => {
    if (isFinished) {
      const correctWords = enteredWords.filter((word) =>
        displayedWords.includes(word)
      );
      const score = correctWords.length;
      updateProgress(score);
    }
  }, [isFinished]);

  const updateProgress = async (score) => {
    if (!userData || !userRole) return; // Kullanıcı giriş yapmamışsa ilerlemeyi güncelleme
    try {
      const apiUrl =
        userRole === 'student'
          ? `${API_URLS.STUDENT_URL}akillikurs/progress/update-game`
          : `${API_URLS.TEACHER_URL}akillikurs/progress/update-game`;

      await axios.post(
        apiUrl,
        {
          stageNumber: stage?.number || 1,
          gameName: exercise?.gameName || 'Takistoskopik Okuma Oyunu',
          score: score,
          time:
            (userData.status === 'inactive' ? 15 : 60) -
            (showingTime + writingTime),
          completed: true,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
    } catch (error) {
      console.error('İlerleme güncellenirken hata oluştu:', error);
    }
  };

  return (
    <div className="container-fluid px-0">
      {isFinished ? (
        <GameOverModal
          displayedWords={displayedWords}
          enteredWords={enteredWords}
          onRestart={handleReset}
          userData={userData}
        />
      ) : (
        <>
    <div className="text-center my-3">
     <button className="btn btn-outline-primary mt-2 me-2" onClick={() => navigate(-1)}>
        <FontAwesomeIcon icon={faArrowLeft} /> Geri Dön
      </button>
      <button className="btn btn-outline-primary mt-2 me-2" onClick={() => navigate('/')}>
        <FontAwesomeIcon icon={faHome} /> Ana Sayfa
      </button>
  
      <button className="btn btn-outline-primary mt-2 me-2" onClick={handleReturnToPreviousStage}>
        <FontAwesomeIcon icon={faArrowLeft} />   Önceki Aşamaya Dön
      </button>
          </div>
          {showInstructions ? (
            <div className="row g-0 justify-content-center">
              <div className="col-12 col-md-10 col-lg-8">
                <div
                  className="card shadow-lg"
                  style={{ borderRadius: '20px' }}
                >
                  <div
                    className="card-body"
                    style={{
                      backgroundColor: '#FAFAFA',
                      borderRadius: '20px',
                    }}
                  >
                    <div
                      className="alert alert-info"
                      style={{ borderRadius: '20px' }}
                    >
                      <h5
                        className="text-center"
                        style={{
                          fontWeight: 'bold',
                          color: '#007BFF',
                        }}
                      >
                        Oyun Talimatları
                      </h5>
                      <p>
                        Takistoskopik Okuma Oyunu, kelimeleri kısa bir süre
                        gösterip ardından hatırladığınız kelimeleri yazmanızı
                        sağlar. Oyun sırasında, seçtiğiniz zorluk seviyesine
                        bağlı olarak kelimeler ekranda belirli bir süre
                        gösterilecektir. Bu sürenin sonunda, hatırladığınız
                        kelimeleri giriş alanına yazmalısınız.
                      </p>
                      <ul>
                        <li>
                          <strong>Zorluk Seç:</strong> Kolay, Orta veya Zor
                          seviyelerinden birini seçin.
                        </li>
                        <li>
                          <strong>Başlat/Duraklat:</strong> Oyunu başlatmak veya
                          duraklatmak için butonlara basın.
                        </li>
                        <li>
                          <strong>Hız Ayarı:</strong> Kelimelerin gösterilme
                          hızını ayarlamak için hız çubuğunu kullanın.
                        </li>
                        <li>
                          <strong>Kelimeler Gösteriliyor:</strong> Kelimeleri
                          dikkatlice izleyin, bu süre boyunca kelimeler ekranda
                          gösterilecek.
                        </li>
                        <li>
                          <strong>Yazma Süresi:</strong> Gösterilen kelimeleri
                          hatırlayıp giriş alanına yazın.
                        </li>
                        <li>
                          <strong>Yeniden Başlat:</strong> Oyunu sıfırlayıp
                          yeniden başlatabilirsiniz.
                        </li>
                        <li>
                          <strong>Sonucu Göster:</strong> Hatırladığınız
                          kelimeleri sonlandırmak için butona tıklayın.
                        </li>
                      </ul>
                    </div>

                    <div className="text-center">
                      <button
                        className="btn btn-primary"
                        onClick={startGame}
                        style={{
                          borderRadius: '15px',
                          padding: '10px 20px',
                        }}
                      >
                        <FontAwesomeIcon icon={faPlay} /> Oyuna Gir
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="row g-0 justify-content-center">
              <div className="col-12 col-md-10 col-lg-8">
                <div
                  className="card shadow-lg"
                  style={{ borderRadius: '20px' }}
                >
                  <div
                    className="card-body"
                    style={{
                      backgroundColor: '#FAFAFA',
                      borderRadius: '20px',
                    }}
                  >
                    <div className="row mb-3">
                      <div className="col-12 col-md-4 mb-2 mb-md-0">
                        <label
                          htmlFor="difficultySelect"
                          className="form-label"
                        >
                          Zorluk Seç:
                        </label>
                        <select
                          className="form-select"
                          id="difficultySelect"
                          value={difficulty}
                          onChange={handleDifficultyChange}
                          style={{
                            borderRadius: '15px',
                            padding: '10px',
                          }}
                        >
                          <option value="easy">Kolay</option>
                          <option value="medium">Orta</option>
                          <option value="hard">Zor</option>
                        </select>
                      </div>
                      <div className="col-12 col-md-4 text-center mb-2 mb-md-0">
                        <button
                          className="btn btn-primary me-2"
                          onClick={handlePlayPause}
                          style={{
                            borderRadius: '15px',
                            padding: '10px 20px',
                          }}
                        >
                          <FontAwesomeIcon
                            icon={isPlaying ? faPause : faPlay}
                          />{' '}
                          {isPlaying ? 'Duraklat' : 'Başlat'}
                        </button>
                        <button
                          className="btn btn-secondary"
                          onClick={handleReset}
                          style={{
                            borderRadius: '15px',
                            padding: '10px 20px',
                          }}
                        >
                          <FontAwesomeIcon icon={faRedo} /> Yeniden Başlat
                        </button>
                      </div>
                      <div className="col-12 col-md-4 text-end">
                        <div className="d-flex align-items-center justify-content-end">
                          <label htmlFor="speedRange" className="me-2">
                            Hız:
                          </label>
                          <input
                            type="range"
                            className="form-range"
                            id="speedRange"
                            value={speed / 1000}
                            onChange={handleSpeedChange}
                            min="1"
                            max="5"
                            step="0.5"
                          />
                          <span className="ms-2">
                            {getSpeedInSeconds(speed)} sn
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-12 text-center">
                        {phase === 'showing' ? (
                          <p
                            style={{
                              fontSize: '20px',
                              fontWeight: 'bold',
                              color: '#555',
                            }}
                          >
                            Kelimeler Gösteriliyor: {showingTime} sn
                          </p>
                        ) : (
                          <p
                            style={{
                              fontSize: '20px',
                              fontWeight: 'bold',
                              color: '#FF5722',
                            }}
                          >
                            Yazma Süresi: {writingTime} sn
                          </p>
                        )}
                      </div>
                    </div>
                    {isPlaying && phase === 'showing' && (
                      <div className="word-display mb-3">
                        {currentWord}
                      </div>
                    )}
                   {phase === 'writing' && (
                    <div className="row">
                      <div className="col-12">
                        <input
                          type="text"
                          value={input}
                          onChange={handleInputChange}
                          onKeyDown={handleKeyPress} // Enter tuşunu dinlemek için onKeyDown kullanıyoruz
                          placeholder="Hatırladığınız kelimeleri girin"
                          className="form-control"
                          style={{
                            borderRadius: '15px',
                            padding: '15px',
                            fontSize: '16px',
                          }}
                          disabled={isFinished || writingTime === 0}
                        />
                      </div>
                    </div>
                  )}
                    <div className="row mt-3">
                      <div className="col-12">
                        <h5 style={{ color: '#007BFF' }}>
                          Girilen Kelimeler:
                        </h5>
                        <p>{enteredWords.join(', ')}</p>
                      </div>
                    </div>
                    {phase === 'writing' && (
                      <div className="row">
                        <div className="col-12 text-center">
                          <button
                            className="btn btn-success mt-3"
                            onClick={handleSubmit}
                            style={{
                              borderRadius: '15px',
                              padding: '10px 20px',
                            }}
                          >
                            Sonucu Göster
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {/* Animasyon ve stil için CSS ekledik */}
      <style jsx>{`
        .word-display {
          font-size: 50px;
          font-weight: 300;
          font-family: 'Arial Narrow', Arial, sans-serif;
          text-align: center;
          animation: fadeInOut 1s ease-in-out;
          color: #333;
          height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        @keyframes fadeInOut {
          0% {
            opacity: 0;
          }
          25% {
            opacity: 1;
          }
          75% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
      `}</style>
    </div>
  );
};

export default TachistoscopicReadingGame;
