// src/pages/ogrenci/oyunlar/HarfIzgarasiOyunu.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { useLetterGridGame } from './hooks/useLetterGridGame';
import HeaderSection from './components/HeaderSection';
import GameControls from './components/GameControls';
import GameStatus from './components/GameStatus';
import LetterGrid from './components/LetterGrid';
import MessageAlert from './components/MessageAlert';
import GameOverModal from './components/GameOverModal';
import { API_URLS } from '../../../config/config';

const HarfIzgarasiOyunu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { stage, exercise } = location.state || {};

  const token = localStorage.getItem('userToken');

  const [userData, setUserData] = useState(null);
  const [userRole, setUserRole] = useState(null);

  // Kullanıcı verilerini ve rolünü getirme
  useEffect(() => {
    const fetchUserData = async () => {
      if (token) {
        try {
          // Öğrenci verilerini getir
          const studentResponse = await fetch(`${API_URLS.STUDENT_URL}/me`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
          if (studentResponse.ok) {
            const studentData = await studentResponse.json();
            setUserData(studentData);
            setUserRole('student');
            return;
          }
        } catch (error) {
          console.error('Öğrenci verisi alınırken hata oluştu:', error);
        }

        try {
          // Öğretmen verilerini getir
          const teacherResponse = await fetch(`${API_URLS.TEACHER_URL}/me`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
          if (teacherResponse.ok) {
            const teacherData = await teacherResponse.json();
            setUserData(teacherData);
            setUserRole('teacher');
            return;
          }
        } catch (error) {
          console.error('Öğretmen verisi alınırken hata oluştu:', error);
        }

        // Eğer her ikisi de başarısız olursa
        setUserData(null);
        setUserRole(null);
      }
    };

    fetchUserData();
  }, [token]);

  const {
    letters,
    targetLetters,
    message,
    timeRemaining,
    score,
    isPlaying,
    clickedLetters,
    difficulty,
    handlePlayPause,
    handleReset,
    handleLetterClick,
    handleDifficultyChange,
  } = useLetterGridGame(userData);

  const [showInstructions, setShowInstructions] = useState(true);
  const [showGameOver, setShowGameOver] = useState(false);

  // updateProgress fonksiyonunu tanımlayalım
  const updateProgress = async () => {
    try {
      if (userData && userRole) {
        const apiUrl =
          userRole === 'student'
            ? `${API_URLS.STUDENT_URL}akillikurs/progress/update-game`
            : `${API_URLS.TEACHER_URL}akillikurs/progress/update-game`;

        await axios.post(
          apiUrl,
          {
            stageNumber: stage?.number || 1,
            gameName: exercise?.gameName || 'Harf Izgarası Oyunu',
            score: score,
            time: (userData.status === 'inactive' ? 15 : 60) - timeRemaining,
            completed: true,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        console.log('İlerleme başarıyla güncellendi.');
      }
    } catch (error) {
      console.error('İlerleme güncellenirken hata oluştu:', error);
    }
  };

  // Oyun başlatma işlevi
  const startGame = () => {
    setShowInstructions(false);
    //handlePlayPause();
  };

  // Oyun sona erdiğinde modal'ı göster
  const onGameOver = () => {
    setShowGameOver(true);
    updateProgress();
  };

  const handleRestart = () => {
    setShowGameOver(false);
    handleReset();
  };

  // Oyun süresi bittiğinde onGameOver fonksiyonunu çağırıyoruz
  useEffect(() => {
    if (timeRemaining === 0 && isPlaying) {
      onGameOver();
    }
  }, [timeRemaining, isPlaying]);

  const handleReturnToPreviousStage = () => {
    if (stage) {
      navigate(
        userRole === 'student' ? '/ogrenci-dashboard' : '/ogretmen-dashboard',
        {
          state: {
            selectedMenu: 'ExerciseList',
            selectedStage: stage,
          },
        }
      );
    } else {
      alert('Aşama bilgisi bulunmamaktadır.');
    }
  };

  return (
    <div
      className="container-fluid px-0"
      style={{ backgroundColor: '#f0f8ff' }}
    >
      {showInstructions ? (
        <div className="container-fluid min-vh-100 d-flex flex-column justify-content-center align-items-center bg-light">
          <div className="row justify-content-center mb-4 w-100">
            <div className="col-12 col-md-10 col-lg-8">
              <div className="card shadow border-0 rounded-lg text-center p-4">
                <div className="card-body">
                  <h1 className="display-5 mb-4 fw-bold text-dark">
                    Harf Izgarası Oyunu
                  </h1>
                  <p className="lead mb-4 text-muted">
                    Bu oyunun amacı, verilen süre içerisinde hedef harfleri
                    ızgarada bulmaktır.
                  </p>
                  <h3 className="mb-4 text-secondary">Nasıl Oynanır?</h3>
                  <ul className="text-start d-inline-block fs-6 text-dark">
                    <li className="mb-2">
                      Verilen hedef harfleri ızgarada bulun ve tıklayın.
                    </li>
                    <li className="mb-2">
                      Her doğru seçim için puan kazanırsınız.
                    </li>
                    <li>Süre dolduğunda oyun sona erer.</li>
                  </ul>
                  <button
                    className="btn btn-primary mt-4 px-4 py-2"
                    onClick={startGame}
                    style={{ fontSize: '1rem', fontWeight: '600' }}
                  >
                    Oyuna Başla
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container-fluid min-vh-100 d-flex flex-column justify-content-center align-items-center bg-light">
          <div className="row g-0 justify-content-center w-100">
            <div className="col-12 col-md-10 col-lg-8">
              <div className="card shadow-lg mt-4">
                <div className="card-body">
                  <HeaderSection
                    handleReturnToPreviousStage={handleReturnToPreviousStage}
                  />
                  <GameControls
                    isPlaying={isPlaying}
                    handlePlayPause={handlePlayPause}
                    handleReset={handleReset}
                  />
                  <GameStatus
                    timeRemaining={timeRemaining}
                    score={score}
                    difficulty={difficulty}
                    handleDifficultyChange={handleDifficultyChange}
                  />
                  <LetterGrid
                    letters={letters}
                    targetLetters={targetLetters}
                    clickedLetters={clickedLetters}
                    handleLetterClick={handleLetterClick}
                  />
                  <MessageAlert message={message} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Oyun bittiğinde modalı göster */}
      {showGameOver && (
        <GameOverModal
          score={score}
          onRestart={handleRestart}
          userData={userData}
        />
      )}

      {/* Stil tanımlamaları */}
      <style jsx>{`
        .container-fluid {
          background-color: #f0f8ff;
        }

        .card {
          background-color: white;
          border-radius: 12px;
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
          max-width: 1200px;
          width: 100%;
          margin: auto;
        }

        .btn-primary {
          background-color: #28a745;
          border: none;
          transition: background-color 0.3s ease;
        }

        .btn-primary:hover {
          background-color: #218838;
        }

        .letter-grid {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
          gap: 10px;
          justify-items: center;
          margin-top: 20px;
        }

        .letter {
          font-size: 24px;
          font-weight: bold;
          padding: 15px;
          cursor: pointer;
          border: 2px solid #ccc;
          border-radius: 8px;
          background-color: #f9f9f9;
          transition: background-color 0.3s ease, transform 0.2s ease;
          user-select: none;
          width: 50px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .letter.correct {
          background-color: #4CAF50;
          color: white;
          border-color: #4CAF50;
          transform: scale(1.1);
        }

        .letter.incorrect {
          background-color: #F44336;
          color: white;
          border-color: #F44336;
          transform: scale(0.9);
        }

        .letter:hover {
          background-color: #e0e0e0;
        }

        .target-letter {
          font-size: 1.5rem;
          font-weight: bold;
          color: #ff5722;
          border: 2px dashed #ff5722;
          padding: 10px 20px;
          border-radius: 8px;
          display: inline-block;
          margin-bottom: 20px;
        }

        @media (max-width: 768px) {
          .letter-grid {
            grid-template-columns: repeat(auto-fill, minmax(40px, 1fr));
          }

          .letter {
            font-size: 20px;
            padding: 10px;
            width: 40px;
            height: 40px;
          }
        }
      `}</style>
    </div>
  );
};

export default HarfIzgarasiOyunu;
