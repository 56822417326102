// src/pages/ogrenci/oyunlar/components/LetterGrid.js

import React from 'react';

const LetterGrid = ({ letters, targetLetters, clickedLetters, handleLetterClick }) => {
  return (
    <div className="text-center">
      <div className="target-letter">
        Hedef Harfler: {targetLetters.join(', ')}
      </div>
      <div className="letter-grid">
        {letters.map((letter, index) => (
          <span
            key={index}
            className={`letter ${
              clickedLetters.includes(index)
                ? targetLetters.includes(letter)
                  ? 'correct'
                  : 'incorrect'
                : ''
            }`}
            onClick={() => handleLetterClick(letter, index)}
          >
            {letter}
          </span>
        ))}
      </div>
    </div>
  );
};

export default LetterGrid;
