import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { useKayanYazilarIkiserOyunu } from './hooks/useKayanYazilarIkiserOyunu';
import GameHeader from './components/GameHeader';
import GameInstructions from './components/GameInstructions';
import GameControls from './components/GameControls';
import GameDisplay from './components/GameDisplay';
import GameOverModal from './components/GameOverModal';
import GameTimer from './components/GameTimer';
import './styles/KayanYazilarOyunu.css';
import { API_URLS } from '../../../config/config';

const KayanYazilarIkiserOyunu = () => {
  const navigate = useNavigate();

  const [userData, setUserData] = useState(null);
  const [userRole, setUserRole] = useState(null);

  const location = useLocation();
  const { stage, exercise } = location.state || {};

  const [showInstructions, setShowInstructions] = useState(true);
  const [showGameOver, setShowGameOver] = useState(false);

  const token = localStorage.getItem('userToken');

  const {
    difficulty,
    paragraphsList,
    selectedParagraphIndex,
    displayedWords,
    isPlaying,
    speed,
    elapsedTime,
    timeLeft,
    message,
    gameOver,
    updateWords,
    handlePlayPause,
    handleReset,
    handleSpeedChange,
    handleParagraphChange,
    handleDifficultyChange,
  } = useKayanYazilarIkiserOyunu(navigate, userData, token);

  // Fetch user data and determine user role
  useEffect(() => {
    const fetchUserData = async () => {
      if (token) {
        try {
          // Try fetching student data
          const studentResponse = await fetch(`${API_URLS.STUDENT_URL}/me`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
          if (studentResponse.ok) {
            const studentData = await studentResponse.json();
            setUserData(studentData);
            setUserRole('student');
            return;
          }
        } catch (error) {
          console.error('Error fetching student data:', error);
        }

        try {
          // Try fetching teacher data
          const teacherResponse = await fetch(`${API_URLS.TEACHER_URL}/me`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
          if (teacherResponse.ok) {
            const teacherData = await teacherResponse.json();
            setUserData(teacherData);
            setUserRole('teacher');
            return;
          }
        } catch (error) {
          console.error('Error fetching teacher data:', error);
        }

        // If neither fetch succeeded
        setUserData(null);
        setUserRole(null);
      }
    };

    fetchUserData();
  }, [token]);

  const startGame = () => {
    handleReset();
    setShowInstructions(false);
  };

  useEffect(() => {
    if (gameOver && !showGameOver) {
      setShowGameOver(true);
    }
  }, [gameOver, showGameOver]);

  // Update progress when the game is over
  useEffect(() => {
    if (gameOver) {
      const updateProgress = async () => {
        try {
          if (userData && userRole) {
            const apiUrl =
              userRole === 'student'
                ? `${API_URLS.STUDENT_URL}akillikurs/progress/update-game`
                : `${API_URLS.TEACHER_URL}akillikurs/progress/update-game`;

            await axios.post(
              apiUrl,
              {
                stageNumber: stage?.number || 1,
                gameName: exercise?.gameName || 'Kayan Yazılar İkişer Oyunu',
                score: displayedWords.length * 2,
                time: Math.floor(elapsedTime / 1000),
                wordCount: displayedWords.length * 2,
                completed: true,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json',
                },
              }
            );
          }
        } catch (error) {
          console.error('Error updating progress:', error);
        }
      };
      updateProgress();
    }
  }, [gameOver, displayedWords.length, elapsedTime, userData, userRole, stage, exercise, token]);

  const handleRestart = () => {
    setShowGameOver(false);
    handleReset();
  };

  const handleReturnToPreviousStage = () => {
    if (stage) {
      navigate(
        userRole === 'student' ? '/ogrenci-dashboard' : '/ogretmen-dashboard',
        {
          state: {
            selectedMenu: 'ExerciseList',
            selectedStage: stage,
          },
        }
      );
    } else {
      alert('Aşama bilgisi bulunmamaktadır.');
    }
  };

  return (
    <>
      <div className="container-fluid vh-50 d-flex flex-column justify-content-center align-items-center bg-light">
        <GameHeader handleReturnToPreviousStage={handleReturnToPreviousStage} />
        {showInstructions ? (
          <div className="row justify-content-center mb-4">
            <div className="col-12 col-md-8 col-lg-6">
              <div className="card shadow border-0 rounded-lg text-center p-4">
                <div className="card-body">
                  <h1 className="display-5 mb-4 fw-bold text-dark">
                    Kayan Yazılar 2
                  </h1>
                  <GameInstructions />
                  <button
                    className="btn btn-primary mt-4 px-4 py-2"
                    onClick={startGame}
                    style={{ fontSize: '1rem', fontWeight: '600' }}
                  >
                    Oyuna Başla
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-12 col-md-10 col-lg-8">
                <div className="card shadow border-0 rounded-lg text-center p-4">
                  <div className="card-body">
                    <GameControls
                      difficulty={difficulty}
                      paragraphsList={paragraphsList}
                      selectedParagraphIndex={selectedParagraphIndex}
                      speed={speed}
                      handleDifficultyChange={handleDifficultyChange}
                      handleParagraphChange={handleParagraphChange}
                      handleSpeedChange={handleSpeedChange}
                    />
                    <GameTimer timeLeft={timeLeft} isPlaying={isPlaying} />
                    <GameDisplay
                      isPlaying={isPlaying}
                      speed={speed}
                      paragraphsList={paragraphsList}
                      selectedParagraphIndex={selectedParagraphIndex}
                      displayedWords={displayedWords}
                      updateWords={updateWords}
                      handlePlayPause={handlePlayPause}
                      handleReset={handleReset}
                      message={message}
                      gameOver={gameOver}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {showGameOver && (
       <GameOverModal
       score={displayedWords.length * 2}
       elapsedTime={Math.floor(elapsedTime / 1000)}
       onRestart={handleRestart}
       userData={userData}
     />
      )}

      {/* CSS Styles */}
      <style jsx>{`
        .container-fluid {
          background-color: #f0f8ff;
        }
        .card {
          background-color: white;
          border-radius: 12px;
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        }
        .btn-primary {
          background-color: #28a745;
          border: none;
          transition: background-color 0.3s ease;
        }
        .btn-primary:hover {
          background-color: #218838;
        }
      `}</style>
    </>
  );
};

export default KayanYazilarIkiserOyunu;
