import React from 'react';

const MessageDisplay = ({ message }) => {
  return message ? (
    <div className="row mt-3">
      <div className="col-12 text-center">
        <div className="alert alert-info" role="alert">
          {message}
        </div>
      </div>
    </div>
  ) : null;
};

export default MessageDisplay;
