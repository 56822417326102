// src/pages/ogrenci/payment/PaymentHistory.js

import React, { useEffect, useState } from 'react';
import { API_URLS } from '../../../config/config';
import {Typography,  Card, CardContent,  Grid,  List,  ListItem,  ListItemText,  Divider,} from '@mui/material';
import useFetchUserData from '../../../hooks/ogrenci/useFetchUserData';

function PaymentHistory() {
  const [payments, setPayments] = useState([]);
  const [userData, setUserData] = useState(null);
  useFetchUserData(setUserData);
  console.log(localStorage.getItem('userToken'))

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const response = await fetch(`${API_URLS.STUDENT_PAYMENT_URL}/list`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        console.log('API Response:', data);
        if (response.ok) {
          setPayments(data);
        } else {
          console.error('Ödeme kayıtları alınamadı:', data.message);
        }
      } catch (error) {
        console.error('Ödeme kayıtları alınırken bir hata oluştu:', error);
      }
    };
  
    fetchPayments();
  }, []);
  
    


  return (
    <Card>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Satın Aldığınız Paketler
        </Typography>
        <List>
          {payments.map((payment) => (
            <React.Fragment key={payment._id}>
              <ListItem>
                <ListItemText
                  primary={`${payment.paketSuresi} Aylık Paket - ${payment.toplamTutar} TL`}
                  secondary={`Satın Alma Tarihi: ${new Date(payment.odemeTarihi).toLocaleDateString()}`}
                />
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>
      </CardContent>
    </Card>
  );
}

export default PaymentHistory;
