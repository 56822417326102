import React, { useState, useEffect } from 'react';
import { IconButton, Button, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid, TextField, Dialog, DialogTitle, DialogContent, DialogActions,
  FormControl, InputLabel, Select, MenuItem, Chip, Box} from '@mui/material';
  import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ResultDialog from '../../../pages/components/Auth/Dialog';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Stack from '@mui/material/Stack';
import { API_URLS } from '../../../config/config';

const Ogretmen = () => {
  const [ogretmenListesi, setOgretmenListesi] = useState([]);
  const [sehirListesi, setSehirListesi] = useState([]);
  const [secilenSehirId, setSecilenSehirId] = useState('');
  const [secilenIlceId, setSecilenIlceId] = useState("");
  const [shouldFetch, setShouldFetch] = useState(true);
  const [ad, setAd] = useState('');
  const [soyad, setSoyad] = useState('');
  const [okulAdi, setOkulAdi] = useState('');
  const [telefon, setTelefon] = useState('');
  const [telefonHata, setTelefonHata] = useState(false);
  const [email, setEmail] = useState('');
  const [sifre, setSifre] = useState('');
  const [dialogContent, setDialogContent] = useState('');
  const [dialogSeverity, setDialogSeverity] = useState('info');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [resultDialogOpen, setResultDialogOpen] = useState(false);
  const [resultDialogMessage, setResultDialogMessage] = useState('');
  const [resultDialogSeverity, setResultDialogSeverity] = useState('info');
  const [eklemeBasariliDialogOpen, setEklemeBasariliDialogOpen] = useState(false);
  const [duzenlemeDialogAcik, setDuzenlemeDialogAcik] = useState(false);
  const [shouldFetchList, setShouldFetchList] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [durum, setDurum] = useState('inactive'); 
  const [balanceDialogOpen, setBalanceDialogOpen] = useState(false);
  const [selectedKurum, setSelectedKurum] = useState(null);
  const [balanceAmount, setBalanceAmount] = useState(0);
  const [balanceReason, setBalanceReason] = useState('');

   // Yeni filtreleme için state değişkenleri
   const [statusFilter, setStatusFilter] = useState('');
   const [cityFilter, setCityFilter] = useState('');
   const [ilceFilter, setIlceFilter] = useState('');

  // Filtrelenmiş öğretmen listesi
  const filteredOgretmenListesi = ogretmenListesi.filter((ogretmen) => {
    // Arama sorgusuna göre filtreleme
    const matchesSearch =
      ogretmen.ad.toLowerCase().includes(searchQuery.toLowerCase()) ||
      ogretmen.soyad.toLowerCase().includes(searchQuery.toLowerCase()) ||
      ogretmen.telefon.includes(searchQuery) ||
      ogretmen.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
      (ogretmen.sehirAdi && ogretmen.sehirAdi.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (ogretmen.ilceAdi && ogretmen.ilceAdi.toLowerCase().includes(searchQuery.toLowerCase()));

    // Durum filtresine göre filtreleme
    const matchesStatus = statusFilter ? ogretmen.status === statusFilter : true;

    // Şehir filtresine göre filtreleme
    const matchesCity = cityFilter ? ogretmen.sehir === cityFilter : true;

    // İlçe filtresine göre filtreleme
    const matchesIlce = ilceFilter ? ogretmen.ilce === ilceFilter : true;

    return matchesSearch && matchesStatus && matchesCity && matchesIlce;
  });


  const [duzenlenenOgretmen, setDuzenlenenOgretmen] = useState({
    ad: '',
    soyad: '',
    okulAdi: '',
    email: '',
    telefon: '',
    sehirId: '',
    ilceId: '',
    ilceler: [],
    status: '', 
    password: '', // Şifre alanı eklendi
  });

  const fetchIlceler = async (sehirId, seciliIlceId) => {
    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}sehirler/ilceler/${sehirId}`);
      if (!response.ok) throw new Error('İlçeler yüklenirken bir hata oluştu.');
      const data = await response.json();
      const ilceler = data.ilceler ? data.ilceler : [];

      setDuzenlenenOgretmen(prev => ({
        ...prev,
        ilceler: ilceler,

        ilceId: seciliIlceId && ilceler.some(ilce => ilce._id === seciliIlceId) ? seciliIlceId : prev.ilceId
      }));
    } catch (error) {
      console.error('İlçeleri yüklerken bir hata oluştu:', error);
    }
  };

  useEffect(() => {
    const fetchSehirler = async () => {
      if (!shouldFetch) return;

      try {
        const response = await fetch(API_URLS.ADMIN_URL + "sehirler");
        if (!response.ok) {
          throw new Error('Veri çekme işlemi başarısız');
        }
        const sehirler = await response.json();
        setSehirListesi(sehirler);

      } catch (err) {
        console.error("Şehirler yüklenirken bir hata oluştu:", err.message);
      } finally {
        setShouldFetch(false);
      }
    };

    const fetchOgretmenListesi = async () => {
      if (!shouldFetchList && !shouldFetch) return;

      try {
        const response = await fetch(`${API_URLS.ADMIN_URL}teachers`);
        if (!response.ok) {
          throw new Error('Öğretmen listesi yüklenirken bir hata oluştu.');
        }
        const data = await response.json();

        const enhancedData = data.map(ogretmen => {
          const sehir = sehirListesi.find(s => s._id === ogretmen.sehir);
          const ilce = sehir?.ilceler.find(i => i._id === ogretmen.ilce);

          return {
            ...ogretmen,
            sehirAdi: sehir ? sehir.sehir_adi : 'Bulunamadı',
            ilceAdi: ilce ? ilce.ilce_adi : 'Bulunamadı',
            status: ogretmen.status || 'inactive',
            bakiye: ogretmen.bakiye || 0,
          };
        });

        setOgretmenListesi(enhancedData);
      } catch (error) {
        console.error(error);
      } finally {
        setShouldFetchList(false);
      }
    };

    fetchSehirler();
    fetchOgretmenListesi();
  },[shouldFetch, shouldFetchList, sehirListesi]);

  const handleDialogClose = () => {
    setDialogOpen(false);
    setResultDialogOpen(false);
  };

  const handleTelefonChange = (event) => {
    const telefonRegex = /^0(\d{3}) (\d{3}) (\d{4})$/;
    let { value } = event.target;
    const numbers = value.replace(/\D/g, '');
    value = numbers.substring(0, 4) + (numbers.length > 4 ? ' ' : '') + numbers.substring(4, 7) + (numbers.length > 7 ? ' ' : '') + numbers.substring(7, 11);
    setTelefonHata(!telefonRegex.test(value) && value !== '');
    setTelefon(value);
  };

  const handleSehirChange = (event) => {
    setSecilenSehirId(event.target.value);
  };

  const handleIlceChange = (event) => {
    setSecilenIlceId(event.target.value);
  };

  const handleSehirChangeDuzenle = (event) => {

    const yeniSehirId = event.target.value;
    setDuzenlenenOgretmen(prev => ({ ...prev, sehirId: yeniSehirId }));
    const secilenSehir = sehirListesi.find(sehir => sehir._id === yeniSehirId);
    const ilceler = secilenSehir ? secilenSehir.ilceler : [];

    setDuzenlenenOgretmen(prev => ({ ...prev, ilceId: '', ilceler: ilceler }));
  };

  const handleIlceChangeDuzenle = (event) => {
    const yeniIlceId = event.target.value;

    setDuzenlenenOgretmen(prev => ({ ...prev, ilceId: yeniIlceId }));
  };

  const secilenSehirinIlceleri = sehirListesi.find(sehir => sehir._id === secilenSehirId)?.ilceler || [];

  const handleEkle = async () => {
    let eksikAlanlar = [];

    if (!ad) eksikAlanlar.push('Adı');
    if (!soyad) eksikAlanlar.push('Soyadı');
    if (!email) eksikAlanlar.push('Email');
    if (!sifre) eksikAlanlar.push('Şifre');
    if (!telefon) eksikAlanlar.push('Telefon');
    if (!secilenSehirId) eksikAlanlar.push('Şehir');
    if (!secilenIlceId) eksikAlanlar.push('İlçe');

    if (eksikAlanlar.length > 0) {
      setDialogSeverity('error');
      setDialogContent(`Lütfen ${eksikAlanlar.join(', ')} alanlarını doldurunuz.`);
      setEklemeBasariliDialogOpen(true);
      return;

    }

    if (telefonHata) {
      setDialogSeverity('error');
      setDialogContent('Lütfen geçerli bir telefon numarası giriniz.');
      setEklemeBasariliDialogOpen(true);
      return;
    }


    try {

      const response = await fetch(API_URLS.ADMIN_URL + 'teachers', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({

          ad: ad,
          soyad: soyad,
          email: email,
          password: sifre,
          password_open: sifre,
          okul_adi: okulAdi,
          telefon: telefon,
          sehir: secilenSehirId,
          ilce: secilenIlceId,
          status: durum, 

        }),
      });

      const responseData = await response.json();
      if (response.ok) {
        const yeniOgretmen = {
          ...responseData,
          sehirAdi: sehirListesi.find(sehir => sehir._id === secilenSehirId)?.sehir_adi || '',
          ilceAdi: secilenSehirinIlceleri.find(ilce => ilce._id === secilenIlceId)?.ilce_adi || ''
        };

        setOgretmenListesi([...ogretmenListesi, yeniOgretmen]);


        setDialogSeverity('success');
        setDialogContent('Yeni Öğretmen başarıyla eklendi.');
        setEklemeBasariliDialogOpen(true);


        setAd('');
        setSoyad('');
        setOkulAdi('');
        setEmail('');
        setSifre('');
        setTelefon('');
        setSecilenSehirId('');
        setSecilenIlceId('');
        setDurum('inactive'); // Durumu sıfırladık
      } else {


        throw new Error(responseData.message || 'Bir hata oluştu');
      }
    } catch (err) {
      setDialogSeverity('error');
      setDialogContent(err.message);

      setEklemeBasariliDialogOpen(true);

    }
  };

  const handleDeleteOgretmen = async (ogretmenId) => {
    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}teachers/${ogretmenId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });


      if (!response.ok) throw new Error('Öğretmen silinirken bir hata oluştu.');

      setOgretmenListesi(ogretmenListesi.filter((ogretmen) => ogretmen._id !== ogretmenId));

      setResultDialogMessage('Öğretmen başarıyla silindi.');

      setResultDialogSeverity('success');
      setResultDialogOpen(true);
    } catch (error) {
      setResultDialogMessage(error.message);
      setResultDialogSeverity('error');
      setResultDialogOpen(true);
    }
  };

  const handleEdit = (ogretmenId) => {
    const seciliOgretmen = ogretmenListesi.find(ogretmen => ogretmen._id === ogretmenId);

    if (seciliOgretmen) {

      const sehirId = seciliOgretmen.sehir;

      setDuzenlenenOgretmen(prev => ({
        ...prev,
        _id: seciliOgretmen._id,
        ad: seciliOgretmen.ad,
        soyad: seciliOgretmen.soyad,
        okulAdi: seciliOgretmen.okul_adi,
        email: seciliOgretmen.email,
        telefon: seciliOgretmen.telefon,
        sehirId: sehirId,
        ilceId: seciliOgretmen.ilce,
        status: seciliOgretmen.status, // Durum eklendi
        password: '', // Şifre alanını temizle
      }));
      fetchIlceler(sehirId);
      setDuzenlemeDialogAcik(true);
    } else {
      console.error('Seçilen öğretmen bulunamadı.');
    }
  };

  const handleGuncelle = async () => {
    const { ad, soyad, okulAdi, email, telefon, sehirId, ilceId, status, password } = duzenlenenOgretmen;
    let eksikAlanlar = [];


    if (!ad) eksikAlanlar.push('Adı');
    if (!soyad) eksikAlanlar.push('Soyadı');
    if (!email) eksikAlanlar.push('Email');
    if (!telefon) eksikAlanlar.push('Telefon');
    if (!sehirId) eksikAlanlar.push('Şehir');
    if (!ilceId) eksikAlanlar.push('İlçe');   

    if (eksikAlanlar.length > 0) {
      setDialogSeverity('error');
      setDialogContent(`Lütfen ${eksikAlanlar.join(', ')} alanlarını doldurunuz.`);
      setDialogOpen(true);
      return;
    }

    try {
      // Güncelleme verilerini oluştur
      const updateData = {
        ad,
        soyad,
        okul_adi: okulAdi,
        email,
        telefon,
        sehir: sehirId,
        ilce: ilceId,
        status, 
      };

      // Şifre alanı doluysa ekle
      if (password.trim() !== '') {
        updateData.password = password;
        updateData.password_open = password;
      }

      const response = await fetch(`${API_URLS.ADMIN_URL}teachers/${duzenlenenOgretmen._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },

        body: JSON.stringify(updateData),
      });


      const data = await response.json();
      if (!response.ok) throw new Error(data.message || 'Bir hata oluştu');

      const guncellenmisOgretmen = {
        ...data.ogretmen,
        sehirAdi: sehirListesi.find(sehir => sehir._id === data.ogretmen.sehir)?.sehir_adi || '',
        ilceAdi: sehirListesi.find(sehir => sehir._id === data.ogretmen.sehir)?.ilceler.find(ilce => ilce._id === data.ogretmen.ilce)?.ilce_adi || ''
      };

      setOgretmenListesi(prevList =>
        prevList.map(ogretmen =>
          ogretmen._id === duzenlenenOgretmen._id ? guncellenmisOgretmen : ogretmen
        )
      );

      //setOgretmenListesi(ogretmenListesi.map(ogretmen => ogretmen._id === duzenlenenOgretmen._id ? guncellenmisOgretmen : ogretmen));

      setShouldFetchList(true);

      setDialogSeverity('success');
      setDialogContent('Öğretmen başarıyla güncellendi.');
      setDialogOpen(true);
      setDuzenlemeDialogAcik(false);
    } catch (err) {
      setDialogSeverity('error');
      setDialogContent(err.message);
      setDialogOpen(true);
    }
  };

  const handleStatusChange = async (ogretmenId, currentStatus) => {
    const newStatus = currentStatus === 'active' ? 'inactive' : 'active';

    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}teachers/${ogretmenId}/status`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status: newStatus }),
      });

      if (!response.ok) throw new Error('Durum güncellenirken bir hata oluştu.');

      const updatedTeacher = await response.json();

      // Öğretmen listesini güncelliyoruz
      setOgretmenListesi(prevList =>
        prevList.map(ogretmen =>
          ogretmen._id === ogretmenId ? { ...ogretmen, status: updatedTeacher.status } : ogretmen
        )
      );

      setShouldFetchList(true);
      setResultDialogMessage('Durum başarıyla güncellendi.');
      setResultDialogSeverity('success');
      setResultDialogOpen(true);
    } catch (error) {
      setResultDialogMessage(error.message);
      setResultDialogSeverity('error');
      setResultDialogOpen(true);
    }
  };

  const handleAdjustBalance = (ogretmen) => {
    setSelectedKurum(ogretmen);
    setBalanceDialogOpen(true);
  };

  const handleBalanceSubmit = async () => {
    if (!balanceReason) {
      setDialogSeverity('error');
      setDialogContent('Lütfen bir neden giriniz.');
      setDialogOpen(true);
      return;
    } 
    const amountChanged = balanceAmount;

    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}bakiye/balance-adjustment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('userToken')}`, 
        },
        body: JSON.stringify({
          userType: 'Ogretmen',
          userId: selectedKurum._id,
          amountChanged: amountChanged, // Hesaplanan farkı gönderin
          reason: balanceReason,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        // Bakiye listesini güncelleyin
        setOgretmenListesi(prevList =>
          prevList.map(ogretmen =>
            ogretmen._id === selectedKurum._id ? { ...ogretmen, bakiye: amountChanged } : ogretmen
          )
        );

        setDialogSeverity('success');
        setDialogContent('Bakiye başarıyla güncellendi.');
        setDialogOpen(true);
        setBalanceDialogOpen(false);
        setBalanceAmount(0);
        setBalanceReason('');
      } else {
        throw new Error(data.message || 'Bakiye güncellenirken bir hata oluştu.');
      }
    } catch (error) {
      setDialogSeverity('error');
      setDialogContent(error.message);
      setDialogOpen(true);
    }
  };  

  const renderDuzenlemeFormuDialog = () => (
    <Dialog open={duzenlemeDialogAcik} onClose={() => setDuzenlemeDialogAcik(false)} maxWidth="sm" fullWidth>
      <DialogTitle>Öğretmen Düzenle</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          label="Adı"
          value={duzenlenenOgretmen.ad}

          onChange={(e) => setDuzenlenenOgretmen({ ...duzenlenenOgretmen, ad: e.target.value })}
          margin="normal"
        />

        <TextField
          fullWidth
          label="Soyadı"
          value={duzenlenenOgretmen.soyad}
          onChange={(e) => setDuzenlenenOgretmen({ ...duzenlenenOgretmen, soyad: e.target.value })}

          margin="normal"
        />

        <TextField
          fullWidth
          label="Okul Adı"
          value={duzenlenenOgretmen.okulAdi}

          onChange={(e) => setDuzenlenenOgretmen({ ...duzenlenenOgretmen, okulAdi: e.target.value })}
          margin="normal"
        />

        <FormControl fullWidth margin="dense">

          <InputLabel id="sehir-select-label-duzenle">Şehir</InputLabel>
          <Select
            labelId="sehir-select-label-duzenle"
            id="sehir-select-duzenle"
            value={duzenlenenOgretmen.sehirId}
            onChange={handleSehirChangeDuzenle}

            displayEmpty

          >
            <MenuItem value="">
              <em>Şehir Seçiniz</em>
            </MenuItem>
            {sehirListesi.map((sehir) => (
              <MenuItem key={sehir._id} value={sehir._id}>
                {sehir.sehir_adi}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {duzenlenenOgretmen.sehirId && (
          <FormControl fullWidth margin="dense">
            <InputLabel id="ilce-select-label-duzenle">İlçe</InputLabel>
            <Select
              labelId="ilce-select-label-duzenle"
              id="ilce-select-duzenle"

              value={duzenlenenOgretmen.ilceId}

              onChange={handleIlceChangeDuzenle}
              displayEmpty
              renderValue={(selected) => {
                if (selected) {
                  const secilenIlce = duzenlenenOgretmen.ilceler.find(ilce => ilce._id === selected);
                  return secilenIlce ? secilenIlce.ilce_adi : 'İlçe bulunamadı';
                }
                return <em>İlçe Seçiniz</em>;

              }}
            >
              {duzenlenenOgretmen.ilceler?.map((ilce) => (

                <MenuItem key={ilce._id} value={ilce._id}>
                  {ilce.ilce_adi}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <TextField
          fullWidth
          label="Email"
          value={duzenlenenOgretmen.email}

          onChange={(e) => setDuzenlenenOgretmen({ ...duzenlenenOgretmen, email: e.target.value })}

          margin="normal"
        />
        <TextField
          fullWidth
          label="Telefon"
          value={duzenlenenOgretmen.telefon}

          onChange={(e) => setDuzenlenenOgretmen({ ...duzenlenenOgretmen, telefon: e.target.value })}
          margin="normal"
        />

        {/* Durum Seçimi */}
        <FormControl fullWidth margin="normal">
          <InputLabel id="status-select-label-duzenle">Durum</InputLabel>
          <Select
            labelId="status-select-label-duzenle"
            id="status-select-duzenle"
            value={duzenlenenOgretmen.status}
            onChange={(e) => setDuzenlenenOgretmen({ ...duzenlenenOgretmen, status: e.target.value })}
          >
            <MenuItem value="active">Aktif</MenuItem>
            <MenuItem value="inactive">Pasif</MenuItem>
          </Select>
        </FormControl>

        {/* Şifre Güncelleme Alanı */}
        <TextField
          fullWidth
          label="Yeni Şifre (isteğe bağlı)"
          type="password"
          value={duzenlenenOgretmen.password}
          onChange={(e) => setDuzenlenenOgretmen({ ...duzenlenenOgretmen, password: e.target.value })}
          margin="normal"
          helperText="Şifreyi güncellemek istiyorsanız buraya yeni şifreyi giriniz."
        />

      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDuzenlemeDialogAcik(false)}>İptal</Button>
        <Button onClick={handleGuncelle} color="primary" variant="contained">Güncelle</Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Paper style={{ padding: 20 }}>
      <Grid container spacing={3}>
        {/* Öğretmen Kayıt Formu */}
        <Grid item xs={12}>
          <Typography variant="h5" component="h3" marginBottom={2}>
            Öğretmen Kayıt Formu
          </Typography>
         
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                label="Adı"
                value={ad}
                onChange={(e) => setAd(e.target.value)}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                label="Soyadı"
                value={soyad}
                onChange={(e) => setSoyad(e.target.value)}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                label="Okul Adı (isteğe bağlı)"
                value={okulAdi}
                onChange={(e) => setOkulAdi(e.target.value)}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth margin="normal">
                <InputLabel id="sehir-select-label">Şehir</InputLabel>
                <Select
                  labelId="sehir-select-label"
                  value={secilenSehirId}
                  onChange={handleSehirChange}
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected === "") {
                      return <em>Şehir Seçiniz</em>;
                    }
                    const secilenSehir = sehirListesi.find(
                      (sehir) => sehir._id === selected
                    );
                    return secilenSehir
                      ? secilenSehir.sehir_adi
                      : <em>Şehir Seçiniz</em>;
                  }}
                >
                  <MenuItem disabled value="">
                    <em>Şehir Seçiniz</em>
                  </MenuItem>
                  {sehirListesi.map((sehir) => (
                    <MenuItem key={sehir._id} value={sehir._id}>
                      {sehir.sehir_adi}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
  
            {secilenSehirId && (
              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth margin="normal">
                  <InputLabel id="ilce-select-label">İlçe</InputLabel>
                  <Select
                    labelId="ilce-select-label"
                    id="ilce-select"
                    value={secilenIlceId}
                    onChange={handleIlceChange}
                    displayEmpty
                    renderValue={(selected) => {
                      if (!selected) {
                        return <em>İlçe Seçiniz</em>;
                      }
                      const secilenIlce = secilenSehirinIlceleri.find(
                        (ilce) => ilce._id === selected
                      );
                      return secilenIlce
                        ? secilenIlce.ilce_adi
                        : <em>İlçe Seçiniz</em>;
                    }}
                  >
                    <MenuItem disabled value="">
                      <em>İlçe Seçiniz</em>
                    </MenuItem>
                    {secilenSehirinIlceleri.map((ilce) => (
                      <MenuItem key={ilce._id} value={ilce._id}>
                        {ilce.ilce_adi}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
  
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                label="Telefon"
                value={telefon}
                onChange={handleTelefonChange}
                error={telefonHata}
                helperText={
                  telefonHata
                    ? "Telefon numarası 05XX XXX XXXX formatında olmalıdır."
                    : ""
                }
                margin="normal"
              />
            </Grid>
  
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth margin="normal">
                <InputLabel id="status-select-label">Durum</InputLabel>
                <Select
                  labelId="status-select-label"
                  id="status-select"
                  value={durum}
                  onChange={(e) => setDurum(e.target.value)}
                >
                  <MenuItem value="active">Aktif</MenuItem>
                  <MenuItem value="inactive">Pasif</MenuItem>
                </Select>
              </FormControl>
            </Grid>
  
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                margin="normal"
              />
            </Grid>
  
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                label="Şifre"
                value={sifre}
                onChange={(e) => setSifre(e.target.value)}
                margin="normal"
                type="password"
              />
            </Grid>
  
            <Grid item xs={12} sm={6} md={4} alignSelf="center">
              <Button
                onClick={handleEkle}
                variant="contained"
                color="primary"
                fullWidth
              >
                Ekle
              </Button>
            </Grid>
          </Grid>
        </Grid>
  
        {/* Öğretmen Tablosu */}
        <Grid item xs={12} md={12}>


        <Box mb={2}>
            <Typography variant="h5" component="h3" gutterBottom>
              Öğretmen Listesi
            </Typography>
          </Box>

          <Box component={Paper} padding={2} elevation={3}>
            {/* Filtreleme Alanları */}
            <Box mb={3}>
              <Grid container spacing={2} alignItems="center">
                {/* Arama Çubuğu */}
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    fullWidth
                    label="Ara..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    margin="normal"
                  />
                </Grid>

                {/* Durum Filtreleme */}
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel id="status-filter-label">Durum</InputLabel>
                    <Select
                      labelId="status-filter-label"
                      value={statusFilter}
                      onChange={(e) => setStatusFilter(e.target.value)}
                      displayEmpty
                    >
                      <MenuItem value="">
                        <em>Tüm Durumlar</em>
                      </MenuItem>
                      <MenuItem value="active">Aktif</MenuItem>
                      <MenuItem value="inactive">Pasif</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {/* Şehir Filtreleme */}
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel id="city-filter-label">Şehir</InputLabel>
                    <Select
                      labelId="city-filter-label"
                      value={cityFilter}
                      onChange={(e) => {
                        setCityFilter(e.target.value);
                        setIlceFilter(''); // Şehir değiştiğinde ilçeyi sıfırla
                      }}
                      displayEmpty
                    >
                      <MenuItem value="">
                        <em>Tüm Şehirler</em>
                      </MenuItem>
                      {sehirListesi.map((sehir) => (
                        <MenuItem key={sehir._id} value={sehir._id}>
                          {sehir.sehir_adi}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                {/* İlçe Filtreleme (Şehir seçildiğinde görünür) */}
                {cityFilter && (
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth margin="normal">
                      <InputLabel id="ilce-filter-label">İlçe</InputLabel>
                      <Select
                        labelId="ilce-filter-label"
                        value={ilceFilter}
                        onChange={(e) => setIlceFilter(e.target.value)}
                        displayEmpty
                      >
                        <MenuItem value="">
                          <em>Tüm İlçeler</em>
                        </MenuItem>
                        {sehirListesi
                          .find((sehir) => sehir._id === cityFilter)
                          ?.ilceler.map((ilce) => (
                            <MenuItem key={ilce._id} value={ilce._id}>
                              {ilce.ilce_adi}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                {/* Filtreleri Sıfırla Butonu */}
                <Grid item xs={12} sm={6} md={4}>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      setSearchQuery('');
                      setStatusFilter('');
                      setCityFilter('');
                      setIlceFilter('');
                    }}
                    sx={{ marginTop: '16px' }}
                  >
                    Filtreleri Sıfırla
                  </Button>
                </Grid>
              </Grid>
            </Box>

          <TableContainer component={Paper}>  
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Adı</TableCell>
                  <TableCell>Soyadı</TableCell>
                  <TableCell>Şifre</TableCell>
                  <TableCell>Telefon</TableCell>
                  <TableCell>Okul</TableCell>
                  <TableCell>E-posta</TableCell>
                  <TableCell>Şehir</TableCell>
                  <TableCell>İlçe</TableCell>
                  <TableCell>Bakiye (TL)</TableCell> 
                  <TableCell>Durum</TableCell>
                  <TableCell>İşlemler</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredOgretmenListesi.map((ogretmen) => (
                  <TableRow key={ogretmen._id}>
                    <TableCell>{ogretmen.ad}</TableCell>
                    <TableCell>{ogretmen.soyad}</TableCell>
                    <TableCell>{ogretmen.password_open}</TableCell>
                    <TableCell>{ogretmen.telefon}</TableCell>
                    <TableCell>{ogretmen.okul_adi}</TableCell>
                    <TableCell>{ogretmen.email}</TableCell>
                    <TableCell>{ogretmen.sehirAdi}</TableCell>
                    <TableCell>{ogretmen.ilceAdi}</TableCell>
                    <TableCell>{ogretmen.bakiye !== undefined && ogretmen.bakiye !== null ? ogretmen.bakiye : '0'}</TableCell> 
                  
                    <TableCell>
                      <Chip
                        label={
                          ogretmen.status === "active" ? "Aktif" : "Pasif"
                        }
                        color={
                          ogretmen.status === "active" ? "success" : "error"
                        }
                        onClick={() =>
                          handleStatusChange(ogretmen._id, ogretmen.status)
                        }
                        sx={{
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "lightgray",
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Stack direction="row" spacing={1}>
                      <IconButton onClick={() => handleAdjustBalance(ogretmen)}
                            variant="contained"
                            color="success"
                            size="small"
                            startIcon={<AttachMoneyIcon />}
                            sx={{
                              '&:hover': {
                                backgroundColor: 'green',
                              },
                              boxShadow: 'none',
                            }}
                          >
                            <AttachMoneyIcon />
                          </IconButton>
                        <Button
                          variant="contained"
                          color="primary"
                          startIcon={<EditIcon />}
                          onClick={() => handleEdit(ogretmen._id)}
                          sx={{
                            mr: 1,
                            "&:hover": {
                              backgroundColor: "lightblue",
                              color: "#fff",
                            },
                          }}
                        >
                          Düzenle
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          startIcon={<DeleteIcon />}
                          onClick={() => handleDeleteOgretmen(ogretmen._id)}
                        >
                          Sil
                        </Button>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          </Box>
        </Grid>
      </Grid>
      <>
      <Dialog open={balanceDialogOpen} onClose={() => setBalanceDialogOpen(false)}>
      <DialogTitle>Bakiye Güncelle</DialogTitle>
      <DialogContent>
        <Typography>Öğretmen: {selectedKurum?.ad} {selectedKurum?.soyad} </Typography>
        <TextField
          margin="dense"
          label="Miktar"
          type="number"
          fullWidth
          value={balanceAmount}
          onChange={(e) => setBalanceAmount(Number(e.target.value))}
        />
        <TextField
          margin="dense"
          label="Neden"
          type="text"
          fullWidth
          value={balanceReason}
          onChange={(e) => setBalanceReason(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setBalanceDialogOpen(false)}>İptal</Button>
        <Button onClick={handleBalanceSubmit}>Güncelle</Button>
      </DialogActions>
    </Dialog>
        <ResultDialog
          open={eklemeBasariliDialogOpen}
          onClose={() => setEklemeBasariliDialogOpen(false)}
          title={
            dialogSeverity === "error" ? "Hata Oluştu" : "İşlem Başarılı"
          }
          message={dialogContent}
          severity={dialogSeverity}
        />
        <ResultDialog
          open={resultDialogOpen}
          onClose={handleDialogClose}
          title={"İşlem Sonucu"}
          message={resultDialogMessage}
          severity={resultDialogSeverity}
        />
        {renderDuzenlemeFormuDialog()}
      </>
    </Paper>
  );

}

export default Ogretmen;
