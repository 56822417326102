// src/pages/ogrenci/oyunlar/components/GameControls.js

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faRedo } from '@fortawesome/free-solid-svg-icons';

const GameControls = ({ isPlaying, handlePlayPause, handleReset }) => {
  return (
    <div className="game-controls">
      <button className={`btn btn-${isPlaying ? 'warning' : 'success'} me-2`} onClick={handlePlayPause}>
        <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} /> {isPlaying ? 'Duraklat' : 'Başlat'}
      </button>
      <button className="btn btn-secondary" onClick={handleReset}>
        <FontAwesomeIcon icon={faRedo} /> Yeniden Başlat
      </button>
    </div>
  );
};

export default GameControls;
