import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Typography, Drawer, List, ListItem, ListItemIcon, ListItemText, CssBaseline, Grid, Card, CardContent, Avatar, Collapse, Divider, Box,  useMediaQuery, useTheme,} from '@mui/material';
import { Menu as MenuIcon, Dashboard as DashboardIcon, Settings as SettingsIcon, Logout as LogoutIcon, LockReset as LockResetIcon, AccountCircle as AccountCircleIcon,
  ExpandLess, ExpandMore, Person as PersonIcon, Phone as PhoneIcon, LocationCity as LocationCityIcon, Home as HomeIcon, School as SchoolIcon, Report as ReportIcon, Payment as PaymentIcon,} from '@mui/icons-material';
import { API_URLS } from '../../config/config';
import useFetchUserData from '../../hooks/ogrenci/useFetchUserData';
import Password from './ayarlar/Password';
import Profil from './ayarlar/Profil';
import StageList from './akillikurs/StageList';
import ExerciseList from './akillikurs/ExerciseList';
import ProgressReport from './raporlar/ProgressReport';
import { styled } from '@mui/system';
import PaymentForm from './payment/PaymentForm';
import PaymentHistory from './payment/PaymentHistory';

const drawerWidth = 240;

// Styled components with updated styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: open ? `${drawerWidth}px` : 0,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
    marginTop: '64px',
    overflow: 'auto',
    height: `calc(100vh - 64px)`,
    backgroundImage: 'linear-gradient(to right, #a1c4fd, #c2e9fb)', // Daha açık tonlu gradient
    color: '#333333', // Genel metin rengi
    padding: theme.spacing(3),
  })
);

const AppBarStyled = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  backgroundImage: 'linear-gradient(135deg, #FF9A9E 0%, #FAD0C4 100%)',
  color: '#FFFFFF',
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
    },
  }),
}));

const DrawerStyled = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    backgroundImage: 'linear-gradient(135deg, #B06AB3 0%, #4568DC 100%)',
    color: '#FFFFFF',
  },
}));

function OgrenciDashboard() {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [userData, setUserData] = useState(null);
  const [open, setOpen] = useState({});

  const navigate = useNavigate();
  const [selectedMenu, setSelectedMenu] = useState('Dashboard');
  const [selectedStage, setSelectedStage] = useState(null);
  const location = useLocation();
  useFetchUserData(setUserData);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleClick = (menu) => {
    setOpen((prevOpen) => ({ ...prevOpen, [menu]: !prevOpen[menu] }));
  };

  useEffect(() => {
    if (location.state) {
      if (location.state.selectedMenu) {
        setSelectedMenu(location.state.selectedMenu);
      }
      if (location.state.selectedStage) {
        setSelectedStage(location.state.selectedStage);
      }
    }
  }, [location.state]);

  const handleLogout = async () => {
    try {
      await fetch(API_URLS.STUDENT_URL + 'logout', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'Content-Type': 'application/json',
        },
      });
      localStorage.removeItem('userToken');
      navigate('/ogrenci-giris');
    } catch (error) {
      console.error('Çıkış işlemi sırasında bir hata oluştu', error);
    }
  };

  const menuItems = [
    { text: 'Anasayfa', icon: <HomeIcon />, action: () => navigate('/') },
    { text: 'Dashboard', icon: <DashboardIcon />, action: () => setSelectedMenu('Dashboard') },
    {
      text: 'Hesabını Aktif Et',
      icon: <PaymentIcon />,
      action: () => setSelectedMenu('BakiyeYukle'),
    },
    {
      text: 'Akıllı Kurs',
      icon: <SchoolIcon />,
      action: () => setSelectedMenu('AkilliKurs'),
    },
    {
      text: 'Raporlar',
      icon: <ReportIcon />,
      action: () => setSelectedMenu('Raporlar'),
    },
    {
      text: 'Satın Alınan Paketler',
      icon: <PaymentIcon />,
      action: () => setSelectedMenu('PaymentHistory'),
    },
    {
      text: 'Ayarlar',
      icon: <SettingsIcon />,
      subMenus: [
        {
          text: 'Şifre Güncelle',
          icon: <LockResetIcon />,
          action: () => setSelectedMenu('SifreSet'),
        },
        {
          text: 'Profil',
          icon: <AccountCircleIcon />,
          action: () => setSelectedMenu('Profil'),
        },
      ],
      action: () => handleClick('Ayarlar'),
    },
    { text: 'Çıkış Yap', icon: <LogoutIcon />, action: handleLogout },
  ];

  const renderContent = () => {
    if (!userData) {
      return <div>Yükleniyor...</div>;
    }

    switch (selectedMenu) {
      case 'Dashboard':
        return (
          <Card
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.9)', // Daha opak arka plan
            color: '#333333', // Daha koyu metin rengi
            mb: 3,
            boxShadow: 3,
            borderRadius: 2,
          }}

          
        >
          <CardContent>
            <Typography
              variant="h5"
              sx={{
                textAlign: 'center',
                fontWeight: 'bold',
                mb: 2,
               
                color: '#2e59d9', // Başlık için belirgin renk
              }}
            >
              PROFİL BİLGİLERİ
            </Typography>
            <Divider sx={{ backgroundColor: '#2e59d9', mb: 2 }} />
            <Grid container spacing={2} justifyContent="center" sx={{ mt: 2 }}>
              <Grid item>
                <Avatar
                  sx={{
                    backgroundColor: '#2e59d9',
                    color: '#FFFFFF',
                    width: 100,
                    height: 100,
                  }}
                >
                  <PersonIcon sx={{ fontSize: '3rem' }} />
                </Avatar>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  align="center"
                  sx={{ fontFamily: 'Roboto, sans-serif', color: '#2e59d9', fontWeight: '500' }}
                >
                  {userData.ad} {userData.soyad}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  align="center"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: '#333333',
                  }}
                >
                  <PhoneIcon sx={{ mr: 1, fontSize: '1.5rem', color: '#2e59d9' }} />
                  {userData.telefon}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  align="center"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: '#333333',
                  }}
                >
                  <LocationCityIcon sx={{ mr: 1, fontSize: '1.5rem', color: '#2e59d9' }} />
                  {userData.sehirAdi} / {userData.ilceAdi}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" align="center" sx={{ color: '#333333' }}>
                  Hesap Durumu: {userData.status === 'active' ? 'Aktif' : 'Pasif'}
                </Typography>
              </Grid>
              {userData.abonelik && (
                <>
                  <Grid item xs={12}>
                    <Typography variant="body1" align="center" sx={{ color: '#333333' }}>
                      Abonelik Başlangıç Tarihi:{' '}
                      {new Date(userData.abonelik.baslangicTarihi).toLocaleDateString()}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" align="center" sx={{ color: '#333333' }}>
                      Abonelik Bitiş Tarihi:{' '}
                      {new Date(userData.abonelik.bitisTarihi).toLocaleDateString()}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </CardContent>
        </Card>
        );

      case 'BakiyeYukle':
        return <PaymentForm userType="Ogrenci" userId={userData._id} />;

      case 'AkilliKurs':
        return (
          <StageList
            onStageSelect={(stage) => {
              setSelectedStage(stage);
              setSelectedMenu('ExerciseList');
            }}
            userData={userData}
          />
        );

      case 'ExerciseList':
        return (
          <ExerciseList
            stage={selectedStage}
            userData={userData}
            onBack={() => setSelectedMenu('AkilliKurs')}
          />
        );

      case 'Raporlar':
        return <ProgressReport userData={userData} />;

      case 'PaymentHistory':
        return <PaymentHistory />;

      case 'SifreSet':
        return <Password />;
      case 'Profil':
        return <Profil />;

      default:
        return <div>404: Sayfa Bulunamadı</div>;
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBarStyled position="fixed" open={drawerOpen}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            edge="start"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ fontFamily: 'Comic Sans MS', flexGrow: 1 }}
          >
            Öğrenci Paneli
          </Typography>
        </Toolbar>
      </AppBarStyled>
      <DrawerStyled
        variant={isMobile ? 'temporary' : 'persistent'}
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer}
      >
        <Toolbar />
        <Divider sx={{ backgroundColor: 'rgba(255, 255, 255, 0.2)' }} />
        <List>
          {menuItems.map((item, index) => (
            <React.Fragment key={index}>
              <ListItem
                button
                onClick={() => {
                  item.action && item.action();
                  if (isMobile) toggleDrawer(); // Mobilde menüden seçim yapınca drawer kapanır
                }}
                sx={{
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  },
                  backgroundColor:
                    selectedMenu === item.text ? 'rgba(255, 255, 255, 0.2)' : 'transparent',
                }}
              >
                <ListItemIcon sx={{ color: '#FFFFFF' }}>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
                {item.subMenus ? (open['Ayarlar'] ? <ExpandLess /> : <ExpandMore />) : null}
              </ListItem>
              {item.subMenus && (
                <Collapse in={open['Ayarlar']} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item.subMenus.map((subItem, subIndex) => (
                      <ListItem
                        button
                        key={subIndex}
                        onClick={() => {
                          subItem.action();
                          if (isMobile) toggleDrawer(); 
                        }}
                        sx={{
                          pl: 4,
                          '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' },
                        }}
                      >
                        <ListItemIcon sx={{ color: '#FFFFFF' }}>{subItem.icon}</ListItemIcon>
                        <ListItemText primary={subItem.text} />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              )}
            </React.Fragment>
          ))}
        </List>
      </DrawerStyled>
      <Main open={drawerOpen}>{renderContent()}</Main>
    </Box>
  );
}

export default OgrenciDashboard;
