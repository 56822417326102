// HangiHarfiGordun.js

import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate, useLocation } from 'react-router-dom';
import GameOverModal from './components/GameOverModal';
import GameHeader from './components/GameHeader';
import { API_URLS } from '../../../config/config';
import './styles/style.css';
import axios from 'axios';

function HangiHarfiGordun() {
  const navigate = useNavigate();
  const location = useLocation();
  const { stage, exercise } = location.state || {};

  const [difficulty, setDifficulty] = useState('kolay');
  const [letters, setLetters] = useState([]);
  const [input, setInput] = useState('');
  const [score, setScore] = useState(0);
  const [gameActive, setGameActive] = useState(false);
  const [timeLeft, setTimeLeft] = useState(40);
  const [showLetters, setShowLetters] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showIntroduction, setShowIntroduction] = useState(true);
  const canvasRef = useRef(null);

  const [userData, setUserData] = useState(null);
  const [userRole, setUserRole] = useState(null);

  const [displayDuration, setDisplayDuration] = useState(1000);

  const token = localStorage.getItem('userToken');

  const totalTime = userData
    ? userData.status === 'inactive'
      ? 15
      : 40
    : 15;

  // Kullanıcı verilerini ve rolünü alıyoruz
  useEffect(() => {
    const fetchUserData = async () => {
      if (token) {
        try {
          const studentResponse = await fetch(`${API_URLS.STUDENT_URL}/me`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
          if (studentResponse.ok) {
            const studentData = await studentResponse.json();
            setUserData(studentData);
            setUserRole('student');
            return;
          }
        } catch (error) {
          console.error('Öğrenci verisi alınırken hata oluştu:', error);
        }

        try {
          const teacherResponse = await fetch(`${API_URLS.TEACHER_URL}/me`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
          if (teacherResponse.ok) {
            const teacherData = await teacherResponse.json();
            setUserData(teacherData);
            setUserRole('teacher');
            return;
          }
        } catch (error) {
          console.error('Öğretmen verisi alınırken hata oluştu:', error);
        }

        setUserData(null);
        setUserRole(null);
      }
    };

    fetchUserData();
  }, [token]);

  useEffect(() => {
    if (gameActive && timeLeft > 0) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer);
    } else if (timeLeft === 0) {
      onGameOver();
    }
  }, [timeLeft, gameActive]);

  useEffect(() => {
    if (gameActive && showLetters) {
      drawCanvas();
    } else {
      clearCanvas();
    }
  }, [gameActive, showLetters, letters]);

  useEffect(() => {
    if (gameActive) {
      generateLetters();
    }
  }, [gameActive]);

  const enterGame = () => {
    setShowIntroduction(false);
  };

  const startGame = () => {
    setScore(0);
    setTimeLeft(totalTime);
    setGameActive(true);
  };

  const stopGame = () => {
    setGameActive(false);
  };

  const resetGame = () => {
    clearCanvas();
    setGameActive(false);
    setScore(0);
    setTimeLeft(totalTime);
    setLetters([]);
    setInput('');
    setShowLetters(false);
    setShowModal(false);
    setShowIntroduction(false);
  };

  const onGameOver = () => {
    setGameActive(false);
    setShowModal(true);
    updateProgress();
  };

  const generateLetters = () => {
    if (!canvasRef.current) return;
  
    let letterCount = 2;
    switch (difficulty) {
      case 'kolay':
        letterCount = 2;
        break;
      case 'orta':
        letterCount = 3;
        break;
      case 'zor':
        letterCount = Math.floor(Math.random() * 2) + 4; 
        break;
      default:
        letterCount = 2;
    }
  
    const canvas = canvasRef.current;
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
  
    // Zaman ilerledikçe yarıçapı artır
    const maxRadius = canvas.width / 2 - 50; // Canvas sınırlarını aşmamak için
    const minRadius = 50;
    const elapsed = totalTime - timeLeft;
    const baseRadius = minRadius + ((maxRadius - minRadius) * elapsed) / totalTime;
  
    const lettersArray = [];
    const positions = [];
  
    // Harflerin yönlerini belirle
    let directions = [];
  
    if (letterCount === 2) {
      // 2 harf varsa, biri doğuda, biri batıda
      directions = [1, -1];
    } else {
      // 2'den fazla harf varsa, en az bir doğuda, en az bir batıda
      directions = [1, -1];
      // Kalan harfler rastgele doğu veya batı
      for (let i = 2; i < letterCount; i++) {
        directions.push(Math.random() < 0.5 ? -1 : 1);
      }
      // Shuffle directions array to randomize positions
      directions = shuffleArray(directions);
    }
  
    for (let i = 0; i < letterCount; i++) {
      let direction = directions[i];
  
      // Her harf için yarıçapı biraz artırarak çakışmayı önleyelim
      const radius = baseRadius + i * 30;
  
      const x = centerX + direction * radius;
      const y = centerY;
  
      // Çakışmayı kontrol et
      const overlap = positions.some((pos) => {
        const dx = pos.x - x;
        const distance = Math.abs(dx);
        return distance < 30; // Harfler arasında minimum mesafe
      });
  
      if (!overlap) {
        positions.push({ x });
        const turkishAlphabet = [
          'A','B','C','Ç','D','E','F','G','Ğ','H','I','İ',
          'J','K','L','M','N','O','Ö','P','R','S','Ş','T',
          'U','Ü','V','Y','Z',
        ];
        const randomLetter = turkishAlphabet[Math.floor(Math.random() * turkishAlphabet.length)];
        lettersArray.push({ value: randomLetter, x, y });
      } else {
        // Eğer çakışma varsa, aynı yönü tekrar dene
        i--; // Tekrar dene
      }
    }
  
    setLetters(lettersArray);
    setShowLetters(true);
  
    setTimeout(() => {
      setShowLetters(false);
      clearCanvas();
    }, displayDuration);
  };
  
  // Yardımcı Fonksiyon: Diziyi Karıştırma (Fisher-Yates Algoritması)
  const shuffleArray = (array) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };
  


  const handleSubmit = (e) => {
    e.preventDefault();

    let userInput = input
      .trim()
      .split(/[\s,]+/)
      .map((str) => str.toUpperCase());
    let correctLetters = letters.map((letterObj) => letterObj.value);

    const sortedUserInput = [...userInput].sort();
    const sortedCorrectLetters = [...correctLetters].sort();

    const isCorrect =
      sortedUserInput.length === sortedCorrectLetters.length &&
      sortedUserInput.every(
        (letter, idx) => letter === sortedCorrectLetters[idx]
      );

    if (isCorrect) {
      setScore(score + 1);
      setInput('');
      generateLetters();
    } else {
      alert('Yanlış cevap!');
      onGameOver();
    }
  };

  const drawCanvas = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    clearCanvas();

    // Ortadaki gri nokta
    ctx.beginPath();
    ctx.arc(centerX, centerY, 10, 0, 2 * Math.PI); 
    ctx.fillStyle = '#888';
    ctx.fill();

    letters.forEach((letterObj) => {
      const x = letterObj.x;
      const y = letterObj.y;

      ctx.fillStyle = '#007acc';
      ctx.font = '34px Arial';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(letterObj.value, x, y);
    });
  };

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    }
  };

  const handleReturnToPreviousStage = () => {
    if (stage) {
      navigate(
        userRole === 'student' ? '/ogrenci-dashboard' : '/ogretmen-dashboard',
        {
          state: {
            selectedMenu: 'ExerciseList',
            selectedStage: stage,
          },
        }
      );
    } else {
      alert('Aşama bilgisi bulunmamaktadır.');
    }
  };

  const updateProgress = async () => {
    if (!userData || !userRole) return;
    const gameTime = totalTime - timeLeft;
    try {
      const apiUrl =
        userRole === 'student'
          ? `${API_URLS.STUDENT_URL}akillikurs/progress/update-game`
          : `${API_URLS.TEACHER_URL}akillikurs/progress/update-game`;

      await axios.post(
        apiUrl,
        {
          stageNumber: stage?.number || 1,
          gameName: exercise?.gameName || 'Hangi Harfi Gördün?',
          score: score,
          time: gameTime,
          completed: true,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
    } catch (error) {
      console.error('İlerleme güncellenirken hata oluştu:', error);
    }
  };

  const handleDifficultyChange = (e) => {
    const newDifficulty = e.target.value;
    setDifficulty(newDifficulty);
    if (gameActive) {
      resetGame();
    }
  };

  const handleDisplayDurationChange = (e) => {
    const newDuration = Number(e.target.value);
    setDisplayDuration(newDuration);
  };

  return (
    <div className="container-fluid px-0">
      {showIntroduction ? (
        <div className="vh-100 d-flex flex-column justify-content-center align-items-center bg-light">
          <div className="row justify-content-center mb-4">
            <div className="col-12 col-md-8 col-lg-8">
              <div className="card shadow border-0 rounded-lg text-center p-4">
                <div className="card-body">
                  <GameHeader
                    handleReturnToPreviousStage={handleReturnToPreviousStage}
                  />
                  <h1 className="display-5 mb-4 fw-bold text-dark">
                    Hangi Harfi Gördün?
                  </h1>
                  <p className="lead mb-4 text-muted">
                    Bu oyunda, ekranda kısa bir süreliğine gösterilen harfleri
                    hatırlayıp doğru şekilde girmeye çalışacaksınız.
                  </p>
                  <div className="instructions">
                <h3>Nasıl Oynanır?</h3>
                <ul>
                  <li className="mb-2">Harfler ekranda belirecek, dikkatlice izleyin.</li>
                  <li className="mb-2">Harfler kaybolduktan sonra gördüğünüz harfleri girin.</li>
                  <li>Zorluk seviyesini seçerek oyunu özelleştirebilirsiniz.</li>
                </ul>
              </div>


                  <button
                    onClick={enterGame}
                    className="btn btn-success mt-4 px-4 py-2"
                    style={{ fontSize: '1rem', fontWeight: '600' }}
                  >
                    Oyuna Gir
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {/* Oyun ekranı */}
          <div className="container-fluid ">
            <div className="row justify-content-center pt-5">
              <div className="col-12 col-md-10 col-lg-8">
                <div className="card shadow border-0 rounded-lg text-center p-4">
                  <div className="card-body">
                    <GameHeader
                      handleReturnToPreviousStage={handleReturnToPreviousStage}
                    />
                    <div className="row mb-3">
                      {/* Zorluk ve ayarlar */}
                      <div className="col-12 col-md-4 mb-2 mb-md-0">
                        <label htmlFor="difficultySelect" className="form-label">
                          Zorluk Seviyesi:
                        </label>
                        <select
                          className="form-select"
                          id="difficultySelect"
                          value={difficulty}
                          onChange={handleDifficultyChange}
                        >
                          <option value="kolay">Kolay</option>
                          <option value="orta">Orta</option>
                          <option value="zor">Zor</option>
                        </select>
                      </div>
                      <div className="col-12 col-md-4 mb-2 mb-md-0">
                        <label
                          htmlFor="displayDurationRange"
                          className="form-label"
                        >
                          Gösterim Süresi:
                        </label>
                        <input
                          type="range"
                          className="form-range"
                          id="displayDurationRange"
                          min="500"
                          max="5000"
                          step="500"
                          value={displayDuration}
                          onChange={handleDisplayDurationChange}
                        />
                        <span>{displayDuration} ms</span>
                      </div>
                      <div className="col-12 col-md-4 d-flex align-items-center justify-content-center">
                        {gameActive ? (
                          <>
                            <button
                              onClick={stopGame}
                              className="btn btn-danger me-2"
                            >
                              Oyunu Durdur
                            </button>
                            <button onClick={resetGame} className="btn btn-warning">
                              Yeniden Başlat
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              onClick={startGame}
                              className="btn btn-success me-2"
                            >
                              Oyunu Başlat
                            </button>
                            <button onClick={resetGame} className="btn btn-warning">
                              Yeniden Başlat
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="status-bar mb-3">
                      <p>Kalan Süre: {timeLeft} saniye</p>
                      <p>Skor: {score}</p>
                    </div>
                   
                  

                      {/* Canvas her zaman render ediliyor */}
                      <div className="d-flex justify-content-center">
                          <canvas
                            ref={canvasRef}
                            width={400}
                            height={400}
                            className="canvas mb-3"
                            style={{ display: gameActive ? 'block' : 'none' }}
                          />
                        </div>
                      {gameActive && !showLetters && (
                        <form onSubmit={handleSubmit} className="input-form">
                          <input
                            type="text"
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            placeholder="Gördüğün harfleri gir (virgül veya boşlukla ayır)"
                            className="form-control mb-2"
                          />
                          <button type="submit" className="btn btn-primary">
                            Gönder
                          </button>
                        </form>
                      )}
                   

                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {showModal && (
        <GameOverModal
          score={score}
          onRestart={resetGame}
          userData={userData}
        />
      )}

  
  
    </div>
  );
}

export default HangiHarfiGordun;
