import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { fetchParagraphs } from './hooks/apiService';
import { useGameLogic } from './hooks/useGameLogic';
import NavigationButtons from './components/NavigationButtons';
import GameControls from './components/GameControls';
import TimerDisplay from './components/TimerDisplay';
import DropdownSelectors from './components/DropdownSelectors';
import GameBoard from './components/GameBoard';
import MessageDisplay from './components/MessageDisplay';
import GameExplanation from './components/GameExplanation';
import GameOverModal from './components/GameOverModal';
import './styles/KelimeBulmaOyunu.css';
import { API_URLS } from '../../../config/config';

const KelimeBulmaOyunu = () => {
  const [showInstructions, setShowInstructions] = useState(true);
  const [showGameOverModal, setShowGameOverModal] = useState(false);
  const [difficulty, setDifficulty] = useState('easy');
  const [paragraphsList, setParagraphsList] = useState([]);
  const [selectedParagraphIndex, setSelectedParagraphIndex] = useState(0);
  const [paragraph, setParagraph] = useState('');
  const [targetWords, setTargetWords] = useState([]);
  const [userData, setUserData] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { stage, exercise } = location.state || {};
  const token = localStorage.getItem('userToken');

  // Kullanıcı verilerini ve rolünü getirme
  useEffect(() => {
    const fetchUserData = async () => {
      if (token) {
        try {
          // Öğrenci verilerini getir
          const studentResponse = await fetch(`${API_URLS.STUDENT_URL}/me`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
          if (studentResponse.ok) {
            const studentData = await studentResponse.json();
            setUserData(studentData);
            setUserRole('student');
            return;
          }
        } catch (error) {
          console.error('Öğrenci verisi alınırken hata oluştu:', error);
        }

        try {
          // Öğretmen verilerini getir
          const teacherResponse = await fetch(`${API_URLS.TEACHER_URL}/me`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
          if (teacherResponse.ok) {
            const teacherData = await teacherResponse.json();
            setUserData(teacherData);
            setUserRole('teacher');
            return;
          }
        } catch (error) {
          console.error('Öğretmen verisi alınırken hata oluştu:', error);
        }

        // Eğer her ikisi de başarısız olursa
        setUserData(null);
        setUserRole(null);
      }
    };

    fetchUserData();
  }, [token]);

  // Zorluk derecesi değiştiğinde paragrafları getir
  useEffect(() => {
    async function fetchData() {
      try {
        const data = await fetchParagraphs(difficulty);
        setParagraphsList(data);
        if (data.length > 0) {
          setSelectedParagraphIndex(0);
        } else {
          setParagraph('');
          setTargetWords([]);
        }
      } catch (error) {
        console.error('Paragraflar alınırken hata oluştu:', error);
      }
    }
    fetchData();
  }, [difficulty]);

  // Paragraf veya seçili indeks değiştiğinde paragrafı ve hedef kelimeleri güncelle
  useEffect(() => {
    if (paragraphsList.length > 0) {
      const newParagraph = paragraphsList[selectedParagraphIndex]?.content || '';
      setParagraph(newParagraph);

      const words = new Set(
        newParagraph
          .split(/\s+/)
          .map((word) =>
            word.toLowerCase().replace(/[^\p{L}\p{N}_]+/gu, '')
          )
      );
      setTargetWords(
        Array.from(words)
          .filter((word) => word.length > 0)
          .sort(() => 0.5 - Math.random())
          .slice(0, 5)
      );
    } else {
      setParagraph('');
      setTargetWords([]);
    }
  }, [paragraphsList, selectedParagraphIndex]);

  const {
    selectedWords,
    correctWords,
    incorrectWords,
    isPlaying,
    timeRemaining,
    score,
    remainingAttempts,
    message,
    handleWordClick,
    handlePlayPause,
    handleReset,
  } = useGameLogic(paragraphsList, selectedParagraphIndex, targetWords, userData, paragraph);

  const startGame = () => {
    setShowInstructions(false);
    handleReset();
  };

  const handleRestart = () => {
    setShowGameOverModal(false);
    handleReset();
  };

  const onGameOver = () => {
    setShowGameOverModal(true);
    updateProgress();
  };

  useEffect(() => {
    if (
      timeRemaining === 0 ||
      remainingAttempts === 0 ||
      (correctWords.length === targetWords.length && targetWords.length > 0)
    ) {
      onGameOver();
    }
  }, [timeRemaining, remainingAttempts, correctWords, targetWords]);

  const updateProgress = async () => {
    try {
      if (userData && userRole) {
        const apiUrl =
          userRole === 'student'
            ? `${API_URLS.STUDENT_URL}akillikurs/progress/update-game`
            : `${API_URLS.TEACHER_URL}akillikurs/progress/update-game`;

        await axios.post(
          apiUrl,
          {
            stageNumber: stage?.number || 1,
            gameName: exercise?.gameName || 'Kelime Bulma ve Seçme Oyunu',
            score: score,
            time: (userData.status === 'inactive' ? 15 : 60) - timeRemaining,
            wordCount: correctWords.length,
            completed: true,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
      }
    } catch (error) {
      console.error('İlerleme güncellenirken hata oluştu:', error);
    }
  };

  const handleReturnToPreviousStage = () => {
    if (stage) {
      navigate(
        userRole === 'student' ? '/ogrenci-dashboard' : '/ogretmen-dashboard',
        {
          state: {
            selectedMenu: 'ExerciseList',
            selectedStage: stage,
          },
        }
      );
    } else {
      alert('Aşama bilgisi bulunmamaktadır.');
    }
  };

  // Zorluk derecesi değiştiğinde seçili paragrafı sıfırla
  const handleDifficultyChange = (e) => {
    setDifficulty(e.target.value);
    setSelectedParagraphIndex(0);
  };

  // Paragraf seçildiğinde indeksini güncelle
  const handleParagraphChange = (e) => {
    setSelectedParagraphIndex(parseInt(e.target.value));
  };

  return (
    <div className="container-fluid vh-75 d-flex flex-column justify-content-center align-items-center bg-light">
      {showInstructions ? (
   
   <div className="row justify-content-center mb-4 mt-8">
   <div className="col-12 col-md-8 col-lg-12">
     <div className="card shadow border-0 rounded-lg text-center p-4">
       <div className="card-body">
         <h1 className="display-5 mb-4 fw-bold text-dark">Kelime Bulma ve Seçme Oyunu</h1>
                  <GameExplanation />
                  <button
                    className="btn btn-primary mt-4 px-4 py-2"
                    onClick={startGame}
                    style={{ fontSize: '1rem', fontWeight: '600' }}
                  >
                    Oyuna Başla
                  </button>
                </div>
              </div>
              </div>
              </div>
      
      ) : (
        <div
          className="container-fluid"
          style={{ backgroundColor: '#f0f8ff' }}
        >
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8">
              <div className="card shadow border-0 rounded-lg text-center p-4">
                <div className="card-body">
                  <div className="navigation-container mb-3">
                    <NavigationButtons
                      handleReturnToPreviousStage={handleReturnToPreviousStage}
                    />
                  </div>
                  <div className="control-timer-container d-flex justify-content-between align-items-center">
                    <div className="game-controls">
                      <GameControls
                        isPlaying={isPlaying}
                        handlePlayPause={handlePlayPause}
                        handleReset={handleReset}
                      />
                    </div>
                    <div className="timer-display">
                      <TimerDisplay timeRemaining={timeRemaining} />
                    </div>
                  </div>
                  <DropdownSelectors
                    difficulty={difficulty}
                    selectedParagraphIndex={selectedParagraphIndex}
                    paragraphsList={paragraphsList}
                    handleDifficultyChange={handleDifficultyChange}
                    handleParagraphChange={handleParagraphChange}
                  />
                  <GameBoard
                    paragraph={paragraph}
                    selectedWords={selectedWords}
                    targetWords={targetWords}
                    handleWordClick={handleWordClick}
                    correctWords={correctWords} 
                  />
                  <MessageDisplay message={message} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showGameOverModal && (
        <GameOverModal
          score={score}
          onRestart={handleRestart}
          userData={userData}
        />
      )}
    </div>
  );
};

export default KelimeBulmaOyunu;
