import { useState, useEffect, useRef } from 'react';

export const useGameLogic = (
  paragraphsList,
  selectedParagraphIndex,
  targetWords,
  userData,
  paragraph
) => {
  const initialTime =
    !userData || userData.status === 'inactive' ? 15 : 60;

  const [selectedWords, setSelectedWords] = useState([]);
  const [correctWords, setCorrectWords] = useState([]);
  const [incorrectWords, setIncorrectWords] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(initialTime);
  const [score, setScore] = useState(0);
  const [remainingAttempts, setRemainingAttempts] = useState(10);
  const [message, setMessage] = useState('');
  const timerRef = useRef(null);

  useEffect(() => {
    // Oyun sıfırlandığında veya zorluk değiştiğinde süreyi güncelle
    setTimeRemaining(initialTime);
  }, [initialTime, paragraph]);

  useEffect(() => {
    if (isPlaying && timeRemaining > 0 && remainingAttempts > 0) {
      timerRef.current = setTimeout(() => {
        setTimeRemaining((prev) => prev - 1);
      }, 1000);
    } else if (
      timeRemaining === 0 ||
      remainingAttempts === 0 ||
      (correctWords.length === targetWords.length &&
        targetWords.length > 0)
    ) {
      setIsPlaying(false);
      if (correctWords.length === targetWords.length) {
        setMessage('Tüm kelimeleri buldunuz! Oyun sona erdi.');
      } else {
        setMessage('Oyun sona erdi!');
      }
    }

    return () => clearTimeout(timerRef.current);
  }, [
    isPlaying,
    timeRemaining,
    remainingAttempts,
    correctWords,
    targetWords,
  ]);

  const handleWordClick = (word) => {
    if (!isPlaying || remainingAttempts <= 0) {
      if (remainingAttempts <= 0) {
        setMessage('Hakkınız bitmiştir.');
      }
      return;
    }

    const normalizedWord = word
      .toLowerCase()
      .replace(/[^\p{L}\p{N}_]+/gu, '');

    if (selectedWords.includes(normalizedWord)) {
      return; // Kelime zaten seçilmiş
    }

    setSelectedWords([...selectedWords, normalizedWord]);
    setRemainingAttempts(remainingAttempts - 1);

    if (targetWords.includes(normalizedWord)) {
      setCorrectWords([...correctWords, normalizedWord]);
      setScore(score + 1);
    } else {
      setIncorrectWords([...incorrectWords, normalizedWord]);
      setScore(score - 1);
    }

    if (remainingAttempts - 1 === 0) {
      setMessage('Hakkınız bitmiştir.');
    }
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      if (userData) {
        setMessage(`Oyun duraklatıldı. Skorunuz: ${score} puan.`);
      } else {
        setMessage('Oyun duraklatıldı. Giriş yapmadığınız için skor gösterilmiyor.');
      }
    } else {
      setMessage('');
    }
    setIsPlaying(!isPlaying);
  };

  const handleReset = () => {
    setIsPlaying(false);
    setSelectedWords([]);
    setCorrectWords([]);
    setIncorrectWords([]);
    setTimeRemaining(initialTime);
    setScore(0);
    setRemainingAttempts(10);
    setMessage('');
  };

  return {
    selectedWords,
    correctWords,
    incorrectWords,
    isPlaying,
    timeRemaining,
    score,
    remainingAttempts,
    message,
    handleWordClick,
    handlePlayPause,
    handleReset,
  };
};
