import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Toolbar, IconButton, Typography,
  List,
  ListItemText,
  CssBaseline,
  Collapse,
  Divider,
  Grid,
  CardContent,
  Avatar,
  Box,
  useMediaQuery,
} from '@mui/material';
import {
  Home as HomeIcon,
  Menu as MenuIcon,
  Dashboard as DashboardIcon,
  Settings as SettingsIcon,
  Logout as LogoutIcon,
  School as SchoolIcon,
  Group as StudentsIcon,
  ExpandLess,
  ExpandMore,
  LibraryBooks as LibraryBooksIcon,
  AddBox as AddBoxIcon,
  FormatListBulleted as FormatListBulletedIcon,
  Assignment as AssignmentIcon,
  LockReset as LockResetIcon,
  AccountCircle as AccountCircleIcon,
  Person as PersonIcon,
  Phone as PhoneIcon,
  LocationCity as LocationCityIcon,
  Payment as PaymentIcon,
} from '@mui/icons-material';
import { useTheme, ThemeProvider, createTheme } from '@mui/material/styles';
import { green } from '@mui/material/colors';
import { API_URLS } from '../../config/config';
import useFetchUserData from '../../hooks/ogretmen/useFetchUserData';
import BookUploadForm from './kutuphane/Add';
import BookListe from './kutuphane/Liste';
import Tests from './kutuphane/Test';
import Ogrenci from './ogrenci/Ogrenci';
import Okul from './ayarlar/Okul';
import Password from './ayarlar/Password';
import Profil from './ayarlar/Profil';
import StageList from './akillikurs/StageList';
import ExerciseList from './akillikurs/ExerciseList';
import PaymentForm from './payment/PaymentForm';
import ActivateAccountForm from './activation/ActivateAccountForm';
import PaymentHistory from './payment/PaymentHistory';

// Stil bileşenlerini içe aktar
import {
  Main,
  AppBarStyled,
  DrawerStyled,
  ProfileCard,
  StyledListItem,
  StyledListItemIcon,
} from './styles/OgretmenDashboardStyles';

import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

function OgretmenDashboard() {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [userData, setUserData] = useState(null);
  const [open, setOpen] = useState({});
  const navigate = useNavigate();
  const [selectedStage, setSelectedStage] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState('Dashboard');

  useFetchUserData(setUserData);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleClick = (menu) => {
    setOpen((prevOpen) => ({ ...prevOpen, [menu]: !prevOpen[menu] }));
  };

  const handleLogout = async () => {
    try {
      await fetch(API_URLS.TEACHER_URL + 'logout', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'Content-Type': 'application/json',
        },
      });
      localStorage.removeItem('userToken');
      navigate('/ogretmen-giris');
    } catch (error) {
      console.error('Çıkış işlemi sırasında bir hata oluştu', error);
    }
  };

  const menuItems = [
    { text: 'Anasayfa', icon: <HomeIcon />, action: () => navigate('/') },
    { text: 'Dashboard', icon: <DashboardIcon />, action: () => setSelectedMenu('Dashboard') },
    {
      text: 'Bakiye Yükle',
      icon: <PaymentIcon />,
      action: () => setSelectedMenu('BakiyeYukle'),
    },
    {
      text: 'Hesabı Aktif Et',
      icon: <PaymentIcon />,
      action: () => setSelectedMenu('HesapAktifEt'),
    },
    {
      text: 'Öğrenciler',
      icon: <StudentsIcon />,
      action: () => setSelectedMenu('Öğrenciler'),
    },
    {
      text: 'Akıllı Kurs',
      icon: <SchoolIcon />,
      action: () => setSelectedMenu('AkilliKurs'),
    },
    {
      text: 'Kütüphane',
      icon: <LibraryBooksIcon />,
      subMenus: [
        { text: 'Kitap Ekle', icon: <AddBoxIcon />, action: () => setSelectedMenu('KitapEkle') },
        {
          text: 'Tüm Liste',
          icon: <FormatListBulletedIcon />,
          action: () => setSelectedMenu('KitapListe'),
        },
        { text: 'Testler', icon: <AssignmentIcon />, action: () => setSelectedMenu('Testler') },
      ],
      action: () => handleClick('Kütüphane'),
    },
    {
      text: 'Satın Alınan Paketler',
      icon: <PaymentIcon />,
      action: () => setSelectedMenu('PaymentHistory'),
    },
    {
      text: 'Ayarlar',
      icon: <SettingsIcon />,
      subMenus: [
        { text: 'Okul Ekle', icon: <AddBoxIcon />, action: () => setSelectedMenu('Okul') },
        {
          text: 'Şifre Güncelle',
          icon: <LockResetIcon />,
          action: () => setSelectedMenu('SifreSet'),
        },
        { text: 'Profil', icon: <AccountCircleIcon />, action: () => setSelectedMenu('Profil') },
      ],
      action: () => handleClick('Ayarlar'),
    },
    { text: 'Çıkış Yap', icon: <LogoutIcon />, action: handleLogout },
  ];

  const renderContent = () => {
    if (!userData) {
      return <div>Yükleniyor...</div>;
    }

    switch (selectedMenu) {
      case 'BakiyeYukle':
        return <PaymentForm userType="Ogretmen" userId={userData._id} />;

      case 'HesapAktifEt':
        return <ActivateAccountForm />;

      case 'Öğrenciler':
        return <Ogrenci />;

      case 'AkilliKurs':
        return (
          <StageList
            onStageSelect={(stage) => {
              setSelectedStage(stage);
              setSelectedMenu('ExerciseList');
            }}
            userData={userData}
          />
        );

      case 'ExerciseList':
        return (
          <ExerciseList
            stage={selectedStage}
            userData={userData}
            onBack={() => setSelectedMenu('AkilliKurs')}
          />
        );

      case 'KitapEkle':
        return <BookUploadForm />;

      case 'KitapListe':
        return <BookListe />;

      case 'Testler':
        return <Tests />;

      case 'Okul':
        return <Okul />;

      case 'SifreSet':
        return <Password />;

      case 'Profil':
        return <Profil />;

      case 'PaymentHistory':
        return <PaymentHistory />;

      default:
        return (
          <ProfileCard>
            <CardContent>
              <Typography
                variant="h4"
                sx={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                  mb: 2,
                  fontFamily: 'Comic Sans MS',
                }}
              >
                PROFİL BİLGİLERİ
              </Typography>
              <Divider />
              <Grid container spacing={2} justifyContent="center" sx={{ mt: 2 }}>
                <Grid item>
                  <Avatar
                    sx={{
                      backgroundColor: '#ffffff',
                      color: '#004d40',
                      width: 100,
                      height: 100,
                      animation: 'spin 5s linear infinite',
                    }}
                  >
                    <PersonIcon sx={{ fontSize: '3rem' }} />
                  </Avatar>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="h5"
                    align="center"
                    sx={{ fontFamily: 'Comic Sans MS', color: '#004d40' }}
                  >
                    {userData.ad} {userData.soyad}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    align="center"
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: '#004d40',
                    }}
                  >
                    <PhoneIcon sx={{ mr: 1, fontSize: '1.5rem' }} />
                    {userData.telefon}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    align="center"
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: '#004d40',
                    }}
                  >
                    <LocationCityIcon sx={{ mr: 1, fontSize: '1.5rem' }} />
                    {userData.sehirAdi} / {userData.ilceAdi}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    align="center"
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: '#004d40',
                    }}
                  >
                    <AccountBalanceWalletIcon sx={{ mr: 1, fontSize: '1.5rem' }} />
                    Bakiye: {userData.bakiye}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="body1" align="center" sx={{ color: '#004d40' }}>
                    Hesap Durumu: {userData.status === 'active' ? 'Aktif' : 'Pasif'}
                  </Typography>
                </Grid>
                {userData.abonelikler && userData.abonelikler.length > 0 && (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="body1" align="center" sx={{ color: '#004d40' }}>
                        Abonelik Başlangıç Tarihi:{' '}
                        {new Date(
                          userData.abonelikler[userData.abonelikler.length - 1].baslangicTarihi
                        ).toLocaleDateString()}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1" align="center" sx={{ color: '#004d40' }}>
                        Abonelik Bitiş Tarihi:{' '}
                        {new Date(
                          userData.abonelikler[userData.abonelikler.length - 1].bitisTarihi
                        ).toLocaleDateString()}
                      </Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            </CardContent>
          </ProfileCard>
        );
    }
  };

  // Özel tema oluşturma
  const customTheme = createTheme({
    palette: {
      primary: {
        main: green[500],
      },
      secondary: {
        main: green[700],
      },
    },
  });

  return (
    <ThemeProvider theme={customTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBarStyled position="fixed" open={drawerOpen}>
          <Toolbar>
            <IconButton color="inherit" aria-label="open drawer" onClick={toggleDrawer} edge="start">
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div" sx={{ fontFamily: 'Comic Sans MS' }}>
              Öğretmen Paneli
            </Typography>
          </Toolbar>
        </AppBarStyled>
        <DrawerStyled
          variant={isMobile ? 'temporary' : 'persistent'}
          anchor="left"
          open={drawerOpen}
          onClose={toggleDrawer}
        >
          <Toolbar />
          <Divider />
          <List>
            {menuItems.map((item, index) => (
              <React.Fragment key={index}>
                <StyledListItem
                  selected={selectedMenu === item.text}
                  onClick={() => {
                    item.action && item.action();
                    if (isMobile) toggleDrawer();
                  }}
                >
                  <StyledListItemIcon>{item.icon}</StyledListItemIcon>
                  <ListItemText primary={item.text} />
                  {item.subMenus ? (open[item.text] ? <ExpandLess /> : <ExpandMore />) : null}
                </StyledListItem>
                {item.subMenus && (
                  <Collapse in={open[item.text]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {item.subMenus.map((subItem, subIndex) => (
                        <StyledListItem
                          key={subIndex}
                          onClick={() => {
                            subItem.action();
                            if (isMobile) toggleDrawer();
                          }}
                          sx={{ pl: 4 }}
                        >
                          <StyledListItemIcon>{subItem.icon}</StyledListItemIcon>
                          <ListItemText primary={subItem.text} />
                        </StyledListItem>
                      ))}
                    </List>
                  </Collapse>
                )}
              </React.Fragment>
            ))}
          </List>
        </DrawerStyled>
        <Main open={drawerOpen}>{renderContent()}</Main>
      </Box>
    </ThemeProvider>
  );
}

export default OgretmenDashboard;
