import React from 'react';
import { Typography, Container, Card, CardContent, Divider, Box } from '@mui/material';
import Header from '../Header';
import Footer from '../Footer';

function TermsOfUse() {
  return (
    <div>
      <Header />
      <Container maxWidth="md" sx={{ mt: 5, mb: 5 }}>
        <Card>
          <CardContent>
            <Typography variant="h4" align="center" gutterBottom>
              Kullanım Şartları
            </Typography>
            <Divider sx={{ mb: 3 }} />
            
            <Typography variant="body1" paragraph>
              Hızlı Okuma ve Anlama Eğitim Platformu'nu kullanarak, aşağıdaki şartları kabul etmiş sayılırsınız:
            </Typography>

            <Typography variant="h6" gutterBottom>
              1. Hizmet Kullanımı
            </Typography>
            <Typography variant="body1" paragraph>
              Platformdaki içerikler eğitim amaçlı olup, yalnızca kişisel kullanım içindir. Ticari amaçlı kullanımlar yasaktır.
            </Typography>

            <Typography variant="h6" gutterBottom>
              2. Üyelik ve Güvenlik
            </Typography>
            <Typography variant="body1" paragraph>
              Üyelik oluştururken sağladığınız bilgilerin doğru ve güncel olması zorunludur. Hesap güvenliğinizden tamamen siz sorumlusunuz.
            </Typography>

            <Typography variant="h6" gutterBottom>
              3. İçerik Paylaşımı ve Telif Hakları
            </Typography>
            <Typography variant="body1" paragraph>
              Platformdaki tüm içerikler telif haklarıyla korunmaktadır. İçeriklerin izinsiz kopyalanması veya paylaşılması yasaktır.
            </Typography>

            <Typography variant="h6" gutterBottom>
              4. İptal ve İade
            </Typography>
            <Typography variant="body1" paragraph>
              Hizmet iptali ve iade talepleri, <a href="/iptalveiadekosullari">İptal ve İade Koşulları</a> sayfamızda belirtilen kurallar çerçevesinde yapılabilir.
            </Typography>

            <Typography variant="h6" gutterBottom>
              5. Gizlilik ve Kişisel Veriler
            </Typography>
            <Typography variant="body1" paragraph>
              Kullanıcı bilgileri, <a href="/gizliliksozlesmesi">Gizlilik Politikası</a> kapsamında korunmaktadır.
            </Typography>

            <Typography variant="h6" gutterBottom>
              6. Sorumluluk Reddi
            </Typography>
            <Typography variant="body1" paragraph>
              Platformda sunulan eğitim ve içeriklerin doğruluğu sürekli güncellense de, hatalardan sorumluluk kabul edilmez. Kullanıcılar, içeriklerin doğruluğunu kendi araştırmalarıyla teyit etmelidir.
            </Typography>

            <Typography variant="h6" gutterBottom>
              7. Uygulanan Hukuk ve Yetki
            </Typography>
            <Typography variant="body1" paragraph>
              İşbu şartlar, Türkiye Cumhuriyeti yasalarına tabidir. Uyuşmazlıkların çözümünde Ankara Mahkemeleri ve İcra Daireleri yetkilidir.
            </Typography>

            <Typography variant="h6" align="center" sx={{ mt: 4 }}>
              Teşekkür ederiz.
            </Typography>
          </CardContent>
        </Card>
      </Container>
      <Footer />
    </div>
  );
}

export default TermsOfUse;
