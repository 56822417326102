import React from 'react';

const GameExplanation = () => {
  return (
    <div className="alert alert-info text-center">
      <h4>Oyun Nasıl Oynanır?</h4>
       
          <p>Oyunu başlatmak için "Başlat" butonuna basın.</p>
          <p>Size verilen paragrafta hedef kelimeleri bulun ve seçin.</p>
          <p>Doğru kelimeleri seçtiğinizde puan kazanırsınız.</p>
          <p>Yanlış kelimeleri seçtiğinizde puan kaybedersiniz.</p>
      
          <p>Oyun süresi veya seçim hakkınız bittiğinde oyun sona erer.</p>
      
      </div>
 
  );
};

export default GameExplanation;
