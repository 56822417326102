import React, { useState, useEffect } from 'react';
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { API_URLS } from '../../../config/config';
import useFetchUserData from '../../../hooks/ogretmen/useFetchUserData';


const Books = () => {
    const [books, setBooks] = useState([]);
    const [userData, setUserData] = useState(null);
    const [duzenlenenKitap, setDuzenlenenKitap] = useState({
        _id: '',
        title: '',
        difficulty: '',
      });
      
    const [duzenlemeDialogAcik, setDuzenlemeDialogAcik] = useState(false);

    const [testFormAcik, setTestFormAcik] = useState(false);
    const [secilenKitapId, setSecilenKitapId] = useState('');
    const [testSorusu, setTestSorusu] = useState(''); 
    const [dogruCevap, setDogruCevap] = useState(null);
    const [testAciklamasi, setTestAciklamasi] = useState('');
    const [testSiklari, setTestSiklari] = useState([]);

    useFetchUserData(setUserData);

    const fetchBooks = async () => {
        if (!userData || !userData._id) {
            console.log("Kullanıcı bilgisi yüklenemedi veya kullanıcı ID'si bulunamadı.");    
            return; 
        }

        const userId = userData._id;  
        console.log("Kullanıcı ID: " + userId);

        try {
            const response = await fetch(`${API_URLS.TEACHER_URL}kutuphane/${userId}`);
            const data = await response.json();
            if (response.ok) {
                setBooks(data);
            } else {
                console.error('Kitapları yüklerken bir sorun oluştu: ' + data.message);
            }
        } catch (error) {
            console.error('Fetch işlemi sırasında hata oluştu:', error);
        }
    }; 

    useEffect(() => {
        fetchBooks();
    }, [userData]); 

    const handleEditBook = (bookId) => {
        const seciliKitap = books.find((kitap) => kitap._id === bookId);
      
        if (seciliKitap) {
          setDuzenlenenKitap({
            _id: seciliKitap._id,
            title: seciliKitap.title,
            difficulty: seciliKitap.difficulty,
          });
          setDuzenlemeDialogAcik(true);
        } else {
          console.error('Seçilen kitap bulunamadı.');
        }
      };

      const handleKitapDegisiklikleri = (e) => {
        setDuzenlenenKitap({
          ...duzenlenenKitap,
          [e.target.name]: e.target.value,
        });
      };

      const handleGuncelle = async () => {
        try {
          const response = await fetch(`${API_URLS.TEACHER_URL}kutuphane/${duzenlenenKitap._id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              title: duzenlenenKitap.title,
              difficulty: duzenlenenKitap.difficulty,
            }),
          });
      
          const data = await response.json();
          if (!response.ok) throw new Error(data.message || 'Bir hata oluştu');
      
          setBooks(books.map((kitap) => (kitap._id === duzenlenenKitap._id ? data : kitap)));
          setDuzenlemeDialogAcik(false);
        } catch (err) {
          console.error(err);
        }
      };

    const handleDeleteBook = async (bookId) => {
        try {
          const response = await fetch(`${API_URLS.TEACHER_URL}kutuphane/${bookId}`, {
            method: 'DELETE',
          });
          const data = await response.json();
          if (response.ok) {
            console.log(data.message);
            // Kitap listesini yenileyin
            fetchBooks();
          } else {
            console.error('Kitap silme işlemi sırasında bir sorun oluştu: ' + data.message);
          }
        } catch (error) {
          console.error('Fetch işlemi sırasında hata oluştu:', error);
        }
      };

      //TEST MODÜLÜ
      const handleSikEkle = () => {
        if (testSiklari.length < 5) {
            setTestSiklari([...testSiklari, '']);
        }
    };
    const handleSikSil = (index) => {
        const yeniSiklar = [...testSiklari];
        yeniSiklar.splice(index, 1);
        setTestSiklari(yeniSiklar);
    };

      const handleCreateTest = (bookId) => {
        setSecilenKitapId(bookId);
        setTestFormAcik(true);
    };

    const handleTestFormKapat = () => {
        setTestFormAcik(false);
        setTestSorusu('');
        setTestSiklari(['', '', '', '', '']);
        setDogruCevap(0);
        setTestAciklamasi('');
    };

    const handleTestFormSubmit = async (e) => {
      e.preventDefault();
  
      if (testSiklari.length < 2 || testSiklari.length > 5) {
          alert('En az 2, en fazla 5 seçenek girilmelidir.');
          return;
      }
  
      // dogruCevap'ın geçerli bir indeks olduğundan emin olun
      if (dogruCevap < 0 || dogruCevap >= testSiklari.length) {
          alert('Geçerli bir doğru cevap seçin.');
          return;
      }
  
      try {
          const response = await fetch(`${API_URLS.TEACHER_URL}kutuphane/tests`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                  bookId: secilenKitapId,
                  question: testSorusu,
                  options: testSiklari,
                  correctAnswer: testSiklari[dogruCevap], // Doğru cevabın kendisini gönder
                  explanation: testAciklamasi,
              }),
          });
  
          const data = await response.json();
  
          if (!response.ok) {
              throw new Error(data.message || 'Bir hata oluştu');
          }
  
          // Test başarıyla oluşturuldu
          alert('Test başarıyla oluşturuldu.');
          handleTestFormKapat();
      } catch (err) {
          console.error(err);
          alert(`Hata: ${err.message}`);
      }
  };

      const renderDuzenlemeFormuDialog = () => (
        <Dialog open={duzenlemeDialogAcik} onClose={() => setDuzenlemeDialogAcik(false)}>
          <DialogTitle>Kitap Düzenle</DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              label="Kitap Adı"
              name="title"
              value={duzenlenenKitap.title}
              onChange={handleKitapDegisiklikleri}
              margin="normal"
            />
            <FormControl fullWidth margin="normal">
              <InputLabel id="difficulty-label">Zorluk Seviyesi</InputLabel>
              <Select
                labelId="difficulty-label"
                id="difficulty"
                value={duzenlenenKitap.difficulty}
                label="Zorluk Seviyesi"
                onChange={(e) => handleKitapDegisiklikleri({ target: { name: 'difficulty', value: e.target.value } })}
              >
                <MenuItem value="easy">Kolay</MenuItem>
                <MenuItem value="medium">Orta</MenuItem>
                <MenuItem value="hard">Zor</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDuzenlemeDialogAcik(false)}>İptal</Button>
            <Button onClick={handleGuncelle} color="primary" variant="contained">
              Güncelle
            </Button>
          </DialogActions>
        </Dialog>
      );


   const renderTestForm = () => (
    <Dialog open={testFormAcik} onClose={handleTestFormKapat} maxWidth="md" fullWidth>
        <DialogTitle>Test Oluştur</DialogTitle>
        <DialogContent>
            <TextField
                fullWidth
                label="Soru"
                value={testSorusu}
                onChange={(e) => setTestSorusu(e.target.value)}
                margin="normal"
            />
            {testSiklari.map((sik, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                        fullWidth
                        label={`Şık ${index + 0}`}
                        value={sik}
                        onChange={(e) => {
                            const yeniSiklar = [...testSiklari];
                            yeniSiklar[index] = e.target.value;
                            setTestSiklari(yeniSiklar);
                        }}
                        margin="normal"
                    />
                    <IconButton onClick={() => handleSikSil(index)}>
                        <DeleteIcon />
                    </IconButton>
                </div>
            ))}
           <Button
            onClick={handleSikEkle}
            color="primary"
            variant="contained"
            style={{ marginTop: '10px' }}
            disabled={testSiklari.length >= 5}
        >
            Şık Ekle
        </Button>
            <FormControl fullWidth margin="normal">
                <InputLabel>Doğru Cevap</InputLabel>
                <Select
                  value={dogruCevap}
                  onChange={(e) => setDogruCevap(e.target.value)}
                  displayEmpty
                   >
                  <MenuItem value="" disabled>Doğru cevabı seçin</MenuItem>
                  {testSiklari.map((_, index) => (
                      <MenuItem key={index} value={index}>
                        Şık  {index + 0}
                      </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <TextField
                fullWidth
                label="Açıklama"
                value={testAciklamasi}
                onChange={(e) => setTestAciklamasi(e.target.value)}
                margin="normal"
                multiline
                rows={4}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleTestFormKapat}>İptal</Button>
            <Button onClick={handleTestFormSubmit} color="primary" variant="contained">
                Kaydet
            </Button>
        </DialogActions>
    </Dialog>
);

    return (
        <TableContainer component={Paper} style={{ marginTop: '20px' }}>
            <Button
                startIcon={<RefreshIcon />}
                onClick={fetchBooks} 
                variant="contained"
                color="primary"
                style={{ marginBottom: '10px' }}
            >
                Yenile
            </Button>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Kitap Adı</TableCell>
                        <TableCell>Zorluk</TableCell>
                        <TableCell>İşlem</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {books.map((book) => (
                        <TableRow key={book._id}>
                            <TableCell>{book.title}</TableCell>
                            <TableCell>
                                {book.difficulty === 'easy'
                                    ? 'Kolay'
                                    : book.difficulty === 'medium'
                                    ? 'Orta'
                                    : book.difficulty === 'hard'
                                    ? 'Zor'
                                    : book.difficulty}
                            </TableCell>
                            <TableCell>
                                <IconButton onClick={() => handleEditBook(book._id)}>
                                    <EditIcon />
                                </IconButton>
                                <IconButton onClick={() => handleDeleteBook(book._id)}>
                                    <DeleteIcon />
                                </IconButton>
                                <IconButton onClick={() => handleCreateTest(book._id)}>
                                    <AddCircleIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {renderDuzenlemeFormuDialog()}
            {renderTestForm()}
        </TableContainer>
         
    );
};

export default Books;
