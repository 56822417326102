// styles/OgretmenDashboardStyles.js
import { styled, keyframes } from '@mui/system';
import { AppBar, Drawer } from '@mui/material';
import { green } from '@mui/material/colors';

const drawerWidth = 240;

// Gradient animasyonu
const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

// Ana içerik stili
export const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: open ? `${drawerWidth}px` : 0,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
    marginTop: '64px',
    overflow: 'auto',
    height: `calc(100vh - 64px)`,
    background: 'linear-gradient(135deg, #e0f7fa 0%, #80deea 100%)',
    backgroundSize: '400% 400%',
    animation: `${gradientAnimation} 20s ease infinite`,
  })
);

// AppBar stili
export const AppBarStyled = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  background: 'linear-gradient(to right, #004d40, #00796b)',
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.enteringScreen,
  }),
  ...(open && {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
    },
  }),
}));

// Drawer stili (Koyu yeşil gradient)
export const DrawerStyled = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    background: 'linear-gradient(to bottom, #004d40, #00251a)',
    color: '#fff',
  },
}));

// Profil Kartı stili
export const ProfileCard = styled('div')(({ theme }) => ({
  background: 'linear-gradient(135deg, #a5d6a7 0%, #81c784 100%)',
  color: '#1b5e20',
  borderRadius: '15px',
  padding: theme.spacing(4),
  boxShadow: '0 8px 16px rgba(0,0,0,0.3)',
  animation: `${gradientAnimation} 15s ease infinite`,
}));

// Buton stili
export const StyledListItem = styled('div')(({ theme, selected }) => ({
  '&:hover': {
    backgroundColor: '#1b5e20',
    transform: 'scale(1.05)',
    transition: 'all 0.3s ease',
  },
  backgroundColor: selected ? '#1b5e20' : 'inherit',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1, 2),
  color: '#fff',
}));

// Icon stili
export const StyledListItemIcon = styled('div')({
  color: '#fff',
  marginRight: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

// İkonlara animasyon ekleme
export const IconAnimation = styled('div')({
  '&:hover': {
    animation: 'rotate 2s infinite',
  },
  '@keyframes rotate': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' },
  },
});
