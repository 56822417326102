import { useEffect } from 'react';
import { API_URLS } from '../../config/config';

const useFetchTeacherDataForHome = (setUserData) => {

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('userToken');
      if (token) {
        try {
          const response = await fetch(`${API_URLS.TEACHER_URL}/me`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
          if (response.ok) {
            const data = await response.json();
            setUserData(data);
          } else {
            throw new Error('Veri alınırken bir hata oluştu');
          }
        } catch (error) {
          console.error(error);
        }
      }
    };

    fetchUserData();
  }, [setUserData]);
};

export default useFetchTeacherDataForHome;
