// SpeedSliderAndScore.js

import React from 'react';
import { Slider } from '@mui/material';

const SpeedSliderAndScore = ({ speed, setSpeed, score }) => {
  return (
    <>
      <div className="row mb-3">
        <div className="col-12">
          <Slider
            value={speed}
            onChange={(e, newValue) => setSpeed(Math.max(0.1, newValue))}
            aria-labelledby="speed-slider"
            valueLabelDisplay="auto"
            step={0.1}
            marks
            min={0.1}
            max={30}
          />
          <div className="text-center mt-2">Hız: {speed.toFixed(1)}</div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 text-center">
          <strong>Skor: {Math.floor(score)}</strong>
        </div>
      </div>
    </>
  );
};

export default SpeedSliderAndScore;
