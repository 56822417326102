import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const GameOverModal = ({ score, onRestart, userData }) => {
  const navigate = useNavigate();
  
  const handleLoginRedirect = () => {
    navigate('/login');
  };

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <h2>Oyun Bitti!</h2>

        {userData ? (
         <>
          <p>Toplam Skorunuz: {score}</p>

          <button className="btn btn-primary" onClick={onRestart}>
          Yeniden Başlat
        </button>
        </>
        ) : (
          <>
          <p>Skoru görmek için giriş yapmalısınız.</p>
          <button className="btn btn-secondary" onClick={handleLoginRedirect}>
              Giriş Yapınız
            </button>

            <button className="btn btn-primary" onClick={() => navigate(-1)}>
              <FontAwesomeIcon icon={faArrowLeft} /> Geri Dön
            </button>
         </>
        )}

   
      </div>

      <style jsx>{`
        .modal-backdrop {
          position: fixed;
          top: 0;
          left: 0;
          width: 100vw;
          height: 100vh;
          background: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
        }

        .modal-content {
          background: white;
          padding: 2rem;
          border-radius: 8px;
          text-align: center;
          max-width: 400px;
          width: 100%;
        }

        .btn {
          margin-top: 1rem;
          padding: 0.5rem 2rem;
          font-size: 1rem;
          border: none;
          border-radius: 5px;
          cursor: pointer;
        }

        .btn-primary {
          background-color: #28a745;
          color: white;
        }

        .btn-primary:hover {
          background-color: #218838;
        }

        .btn-secondary {
          background-color: #007bff;
          color: white;
        }

        .btn-secondary:hover {
          background-color: #0056b3;
        }
      `}</style>
    </div>
  );
};

export default GameOverModal;
