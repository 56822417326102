import React, { useState, useEffect } from 'react';
import {Button, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,  Grid,  TextField, Dialog, DialogTitle, DialogContent, DialogActions,
FormControl, InputLabel, Select, MenuItem, Chip, Stack,} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { API_URLS } from '../../../config/config';
import ResultDialog from '../../../pages/components/Auth/Dialog'; 

const PriceManagement = () => {
  const [priceListesi, setPriceListesi] = useState([]);
  const [paketSuresi, setPaketSuresi] = useState('');
  const [basePrice, setBasePrice] = useState('');
  const [campaignPrice, setCampaignPrice] = useState('');
  const [discountPercentage, setDiscountPercentage] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isActive, setIsActive] = useState(true);
  
  const [dialogContent, setDialogContent] = useState('');
  const [dialogSeverity, setDialogSeverity] = useState('info');
  const [dialogOpen, setDialogOpen] = useState(false);
  
  const [duzenlemeDialogAcik, setDuzenlemeDialogAcik] = useState(false);
  const [duzenlenenPrice, setDuzenlenenPrice] = useState(null);
  
  const [shouldFetch, setShouldFetch] = useState(true);

  // Helper fonksiyon ile başlıkları yönetme (Opsiyonel)
  const getAuthHeaders = () => ({
    Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    'Content-Type': 'application/json',
  });

  useEffect(() => {
    const fetchPrices = async () => {
      if (!shouldFetch) return;
      try {
        const response = await fetch(`${API_URLS.ADMIN_URL}kampanya/prices`, {
          headers: getAuthHeaders(),
        });
        if (!response.ok) {
          throw new Error('Fiyatlar yüklenirken bir hata oluştu.');
        }
        const data = await response.json();
        setPriceListesi(data);
      } catch (error) {
        console.error(error);
        setDialogSeverity('error');
        setDialogContent(error.message);
        setDialogOpen(true);
      } finally {
        setShouldFetch(false);
      }
    };
    
    fetchPrices();
  }, [shouldFetch]);

  const handleEkle = async () => {
    let eksikAlanlar = [];

    if (!paketSuresi) eksikAlanlar.push('Paket Süresi');
    if (!basePrice) eksikAlanlar.push('Temel Fiyat');
    // Kampanya fiyatı isteğe bağlı olabilir, bu nedenle kontrol etmiyoruz
    // Aynı şekilde diğer alanlar da isteğe bağlı olabilir

    if (eksikAlanlar.length > 0) {
      setDialogSeverity('error');
      setDialogContent(`Lütfen ${eksikAlanlar.join(', ')} alanlarını doldurunuz.`);
      setDialogOpen(true);
      return;
    }

    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}kampanya/prices`, {
        method: 'POST',
        headers: getAuthHeaders(),
        body: JSON.stringify({
          paketSuresi: Number(paketSuresi),
          basePrice: Number(basePrice),
          campaignPrice: campaignPrice ? Number(campaignPrice) : undefined,
          discountPercentage: discountPercentage ? Number(discountPercentage) : undefined,
          startDate: startDate ? new Date(startDate) : undefined,
          endDate: endDate ? new Date(endDate) : undefined,
          isActive,
        }),
      });

      const responseData = await response.json();
      if (response.ok) {
        setPriceListesi([...priceListesi, responseData]);
        setDialogSeverity('success');
        setDialogContent('Yeni fiyat başarıyla eklendi.');
        setDialogOpen(true);
        // Form alanlarını temizle
        setPaketSuresi('');
        setBasePrice('');
        setCampaignPrice('');
        setDiscountPercentage('');
        setStartDate('');
        setEndDate('');
        setIsActive(true);
      } else {
        throw new Error(responseData.message || 'Bir hata oluştu.');
      }
    } catch (err) {
      setDialogSeverity('error');
      setDialogContent(err.message);
      setDialogOpen(true);
    }
  };

  const handleGuncelle = async () => {
    if (!duzenlenenPrice) return;

    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}kampanya/prices/${duzenlenenPrice._id}`, {
        method: 'PUT',
        headers: getAuthHeaders(),
        body: JSON.stringify({
          paketSuresi: Number(duzenlenenPrice.paketSuresi),
          basePrice: Number(duzenlenenPrice.basePrice),
          campaignPrice: duzenlenenPrice.campaignPrice ? Number(duzenlenenPrice.campaignPrice) : undefined,
          discountPercentage: duzenlenenPrice.discountPercentage ? Number(duzenlenenPrice.discountPercentage) : undefined,
          startDate: duzenlenenPrice.startDate ? new Date(duzenlenenPrice.startDate) : undefined,
          endDate: duzenlenenPrice.endDate ? new Date(duzenlenenPrice.endDate) : undefined,
          isActive: duzenlenenPrice.isActive,
        }),
      });

      const data = await response.json();
      if (!response.ok) throw new Error(data.message || 'Bir hata oluştu.');

      // Local state'i güncelle
      setPriceListesi(priceListesi.map(price => price._id === data._id ? data : price));
      setDialogSeverity('success');
      setDialogContent('Fiyat başarıyla güncellendi.');
      setDialogOpen(true);
      setDuzenlemeDialogAcik(false);
      setDuzenlenenPrice(null);
    } catch (err) {
      setDialogSeverity('error');
      setDialogContent(err.message);
      setDialogOpen(true);
    }
  };

  const handleDeletePrice = async (priceId) => {
    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}kampanya/prices/${priceId}`, {
        method: 'DELETE',
        headers: getAuthHeaders(),
      });

      if (!response.ok) throw new Error('Fiyat silinirken bir hata oluştu.');

      setPriceListesi(priceListesi.filter(price => price._id !== priceId));
      setDialogSeverity('success');
      setDialogContent('Fiyat başarıyla silindi.');
      setDialogOpen(true);
    } catch (error) {
      setDialogSeverity('error');
      setDialogContent(error.message);
      setDialogOpen(true);
    }
  };

  const handleEdit = (price) => {
    setDuzenlenenPrice(price);
    setDuzenlemeDialogAcik(true);
  };

  const renderDuzenlemeFormuDialog = () => {
    if (!duzenlenenPrice) return null;

    return (
      <Dialog open={duzenlemeDialogAcik} onClose={() => setDuzenlemeDialogAcik(false)}>
        <DialogTitle>Fiyat Düzenle</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <InputLabel id="paket-suresi-label">Paket Süresi</InputLabel>
            <Select
              labelId="paket-suresi-label"
              value={duzenlenenPrice.paketSuresi}
              onChange={(e) => setDuzenlenenPrice({ ...duzenlenenPrice, paketSuresi: e.target.value })}
            >
              <MenuItem value={3}>3 Ay</MenuItem>
              <MenuItem value={6}>6 Ay</MenuItem>
              <MenuItem value={12}>12 Ay</MenuItem>
            </Select>
          </FormControl>

          <TextField
            fullWidth
            label="Temel Fiyat"
            type="number"
            value={duzenlenenPrice.basePrice}
            onChange={(e) => setDuzenlenenPrice({ ...duzenlenenPrice, basePrice: e.target.value })}
            margin="normal"
          />

          <TextField
            fullWidth
            label="Kampanya Fiyatı (isteğe bağlı)"
            type="number"
            value={duzenlenenPrice.campaignPrice || ''}
            onChange={(e) => setDuzenlenenPrice({ ...duzenlenenPrice, campaignPrice: e.target.value })}
            margin="normal"
          />

          <TextField
            fullWidth
            label="İndirim Oranı (%) (isteğe bağlı)"
            type="number"
            value={duzenlenenPrice.discountPercentage || ''}
            onChange={(e) => setDuzenlenenPrice({ ...duzenlenenPrice, discountPercentage: e.target.value })}
            margin="normal"
          />

          <TextField
            fullWidth
            label="Kampanya Başlangıç Tarihi (isteğe bağlı)"
            type="date"
            value={duzenlenenPrice.startDate ? duzenlenenPrice.startDate.substring(0, 10) : ''}
            onChange={(e) => setDuzenlenenPrice({ ...duzenlenenPrice, startDate: e.target.value })}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />

          <TextField
            fullWidth
            label="Kampanya Bitiş Tarihi (isteğe bağlı)"
            type="date"
            value={duzenlenenPrice.endDate ? duzenlenenPrice.endDate.substring(0, 10) : ''}
            onChange={(e) => setDuzenlenenPrice({ ...duzenlenenPrice, endDate: e.target.value })}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />

          <FormControl fullWidth margin="normal">
            <InputLabel id="is-active-label">Aktif/Pasif</InputLabel>
            <Select
              labelId="is-active-label"
              value={duzenlenenPrice.isActive}
              onChange={(e) => setDuzenlenenPrice({ ...duzenlenenPrice, isActive: e.target.value })}
            >
              <MenuItem value={true}>Aktif</MenuItem>
              <MenuItem value={false}>Pasif</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDuzenlemeDialogAcik(false)}>İptal</Button>
          <Button onClick={handleGuncelle} color="primary" variant="contained">
            Güncelle
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <Paper style={{ padding: 20 }}>
      <Grid container spacing={3}>
        {/* Fiyat Ekleme Formu */}
        <Grid item xs={12} md={4}>
          <Typography variant="h5" component="h3" marginBottom={2}>
            Fiyat Ekleme Formu
          </Typography>
          <FormControl fullWidth margin="normal">
            <InputLabel id="paket-suresi-label">Paket Süresi</InputLabel>
            <Select
              labelId="paket-suresi-label"
              value={paketSuresi}
              onChange={(e) => setPaketSuresi(e.target.value)}
            >
              <MenuItem value={3}>3 Ay</MenuItem>
              <MenuItem value={6}>6 Ay</MenuItem>
              <MenuItem value={12}>12 Ay</MenuItem>
            </Select>
          </FormControl>

          <TextField
            fullWidth
            label="Temel Fiyat"
            type="number"
            value={basePrice}
            onChange={(e) => setBasePrice(e.target.value)}
            margin="normal"
          />

          <TextField
            fullWidth
            label="Kampanya Fiyatı (isteğe bağlı)"
            type="number"
            value={campaignPrice}
            onChange={(e) => setCampaignPrice(e.target.value)}
            margin="normal"
          />

          <TextField
            fullWidth
            label="İndirim Oranı (%) (isteğe bağlı)"
            type="number"
            value={discountPercentage}
            onChange={(e) => setDiscountPercentage(e.target.value)}
            margin="normal"
          />

          <TextField
            fullWidth
            label="Kampanya Başlangıç Tarihi (isteğe bağlı)"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />

          <TextField
            fullWidth
            label="Kampanya Bitiş Tarihi (isteğe bağlı)"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />

          <FormControl fullWidth margin="normal">
            <InputLabel id="is-active-label">Aktif/Pasif</InputLabel>
            <Select
              labelId="is-active-label"
              value={isActive}
              onChange={(e) => setIsActive(e.target.value)}
            >
              <MenuItem value={true}>Aktif</MenuItem>
              <MenuItem value={false}>Pasif</MenuItem>
            </Select>
          </FormControl>

          <Button onClick={handleEkle} variant="contained" color="primary" fullWidth>
            Ekle
          </Button>
        </Grid>

        {/* Fiyat Listesi */}
        <Grid item xs={12} md={8}>
          <Typography variant="h5" component="h3" marginBottom={2}>
            Fiyat Listesi
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Paket Süresi</TableCell>
                  <TableCell>Temel Fiyat</TableCell>
                  <TableCell>Kampanya Fiyatı</TableCell>
                  <TableCell>İndirim Oranı (%)</TableCell>
                  <TableCell>Kampanya Başlangıç</TableCell>
                  <TableCell>Kampanya Bitiş</TableCell>
                  <TableCell>Durum</TableCell>
                  <TableCell>İşlemler</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {priceListesi.map((price) => (
                  <TableRow key={price._id}>
                    <TableCell>{price.paketSuresi} Ay</TableCell>
                    <TableCell>{price.basePrice} TL</TableCell>
                    <TableCell>{price.campaignPrice ? `${price.campaignPrice} TL` : 'Yok'}</TableCell>
                    <TableCell>{price.discountPercentage || 'Yok'}</TableCell>
                    <TableCell>{price.startDate ? new Date(price.startDate).toLocaleDateString() : 'Yok'}</TableCell>
                    <TableCell>{price.endDate ? new Date(price.endDate).toLocaleDateString() : 'Yok'}</TableCell>
                    <TableCell>
                      <Chip
                        label={price.isActive ? 'Aktif' : 'Pasif'}
                        color={price.isActive ? 'success' : 'error'}
                      />
                    </TableCell>
                    <TableCell>
                      <Stack direction="row" spacing={1}>
                        <Button
                          variant="contained"
                          color="primary"
                          startIcon={<EditIcon />}
                          onClick={() => handleEdit(price)}
                        >
                          Düzenle
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          startIcon={<DeleteIcon />}
                          onClick={() => handleDeletePrice(price._id)}
                        >
                          Sil
                        </Button>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      {/* Sonuç Dialog'u */}
      <ResultDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        title={dialogSeverity === 'error' ? 'Hata Oluştu' : 'İşlem Başarılı'}
        message={dialogContent}
        severity={dialogSeverity}
      />

      {/* Düzenleme Dialog'u */}
      {renderDuzenlemeFormuDialog()}
    </Paper>
  );
};

export default PriceManagement;
