import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faRedo } from '@fortawesome/free-solid-svg-icons';

const GameOptions = ({
  isPlaying,
  setIsPlaying,
  resetGame,
  orientation,
  setOrientation,
  difficulty,
  handleDifficultyChange,
}) => {
  return (
    <div className="row mb-3">
      <div className="col-12 col-md-6 mb-2 mb-md-0">
        <FormControl fullWidth>
          <InputLabel id="orientation-select-label">Yön</InputLabel>
          <Select
            labelId="orientation-select-label"
            id="orientation-select"
            value={orientation}
            label="Yön"
            onChange={(e) => setOrientation(e.target.value)}
          >
            <MenuItem value="horizontal">Yatay</MenuItem>
            <MenuItem value="vertical">Dikey</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="col-12 col-md-6 mb-2 mb-md-0">
        <FormControl fullWidth>
          <InputLabel id="difficulty-select-label">Zorluk</InputLabel>
          <Select
            labelId="difficulty-select-label"
            id="difficulty-select"
            value={difficulty}
            label="Zorluk"
            onChange={handleDifficultyChange}
          >
            <MenuItem value="seciniz">Zorluk Seviyesi Seçiniz</MenuItem>
            <MenuItem value="easy">Kolay</MenuItem>
            <MenuItem value="normal">Normal</MenuItem>
            <MenuItem value="hard">Zor</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="col-12 text-center mt-3">
        <button
          className={`btn btn-${isPlaying ? 'warning' : 'success'} me-2`}
          onClick={() => setIsPlaying(!isPlaying)}
        >
          <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />{' '}
          {isPlaying ? 'Duraklat' : 'Başlat'}
        </button>
        <button className="btn btn-secondary" onClick={resetGame}>
          <FontAwesomeIcon icon={faRedo} /> Sıfırla
        </button>
      </div>
    </div>
  );
};

export default GameOptions;
