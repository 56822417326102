import React, { useState, useEffect } from 'react';
import { Button, Typography, Paper, Card, CardContent, Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';
import useFetchUserData from '../../../hooks/ogretmen/useFetchUserData';
import { API_URLS } from '../../../config/config';
import 'bootstrap/dist/css/bootstrap.min.css';

const OkulEkle = () => {
  const [userData, setUserData] = useState(null);
  const [secilenOkul, setSecilenOkul] = useState('');
  const [okulIsimleri, setOkulIsimleri] = useState([]);
  const [mevcutOkul, setMevcutOkul] = useState(null);

  useFetchUserData(setUserData);

  useEffect(() => {
    const fetchOkullar = async () => {
      try {
        const response = await fetch(`${API_URLS.TEACHER_URL}ayarlar/okul`); 
        const data = await response.json();
        setOkulIsimleri(data);

      
        if (userData && userData.okulId) {
          const currentSchool = data.find(okul => okul._id === userData.okulId);
          setMevcutOkul(currentSchool);
        }
      } catch (error) {
        console.error('Okullar yüklenirken bir hata oluştu:', error);
      }
    };

    fetchOkullar();
  }, [userData]); // userData değiştiğinde okullar tekrar yüklenir

  const handleOkulGuncelle = async () => {
    if (secilenOkul && userData) {
      const secilenOkulObjesi = okulIsimleri.find(okul => okul._id === secilenOkul);
      if (secilenOkulObjesi) {
        try {
          const response = await fetch(`${API_URLS.TEACHER_URL}ayarlar/okul/${userData._id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ okulId: secilenOkulObjesi._id }),
          });

          if (response.ok) {
            setMevcutOkul(secilenOkulObjesi);
            // Optional: userData içindeki okulId'yi de güncellemek istersen, setUserData ile güncelleyebilirsin
            setUserData(prevData => ({
              ...prevData,
              okulId: secilenOkulObjesi._id
            }));
          } else {
            console.error('Okul güncellenirken bir hata oluştu.');
          }
        } catch (error) {
          console.error('Okul güncellenirken bir hata oluştu:', error);
        }
      }
    }
  };

  return (
    <Paper style={{ padding: 20 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
       
          <FormControl fullWidth margin="normal">
            <InputLabel id="okul-select-label">Okul</InputLabel>
            <Select
              labelId="okul-select-label"
              value={secilenOkul}
              onChange={(e) => setSecilenOkul(e.target.value)}
            >
              {okulIsimleri.map((okul) => (
                <MenuItem key={okul._id} value={okul._id}>
                  {okul.okul_adi}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button onClick={handleOkulGuncelle} variant="contained" color="primary" fullWidth>
            GÜNCELLE
          </Button>
        </Grid>
        <Grid item xs={12} md={8}>
     
      {mevcutOkul ? (
         <Card className="bg-primary text-white mb-3">
         <div className="card-header text-center font-weight-bold" style={{ fontSize: '1.25rem', letterSpacing: '0.05rem' }}>
           OKUL BİLGİLERİ
         </div>
         <hr style={{ margin: '0', borderColor: 'white' }} />
         <CardContent>
            <Typography variant="h6" component="h2" style={{ display: 'flex', alignItems: 'center' }}>
              <SchoolIcon style={{ marginRight: '8px' }} /> 
              {mevcutOkul.okul_adi}
            </Typography>
          </CardContent>
       </Card>
      ) : (
        <Typography color="textSecondary">
          Henüz bir okul seçilmedi.
        </Typography>
      )}
    </Grid>
      </Grid>
    </Paper>
  );
};

export default OkulEkle;
