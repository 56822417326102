import React, { useState } from 'react';
import { Button, Typography, Paper, Card, CardContent, Grid, TextField, InputAdornment, Checkbox, FormControlLabel } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import KeyIcon from '@mui/icons-material/VpnKey';
import 'bootstrap/dist/css/bootstrap.min.css';
import { API_URLS } from '../../../config/config';
import useFetchUserData from '../../../hooks/kurum/useFetchUserData';
import ResultDialog from '../../../pages/components/Auth/Dialog';

const SifreGuncelle = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');
  const [dialogSeverity, setDialogSeverity] = useState('error');

  useFetchUserData(setUserData);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setDialogContent('Yeni şifreler eşleşmiyor.');
      setDialogSeverity('error');
      setDialogOpen(true);
      return;
    }

    setLoading(true);

    try {
      const response = await fetch(`${API_URLS.KURUMSAL_URL}ayarlar/update-password`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('userToken')}`, // JWT token
        },
        body: JSON.stringify({
          oldPassword: currentPassword,
          newPassword: newPassword,
        }),
      });

      const data = await response.json();
      setLoading(false);

      if (!response.ok) {
        throw new Error(data.message || 'Şifre güncellenirken bir hata oluştu.');
      }

      setDialogContent('Şifre başarıyla güncellendi!');
      setDialogSeverity('success');
      setDialogOpen(true);
    } catch (error) {
      setLoading(false);
      setDialogContent(error.message || 'Bir hata oluştu.');
      setDialogSeverity('error');
      setDialogOpen(true);
    }
  };

  return (
    <Paper style={{ padding: 20 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card className="bg-warning text-dark mb-3">
            <div className="card-header text-center font-weight-bold" style={{ fontSize: '1.25rem', letterSpacing: '0.05rem' }}>
              ŞİFRE GÜNCELLEME
            </div>
            <hr style={{ margin: '0', borderColor: 'dark' }} />
            <CardContent>
              <Typography variant="h6" component="h2" style={{ display: 'flex', alignItems: 'center' }}>
                <LockIcon style={{ marginRight: '8px', fontSize: '1.5rem' }} />
                Şifrenizi Değiştirin
              </Typography>
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  label="Mevcut Şifre"
                  type={showPassword ? 'text' : 'password'}
                  margin="normal"
                  variant="outlined"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon style={{ fontSize: '1.2rem' }} />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  fullWidth
                  label="Yeni Şifre"
                  type={showPassword ? 'text' : 'password'}
                  margin="normal"
                  variant="outlined"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <KeyIcon style={{ fontSize: '1.2rem' }} />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  fullWidth
                  label="Yeni Şifreyi Onayla"
                  type={showPassword ? 'text' : 'password'}
                  margin="normal"
                  variant="outlined"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <KeyIcon style={{ fontSize: '1.2rem' }} />
                      </InputAdornment>
                    ),
                  }}
                />
                <FormControlLabel
                  control={<Checkbox checked={showPassword} onChange={toggleShowPassword} />}
                  label="Şifreyi Göster"
                  style={{ marginTop: '8px' }}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  style={{ marginTop: '16px' }}
                  disabled={loading}
                >
                  {loading ? 'GÜNCELLENİYOR...' : 'GÜNCELLE'}
                </Button>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <ResultDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        title={dialogSeverity === 'error' ? "Güncelleme Başarısız" : "Güncelleme Başarılı"}
        message={dialogContent}
        severity={dialogSeverity}
      />
    </Paper>
  );
};

export default SifreGuncelle;
