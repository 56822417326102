import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Grid, Card, CardActionArea, CardContent, Typography, Container, Box,
  Chip, Avatar, List, ListItem, ListItemIcon, Divider, Modal, Backdrop,
  Fade, Button, Dialog, DialogActions, DialogContent, DialogContentText,
  DialogTitle, Snackbar, Alert
} from '@mui/material';
import {CheckCircle, Lock, School, Speed, Assignment, Timer, TrendingUp, Info as InfoIcon} from '@mui/icons-material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import useFetchStudentDataForHome from '../../../hooks/ogrenci/useFetchStudentDataForHome';
import { API_URLS } from '../../../config/config';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '20px',
  transition: 'transform 0.3s, box-shadow 0.3s',
  position: 'relative',
  backgroundImage: 'linear-gradient(135deg, #FF9A9E 0%, #FAD0C4 100%)',
  color: '#FFFFFF',
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: theme.shadows[10],
  },
}));

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(6),
  paddingBottom: theme.spacing(6),
  minHeight: '100vh',
  color: '#333333',
}));

const StageList = ({ onStageSelect }) => {
  const navigate = useNavigate();
  const [studentData, setStudentData] = useState(null);
  const [progressData, setProgressData] = useState(null);
  const [initialTestResult, setInitialTestResult] = useState(null);
  const [openModal, setOpenModal] = useState(() => {
    const hasVisited = localStorage.getItem('hasVisitedStageList');
    return !hasVisited;
  });
  const [showInitialTestModal, setShowInitialTestModal] = useState(false);
 
  useFetchStudentDataForHome(setStudentData);

  const [openDeleteProgressDialog, setOpenDeleteProgressDialog] = useState(false);
  const [openDeleteTestDialog, setOpenDeleteTestDialog] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    const fetchInitialTest = async (token) => {
      try {
        const response = await axios.get(`${API_URLS.STUDENT_URL}akillikurs/test/initial-test`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setInitialTestResult(response.data.testResult);
        return true;
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setShowInitialTestModal(true);
        } else {
          console.error('Başlangıç testi alınırken hata oluştu:', error);
        }
        return false;
      }
    };

    const fetchProgress = async (token) => {
      try {
        const response = await axios.get(`${API_URLS.STUDENT_URL}akillikurs/progress`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setProgressData(response.data.progress);
      } catch (error) {
        console.error('İlerleme verisi alınırken hata oluştu:', error);
      }
    };

    const fetchData = async () => {
      const token = localStorage.getItem('userToken');
      if (!token) {
        console.error('Token bulunamadı.');
        return;
      }

      const initialTestSuccess = await fetchInitialTest(token);
      if (initialTestSuccess) {
        await fetchProgress(token);
      }
    };

    fetchData();
  }, [navigate]);

// Hedef okuma hızlarını hesaplayan fonksiyon
const calculateTargets = () => {
  if (!initialTestResult || !studentData) return [];

  const initialSpeed = initialTestResult.readingSpeed;
  const sinif = parseInt(studentData.sinif, 10); // Sınıf bilgisini sayısal değere çeviriyoruz

  let multiplier;

  // Sınıfa göre çarpanı belirleme
  if (sinif >= 1 && sinif <= 4) {
    multiplier = 2.85; // İlkokul
  } else if (sinif >= 5 && sinif <= 8) {
    multiplier = 3.5; // Ortaokul
  } else if (sinif >= 9 && sinif <= 12) {
    multiplier = 4.4; // Lise
  } else {
    multiplier = 5.6; // Üniversite veya diğer eğitim seviyeleri
  }

  const result1 = initialSpeed * multiplier;
  const result2 = result1 - initialSpeed;
  const incrementPerStage = result2 / 21;

  const targets = [];
  for (let i = 1; i <= 21; i++) {
    const targetSpeed = initialSpeed + incrementPerStage * i;
    targets.push(Math.round(targetSpeed));
  }

  return targets;
};

  const targets = calculateTargets();

  // İlk tamamlanan aşamanın testResult.readingSpeed değerini bulma
  let firstCompletedReadingSpeed = null;
  if (progressData && progressData.stages) {
    for (let stageProgress of progressData.stages) {
      if (stageProgress.stageCompleted && stageProgress.testResult) {
        firstCompletedReadingSpeed = stageProgress.testResult.readingSpeed;
        break; // İlk tamamlanan aşamayı bulduk
      }
    }
  }

  // Stages dizisini oluşturma
  const stages = Array.from({ length: 21 }, (_, index) => {
    const stageNumber = index + 1;
    let unlocked = false;
    let completed = false;
    let testResult = null;
    let newTargetSpeed = null; // Yeni hedef okuma hızı

    if (progressData) {
      const stageProgress = progressData.stages.find(
        (stage) => stage.stageNumber === stageNumber
      );

      if (stageProgress) {
        completed = stageProgress.stageCompleted;
        testResult = stageProgress.testResult || null;
      }

      if (stageNumber === 1) {
        unlocked = true; // İlk aşama her zaman açık
      } else {
        const previousStage = progressData.stages.find(
          (stage) => stage.stageNumber === stageNumber - 1
        );
        const previousStageCompleted = previousStage ? previousStage.stageCompleted : false;
        unlocked = previousStageCompleted; // Önceki aşama tamamlandıysa kilidi aç
      }
    } else {
      // Eğer ilerleme verisi yoksa, ilk aşamayı aç
      unlocked = stageNumber === 1;
    }

    // currentSpeed'i belirleme
    let currentSpeed = null;
    if (completed && testResult) {
      currentSpeed = testResult.readingSpeed;
    } else if (firstCompletedReadingSpeed) {
      currentSpeed = firstCompletedReadingSpeed;
    } else if (initialTestResult) {
      currentSpeed = initialTestResult.readingSpeed;
    }

    // newTargetSpeed'i hesaplama
    if (currentSpeed !== null) {
      const result1 = currentSpeed * 3.5;
      const result2 = result1 - currentSpeed;
      const incrementPerStage = result2 / 21;
      newTargetSpeed = Math.round(currentSpeed + incrementPerStage * stageNumber);
    }

    return {
      number: stageNumber,
      title: `Aşama ${stageNumber}`,
      unlocked,
      completed,
      targetSpeed: targets[index] || null,
      testResult,
      newTargetSpeed,
    };
  });

  const handleStageClick = (stage) => {
    if (onStageSelect) {
      onStageSelect(stage);
    } else {
      navigate(`/exercises/${stage.number}`, { state: { stage } });
    }
  };

  const formatTime = (totalSeconds) => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes} dakika ${seconds} saniye`;
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    localStorage.setItem('hasVisitedStageList', 'true');

   };

  // Handler fonksiyonları silme işlemleri için
  const handleOpenDeleteProgressDialog = () => {
    setOpenDeleteProgressDialog(true);
  };

  const handleCloseDeleteProgressDialog = () => {
    setOpenDeleteProgressDialog(false);
  };

  const handleConfirmDeleteProgress = async () => {
    try {
      const token = localStorage.getItem('userToken');
      if (!token) {
        setSnackbar({ open: true, message: 'Token bulunamadı.', severity: 'error' });
        return;
      }

      await axios.delete(`${API_URLS.STUDENT_URL}akillikurs/progress`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setSnackbar({ open: true, message: 'İlerleme başarıyla silindi.', severity: 'success' });
      setProgressData(null); // İlerleme verisini state'den temizle
      setOpenDeleteProgressDialog(false);
    } catch (error) {
      console.error('İlerleme silinirken hata oluştu:', error);
      setSnackbar({ open: true, message: 'İlerleme silinirken bir hata oluştu.', severity: 'error' });
      setOpenDeleteProgressDialog(false);
    }
  };

  const handleOpenDeleteTestDialog = () => {
    setOpenDeleteTestDialog(true);
  };

  const handleCloseDeleteTestDialog = () => {
    setOpenDeleteTestDialog(false);
  };

  const handleConfirmDeleteTest = async () => {
    try {
      const token = localStorage.getItem('userToken');
      if (!token) {
        setSnackbar({ open: true, message: 'Token bulunamadı.', severity: 'error' });
        return;
      }

      await axios.delete(`${API_URLS.STUDENT_URL}akillikurs/test/initial-test`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setSnackbar({ open: true, message: 'Test sonucu başarıyla silindi.', severity: 'success' });
      setInitialTestResult(null); 
      setOpenDeleteTestDialog(false);
    } catch (error) {
      console.error('Test sonucu silinirken hata oluştu:', error);
      setSnackbar({ open: true, message: 'Test sonucu silinirken bir hata oluştu.', severity: 'error' });
      setOpenDeleteTestDialog(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleInitialTestModalClose = () => {
    setShowInitialTestModal(false);
    navigate('/seviye-tespit-sinav', { state: { initialTest: true } });
  };
  return (
    <StyledContainer maxWidth="lg">
      <Box sx={{ textAlign: 'center', mb: 6 }}>
        <Typography
          variant="h3"
          component="h1"
          gutterBottom
          sx={{ fontFamily: `'Comic Neue', cursive`, color: '#2c3e50' }}
        >
          21 Günlük Eğitim Aşamaları
        </Typography>
        <Typography variant="subtitle1" sx={{ mb: 2, color: '#4a4a4a' }}>
          Her bir aşama için aşağıdaki kartlardan seçim yapabilirsiniz.
        </Typography>

        {/* Seviye test sonucunu burada gösterelim */}
        {initialTestResult && (
          <Typography variant="h5" sx={{ mb: 2, color: '#2c3e50' }}>
            Başlangıç Okuma Hızınız:{' '}
            <strong>{initialTestResult.readingSpeed} kelime/dakika</strong>
          </Typography>
        )}
      </Box>

      <Grid container spacing={4}>
        {stages.map((stage) => (
          <Grid item xs={12} sm={6} md={4} key={stage.number}>
            <StyledCard
              sx={{
                opacity: stage.unlocked ? 1 : 0.6,
                pointerEvents: stage.unlocked ? 'auto' : 'none',
                backgroundImage: stage.completed
                  ? 'linear-gradient(135deg, #FFC371 0%, #FF5F6D 100%)' // Tamamlandı
                  : stage.unlocked
                  ? 'linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%)' // Açık
                  : 'linear-gradient(135deg, #B06AB3 0%, #4568DC 100%)', // Kilitli
              }}
            >
              {stage.unlocked ? (
                <CardActionArea
                  onClick={() => handleStageClick(stage)}
                  sx={{ flexGrow: 1 }}
                >
                  <CardContent>
                    {/* Başlık ve İkon */}
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        mb: 3,
                      }}
                    >
                      <Avatar
                        sx={{
                          bgcolor: 'transparent',
                          mt: 1,
                        }}
                      >
                        {stage.completed ? (
                          <CheckCircle sx={{ color: '#198754', fontSize: 40 }} />
                        ) : (
                          <School sx={{ color: '#FFFFFF', fontSize: 40 }} />
                        )}
                      </Avatar>
                      <Typography
                        variant="h5"
                        component="div"
                        sx={{
                          fontFamily: `'Comic Neue', cursive`,
                          color: '#FFFFFF',
                          mt: 1,
                          textAlign: 'center',
                        }}
                      >
                        {stage.title}
                      </Typography>
                    </Box>
                    {/* Bilgi Bölümleri - Düzenli Grid */}
                    <Box sx={{ mb: 2 }}>
                      <List>
                        {/* Başlangıçtaki Hedef Okuma Hızı */}
                        <ListItem
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            paddingLeft: 0,
                            paddingRight: 0
                          }}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <ListItemIcon sx={{ minWidth: '40px', color: '#FFFFFF' }}>
                              <Speed />
                            </ListItemIcon>
                            <Typography
                              variant="body1"
                              sx={{
                                color: '#FFFFFF',
                                fontWeight: 'bold',
                                textAlign: 'center' // Metni ortaladık
                              }}
                            >
                              Başlangıçtaki hedef okuma hızınız:
                            </Typography>
                          </Box>
                          <Divider sx={{ width: '100%', my: 1, backgroundColor: 'rgba(255, 255, 255, 0.3)' }} />
                          <Chip
                            label={`${stage.targetSpeed} kelime/dakika`}
                            color="primary"
                            sx={{
                              backgroundColor: 'rgba(255, 255, 255, 0.3)',
                              color: '#FFFFFF',
                              alignSelf: 'center' // Chip'i ortaladık
                            }}
                          />
                        </ListItem>
                        {/* Test Sonuçları */}
                        {stage.testResult && (
                          <>
                            {/* Okuduğunuz Kelime Sayısı */}
                            <ListItem
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center', // Ortalamak için flex-start yerine center kullandık
                                paddingLeft: 0,
                                paddingRight: 0
                              }}
                            >
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <ListItemIcon sx={{ minWidth: '40px', color: '#FFFFFF' }}>
                                  <Assignment />
                                </ListItemIcon>
                                <Typography
                                  variant="body1"
                                  sx={{
                                    color: '#FFFFFF',
                                    fontWeight: 'bold',
                                    textAlign: 'center' // Metni ortaladık
                                  }}
                                >
                                  Okuduğunuz kelime sayısı:
                                </Typography>
                              </Box>
                              <Divider sx={{ width: '100%', my: 1, backgroundColor: 'rgba(255, 255, 255, 0.3)' }} />
                              <Chip
                                label={stage.testResult.wordCount}
                                color="success"
                                sx={{
                                  backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                  color: '#FFFFFF',
                                  alignSelf: 'center' 
                                }}
                              />
                            </ListItem>

                            {/* Süre */}
                            <ListItem
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center', 
                                paddingLeft: 0,
                                paddingRight: 0
                              }}
                            >
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <ListItemIcon sx={{ minWidth: '40px', color: '#FFFFFF' }}>
                                  <Timer />
                                </ListItemIcon>
                                <Typography
                                  variant="body1"
                                  sx={{
                                    color: '#FFFFFF',
                                    fontWeight: 'bold',
                                    textAlign: 'center' // Metni ortaladık
                                  }}
                                >
                                  Süre:
                                </Typography>
                              </Box>
                              <Divider sx={{ width: '100%', my: 1, backgroundColor: 'rgba(255, 255, 255, 0.3)' }} />
                              <Chip
                                label={formatTime(stage.testResult.totalTime)}
                                color="info"
                                sx={{
                                  backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                  color: '#FFFFFF',
                                  alignSelf: 'center' 
                                }}
                              />
                            </ListItem>

                            {/* Okuma Hızı */}
                            <ListItem
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center', 
                                paddingLeft: 0,
                                paddingRight: 0
                              }}
                            >
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <ListItemIcon sx={{ minWidth: '40px', color: '#FFFFFF' }}>
                                  <Speed />
                                </ListItemIcon>
                                <Typography
                                  variant="body1"
                                  sx={{
                                    color: '#FFFFFF',
                                    fontWeight: 'bold',
                                    textAlign: 'center' // Metni ortaladık
                                  }}
                                >
                                  Okuma hızınız:
                                </Typography>
                              </Box>
                              <Divider sx={{ width: '100%', my: 1, backgroundColor: 'rgba(255, 255, 255, 0.3)' }} />
                              <Chip
                                label={`${stage.testResult.readingSpeed} kelime/dakika`}
                                color="warning"
                                sx={{
                                  backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                  color: '#FFFFFF',
                                  alignSelf: 'center' // Chip'i ortaladık
                                }}
                              />
                            </ListItem>
                          </>
                        )}

                        {/* Yeni Aşamadaki Hedef Okuma Hızı */}
                        {stage.newTargetSpeed && (
                          <ListItem
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center', // Ortalamak için flex-start yerine center kullandık
                              paddingLeft: 0,
                              paddingRight: 0
                            }}
                          >
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <ListItemIcon sx={{ minWidth: '40px', color: '#FFFFFF' }}>
                                <TrendingUp />
                              </ListItemIcon>
                              <Typography
                                variant="body1"
                                sx={{
                                  color: '#FFFFFF',
                                  fontWeight: 'bold',
                                  textAlign: 'center' // Metni ortaladık
                                }}
                              >
                                Yeni aşamadaki okuma hızı hedefiniz bu olmalı:
                              </Typography>
                            </Box>
                            <Divider sx={{ width: '100%', my: 1, backgroundColor: 'rgba(255, 255, 255, 0.3)' }} />
                            <Chip
                              label={`${stage.newTargetSpeed} kelime/dakika`}
                              color="secondary"
                              sx={{
                                backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                color: '#FFFFFF',
                                alignSelf: 'center' // Chip'i ortaladık
                              }}
                            />
                          </ListItem>
                        )}
                      </List>
                    </Box>

                  </CardContent>
                  {/* Tamamlandı Chip'i */}
                  {stage.completed && (
                    <Box sx={{ p: 2, width: '100%', display: 'flex', justifyContent: 'center' }}>
                      <Chip
                        label="Tamamlandı"
                        icon={<CheckCircle />}
                        sx={{
                          fontWeight: 'bold',
                          color: '#FFFFFF',
                          backgroundImage: 'linear-gradient(135deg, #4caf50 0%, #81c784 100%)',
                          width: '100%',
                          maxWidth: '400px',
                          fontSize: '1.2rem',
                          height: '48px',
                          padding: '0 16px',
                          borderRadius: '8px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          textTransform: 'none',
                          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                          transition: 'transform 0.3s, box-shadow 0.3s',
                          '&:hover': {
                            transform: 'scale(1.05)',
                            boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2)',
                          },
                        }}
                      />
                    </Box>
                  )}
                </CardActionArea>
              ) : (
                <CardContent sx={{ flexGrow: 1 }}>
                  {/* Başlık ve Kilit İkonu */}
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      mb: 3,
                    }}
                  >
                    <Typography
                      variant="h4"
                      component="div"
                      sx={{
                        fontFamily: `'Comic Neue', cursive`,
                        color: '#FFFFFF',
                        fontWeight: 'bold',
                      }}
                    >
                      {stage.number}
                    </Typography>
                    <Avatar
                      sx={{
                        bgcolor: 'transparent',
                        mt: 1,
                      }}
                    >
                      <Lock sx={{ color: '#FFFFFF', fontSize: 40 }} />
                    </Avatar>
                    <Typography
                      variant="h5"
                      component="div"
                      sx={{
                        fontFamily: `'Comic Neue', cursive`,
                        color: '#FFFFFF',
                        mt: 1,
                        textAlign: 'center',
                      }}
                    >
                      {stage.title}
                    </Typography>
                  </Box>

                  {/* Bilgi Bölümleri - Düzenli Grid */}
                  <Box sx={{ mb: 2 }}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <ListItem
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            paddingLeft: 0,
                            paddingRight: 0
                          }}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <ListItemIcon sx={{ minWidth: '40px', color: '#FFFFFF' }}>
                              <Speed />
                            </ListItemIcon>
                            <Typography
                              variant="body1"
                              sx={{
                                color: '#FFFFFF',
                                fontWeight: 'bold',
                                textAlign: 'center'
                              }}
                            >
                              Bu aşamadaki hedef okuma hızınız:
                            </Typography>
                          </Box>
                          <Divider sx={{ width: '100%', my: 1, backgroundColor: 'rgba(255, 255, 255, 0.3)' }} />
                          <Chip
                            label={`${stage.targetSpeed} kelime/dakika`}
                            color="primary"
                            sx={{
                              backgroundColor: 'rgba(255, 255, 255, 0.3)',
                              color: '#FFFFFF',
                              alignSelf: 'center'
                            }}
                          />
                        </ListItem>
                      </Grid>

                      <Grid item xs={12}>
                        <ListItem
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            paddingLeft: 0,
                            paddingRight: 0
                          }}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <ListItemIcon sx={{ minWidth: '40px', color: '#FFFFFF' }}>
                              <TrendingUp />
                            </ListItemIcon>
                            <Typography
                              variant="body1"
                              sx={{
                                color: '#FFFFFF',
                                fontWeight: 'bold',
                                textAlign: 'center'
                              }}
                            >
                              Yeni aşamadaki okuma hızı hedefiniz bu olmalı:
                            </Typography>
                          </Box>
                          <Divider sx={{ width: '100%', my: 1, backgroundColor: 'rgba(255, 255, 255, 0.3)' }} />
                          <Chip
                            label={`${stage.newTargetSpeed} kelime/dakika`}
                            color="secondary"
                            sx={{
                              backgroundColor: 'rgba(255, 255, 255, 0.3)',
                              color: '#FFFFFF',
                              alignSelf: 'center'
                            }}
                          />
                        </ListItem>
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant="body1" sx={{ color: '#FFFFFF', textAlign: 'center' }}>
                          Bu aşama henüz kilitli. Devam etmek için önceki aşamayı tamamlayın.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
              )}
            </StyledCard>
          </Grid>
        ))}
      </Grid>

      {/* Silme Butonları */}
      {(!studentData?.ogretmenId || studentData.ogretmenId.length === 0) && (
        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center', gap: 2 }}>
          <Button
            variant="outlined"
            color="error"
            onClick={handleOpenDeleteProgressDialog}
          >
            Tüm Aşamaları Sil
          </Button>
          <Button
            variant="outlined"
            color="warning"
            onClick={handleOpenDeleteTestDialog}
            disabled={!initialTestResult} // İlk test sonucu yoksa butonu devre dışı bırak
          >
            İlk Okuma Hızı Testini Sil
          </Button>
        </Box>
      )}


      {/* İlerlemeyi Silme Onay Dialog'ı */}
      <Dialog
        open={openDeleteProgressDialog}
        onClose={handleCloseDeleteProgressDialog}
        aria-labelledby="delete-progress-dialog-title"
        aria-describedby="delete-progress-dialog-description"
      >
        <DialogTitle id="delete-progress-dialog-title">Tüm Aşamaları Sil</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-progress-dialog-description">
            Tüm ilerleme verilerinizi silmek istediğinizden emin misiniz? Bu işlem geri alınamaz.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteProgressDialog}>İptal</Button>
          <Button onClick={handleConfirmDeleteProgress} color="error">Sil</Button>
        </DialogActions>
      </Dialog>

      {/* İlk Test Sonucunu Silme Onay Dialog'ı */}
      <Dialog
        open={openDeleteTestDialog}
        onClose={handleCloseDeleteTestDialog}
        aria-labelledby="delete-test-dialog-title"
        aria-describedby="delete-test-dialog-description"
      >
        <DialogTitle id="delete-test-dialog-title">İlk Okuma Hızı Testini Sil</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-test-dialog-description">
            İlk okuma hızı test sonucunuzu silmek istediğinizden emin misiniz? Bu işlem geri alınamaz.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteTestDialog}>İptal</Button>
          <Button onClick={handleConfirmDeleteTest} color="warning">Sil</Button>
        </DialogActions>
      </Dialog>

      {/* Bildirim (Snackbar) */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      {/* Hoş Geldiniz Modal'ı */}
 
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openModal}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: { xs: '80%', md: '50%' }, // Boyutu küçülttük
                bgcolor: 'rgba(30, 30, 30, 0.85)', // Hafif transparan koyu arka plan
                borderRadius: 4,
                boxShadow: 24,
                p: 4,
                color: '#FFFFFF',
                border: '2px solid #4caf50',
                backgroundImage: 'linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.05))', // Gradient ekledik
                backdropFilter: 'blur(5px)', // Arka plan bulanıklığı efekti
                // Hover ve animasyon efektleri
                transition: 'transform 0.3s, box-shadow 0.3s',
                '&:hover': {
                  transform: 'translate(-50%, -50%) scale(1.02)',
                  boxShadow: '0 0 20px #4caf50',
                },
              }}
            >
              {/* Başlık ve İkon */}
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <InfoIcon sx={{ mr: 1, fontSize: '2rem', color: '#4caf50' }} />
                <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                  Hoş Geldiniz!
                </Typography>
              </Box>
              
              {/* Talimatlar */}
              <Typography variant="body1" sx={{ mb: 1, fontSize: '1.1rem' }}>
                - <strong>Hızlı okuma testi</strong> yapınız.
              </Typography>
              <Typography variant="body1" sx={{ mb: 1, fontSize: '1.1rem' }}>
                - <strong>Akıllı kurs</strong> işlemlerine devam ediniz.
              </Typography>
              <Typography variant="body1" sx={{ mb: 1, fontSize: '1.1rem' }}>
                - Akıllı kurs otomatik olarak aşama aşama hedeflerinizi belirleyecektir.
              </Typography>
              <Typography variant="body1" sx={{ mb: 1, fontSize: '1.1rem' }}>
                - <strong>Raporlar</strong> bölümünden ilerlemelerinizi detaylı görebilirsiniz.
              </Typography>
              <Typography variant="body1" sx={{ mb: 1, fontSize: '1.1rem' }}>
                - 21 günün sonunda <strong>sertifikanız</strong> düzenlenecektir.
              </Typography>
              
              {/* Tamam Butonu */}
              <Box sx={{ mt: 4, textAlign: 'center' }}>
                <Button
                  variant="contained"
                  color="success"
                  onClick={handleCloseModal}
                  sx={{
                    px: 4,
                    py: 1.5,
                    fontSize: '1rem',
                    borderRadius: 2,
                    textTransform: 'none',
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
                    transition: 'background-color 0.3s, transform 0.3s',
                    '&:hover': {
                      backgroundColor: '#388e3c',
                      transform: 'scale(1.05)',
                      boxShadow: '0 6px 14px rgba(0, 0, 0, 0.3)',
                    },
                  }}
                >
                  Tamam
                </Button>
              </Box>
            </Box>
          </Fade>
        </Modal>

        <Modal
        open={showInitialTestModal}
        onClose={handleInitialTestModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showInitialTestModal}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: { xs: '80%', md: '40%' },
              bgcolor: 'rgba(255, 255, 255, 0.9)',
              borderRadius: 4,
              boxShadow: 24,
              p: 4,
              color: '#333333',
              border: '2px solid #e53935',
              backdropFilter: 'blur(5px)',
            }}
          >
            {/* Başlık ve Uyarı İkonu */}
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <InfoIcon sx={{ mr: 1, fontSize: '2rem', color: '#e53935' }} />
              <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                Dikkat!
              </Typography>
            </Box>

            {/* Uyarı Mesajı */}
            <Typography variant="body1" sx={{ mb: 3 }}>
              Öncelikle hız testinizi yapmak zorundasınız.
            </Typography>

            {/* Tamam Butonu */}
            <Box sx={{ textAlign: 'center' }}>
              <Button
                variant="contained"
                color="error"
                onClick={handleInitialTestModalClose}
                sx={{
                  px: 4,
                  py: 1.5,
                  fontSize: '1rem',
                  borderRadius: 2,
                  textTransform: 'none',
                  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
                  transition: 'background-color 0.3s, transform 0.3s',
                  '&:hover': {
                    backgroundColor: '#d32f2f',
                    transform: 'scale(1.05)',
                    boxShadow: '0 6px 14px rgba(0, 0, 0, 0.3)',
                  },
                }}
              >
                Tamam
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </StyledContainer>
  );
};

export default StageList;
