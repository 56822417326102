// src/pages/ogretmen/activation/ActivateAccountForm.js

import React, { useState } from 'react';
import { Typography, Button, Card, CardContent, Grid } from '@mui/material';
import { API_URLS } from '../../../config/config';

function ActivateAccountForm() {
  const [message, setMessage] = useState('');

  const handleActivate = async () => {
    try {
      const response = await fetch(API_URLS.KURUMSAL_URL + 'activate', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();

      if (response.ok) {
        setMessage('Hesabınız başarılı bir şekilde aktif edildi.');
      } else {
        setMessage('Hesap aktif etme başarısız: ' + data.message);
      }
    } catch (error) {
      console.error('Hesap aktif etme işlemi sırasında bir hata oluştu', error);
      setMessage('Hesap aktif etme işlemi sırasında bir hata oluştu.');
    }
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Hesabınızı Aktif Edin
        </Typography>
        <Typography variant="body1" gutterBottom>
          Hesabınızı aktif etmek için aşağıdaki butona tıklayın. Bakiyenizden 1 adet düşülecektir.
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" fullWidth onClick={handleActivate}>
              Hesabı Aktif Et
            </Button>
          </Grid>
        </Grid>
        {message && (
          <Typography variant="body1" color="error" sx={{ mt: 2 }}>
            {message}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}

export default ActivateAccountForm;
