import { useState, useEffect, useRef, useCallback } from 'react';
import { API_URLS } from '../../../../config/config';

export const useKayanYazilarUcerOyunu = (navigate, userData) => {
  const [difficulty, setDifficulty] = useState('easy');
  const [paragraphsList, setParagraphsList] = useState([]);
  const [selectedParagraphIndex, setSelectedParagraphIndex] = useState(0);
  const [displayedWords, setDisplayedWords] = useState([]);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [speed, setSpeed] = useState(2);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [gameTime, setGameTime] = useState(0);
  const [timeLeft, setTimeLeft] = useState(0);
  const [message, setMessage] = useState('');
  const [gameOver, setGameOver] = useState(false);
  const timerRef = useRef(null);
  const token = localStorage.getItem('userToken');

  // Kullanıcı durumuna göre oyun süresini ayarla
  useEffect(() => {
    if (userData) {
      const time = userData.status === 'inactive' ? 15 : 60;
      setGameTime(time);
      setTimeLeft(time);
    } else {
      setGameTime(15);
      setTimeLeft(15);
    }
  }, [userData]);

  useEffect(() => {
    const fetchParagraphs = async () => {
      try {
        const url = `${API_URLS.HIZLIOKUMA_URL}akanyazi/${difficulty}`;
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setParagraphsList(data);
        setSelectedParagraphIndex(0);
        handleReset();
      } catch (error) {
        console.error('Paragraflar alınırken hata oluştu:', error);
      }
    };

    fetchParagraphs();
  }, [difficulty, token]);

  useEffect(() => {
    handleReset();
  }, [selectedParagraphIndex]);

  // Oyun süresi zamanlayıcısı
  useEffect(() => {
    let timer;
    if (isPlaying && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prev) => {
          if (prev > 0) {
            return prev - 1;
          } else {
            clearInterval(timer);
            setIsPlaying(false);
            setGameOver(true);
            return 0;
          }
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isPlaying]);

  const updateWords = useCallback(() => {
    if (!isPlaying || !paragraphsList[selectedParagraphIndex]) return;

    const words = paragraphsList[selectedParagraphIndex].content.split(' ');

    if (currentWordIndex >= words.length) {
      setIsPlaying(false);
      setGameOver(true);
      return;
    }

    const nextWords = words.slice(currentWordIndex, currentWordIndex + 3);
    setDisplayedWords((prev) => [...prev, nextWords.join(' ')]);
    setCurrentWordIndex((prev) => prev + 3);
    setElapsedTime((prev) => prev + (1000 / speed));
  }, [isPlaying, paragraphsList, selectedParagraphIndex, currentWordIndex, speed]);

  const handlePlayPause = () => {
    if (gameOver) return;
    if (isPlaying) {
      setMessage(`Oyun duraklatıldı. Skorunuz: ${currentWordIndex} kelime`);
    } else {
      setMessage('');
    }
    setIsPlaying(!isPlaying);
  };

  const handleReset = () => {
    setIsPlaying(false);
    setDisplayedWords([]);
    setCurrentWordIndex(0);
    setElapsedTime(0);
    setTimeLeft(gameTime);
    setMessage('');
    setGameOver(false);
  };

  const handleSpeedChange = (e) => {
    setSpeed(Number(e.target.value));
  };

  const handleParagraphChange = (e) => {
    setSelectedParagraphIndex(Number(e.target.value));
  };

  const handleDifficultyChange = (e) => {
    setDifficulty(e.target.value);
  };

  const onTimeUp = () => {
    setIsPlaying(false);
    setGameOver(true);
  };

  return {
    difficulty,
    paragraphsList,
    selectedParagraphIndex,
    displayedWords,
    currentWordIndex,
    isPlaying,
    speed,
    elapsedTime,
    gameTime,
    timeLeft,
    message,
    gameOver,
    updateWords,
    handlePlayPause,
    handleReset,
    handleSpeedChange,
    handleParagraphChange,
    handleDifficultyChange,
    onTimeUp,
  };
};
