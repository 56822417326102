// src/pages/ogrenci/oyunlar/components/GameInstructions.js

import React from 'react';

const GameInstructions = () => {
  return (
    <div className="alert alert-info text-center">
      <h4>Oyun Nasıl Oynanır?</h4>
      <p>
        Bu oyunda seçtiğiniz zorluk seviyesine göre kelimeler ekranda belirecek. Amacınız bu kelimeleri en hızlı şekilde okumak. Oyun başladığında kelimeler akmaya başlayacak ve "Duraklat" butonuna basarak oyunu durdurabilirsiniz. "Yeniden Başlat" butonuna basarak oyunu sıfırlayabilir ve yeni bir başlangıç yapabilirsiniz.
      </p>
      <p>
        Hızı değiştirebilir ve farklı paragraflar seçerek oyunu kişiselleştirebilirsiniz. Skorunuz ekranda gösterilecek ve oyunu duraklattığınızda kelime sayısı ve geçen süre görüntülenecek.
      </p>
    </div>
  );
};

export default GameInstructions;
