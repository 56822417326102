import React from 'react';

const DropdownSelectors = ({
  difficulty,
  selectedParagraphIndex,
  paragraphsList,
  handleDifficultyChange,
  handleParagraphChange,
}) => {
  return (
    <div className="row mb-3">
      <div className="col-12 col-md-6 mb-2 mb-md-0">
        <label
          htmlFor="difficultySelect"
          className="form-label"
          style={{ fontSize: '1.5rem', fontWeight: 'bold' }}
        >
          Zorluk Seç:
        </label>
        <select
          className="form-select"
          id="difficultySelect"
          value={difficulty}
          onChange={handleDifficultyChange}
          style={{ fontSize: '1.25rem' }}
        >
          <option value="easy">Kolay</option>
          <option value="medium">Orta</option>
          <option value="hard">Zor</option>
        </select>
      </div>
      <div className="col-12 col-md-6">
        <label
          htmlFor="paragraphSelect"
          className="form-label"
          style={{ fontSize: '1.5rem', fontWeight: 'bold' }}
        >
          Paragraf Seç:
        </label>
        <select
          className="form-select"
          id="paragraphSelect"
          value={selectedParagraphIndex}
          onChange={handleParagraphChange}
          style={{ fontSize: '1.25rem' }}
        >
          {paragraphsList.map((paragraph, index) => (
            <option key={index} value={index}>
              {paragraph.title}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default DropdownSelectors;
