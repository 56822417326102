import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Box, Typography, Button, CircularProgress, Grid, Paper, IconButton,} from '@mui/material';
import { styled } from '@mui/material/styles';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { API_URLS } from '../../config/config';

// Stil Verilmiş Bileşenler
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: '20px',
  background: 'linear-gradient(to bottom right, #ffffff, #e8f5e9)',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '25px',
  padding: '10px 30px',
  fontWeight: 'bold',
  margin: '0 10px',
}));

const TimerDisplay = styled(Typography)(({ theme }) => ({
  fontSize: '2rem',
  fontWeight: 'bold',
  color: '#388e3c',
  marginBottom: theme.spacing(2),
}));

const OkumaSeviyeTesti = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { stage, fromStage, initialTest } = location.state || {};

  const [paragraf, setParagraf] = useState('');
  const [loading, setLoading] = useState(true);
  const [readingTime, setReadingTime] = useState(0);
  const [readingSpeed, setReadingSpeed] = useState(null);
  const [step, setStep] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const timerRef = useRef(null);
  const [bookId, setBookId] = useState(null);

  // Yeni eklenen state değişkenleri
  const [wordCount, setWordCount] = useState(0);
  const [speed, setSpeed] = useState(0);

  // Kullanıcı verileri ve rolü için state
  const [userData, setUserData] = useState(null);
  const [userRole, setUserRole] = useState(null);

  // Kullanıcı verilerini ve rolünü getiren useEffect
  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('userToken');
      if (token) {
        try {
          // Öğrenci verilerini getir
          const studentResponse = await fetch(`${API_URLS.STUDENT_URL}/me`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
          if (studentResponse.ok) {
            const studentData = await studentResponse.json();
            setUserData(studentData);
            setUserRole('student');
            return;
          }
        } catch (error) {
          console.error('Öğrenci verisi alınırken hata oluştu:', error);
        }
        try {
          // Öğretmen verilerini getir
          const teacherResponse = await fetch(`${API_URLS.TEACHER_URL}/me`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
          if (teacherResponse.ok) {
            const teacherData = await teacherResponse.json();
            setUserData(teacherData);
            setUserRole('teacher');
            return;
          }
        } catch (error) {
          console.error('Öğretmen verisi alınırken hata oluştu:', error);
        }
        // Her ikisi de başarısız olursa
        setUserData(null);
        setUserRole(null);
      }
    };
    fetchUserData();
  }, []);

  // Paragrafı API'den çekme
  useEffect(() => {
    const fetchParagraf = async () => {
      try {
        const response = await axios.get(`${API_URLS.TESTLER_URL}seviye/medium`);
        const { content, _id } = response.data;
        setParagraf(content);
        setBookId(_id);
        setLoading(false);
      } catch (error) {
        console.error('Paragraf verisi çekilemedi:', error);
        setLoading(false);
      }
    };

    fetchParagraf();
  }, []);

  // Zamanlayıcıyı kontrol etme
  useEffect(() => {
    if (isRunning) {
      timerRef.current = setInterval(() => {
        setReadingTime((prevTime) => prevTime + 1);
      }, 1000);
    } else if (!isRunning && timerRef.current) {
      clearInterval(timerRef.current);
    }

    return () => clearInterval(timerRef.current);
  }, [isRunning]);

  const handleStart = () => {
    setIsRunning(true);
    setStep(2);
  };

  const handlePauseResume = () => {
    setIsRunning((prev) => !prev);
  };

  const handleFinishReading = async () => {
    setIsRunning(false);

    // Okuma hızını hesapla
    const wc = paragraf.split(' ').length;
    const timeInMinutes = readingTime / 60;
    const spd = wc / timeInMinutes;

    setWordCount(wc);
    setSpeed(spd);
    setReadingSpeed(Math.round(spd));
    setStep(3);

    const token = localStorage.getItem('userToken');

    if (userData && userRole) {
      // Kullanıcı giriş yapmışsa sonucu kaydet
      if (fromStage && stage) {
        // Aşama testi sonucu kaydet
        try {
          const apiUrl =
            userRole === 'student'
              ? `${API_URLS.STUDENT_URL}akillikurs/progress/update-stage-test`
              : `${API_URLS.TEACHER_URL}akillikurs/progress/update-stage-test`;
          const response = await axios.post(
            apiUrl,
            {
              stageNumber: stage.number,
              wordCount: wc,
              totalTime: readingTime,
              readingSpeed: Math.round(spd),
              bookId,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          console.log('Aşama test sonucu kaydedildi:', response.data);
        } catch (error) {
          console.error('Aşama test sonucu kaydedilemedi:', error);
        }
      } else if (initialTest) {
        // Başlangıç testi sonucu kaydet
        try {
          const apiUrl =
            userRole === 'student'
              ? `${API_URLS.STUDENT_URL}akillikurs/test/initial-test`
              : `${API_URLS.TEACHER_URL}akillikurs/test/initial-test`;
          const response = await axios.post(
            apiUrl,
            {
              wordCount: wc,
              totalTime: readingTime,
              readingSpeed: Math.round(spd),
           
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          console.log('Başlangıç test sonucu kaydedildi:', response.data);
         
          navigate(userRole === 'student' ? '/ogrenci-dashboard' : '/ogretmen-dashboard');
        } catch (error) {
          console.error('Başlangıç test sonucu kaydedilemedi:', error);
        }
      }
    }
  };

  return (
    <Box
      sx={{
        p: 4,
        backgroundColor: '#e8f5e9',
        minHeight: '100vh',
      }}
    >
      <Typography
        variant="h4"
        sx={{
          fontWeight: 'bold',
          color: '#2e7d32',
          textAlign: 'center',
          mb: 3,
          fontFamily: 'Comic Sans MS',
        }}
      >
        Okuma Seviye Testi
      </Typography>

      <Grid container justifyContent="center">
        <Grid item xs={12} md={8}>
          <StyledPaper>
            {loading ? (
              <Box sx={{ textAlign: 'center', py: 5 }}>
                <CircularProgress />
                <Typography sx={{ mt: 2 }}>Yükleniyor...</Typography>
              </Box>
            ) : (
              <>
                {step === 0 && (
                  <Box sx={{ textAlign: 'center' }}>
                    <Typography
                      variant="h5"
                      sx={{ mb: 2, fontWeight: 'bold', color: '#2e7d32' }}
                    >
                      Oyuna Hoş Geldiniz!
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ mb: 3, fontSize: '1.1rem', lineHeight: 1.6 }}
                    >
                      Bu oyun okuma hızınızı ölçmek için tasarlanmıştır. Karşınıza
                      çıkacak paragrafı mümkün olan en kısa sürede okuyun. "Başlat"
                      butonuna bastığınızda zamanlayıcı başlayacak ve paragraf
                      görünecektir. Okumayı bitirdiğinizde "Okumayı Bitirdim"
                      butonuna basarak sonucu görebilirsiniz.
                    </Typography>
                    <StyledButton
                      variant="contained"
                      color="primary"
                      onClick={() => setStep(1)}
                    >
                      Başla
                    </StyledButton>
                  </Box>
                )}

                {step === 1 && (
                  <Box sx={{ textAlign: 'center' }}>
                    <Typography
                      variant="h5"
                      sx={{ mb: 2, fontWeight: 'bold', color: '#2e7d32' }}
                    >
                      Hazır mısınız?
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ mb: 3, fontSize: '1.1rem', lineHeight: 1.6 }}
                    >
                      "Başlat" butonuna bastığınızda zamanlayıcı başlayacaktır.
                    </Typography>
                    <StyledButton
                      variant="contained"
                      color="primary"
                      onClick={handleStart}
                    >
                      Başlat
                    </StyledButton>
                  </Box>
                )}

                {step === 2 && (
                  <Box sx={{ textAlign: 'center' }}>
                    <TimerDisplay>
                      Geçen Süre: {Math.floor(readingTime / 60)} dk {readingTime % 60} sn
                    </TimerDisplay>
                    <Box
                      sx={{
                        maxHeight: '400px',
                        overflowY: 'auto',
                        mb: 2,
                        p: 2,
                        backgroundColor: '#f1f8e9',
                        borderRadius: '10px',
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          mt: 2,
                        maxWidth: '1000px',
                        margin: '0 auto',
                        fontSize: '25px',
                        textAlign: 'center',
                        lineHeight: 1.8,
                        letterSpacing: '0.8px',
                        }}
                      >
                        {paragraf}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <IconButton
                        color="primary"
                        onClick={handlePauseResume}
                        sx={{
                          border: '2px solid',
                          borderColor: '#2e7d32',
                          mr: 2,
                          color: '#2e7d32',
                        }}
                      >
                        {isRunning ? <PauseIcon /> : <PlayArrowIcon />}
                      </IconButton>
                    </Box>
                    <Box sx={{ textAlign: 'center', mt: 2 }}>
                      <StyledButton
                        variant="contained"
                        color="secondary"
                        onClick={handleFinishReading}
                      >
                        Okumayı Bitirdim
                      </StyledButton>
                    </Box>
                  </Box>
                )}

                {step === 3 && (
                  <Box sx={{ textAlign: 'center' }}>
                    {userData ? (
                      <>
                        <Typography
                          variant="h5"
                          sx={{ mb: 2, fontWeight: 'bold', color: '#2e7d32' }}
                        >
                          Sonuçlarınız
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 1, fontSize: '1.1rem' }}>
                          Okuduğunuz Kelime Sayısı: {wordCount}
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 1, fontSize: '1.1rem' }}>
                          Toplam Süre: {Math.floor(readingTime / 60)} dakika{' '}
                          {readingTime % 60} saniye
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 1, fontSize: '1.1rem' }}>
                          Okuma Hızınız: {readingSpeed} kelime/dakika
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2, fontSize: '1rem' }}>
                          Ortalama bir insanın okuma hızı yaklaşık 200-250
                          kelime/dakikadır.
                        </Typography>

                        {/* Yeni eklenen buton */}
                        {fromStage && stage && bookId && (
                          <StyledButton
                            variant="contained"
                            color="secondary"
                            onClick={() =>
                              navigate(`/testsayfasi/${bookId}`, {
                                state: {
                                  fromStage: true,
                                  stageNumber: stage.number,
                                  wordCount,
                                  totalTime: readingTime,
                                  readingSpeed,
                                  bookId,
                                },
                              })
                            }
                            sx={{ mt: 2 }}
                          >
                            Test Sayfasına Geç
                          </StyledButton>
                        )}

                        <StyledButton
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            if (fromStage && stage) {
                              navigate(
                                userRole === 'student' ? '/ogrenci-dashboard' : '/ogretmen-dashboard',
                                {
                                  state: {
                                    selectedMenu: 'ExerciseList',
                                    selectedStage: stage,
                                  },
                                }
                              );
                            } else {
                              navigate('/');
                            }
                          }}
                          sx={{ mt: 2 }}
                        >
                          Ana Sayfaya Dön
                        </StyledButton>
                      </>
                    ) : (
                      <>
                        <Typography
                          variant="h5"
                          sx={{ mb: 2, fontWeight: 'bold', color: '#2e7d32' }}
                        >
                          Sonuçları Görmek İçin Yeni Kayıt Yapınız
                        </Typography>
                        <StyledButton
                          variant="contained"
                          color="primary"
                          onClick={() => navigate('/register')}
                        >
                          Giriş Yap
                        </StyledButton>
                      </>
                    )}
                  </Box>
                )}
              </>
            )}
          </StyledPaper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OkumaSeviyeTesti;
