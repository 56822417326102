import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, CardContent, TextField, Button, Typography, Container, CircularProgress, IconButton, InputAdornment } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Header from '../../../../home/components/Header';
import Footer from '../../../../home/components/Footer';
import ResultDialog from '../../../components/Auth/Dialog';
import { API_URLS } from '../../../../config/config';

function OgrenciResetPasswordForm() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');
  const [dialogSeverity, setDialogSeverity] = useState('info');
  const navigate = useNavigate();
  const { resetToken } = useParams(); 

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

 
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      setDialogSeverity('error');
      setDialogContent('Şifreler uyuşmuyor, lütfen tekrar deneyiniz.');
      setDialogOpen(true);
      return;
    }
  
    setLoading(true);
  
    try {
      
      const response = await fetch(`${API_URLS.STUDENT_URL}reset-password/${resetToken}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ password: password }),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        setLoading(false);
        setDialogSeverity('success');
        setDialogContent(data.message || 'Şifreniz başarıyla sıfırlandı. Yeni şifreniz ile giriş yapabilirsiniz.');
        setDialogOpen(true);
       
        setTimeout(() => {
          navigate('/ogrenci-giris'); 
        }, 2000);
      } else {
        setLoading(false);
        setDialogSeverity('error');
        setDialogContent(data.message || 'Şifre sıfırlama işlemi başarısız oldu.');
        setDialogOpen(true);
      }
    } catch (error) {
      setLoading(false);
      setDialogSeverity('error');
      setDialogContent('Şifre sıfırlama işlemi sırasında bir hata oluştu: ' + error.message);
      setDialogOpen(true);
    }
  };
  


  return (
    <div>
      <Header />
      <div className='bg-main'>
        <Container maxWidth="sm" sx={{
          display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh',
          '.MuiCard-root': {
            background: 'linear-gradient(145deg, rgba(105,105,105,0.8) 0%, rgba(220,20,60,0.8) 100%)', 
            backdropFilter: 'blur(8px)',
          }
        }}>
          <Card sx={{
            minWidth: 275, backgroundColor: 'rgba(255, 255, 255, 0.75)', 
            backdropFilter: 'blur(8px)', 
            borderRadius: '16px',
            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
          }}>
            <CardContent sx={{
              display: 'flex', flexDirection: 'column', alignItems: 'center', 
            }}>
              <Typography variant="h5" component="div" sx={{ marginBottom: 2, color: '#fff', fontWeight: 'bold', textShadow: '2px 2px 4px rgba(0,0,0,0.5)', letterSpacing: '0.05rem', fontSize: '1.5rem' }}>
                Şifre Sıfırla - Öğrenci
              </Typography>

              <form onSubmit={handleSubmit} style={{ width: '100%' }}> 
                <TextField
                  fullWidth
                  margin="normal"
                  label="Yeni Şifre"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{ input: { color: 'white' }, label: { color: 'white' }, '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: 'white' }, '&:hover fieldset': { borderColor: 'lightgray' }, '&.Mui-focused fieldset': { borderColor: 'white' }, } }}
                />

                <TextField
                  fullWidth
                  margin="normal"
                  label="Yeni Şifre Tekrar"
                  type={showPassword ? 'text' : 'password'}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{ input: { color: 'white' }, label: { color: 'white' }, '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: 'white' }, '&:hover fieldset': { borderColor: 'lightgray' }, '&.Mui-focused fieldset': { borderColor: 'white' }, } }}
                />

                <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2, py: 1.5 }}>
                  Şifre Sıfırla
                </Button>
              </form>
            </CardContent>
          </Card>
        </Container>
      </div>
      {loading && <CircularProgress />}
      <ResultDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        title={dialogSeverity === 'error' ? "Hata" : "Başarılı"}
        message={dialogContent}
        severity={dialogSeverity}
      />
      <Footer />
    </div>
  );
}

export default OgrenciResetPasswordForm;
