import { useRef, useState, useCallback, useEffect } from 'react';

const CANVAS_WIDTH = 800;
const CANVAS_HEIGHT = 600;

export default function useGameLogic(userData) {
  const canvasRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [speed, setSpeed] = useState(1); // Varsayılan hız

  const [score, setScore] = useState(0);
  const [isGameOver, setIsGameOver] = useState(false);

  // Ölçeklendirme ayarları
  const [scale, setScale] = useState(0.5); // Başlangıç ölçeği
  const scaleMin = 0.5;
  const scaleMax = 1.0;

  const getInitialGameTime = () => {
    if (userData) {
      return userData.status === 'inactive' ? 15 : 60;
    }
    return 15;
  };

  const [initialGameTime, setInitialGameTime] = useState(getInitialGameTime());
  const [gameTime, setGameTime] = useState(initialGameTime);

  useEffect(() => {
    if (userData) {
      const newGameTime = userData.status === 'inactive' ? 15 : 60;
      setGameTime(newGameTime);
      setInitialGameTime(newGameTime); // Başlangıç zamanını güncelle
      setSpeed(1);
    } else {
      setGameTime(15);
      setInitialGameTime(15); // Başlangıç zamanını güncelle
      setSpeed(1);
    }
  }, [userData]);

  const updateLetters = useCallback(() => {
    const turkishAlphabet = [
      'A', 'B', 'C', 'Ç', 'D', 'E', 'F', 'G',
      'Ğ', 'H', 'I', 'İ', 'J', 'K', 'L', 'M',
      'N', 'O', 'Ö', 'P', 'R', 'S', 'Ş', 'T',
      'U', 'Ü', 'V', 'Y', 'Z'
    ];
    return Array.from({ length: 4 }, () =>
      turkishAlphabet[Math.floor(Math.random() * turkishAlphabet.length)]
    );
  }, []);

  const [letters, setLetters] = useState(updateLetters);

  // Oyun süresini her saniye azalt
  useEffect(() => {
    let gameTimeInterval;
    if (isPlaying && gameTime > 0) {
      gameTimeInterval = setInterval(() => {
        setGameTime((prevTime) => prevTime - 1);
      }, 1000);
    } else if (gameTime === 0) {
      setIsPlaying(false);
      setIsGameOver(true);
    }

    return () => clearInterval(gameTimeInterval);
  }, [isPlaying, gameTime]);

  // Harfleri hıza bağlı olarak güncelle
  useEffect(() => {
    let lettersInterval;
    if (isPlaying && gameTime > 0) {
      const updateInterval = Math.max(600 / speed, 100);
      lettersInterval = setInterval(() => {
        setLetters(updateLetters());
        setScore((prevScore) => prevScore + 1);
      }, updateInterval);
    }

    return () => clearInterval(lettersInterval);
  }, [isPlaying, speed, gameTime, updateLetters]);

  // Yumuşak ölçeklendirme animasyonu
  useEffect(() => {
    let animationFrameId;

    const animateScale = () => {
      const time = Date.now() / 1000;
      const amplitude = (scaleMax - scaleMin) / 2;
      const offset = scaleMin + amplitude;
      setScale(offset + amplitude * Math.abs(Math.sin(time * speed)));

      animationFrameId = requestAnimationFrame(animateScale);
    };

    if (isPlaying) {
      animationFrameId = requestAnimationFrame(animateScale);
    }

    return () => cancelAnimationFrame(animationFrameId);
  }, [isPlaying, speed]);

  const drawDiamond = useCallback(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    const sideLength = 150; // Küçük yan uzunluğu
    let currentScale = scale;
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;

    // Canvas'ı temizle
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Arka plan gradyanı
    const gradient = ctx.createLinearGradient(0, 0, canvas.width, canvas.height);
    gradient.addColorStop(0, '#ffecd2'); // Açık pembe
    gradient.addColorStop(1, '#fcb69f'); // Mercan tonları
    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    ctx.save();
    ctx.translate(centerX, centerY);

    ctx.lineWidth = 2;
    ctx.strokeStyle = '#ffffff'; // Beyaz kenarlık
    ctx.fillStyle = '#ffcc5c'; // Sarı dolgu
    ctx.shadowColor = 'rgba(0, 0, 0, 0.9)';
    ctx.shadowBlur = 0;

    const points = [
      { x: 0, y: -sideLength * currentScale },
      { x: sideLength * currentScale, y: 0 },
      { x: 0, y: sideLength * currentScale },
      { x: -sideLength * currentScale, y: 0 },
    ];

    // Döngü alanını çiz
    ctx.beginPath();
    points.forEach((point, index) => {
      if (index === 0) {
        ctx.moveTo(point.x, point.y);
      } else {
        ctx.lineTo(point.x, point.y);
      }
    });
    ctx.closePath();
    ctx.fill();
    ctx.stroke();

    // Harfleri çevreleyen daireleri çiz
    points.forEach((point, index) => {
      const radius = 30 * currentScale;
      ctx.beginPath();
      ctx.arc(point.x, point.y, radius, 0, Math.PI * 2);
      ctx.fillStyle = '#fff';
      ctx.strokeStyle = '#ffffff';
      ctx.lineWidth = 1;
      ctx.fill();
      ctx.stroke();

      // Harfleri çiz
      ctx.font = `${28 * currentScale}px "Comic Sans MS", cursive, sans-serif`;
      ctx.fillStyle = '#000';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(letters[index], point.x, point.y);
    });

    ctx.restore();

    // Ortadaki siyah noktayı çiz
    ctx.beginPath();
    ctx.arc(centerX, centerY, 15, 0, Math.PI * 2);
    ctx.fillStyle = '#000000';
    ctx.fill();
  }, [scale, letters]);

  useEffect(() => {
    let animationFrameId;

    const render = () => {
      drawDiamond();
      animationFrameId = requestAnimationFrame(render);
    };

    if (isPlaying) {
      animationFrameId = requestAnimationFrame(render);
    }

    return () => cancelAnimationFrame(animationFrameId);
  }, [isPlaying, drawDiamond]);

  const resetGame = () => {
    setIsPlaying(false);
    setScore(0);
    setGameTime(initialGameTime);
    setScale(scaleMin);
    setLetters(updateLetters());
    setIsGameOver(false);
  };

  const setSpeedWithLimit = (newSpeed) => {
    setSpeed(Math.max(newSpeed, 0.01));
  };

  return {
    canvasRef,
    isPlaying,
    setIsPlaying,
    speed,
    setSpeed: setSpeedWithLimit,
    score,
    gameTime,
    initialGameTime, // Yeni eklenen
    drawDiamond,
    resetGame,
    isGameOver,
    setIsGameOver,
  };
}
