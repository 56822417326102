import React, { useState, useEffect } from 'react';
import HeaderSection from './components/HeaderSection';
import GameOptions from './components/GameOptions';
import SpeedSliderAndScore from './components/SpeedSliderAndScore';
import GameTimer from './components/GameTimer';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useGameLogic } from './hooks/gameLogic';
import GameOverModal from './components/GameOverModal';
import axios from 'axios';
import { API_URLS } from '../../../config/config';
import { useNavigate, useLocation } from 'react-router-dom';

function ZigzagTracker() {
  const navigate = useNavigate();
  const location = useLocation();
  const { stage, exercise } = location.state || {};

  const token = localStorage.getItem('userToken');

  const [userData, setUserData] = useState(null);
  const [userRole, setUserRole] = useState(null); // Kullanıcı rolü eklendi

  // Kullanıcı verilerini ve rolünü getirme
  useEffect(() => {
    const fetchUserData = async () => {
      if (token) {
        try {
          // Öğrenci verilerini getir
          const studentResponse = await fetch(`${API_URLS.STUDENT_URL}/me`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
          if (studentResponse.ok) {
            const studentData = await studentResponse.json();
            setUserData(studentData);
            setUserRole('student');
            return;
          }
        } catch (error) {
          console.error('Öğrenci verisi alınırken hata oluştu:', error);
        }

        try {
          // Öğretmen verilerini getir
          const teacherResponse = await fetch(`${API_URLS.TEACHER_URL}/me`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
          if (teacherResponse.ok) {
            const teacherData = await teacherResponse.json();
            setUserData(teacherData);
            setUserRole('teacher');
            return;
          }
        } catch (error) {
          console.error('Öğretmen verisi alınırken hata oluştu:', error);
        }

        // Eğer her ikisi de başarısız olursa
        setUserData(null);
        setUserRole(null);
      }
    };

    fetchUserData();
  }, [token]);

  // Oyun bittiğinde çağrılacak fonksiyon
  const onGameOver = () => {
    setIsPlaying(false);
    setShowModal(true);
    updateProgress();
  };

  // updateProgress fonksiyonunu tanımlayalım
  const updateProgress = async () => {
    try {
      if (userData && userRole) {
        const apiUrl =
          userRole === 'student'
            ? `${API_URLS.STUDENT_URL}akillikurs/progress/update-game`
            : `${API_URLS.TEACHER_URL}akillikurs/progress/update-game`;

        await axios.post(
          apiUrl,
          {
            stageNumber: stage?.number || 1,
            gameName: exercise?.gameName || 'Zigzag Takip Oyunu',
            score: Math.floor(score),
            time:
              (userData.status === 'inactive' ? 15 : 60) - timeRemaining,
            completed: true,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        console.log('İlerleme başarıyla güncellendi.');
      }
    } catch (error) {
      console.error('İlerleme güncellenirken hata oluştu:', error);
    }
  };

  const {
    canvasRef,
    isPlaying,
    setIsPlaying,
    speed,
    setSpeed,
    orientation,
    setOrientation,
    canvasSize,
    score,
    timeRemaining,
    setTimeRemaining,
    difficulty,
    handleDifficultyChange,
    resetGame,
    showModal,
    setShowModal,
  } = useGameLogic(userData, onGameOver);

  // Oyun tanıtımı için bir state
  const [showIntroduction, setShowIntroduction] = useState(true);

  const startGame = () => {
    setShowIntroduction(false);
    resetGame(); // Oyun başladığında sıfırla
    setIsPlaying(true);
  };

  // Oyun süresi bittiğinde onGameOver fonksiyonunu çağırıyoruz
  useEffect(() => {
    if (timeRemaining === 0 && isPlaying) {
      onGameOver();
    }
  }, [timeRemaining, isPlaying]);

  const handleReturnToPreviousStage = () => {
    if (stage) {
      navigate(
        userRole === 'student' ? '/ogrenci-dashboard' : '/ogretmen-dashboard',
        {
          state: {
            selectedMenu: 'ExerciseList',
            selectedStage: stage,
          },
        }
      );
    } else {
      alert('Aşama bilgisi bulunmamaktadır.');
    }
  };

  return (
    <div className="container-fluid px-0">
      {showIntroduction ? (
        <div className="introduction-bg vh-100 d-flex flex-column justify-content-center align-items-center">
          <div className="row justify-content-center mb-4 w-100">
            <div className="col-12 col-md-10 col-lg-8">
              <div className="card shadow border-0 rounded-lg text-center p-5 introduction-card">
                <div className="card-body">
                  <HeaderSection
                    handleReturnToPreviousStage={handleReturnToPreviousStage}
                  />
                  <h1 className="display-4 mb-4 fw-bold text-primary">
                    Zigzag Takip Oyunu
                  </h1>
                  <p className="lead mb-4 text-secondary">
                    Bu oyunun amacı, hareket eden zigzag desenini gözlerinizle
                    takip etmektir.
                  </p>
                  <h3 className="mb-3 text-info">Nasıl Oynanır?</h3>
                  <ul className="text-start d-inline-block fs-6 text-dark">
                    <li className="mb-2">
                      <strong>1. Zigzagı Takip Edin:</strong> Hareket eden
                      zigzag desenini gözlerinizle izleyin.
                    </li>
                    <li className="mb-2">
                      <strong>2. Dikkatinizi Verin:</strong> Hızla yön
                      değiştiren objeyi gözden kaçırmayın.
                    </li>
                    <li>
                      <strong>3. Zorluk Seviyesi:</strong> Hızı artırarak ve
                      yönü değiştirerek zorluğu yükseltin.
                    </li>
                  </ul>
                  <button
                    className="btn btn-success mt-4 px-4 py-2 start-button"
                    onClick={startGame}
                  >
                    Oyuna Başla
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="row g-0 justify-content-center w-100">
            <div className="col-12 col-md-10 col-lg-8">
              <div className="card shadow mt-4 game-card">
                <div className="card-body">
                  <div className="row mb-4 d-flex justify-content-between align-items-center">
                    <div className="col-12 text-center mb-3">
                      <HeaderSection
                        handleReturnToPreviousStage={
                          handleReturnToPreviousStage
                        }
                      />
                    </div>
                    <div className="col-12 col-md-4 text-center mb-4 mb-md-0">
                      <GameTimer
                        isPlaying={isPlaying}
                        timeRemaining={timeRemaining}
                        setTimeRemaining={setTimeRemaining}
                      />
                    </div>
                    <div className="col-12 col-md-8 mb-4 mb-md-0">
                      <GameOptions
                        isPlaying={isPlaying}
                        setIsPlaying={setIsPlaying}
                        resetGame={resetGame}
                        orientation={orientation}
                        setOrientation={setOrientation}
                        difficulty={difficulty}
                        handleDifficultyChange={handleDifficultyChange}
                      />
                    </div>
                  </div>
                  {userData && (
                    <SpeedSliderAndScore
                      speed={speed}
                      setSpeed={setSpeed}
                      score={score}
                    />
                  )}
                  <div className="row">
                    <div className="col-12 d-flex justify-content-center">
                      <div className="game-container">
                        <canvas
                          ref={canvasRef}
                          width={canvasSize.width}
                          height={canvasSize.height}
                          className="game-canvas"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showModal && (
            <GameOverModal
              score={score}
              onRestart={resetGame}
              userData={userData}
            />
          )}
        </>
      )}
      {/* Stil tanımlamaları */}
      <style jsx>{`
        @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

        body {
          font-family: 'Poppins', sans-serif;
        }

        .container-fluid {
          background-color: #f7f9fc;
        }

        .introduction-bg {
          background: linear-gradient(135deg, #ece9e6, #ffffff);
        }

        .game-bg {
          background: linear-gradient(135deg, #e0eafc, #cfdef3);
        }

        .card {
          background-color: white;
          border-radius: 15px;
          box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
          animation: fadeIn 0.5s ease-in-out;
        }

        .introduction-card {
          padding: 2rem;
          border: none;
        }

        .game-card {
          padding: 1.5rem;
          border: none;
        }

        h1, h2, h3 {
          color: #2c3e50;
        }

        p, li {
          color: #555;
        }

        .btn-primary, .btn-outline-primary, .btn-success {
          border-radius: 25px;
          padding: 0.6rem 1.2rem;
          font-weight: 600;
          transition: background-color 0.3s ease, transform 0.2s;
        }

        .btn-success {
          background-color: #28a745;
          border: none;
        }

        .btn-success:hover {
          background-color: #218838;
          transform: translateY(-2px);
        }

        .btn-outline-primary {
          border-color: #3498db;
          color: #3498db;
        }

        .btn-outline-primary:hover {
          background-color: #3498db;
          color: white;
          transform: translateY(-2px);
        }

        .start-button {
          font-size: 1.1rem;
        }

        .custom-button {
          font-size: 0.9rem;
        }

        .game-container {
          border: 3px solid #bdc3c7;
          border-radius: 15px;
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 500px;
          width: 100%;
          background-color: #ffffff;
          margin-top: 20px;
        }

        .game-canvas {
          width: 100%;
          height: 100%;
        }

        /* Animasyon Tanımlaması */
        @keyframes fadeIn {
          from {
            opacity: 0;
            transform: translateY(20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        /* Responsive Tasarım */
        @media (max-width: 768px) {
          .introduction-bg, .game-bg {
            height: 100vh;
          }

          .card-body {
            padding: 1rem;
          }

          .game-container {
            height: 300px;
          }

          .btn {
            font-size: 0.8rem;
            padding: 0.5rem 1rem;
          }

          .start-button {
            font-size: 1rem;
          }

          .custom-button {
            font-size: 0.8rem;
          }

          .introduction-card {
            padding: 1.5rem;
          }

          .game-card {
            padding: 1rem;
          }
        }
      `}</style>
    </div>
  );
}

export default ZigzagTracker;
