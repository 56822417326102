import { useState, useEffect, useRef, useCallback } from 'react';
import { API_URLS } from '../../../../config/config';

export const useKayanYazilarOyunu = (navigate, userData, token) => {
  const [difficulty, setDifficulty] = useState('easy');
  const [paragraphsList, setParagraphsList] = useState([]);
  const [selectedParagraphIndex, setSelectedParagraphIndex] = useState(0);
  const [displayedWords, setDisplayedWords] = useState([]);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [speed, setSpeed] = useState(2);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [gameTime, setGameTime] = useState(0);
  const [timeLeft, setTimeLeft] = useState(0);
  const [message, setMessage] = useState('');
  const [gameOver, setGameOver] = useState(false);

  // Kullanıcı durumuna göre oyun süresini ayarla
  useEffect(() => {
    if (userData) {
      setGameTime(userData.status === 'inactive' ? 15 : 60);
      setTimeLeft(userData.status === 'inactive' ? 15 : 60);
    } else {
      setGameTime(15);
      setTimeLeft(15);
    }
  }, [userData]);

  // Kelimeleri güncelleyen fonksiyon (BU KISMI ÜSTE TAŞIDIK)
  const updateWords = useCallback(() => {
    if (!isPlaying || !paragraphsList[selectedParagraphIndex]) return;

    const words = paragraphsList[selectedParagraphIndex].content.split(' ');

    if (currentWordIndex >= words.length) {
      // Paragraf tamamlandı
      setIsPlaying(false);
      setGameOver(true);
      return;
    }

    setDisplayedWords((prev) => [...prev, words[currentWordIndex]]);
    setCurrentWordIndex((prev) => prev + 1);
    setElapsedTime((prev) => prev + (1000 / speed));
  }, [isPlaying, paragraphsList, selectedParagraphIndex, currentWordIndex, speed]);

  // Paragrafları çekiyoruz
  useEffect(() => {
    const fetchParagraphs = async () => {
      try {
        const url = `${API_URLS.HIZLIOKUMA_URL}akanyazi/${difficulty}`;
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setParagraphsList(data);
        setSelectedParagraphIndex(0);
        handleReset();
      } catch (error) {
        console.error('Paragraflar alınırken hata oluştu:', error);
      }
    };

    fetchParagraphs();
  }, [difficulty, token]);

  // Paragraf değiştiğinde oyunu sıfırla
  useEffect(() => {
    handleReset();
  }, [selectedParagraphIndex]);

  // Oyun süresi zamanlayıcısı
  useEffect(() => {
    let timer;
    if (isPlaying) {
      timer = setInterval(() => {
        setTimeLeft((prevTimeLeft) => {
          if (prevTimeLeft > 1) {
            return prevTimeLeft - 1;
          } else {
            clearInterval(timer);
            setIsPlaying(false);
            setGameOver(true);
            return 0;
          }
        });
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [isPlaying]);

  // Kelimeleri güncelleyen zamanlayıcı
  useEffect(() => {
    let wordInterval;
    if (isPlaying) {
      const intervalDuration = 1000 / speed;
      wordInterval = setInterval(() => {
        updateWords();
      }, intervalDuration);
    }

    return () => clearInterval(wordInterval);
  }, [isPlaying, speed, updateWords]);

  // Oyun duraklatıldığında veya başlatıldığında yapılan işlem
  const handlePlayPause = () => {
    if (gameOver) return; // Oyun bittiyse başlatma
    if (isPlaying) {
      setMessage(`Oyun duraklatıldı. Skorunuz: ${currentWordIndex} kelime`);
    } else {
      setMessage('');
    }
    setIsPlaying(!isPlaying);
  };

  // Oyun sıfırlama fonksiyonu
  const handleReset = () => {
    setIsPlaying(false);
    setDisplayedWords([]);
    setCurrentWordIndex(0);
    setElapsedTime(0);
    setTimeLeft(gameTime); // Zamanı sıfırla
    setMessage('');
    setGameOver(false);
  };

  // Hızı değiştirme
  const handleSpeedChange = (e) => {
    setSpeed(Number(e.target.value));
  };

  // Paragrafı değiştirme
  const handleParagraphChange = (e) => {
    setSelectedParagraphIndex(Number(e.target.value));
  };

  // Zorluk seviyesini değiştirme
  const handleDifficultyChange = (e) => {
    setDifficulty(e.target.value);
  };

  // Zaman bittiğinde çağrılan fonksiyon
  const onTimeUp = () => {
    setIsPlaying(false);
    setGameOver(true);
  };

  return {
    difficulty,
    paragraphsList,
    selectedParagraphIndex,
    displayedWords,
    currentWordIndex,
    isPlaying,
    speed,
    elapsedTime,
    gameTime,
    timeLeft,
    message,
    gameOver,
    handlePlayPause,
    handleReset,
    handleSpeedChange,
    handleParagraphChange,
    handleDifficultyChange,
    onTimeUp,
  };
};
