import React, { useState, useEffect } from 'react';
import { Button, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid, TextField, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, InputLabel, Select, MenuItem, Chip, Box  } from '@mui/material';
import ResultDialog from '../../../pages/components/Auth/Dialog';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Stack from '@mui/material/Stack';
import { API_URLS } from '../../../config/config';

const Ogrenci = () => {
  const [ogrenciListesi, setOgrenciListesi] = useState([]);
  const [sehirListesi, setSehirListesi] = useState([]);  
  const [secilenSehirId, setSecilenSehirId] = useState('');
  const [secilenIlceId, setSecilenIlceId] = useState("");
  const [shouldFetch, setShouldFetch] = useState(true);
  const [ad, setAd] = useState('');
  const [soyad, setSoyad] = useState('');
  const [okulAdi, setOkulAdi] = useState('');
  const [telefon, setTelefon] = useState('');
  const [telefonHata, setTelefonHata] = useState(false);
  const [email, setEmail] = useState('');
  const [sifre, setSifre] = useState('');
  const [sinif, setSinif] = useState('');
  const [yas, setYas] = useState('');
  const [dialogContent, setDialogContent] = useState('');
  const [dialogSeverity, setDialogSeverity] = useState('info');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [resultDialogOpen, setResultDialogOpen] = useState(false);
  const [resultDialogMessage, setResultDialogMessage] = useState('');
  const [resultDialogSeverity, setResultDialogSeverity] = useState('info');
  const [eklemeBasariliDialogOpen, setEklemeBasariliDialogOpen] = useState(false); 
  const [duzenlemeDialogAcik, setDuzenlemeDialogAcik] = useState(false);
  const [shouldFetchList, setShouldFetchList] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const [durum, setDurum] = useState('inactive'); 


  const [duzenlenenOgrenci, setDuzenlenenOgrenci] = useState({
    ad:'',
    soyad:'',
    okulAdi: '',
    email: '',
    telefon: '',
    sehirId: '',
    ilceId: '', 
    ilceler: [],
    sifre: '',
  });

  const fetchIlceler = async (sehirId, seciliIlceId) => {
    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}sehirler/ilceler/${sehirId}`);
      if (!response.ok) throw new Error('İlçeler yüklenirken bir hata oluştu.');
      const data = await response.json(); 
      const ilceler = data.ilceler ? data.ilceler : []; 

      setDuzenlenenOgrenci(prev => ({ 
        ...prev, 
        ilceler: ilceler,
    
        ilceId: seciliIlceId && ilceler.some(ilce => ilce._id === seciliIlceId) ? seciliIlceId : prev.ilceId 
      }));
    } catch (error) {
      console.error('İlçeleri yüklerken bir hata oluştu:', error);
    }
  }; 

  useEffect(() => {
    const fetchSehirler = async () => {
      if (!shouldFetch) return;

      try {
        const response = await fetch(API_URLS.ADMIN_URL + 'sehirler');
        if (!response.ok) {
          throw new Error('Veri çekme işlemi başarısız');
        }
        const sehirler = await response.json();
        setSehirListesi(sehirler);
      } catch (err) {
        console.error('Şehirler yüklenirken bir hata oluştu:', err.message);
      } finally {
        setShouldFetch(false);
      }
    };

    const fetchOgrenciListesi = async () => {
      try {
        const response = await fetch(`${API_URLS.ADMIN_URL}student`);
        if (!response.ok) {
          throw new Error('Öğrenci listesi yüklenirken bir hata oluştu.');
        }
        const data = await response.json();
        console.log("Sunucudan gelen veri:", data);
    
        const enhancedData = data.map(ogrenci => {
          const sehir = sehirListesi.find(s => s._id === ogrenci.sehir);
          const ilce = sehir?.ilceler.find(i => i._id === ogrenci.ilce);
    
          return {
            ...ogrenci,
            sehirAdi: sehir ? sehir.sehir_adi : 'Bulunamadı',
            ilceAdi: ilce ? ilce.ilce_adi : 'Bulunamadı',
            sinif: ogrenci.sinif?.toString() || '',
            yas: ogrenci.yas?.toString() || '',
            status: ogrenci.status || 'inactive', 

          };
        });
    
        setOgrenciListesi(enhancedData);
      } catch (error) {
        console.error(error);
      } finally {
        setShouldFetchList(false);
      }
    };

    fetchSehirler();
    fetchOgrenciListesi();
  }, [shouldFetch, shouldFetchList]);

  const handleDialogClose = () => {
    setDialogOpen(false);
    setResultDialogOpen(false); 
  };
 
  const handleTelefonChange = (event) => {
    const telefonRegex = /^0(\d{3}) (\d{3}) (\d{4})$/;
    let { value } = event.target;
    const numbers = value.replace(/\D/g, '');  
    value = numbers.substring(0, 4) + (numbers.length > 4 ? ' ' : '') + numbers.substring(4, 7) + (numbers.length > 7 ? ' ' : '') + numbers.substring(7, 11); 
    setTelefonHata(!telefonRegex.test(value) && value !== '');
    setTelefon(value);
  }; 

  const handleSehirChange = (event) => {
    setSecilenSehirId(event.target.value);
  };  

  const handleIlceChange = (event) => {
    setSecilenIlceId(event.target.value);
  };

  const handleSehirChangeDuzenle = (event) => {
    const yeniSehirId = event.target.value; 
    setDuzenlenenOgrenci(prev => ({ ...prev, sehirId: yeniSehirId }));  
    const secilenSehir = sehirListesi.find(sehir => sehir._id === yeniSehirId);
    const ilceler = secilenSehir ? secilenSehir.ilceler : [];  
    setDuzenlenenOgrenci(prev => ({ ...prev, ilceId: '', ilceler: ilceler }));
  };

  const handleIlceChangeDuzenle = (event) => {
    const yeniIlceId = event.target.value; 
    setDuzenlenenOgrenci(prev => ({ ...prev, ilceId: yeniIlceId }));
  };

  const secilenSehirinIlceleri = sehirListesi.find(sehir => sehir._id === secilenSehirId)?.ilceler || [];

  const handleEkle = async () => {
   
    let eksikAlanlar = [];
  
    if (!ad) eksikAlanlar.push('Adı');
    if (!soyad) eksikAlanlar.push('Soyadı');
    if (!email) eksikAlanlar.push('Email');
    if (!sifre) eksikAlanlar.push('Şifre');
    if (!telefon) eksikAlanlar.push('Telefon');
    if (!secilenSehirId) eksikAlanlar.push('Şehir');
    if (!secilenIlceId) eksikAlanlar.push('İlçe');
    if (!sinif) eksikAlanlar.push('Sınıf');
    if (!yas) eksikAlanlar.push('Yaş');
  
    if (eksikAlanlar.length > 0) {
      setDialogSeverity('error');
      setDialogContent(`Lütfen ${eksikAlanlar.join(', ')} alanlarını doldurunuz.`);
      setEklemeBasariliDialogOpen(true);
      return;
    }
  
    if (telefonHata) {
      setDialogSeverity('error');
      setDialogContent('Lütfen geçerli bir telefon numarası giriniz.');
      setEklemeBasariliDialogOpen(true);
      return;
    }  
 
    try {
      const response = await fetch(API_URLS.ADMIN_URL + 'student', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ad: ad,
          soyad: soyad,
          email: email,
          password: sifre,
          password_open: sifre,
          okul_adi: okulAdi,
          telefon: telefon,
          sehir: secilenSehirId,
          ilce: secilenIlceId,
          sinif: sinif,
          yas: yas,
          status: durum, 

        }),
      });
  
      const responseData = await response.json();
      if (response.ok) {
        setOgrenciListesi([...ogrenciListesi, responseData]);
  
        setDialogSeverity('success');
        setDialogContent('Yeni Öğrenci başarıyla eklendi.');
        setEklemeBasariliDialogOpen(true);
        setAd('');
        setSoyad('');
        setOkulAdi('');
        setEmail('');
        setSifre('');
        setTelefon('');
        setSecilenSehirId('');
        setSecilenIlceId('');
        setSinif('');
        setYas('');
      } else {
        throw new Error(responseData.message || 'Bir hata oluştu');
      }
    } catch (err) {
      setDialogSeverity('error');
      setDialogContent(err.message);
      setEklemeBasariliDialogOpen(true);
    }
  };
  
  const handleEdit = (ogrenciId) => {
    const seciliOgrenci = ogrenciListesi.find((ogr) => ogr._id === ogrenciId);
  
    if (seciliOgrenci) {
      const sehirId = seciliOgrenci.sehir;
  
      setDuzenlenenOgrenci((prev) => ({
        ...prev,
        _id: seciliOgrenci._id,
        ad: seciliOgrenci.ad,
        soyad: seciliOgrenci.soyad,
        okulAdi: seciliOgrenci.okul_adi,
        email: seciliOgrenci.email,
        telefon: seciliOgrenci.telefon,
        sehirId: sehirId,
        ilceId: seciliOgrenci.ilce,
        sinif: seciliOgrenci.sinif,
        yas: seciliOgrenci.yas,

      }));
      fetchIlceler(sehirId);
      setDuzenlemeDialogAcik(true);
    } else {
      console.error('Seçilen öğrenci bulunamadı.');
    }
  }; 

  const handleGuncelle = async () => {
    const { ad, soyad, okulAdi, email, telefon, sehirId, ilceId, sinif, yas, sifre } = duzenlenenOgrenci;
    let eksikAlanlar = [];
  
    if (!ad) eksikAlanlar.push('Adı');
    if (!soyad) eksikAlanlar.push('Soyadı');
    if (!email) eksikAlanlar.push('Email');
    if (!telefon) eksikAlanlar.push('Telefon');
    if (!sehirId) eksikAlanlar.push('Şehir');
    if (!ilceId) eksikAlanlar.push('İlçe');
  
    // Şifre alanı isteğe bağlı olduğu için eksik alanlara eklemiyoruz
  
    if (eksikAlanlar.length > 0) {
      setDialogSeverity('error');
      setDialogContent(`Lütfen ${eksikAlanlar.join(', ')} alanlarını doldurunuz.`);
      setDialogOpen(true);
      return;
    }
  
    try {
      const body = {
        ad,
        soyad,
        okul_adi: okulAdi,
        email,
        telefon,
        sehir: sehirId,
        ilce: ilceId,
        sinif,
        yas,
      };
  
      // Şifre alanı doldurulmuşsa ekleyin
      if (sifre) {
        body.password = sifre;
     
     
      }
  
      const response = await fetch(`${API_URLS.ADMIN_URL}student/${duzenlenenOgrenci._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });
  
      const data = await response.json();
      if (!response.ok) throw new Error(data.message || 'Bir hata oluştu');
  
      // Update the ogrenciListesi state with the updated student data
      setOgrenciListesi(
        ogrenciListesi.map((ogr) =>
          ogr._id === duzenlenenOgrenci._id ? { ...ogr, ...data.ogrenci } : ogr
        )
      );
  
      setShouldFetchList(true);
  
      setDialogSeverity('success');
      setDialogContent('Öğrenci başarıyla güncellendi.');
      setDialogOpen(true);
      setDuzenlemeDialogAcik(false);
    } catch (err) {
      setDialogSeverity('error');
      setDialogContent(err.message);
      setDialogOpen(true);
    }
  };
  
  const handleStatusChange = async (ogrenciId, currentStatus) => {
    const newStatus = currentStatus === 'active' ? 'inactive' : 'active';
  
    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}student/${ogrenciId}/status`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status: newStatus }),
      });
  
      if (!response.ok) throw new Error('Durum güncellenirken bir hata oluştu.');
  
      const updatedStudent = await response.json();
  
      // Option 1: Directly update the local state
      setOgrenciListesi(prevList =>
        prevList.map(ogrenci =>
          ogrenci._id === ogrenciId ? { ...ogrenci, status: updatedStudent.status } : ogrenci
        )
      );
  
      // Option 2: Trigger re-fetching the updated data from the server
      setShouldFetchList(true); // This will trigger the useEffect to refresh the list
  
      setResultDialogMessage('Durum başarıyla güncellendi.');
      setResultDialogSeverity('success');
      setResultDialogOpen(true);
    } catch (error) {
      setResultDialogMessage(error.message);
      setResultDialogSeverity('error');
      setResultDialogOpen(true);
    }
  }; 

  const handleDeleteOgrenci = async (ogrenciId) => {
    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}student/${ogrenciId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) throw new Error('Öğrenci silinirken bir hata oluştu.');  
     
      setOgrenciListesi(ogrenciListesi.filter((ogretmen) => ogretmen._id !== ogrenciId));
  
      setResultDialogMessage('Admin başarıyla silindi.');
      setResultDialogSeverity('success');
      setResultDialogOpen(true);
    } catch (error) {
      setResultDialogMessage(error.message);
      setResultDialogSeverity('error');
      setResultDialogOpen(true);
    }
  };

  const [statusFilter, setStatusFilter] = useState('');
  const [cityFilter, setCityFilter] = useState('');
  const [ilceFilter, setIlceFilter] = useState('');


 // Filtrelenmiş öğrenci listesi
  const filteredOgrenciListesi = ogrenciListesi.filter((ogrenci) => {
    // Arama sorgusuna göre filtreleme
    const matchesSearch =
      ogrenci.ad.toLowerCase().includes(searchTerm.toLowerCase()) ||
      ogrenci.soyad.toLowerCase().includes(searchTerm.toLowerCase()) ||
      ogrenci.telefon.includes(searchTerm) ||
      ogrenci.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      ogrenci.okul_adi.toLowerCase().includes(searchTerm.toLowerCase()) ||
      ogrenci.sinif.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
      ogrenci.yas.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
      ogrenci.sehirAdi.toLowerCase().includes(searchTerm.toLowerCase()) ||
      ogrenci.ilceAdi.toLowerCase().includes(searchTerm.toLowerCase());

    // Durum filtresine göre filtreleme
    const matchesStatus = statusFilter ? ogrenci.status === statusFilter : true;

    // Şehir filtresine göre filtreleme
    const matchesCity = cityFilter ? ogrenci.sehir === cityFilter : true;

    // İlçe filtresine göre filtreleme
    const matchesIlce = ilceFilter ? ogrenci.ilce === ilceFilter : true;

    return matchesSearch && matchesStatus && matchesCity && matchesIlce;
  });

const renderDuzenlemeFormuDialog = () => (
  <Dialog open={duzenlemeDialogAcik} onClose={() => setDuzenlemeDialogAcik(false)}>
    <DialogTitle>Öğrenci Düzenle</DialogTitle>
    <DialogContent>
      <TextField
        fullWidth
        label="Adı"
        value={duzenlenenOgrenci.ad}
        onChange={(e) => setDuzenlenenOgrenci({...duzenlenenOgrenci, ad: e.target.value})}
        margin="normal"
      />

      <TextField
        fullWidth
        label="Soyadı"
        value={duzenlenenOgrenci.soyad}
        onChange={(e) => setDuzenlenenOgrenci({...duzenlenenOgrenci, soyad: e.target.value})}
        margin="normal"
      />

      <TextField
        fullWidth
        label="Okul Adı"
        value={duzenlenenOgrenci.okulAdi}
        onChange={(e) => setDuzenlenenOgrenci({...duzenlenenOgrenci, okulAdi: e.target.value})}
        margin="normal"
      />
       
      <TextField
        fullWidth
        label="Sınıf"
        value={duzenlenenOgrenci.sinif}
        onChange={(e) => setDuzenlenenOgrenci({...duzenlenenOgrenci, sinif: e.target.value})}
        margin="normal"
      />

      <TextField
        fullWidth
        label="Yaş"
        value={duzenlenenOgrenci.yas}
        onChange={(e) => setDuzenlenenOgrenci({...duzenlenenOgrenci, yas: e.target.value})}
        margin="normal"
      />
      <FormControl fullWidth margin="dense">
        <InputLabel id="sehir-select-label-duzenle">Şehir</InputLabel>
        <Select
          labelId="sehir-select-label-duzenle"
          id="sehir-select-duzenle"
          value={duzenlenenOgrenci.sehirId}
          onChange={handleSehirChangeDuzenle}
          displayEmpty 
        >
          <MenuItem value="">
            <em>Şehir Seçiniz</em>
          </MenuItem>
          {sehirListesi.map((sehir) => (
            <MenuItem key={sehir._id} value={sehir._id}>
              {sehir.sehir_adi}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {duzenlenenOgrenci.sehirId && (
        <FormControl fullWidth margin="dense">
          <InputLabel id="ilce-select-label-duzenle">İlçe</InputLabel>
          <Select
            labelId="ilce-select-label-duzenle"
            id="ilce-select-duzenle"
            value={duzenlenenOgrenci.ilceId} 
            onChange={handleIlceChangeDuzenle}
            displayEmpty
            renderValue={(selected) => {
              if (selected) {
                const secilenIlce = duzenlenenOgrenci.ilceler.find(ilce => ilce._id === selected);
                return secilenIlce ? secilenIlce.ilce_adi : 'İlçe bulunamadı';
              }
              return <em>İlçe Seçiniz</em>;
            }}              
          >          
            {duzenlenenOgrenci.ilceler?.map((ilce) => ( 
              <MenuItem key={ilce._id} value={ilce._id}>
                {ilce.ilce_adi}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <TextField
        fullWidth
        label="Email"
        value={duzenlenenOgrenci.email}
        onChange={(e) => setDuzenlenenOgrenci({...duzenlenenOgrenci, email: e.target.value})}
        margin="normal"
      />
      <TextField
        fullWidth
        label="Telefon"
        value={duzenlenenOgrenci.telefon}
        onChange={(e) => setDuzenlenenOgrenci({...duzenlenenOgrenci, telefon: e.target.value})}
        margin="normal"
      />
      <TextField
        fullWidth
        label="Şifre" // Yeni eklenen alan
        value={duzenlenenOgrenci.sifre}
        onChange={(e) => setDuzenlenenOgrenci({...duzenlenenOgrenci, sifre: e.target.value})}
        margin="normal"
        type="password"
        helperText="Şifreyi güncellemek istiyorsanız buraya yeni şifreyi girin. Aksi takdirde boş bırakın."
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={() => setDuzenlemeDialogAcik(false)}>İptal</Button>
      <Button onClick={handleGuncelle} color="primary" variant="contained">Güncelle</Button> 
    </DialogActions>
  </Dialog>
);  

  return (
    <Paper style={{ padding: 20 }}>
      <Grid container spacing={3}>
        {/* Öğrenci Kayıt Formu */}
        <Grid item xs={12}>
          <Typography variant="h5" component="h3" marginBottom={2}>
            Öğrenci Kayıt Formu
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                label="Adı"
                value={ad}
                onChange={(e) => setAd(e.target.value)}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                label="Soyadı"
                value={soyad}
                onChange={(e) => setSoyad(e.target.value)}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                label="Okul Adı (isteğe bağlı)"
                value={okulAdi}
                onChange={(e) => setOkulAdi(e.target.value)}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                label="Sınıf"
                value={sinif}
                onChange={(e) => setSinif(e.target.value)}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                label="Yaş"
                value={yas}
                onChange={(e) => setYas(e.target.value)}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth margin="normal">
                <InputLabel id="sehir-select-label">Şehir</InputLabel>
                <Select
                  labelId="sehir-select-label"
                  value={secilenSehirId}
                  onChange={handleSehirChange}
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected === "") {
                      return <em>Şehir Seçiniz</em>;
                    }
                    const secilenSehir = sehirListesi.find(
                      (sehir) => sehir._id === selected
                    );
                    return secilenSehir
                      ? secilenSehir.sehir_adi
                      : <em>Şehir Seçiniz</em>;
                  }}
                >
                  <MenuItem disabled value="">
                    <em>Şehir Seçiniz</em>
                  </MenuItem>
                  {sehirListesi.map((sehir) => (
                    <MenuItem key={sehir._id} value={sehir._id}>
                      {sehir.sehir_adi}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
  
            {secilenSehirId && (
              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth margin="normal">
                  <InputLabel id="ilce-select-label">İlçe</InputLabel>
                  <Select
                    labelId="ilce-select-label"
                    id="ilce-select"
                    value={secilenIlceId}
                    onChange={handleIlceChange}
                    displayEmpty
                    renderValue={(selected) => {
                      if (!selected) {
                        return <em>İlçe Seçiniz</em>;
                      }
                      const secilenIlce = secilenSehirinIlceleri.find(
                        (ilce) => ilce._id === selected
                      );
                      return secilenIlce
                        ? secilenIlce.ilce_adi
                        : <em>İlçe Seçiniz</em>;
                    }}
                  >
                    <MenuItem disabled value="">
                      <em>İlçe Seçiniz</em>
                    </MenuItem>
                    {secilenSehirinIlceleri.map((ilce) => (
                      <MenuItem key={ilce._id} value={ilce._id}>
                        {ilce.ilce_adi}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
  
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                label="Telefon"
                value={telefon}
                onChange={handleTelefonChange}
                error={telefonHata}
                helperText={
                  telefonHata
                    ? "Telefon numarası 05XX XXX XXXX formatında olmalıdır."
                    : ""
                }
                margin="normal"
              />
            </Grid>
  
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth margin="normal">
                <InputLabel id="status-select-label">Durum</InputLabel>
                <Select
                  labelId="status-select-label"
                  id="status-select"
                  value={durum}
                  onChange={(e) => setDurum(e.target.value)}
                >
                  <MenuItem value="active">Aktif</MenuItem>
                  <MenuItem value="inactive">Pasif</MenuItem>
                </Select>
              </FormControl>
            </Grid>
  
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                margin="normal"
              />
            </Grid>
  
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                label="Şifre"
                value={sifre}
                onChange={(e) => setSifre(e.target.value)}
                margin="normal"
                type="password"
              />
            </Grid>
  
            <Grid item xs={12} sm={6} md={4} alignSelf="center">
              <Button
                onClick={handleEkle}
                variant="contained"
                color="primary"
                fullWidth
              >
                Ekle
              </Button>
            </Grid>
          </Grid>
        </Grid>  

 {/* Filtreleme ve Öğrenci Listesi */}
        <Grid item xs={12} md={12}>
          <Box mb={2}>
            <Typography variant="h5" component="h3" gutterBottom>
              Öğrenci Listesi
            </Typography>
          </Box>
          <Box component={Paper} padding={2} elevation={3}>
            {/* Filtreleme Alanları */}
            <Box mb={3}>
              <Grid container spacing={2} alignItems="center">
                {/* Arama Çubuğu */}
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    fullWidth
                    label="Ara..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    margin="normal"
                  />
                </Grid>

                {/* Durum Filtreleme */}
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel id="status-filter-label">Durum</InputLabel>
                    <Select
                      labelId="status-filter-label"
                      value={statusFilter}
                      onChange={(e) => setStatusFilter(e.target.value)}
                      displayEmpty
                    >
                      <MenuItem value="">
                        <em>Tüm Durumlar</em>
                      </MenuItem>
                      <MenuItem value="active">Aktif</MenuItem>
                      <MenuItem value="inactive">Pasif</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {/* Şehir Filtreleme */}
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel id="city-filter-label">Şehir</InputLabel>
                    <Select
                      labelId="city-filter-label"
                      value={cityFilter}
                      onChange={(e) => {
                        setCityFilter(e.target.value);
                        setIlceFilter(''); // Şehir değiştiğinde ilçeyi sıfırla
                      }}
                      displayEmpty
                    >
                      <MenuItem value="">
                        <em>Tüm Şehirler</em>
                      </MenuItem>
                      {sehirListesi.map((sehir) => (
                        <MenuItem key={sehir._id} value={sehir._id}>
                          {sehir.sehir_adi}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                {/* İlçe Filtreleme (Şehir seçildiğinde görünür) */}
                {cityFilter && (
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth margin="normal">
                      <InputLabel id="ilce-filter-label">İlçe</InputLabel>
                      <Select
                        labelId="ilce-filter-label"
                        value={ilceFilter}
                        onChange={(e) => setIlceFilter(e.target.value)}
                        displayEmpty
                      >
                        <MenuItem value="">
                          <em>Tüm İlçeler</em>
                        </MenuItem>
                        {sehirListesi
                          .find((sehir) => sehir._id === cityFilter)
                          ?.ilceler.map((ilce) => (
                            <MenuItem key={ilce._id} value={ilce._id}>
                              {ilce.ilce_adi}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                {/* Filtreleri Sıfırla Butonu */}
                <Grid item xs={12} sm={6} md={4}>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      setSearchTerm('');
                      setStatusFilter('');
                      setCityFilter('');
                      setIlceFilter('');
                    }}
                    sx={{ marginTop: '16px' }}
                  >
                    Filtreleri Sıfırla
                  </Button>
                </Grid>
              </Grid>
            </Box>














          <TableContainer component={Paper}>
         
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Adı</TableCell>
                  <TableCell>Soyadı</TableCell>
                  <TableCell>Şifre</TableCell>
                  <TableCell>Telefon</TableCell>
                  <TableCell>Okul Adı</TableCell>
                  <TableCell>Sınıfı</TableCell>
                  <TableCell>Yaş</TableCell>
                  <TableCell>E-posta</TableCell>
                  <TableCell>Şehir</TableCell>
                  <TableCell>İlçe</TableCell>
                  <TableCell>Durum</TableCell>
                  <TableCell>İşlemler</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredOgrenciListesi.map((ogrenci) => (
                  <TableRow key={ogrenci._id}>
                    <TableCell>{ogrenci.ad}</TableCell>
                    <TableCell>{ogrenci.soyad}</TableCell>
                    <TableCell>{ogrenci.password_open}</TableCell>
                    <TableCell>{ogrenci.telefon}</TableCell>
                    <TableCell>{ogrenci.okul_adi}</TableCell>
                    <TableCell>{ogrenci.sinif}</TableCell>
                    <TableCell>{ogrenci.yas}</TableCell>
                    <TableCell>{ogrenci.email}</TableCell>
                    <TableCell>{ogrenci.sehirAdi}</TableCell>
                    <TableCell>{ogrenci.ilceAdi}</TableCell>
                    <TableCell>
                      <Chip
                        label={
                          ogrenci.status === "active" ? "Aktif" : "Pasif"
                        }
                        color={
                          ogrenci.status === "active" ? "success" : "error"
                        }
                        onClick={() =>
                          handleStatusChange(ogrenci._id, ogrenci.status)
                        }
                        sx={{
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "lightgray",
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Stack direction="row" spacing={1}>
                        <Button
                          variant="contained"
                          color="primary"
                          startIcon={<EditIcon />}
                          onClick={() => handleEdit(ogrenci._id)}
                          sx={{
                            mr: 1,
                            "&:hover": {
                              backgroundColor: "lightblue",
                              color: "#fff",
                            },
                          }}
                        >
                          Düzenle
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          startIcon={<DeleteIcon />}
                          onClick={() => handleDeleteOgrenci(ogrenci._id)}
                        >
                          Sil
                        </Button>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          </Box>
        </Grid>
      </Grid>
      <>
        <ResultDialog
          open={eklemeBasariliDialogOpen}
          onClose={() => setEklemeBasariliDialogOpen(false)}
          title={
            dialogSeverity === "error" ? "Hata Oluştu" : "İşlem Başarılı"
          }
          message={dialogContent}
          severity={dialogSeverity}
        />
        <ResultDialog
          open={resultDialogOpen}
          onClose={handleDialogClose}
          title={"İşlem Sonucu"}
          message={resultDialogMessage}
          severity={resultDialogSeverity}
        />
        {renderDuzenlemeFormuDialog()}
      </>
    </Paper>
  );
  
  
}

export default Ogrenci;