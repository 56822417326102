import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';

function ResultDialog({ open, onClose, title, message, severity }) {
  const dialogStyles = {
    title: {
      backgroundColor: severity === 'error' ? '#d32f2f' : '#4caf50', // Hata için kırmızı, başarı için yeşil
      color: 'white',
    },
    content: {
      padding: '16px',
    },
    contentText: {
      color: severity === 'error' ? '#d32f2f' : 'black', // Hata için kırmızı, başarı için siyah metin
    },
    button: {
      color: severity === 'error' ? '#d32f2f' : '#4caf50', // Buton rengi
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={dialogStyles.title}>{title}</DialogTitle>
      <DialogContent sx={dialogStyles.content}>
        <DialogContentText sx={dialogStyles.contentText}>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} sx={dialogStyles.button}>Tamam</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ResultDialog;
