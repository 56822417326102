import React from 'react';

const GameTimer = ({ time }) => {
  const formatTime = (seconds) => {
    if (seconds < 0) seconds = 0;
    const minutes = Math.floor(seconds / 60).toString().padStart(2, '0');
    const remainingSeconds = (seconds % 60).toString().padStart(2, '0'); 
    return `${minutes}:${remainingSeconds}`;
  };

  return (
    <div className="game-timer" style={{ textAlign: 'center', marginTop: '20px' }}>
      <div style={{ fontSize: '24px', fontFamily: 'Monospace', background: '#000', color: '#0F0', padding: '10px', display: 'inline-block', borderRadius: '8px' }}>
        {formatTime(time)}
      </div>
    </div>
  );
};

export default GameTimer;
