import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  AppBar, Toolbar, IconButton, Typography, Drawer, List, ListItem, ListItemIcon, ListItemText, CssBaseline, 
  Collapse, Divider, Box, useTheme, useMediaQuery, Tooltip 
} from '@mui/material';
import {
  Menu as MenuIcon, Dashboard as DashboardIcon, Mail as MailIcon, School as SchoolIcon, 
  Settings as SettingsIcon, Logout as LogoutIcon, ExpandLess, ExpandMore, Payment as PaymentIcon, 
  Report as ReportIcon, LocationCity as CityIcon, Business as BranchIcon, Person as TeacherIcon, 
  Event as EventIcon, List as ListIcon, ContactPhone as ContactPhoneIcon, AccountCircle as AccountCircleIcon, 
  AdminPanelSettings as AdminPanelSettingsIcon, Group as StudentsIcon, People as PeopleIcon, 
  Security as SecurityIcon, FormatListBulleted as FormatListBulletedIcon, 
  LibraryBooks as LibraryBooksIcon, AddBox as AddBoxIcon, Assignment as AssignmentIcon, 
  Assessment as AssessmentIcon, MonetizationOn as MonetizationOnIcon, 
  AccountBalanceWallet as AccountBalanceWalletIcon, BarChart as BarChartIcon 
} from '@mui/icons-material';
import { styled } from '@mui/system';
import { Card, CardContent, Grid } from '@mui/material';
import SehirlerContent from './kurumlar/Sehirler';
import KurumlarContent from './kurumlar/Kurumlar';
import OkullarContent from './kurumlar/Okullar';
import Users from './users/Users';
import BookUploadForm from './ogretmen/kutuphane/Add';
import BookListe from './ogretmen/kutuphane/Liste';
import Tests from './ogretmen/kutuphane/Test';
import Ogretmen from './ogretmen/Ogretmen';
import Ogrenci from './ogrenci/Ogrenci';
import RolePermissionManagement from './users/Role';
import PaymentList from './payments/PaymentList';
import PriceManagement from './kampanya/PriceManagement';
import PaymentReports from './payments/PaymentReports';
import BalanceList from './payments/BalanceList';
import { API_URLS } from '../../config/config';
import useFetchUserData from '../../hooks/admin/useFetchUserData';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: open ? `${drawerWidth}px` : 0,
  [theme.breakpoints.down('sm')]: { marginLeft: 0 },
  marginTop: '64px',
  overflow: 'auto',
  height: `calc(100vh - 64px)`,
}));

const AppBarStyled = styled(AppBar, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  backgroundColor: '#4e73df',
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
    },
  }),
}));

const DrawerStyled = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    backgroundColor: '#2e59d9',
    color: '#fff',
  },
}));

function AdminDashboard() {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [userData, setUserData] = useState(null);
  const [userRoles, setUserRoles] = useState([]);
  const [userAccessControls, setUserAccessControls] = useState([]);
  const [open, setOpen] = useState({});
  const [selectedMenu, setSelectedMenu] = useState('Anasayfa');
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [paymentSummary, setPaymentSummary] = useState(null);

  useFetchUserData(setUserData);

  useEffect(() => {
    if (userData) {
      const roleNames = userData.roles.map((role) => role.name);
      setUserRoles(roleNames);

      const resourceNames = userData.roles
        .flatMap((role) => role.accessControl)
        .flatMap((ac) => ac.resources.map((resource) => resource.name));

      setUserAccessControls(resourceNames);
    }
  }, [userData]);

  useEffect(() => {
    const fetchPaymentSummary = async () => {
      try {
        const response = await fetch(API_URLS.ADMIN_URL + 'payments/summary', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          },
        });
        const data = await response.json();
        if (response.ok) {
          setPaymentSummary(data);
        } else {
          console.error('Toplam ödemeler alınamadı:', data.message);
        }
      } catch (error) {
        console.error('Toplam ödemeler alınırken bir hata oluştu:', error);
      }
    };

    fetchPaymentSummary();
  }, []);

  const isAdmin = userData && userData.roles.some((role) => role.name === 'Admin');

  const toggleDrawer = () => setDrawerOpen(!drawerOpen);

  const handleClick = (menu) => setOpen((prevOpen) => ({ ...prevOpen, [menu]: !prevOpen[menu] }));

  const handleLogout = async () => {
    try {
      await fetch(API_URLS.ADMIN_URL + 'logout', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'Content-Type': 'application/json',
        },
      });
      localStorage.removeItem('userToken');
      navigate('/login');
    } catch (error) {
      console.error('Çıkış işlemi sırasında bir hata oluştu', error);
    }
  };
  
  const formatter = new Intl.NumberFormat('tr-TR', {
    style: 'currency',
    currency: 'TRY',
    minimumFractionDigits: 2,
  });

  // Helper function to handle submenu clicks
  const handleSubMenuClick = (parent, action) => {
    setOpen((prevOpen) => ({ ...prevOpen, [parent]: true }));
    action();
  };

  const menuItems = [
    { text: 'Anasayfa', icon: <DashboardIcon />, action: () => setSelectedMenu('Anasayfa') },
    ...(isAdmin
      ? [
          {
            text: 'Kullanıcılar',
            icon: <PeopleIcon />,
            subMenus: [
              { 
                text: 'Kullanıcılar', 
                icon: <StudentsIcon />, 
                parent: 'Kullanıcılar', 
                action: () => handleSubMenuClick('Kullanıcılar', () => setSelectedMenu('Kullanıcılar')) 
              },
              { 
                text: 'Roller ve Yetkiler', 
                icon: <SecurityIcon />, 
                parent: 'Kullanıcılar', 
                action: () => handleSubMenuClick('Kullanıcılar', () => setSelectedMenu('Roller')) 
              },
            ],
            action: () => handleClick('Kullanıcılar'),
          },
        ]
      : []),
    {
      text: 'Kurumlar',
      icon: <SchoolIcon />,
      subMenus: [
        { text: 'Şehirler', icon: <CityIcon />, parent: 'Kurumlar', action: () => handleSubMenuClick('Kurumlar', () => setSelectedMenu('Şehirler')) },
        { text: 'Bayiler', icon: <BranchIcon />, parent: 'Kurumlar', action: () => handleSubMenuClick('Kurumlar', () => setSelectedMenu('Bayiler')) },
        { text: 'Okullar', icon: <BranchIcon />, parent: 'Kurumlar', action: () => handleSubMenuClick('Kurumlar', () => setSelectedMenu('Okullar')) },
      ],
      action: () => handleClick('Kurumlar'),
    },
    {
      text: 'Kütüphane',
      icon: <LibraryBooksIcon />,
      subMenus: [
        { text: 'Kitap Ekle', icon: <AddBoxIcon />, parent: 'Kütüphane', action: () => handleSubMenuClick('Kütüphane', () => setSelectedMenu('KitapEkle')) },
        { text: 'Tüm Liste', icon: <FormatListBulletedIcon />, parent: 'Kütüphane', action: () => handleSubMenuClick('Kütüphane', () => setSelectedMenu('KitapListe')) },
        { text: 'Testler', icon: <AssignmentIcon />, parent: 'Kütüphane', action: () => handleSubMenuClick('Kütüphane', () => setSelectedMenu('Testler')) },
      ],
      action: () => handleClick('Kütüphane'),
    },
    { text: 'Öğretmenler', icon: <TeacherIcon />, action: () => setSelectedMenu('Öğretmenler') },
    { text: 'Öğrenciler', icon: <StudentsIcon />, action: () => setSelectedMenu('Öğrenciler') },
    { text: 'Ödemeler', icon: <PaymentIcon />, action: () => setSelectedMenu('Ödemeler') },
    { text: 'Kampanyalar', icon: <PaymentIcon />, action: () => setSelectedMenu('Kampanyalar') },
    {
      text: 'Raporlar',
      icon: <ReportIcon />, 
      subMenus: [
        { text: 'Ödeme Raporları', icon: <MonetizationOnIcon />, parent: 'Raporlar', action: () => handleSubMenuClick('Raporlar', () => setSelectedMenu('Ödeme Raporları')) },
        { text: 'Bakiyeler', icon: <AccountBalanceWalletIcon />, parent: 'Raporlar', action: () => handleSubMenuClick('Raporlar', () => setSelectedMenu('Bakiyeler')) },
      ],
      action: () => handleClick('Raporlar'),
    },
    { text: 'Ayarlar', icon: <SettingsIcon />, action: () => handleClick('Ayarlar') },
    { text: 'Çıkış Yap', icon: <LogoutIcon />, action: handleLogout },
  ];

  const renderContent = () => {
    if (!userData) return <Typography paragraph>Yükleniyor...</Typography>;

    switch (selectedMenu) {
      case 'Şehirler':
        return userAccessControls.includes('Şehirler') ? <SehirlerContent /> : <Typography paragraph>Erişim izniniz yok.</Typography>;
      case 'Bayiler':
        return userAccessControls.includes('Bayiler') ? <KurumlarContent /> : <Typography paragraph>Erişim izniniz yok.</Typography>;
      case 'Okullar':
        return userAccessControls.includes('Okullar') ? <OkullarContent /> : <Typography paragraph>Erişim izniniz yok.</Typography>;
      case 'Kullanıcılar':
        return isAdmin ? <Users /> : <Typography paragraph>Erişim izniniz yok.</Typography>;
      case 'Roller':
        return isAdmin ? <RolePermissionManagement /> : <Typography paragraph>Erişim izniniz yok.</Typography>;
      case 'KitapEkle':
        return <BookUploadForm />;
      case 'KitapListe':
        return <BookListe />;
      case 'Testler':
        return <Tests />;
      case 'Öğretmenler':
        return <Ogretmen />;
      case 'Öğrenciler':
        return <Ogrenci />;
      case 'Ödemeler':
        return <PaymentList />;
      case 'Kampanyalar':
        return <PriceManagement />;
      case 'Ödeme Raporları':
        return <PaymentReports />;
      case 'Bakiyeler':
        return <BalanceList />;
      default:
        return (
          <>
            <Typography variant="h5" gutterBottom>
              Dashboard
            </Typography>
            <Typography paragraph>
              Rolleriniz: {userRoles.join(', ')} - Yetkili Olduğunuz Modüller: {userAccessControls.join(', ')}
            </Typography>
            <Grid container spacing={4} sx={{ mt: 2 }}>
              <Grid item xs={12} sm={4}>
                <Card sx={{ 
                  background: 'linear-gradient(135deg, #f6d365 0%, #fda085 100%)',
                  color: '#fff',
                  boxShadow: 3,
                  borderRadius: 2,
                  transition: 'transform 0.3s',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: 6,
                  },
                }}>
                  <CardContent>
                    <Box display="flex" alignItems="center" mb={2}>
                      <PaymentIcon sx={{ fontSize: 40, mr: 2 }} />
                      <Typography variant="h6">Günlük Toplam Ödeme</Typography>
                    </Box>
                    <Typography variant="h4">
                      {paymentSummary ? `${formatter.format(paymentSummary.dailyTotal)}` : 'Yükleniyor...'}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Card sx={{ 
                  background: 'linear-gradient(135deg, #a1c4fd 0%, #c2e9fb 100%)',
                  color: '#fff',
                  boxShadow: 3,
                  borderRadius: 2,
                  transition: 'transform 0.3s',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: 6,
                  },
                }}>
                  <CardContent>
                    <Box display="flex" alignItems="center" mb={2}>
                      <PaymentIcon sx={{ fontSize: 40, mr: 2 }} />
                      <Typography variant="h6">Haftalık Toplam Ödeme</Typography>
                    </Box>
                    <Typography variant="h4">
                      {paymentSummary ? `${formatter.format(paymentSummary.weeklyTotal)}` : 'Yükleniyor...'}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Card sx={{ 
                  background: 'linear-gradient(135deg, #84fab0 0%, #8fd3f4 100%)',
                  color: '#fff',
                  boxShadow: 3,
                  borderRadius: 2,
                  transition: 'transform 0.3s',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: 6,
                  },
                }}>
                  <CardContent>
                    <Box display="flex" alignItems="center" mb={2}>
                      <PaymentIcon sx={{ fontSize: 40, mr: 2 }} />
                      <Typography variant="h6">Aylık Toplam Ödeme</Typography>
                    </Box>
                    <Typography variant="h4">
                      {paymentSummary ? `${formatter.format(paymentSummary.monthlyTotal)}` : 'Yükleniyor...'}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </>
        );
    }
  };

  useEffect(() => {
    if (selectedMenu) {
    
      menuItems.forEach((item) => {
        if (item.subMenus) {
          item.subMenus.forEach((subItem) => {
            if (subItem.text === selectedMenu && subItem.parent) {
              setOpen((prevOpen) => ({ ...prevOpen, [subItem.parent]: true }));
            }
          });
        }
      });
    }
  }, [selectedMenu, menuItems]);

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBarStyled position="fixed" open={drawerOpen}>
          <Toolbar>
            <IconButton color="inherit" aria-label="open drawer" onClick={toggleDrawer} edge="start" sx={{ mr: 2 }}>
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              {userData ? `Hoş Geldiniz ${userRoles.join(', ')} - ${userData.fullname}` : 'Yükleniyor...'}
            </Typography>
          </Toolbar>
        </AppBarStyled>
        <DrawerStyled variant={isMobile ? 'temporary' : 'persistent'} anchor="left" open={drawerOpen} onClose={toggleDrawer}>
          <Toolbar />
          <Divider />
          <List>
            {menuItems.map((item, index) => (
              <React.Fragment key={index}>
                <ListItem 
                  button 
                  onClick={() => item.action && item.action()} 
                  selected={item.text === selectedMenu}
                  sx={{
                    backgroundColor: item.subMenus ? 'rgba(255, 255, 255, 0.1)' : 'inherit',
                    '&.Mui-selected': {
                      backgroundColor: 'rgba(255, 255, 255, 0.2)',
                    },
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.2)',
                    },
                  }}
                >
                  <ListItemIcon sx={{ color: '#fff' }}>
                    <Tooltip title={item.text} placement="right" disableHoverListener={!isMobile}>
                      {item.icon}
                    </Tooltip>
                  </ListItemIcon>
                  <ListItemText primary={item.text} />
                  {item.subMenus ? (open[item.text] ? <ExpandLess /> : <ExpandMore />) : null}
                </ListItem>
                {item.subMenus && (
                  <Collapse in={open[item.text]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {item.subMenus.map((subItem, subIndex) => (
                        <ListItem 
                          button 
                          key={subIndex} 
                          onClick={subItem.action}
                          selected={subItem.text === selectedMenu}
                          sx={{ 
                            pl: 4, 
                            '&.Mui-selected': { backgroundColor: 'rgba(255, 255, 255, 0.2)' },
                            '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' } 
                          }}
                        >
                          <ListItemIcon sx={{ color: '#fff' }}>
                            <Tooltip title={subItem.text} placement="right" disableHoverListener={!isMobile}>
                              {subItem.icon}
                            </Tooltip>
                          </ListItemIcon>
                          <ListItemText primary={subItem.text} />
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                )}
              </React.Fragment>
            ))}
          </List>
        </DrawerStyled>
        <Main open={drawerOpen}>{renderContent()}</Main>
      </Box>
    </>
  );
}

export default AdminDashboard;
