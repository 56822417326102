import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faHome } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';


const Header = ({handleReturnToPreviousStage}) => {
  const navigate = useNavigate();

  return (
    <div className="row g-0">
      <div className="col-12 text-center my-3">
      <button className="btn btn-outline-primary mt-2 me-2" onClick={() => navigate(-1)}>
        <FontAwesomeIcon icon={faArrowLeft} /> Geri Dön
      </button>
      <button className="btn btn-outline-primary mt-2 me-2" onClick={() => navigate('/')}>
        <FontAwesomeIcon icon={faHome} /> Ana Sayfa
      </button>
  
      <button className="btn btn-outline-primary mt-2 me-2" onClick={handleReturnToPreviousStage}>
        <FontAwesomeIcon icon={faArrowLeft} />   Önceki Aşamaya Dön
      </button>
        <h2 className="text-center my-3">Kelime Tamamlama Oyunu</h2>
      </div>
    </div>
  );
};

export default Header;
