// src/pages/HizliOku.js

import React from 'react';
import {
  Container,
  Typography,
  Box,
  Stack,
  Card,
  CardActionArea,
  CardContent,
} from '@mui/material';
import Header from '../home/components/Header';
import Footer from '../home/components/Footer';
import { useNavigate } from 'react-router-dom';

// İkonları içe aktarın
import TextFieldsIcon from '@mui/icons-material/TextFields';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import EditIcon from '@mui/icons-material/Edit';
import GridOnIcon from '@mui/icons-material/GridOn';
import VisibilityIcon from '@mui/icons-material/Visibility';

function HizliOku() {
  const navigate = useNavigate();

  const menuItems = [
    {
      title: 'Kayan Yazılar 1',
      description:
        'Sıra ile gelen kelime veya kelime gruplarına ayak uydurun. Bu çalışma okumanıza tempo kazandıracaktır.',
      path: '/kayan-yazilar',
      icon: <TextFieldsIcon sx={{ fontSize: 60, color: '#fff' }} />,
    },
    {
      title: 'Kayan Yazılar 2',
      description:
        'Sıra ile ikişer gelen kelime veya kelime gruplarına ayak uydurun. Bu çalışma okumanıza tempo kazandıracaktır.',
      path: '/kayan-yazilar-ikiser',
      icon: <TextFieldsIcon sx={{ fontSize: 60, color: '#fff' }} />,
    },
    {
      title: 'Kayan Yazılar 3',
      description:
        'Sıra ile üçer gelen kelime veya kelime gruplarına ayak uydurun. Bu çalışma okumanıza tempo kazandıracaktır.',
      path: '/kayan-yazilar-ucer',
      icon: <TextFieldsIcon sx={{ fontSize: 60, color: '#fff' }} />,
    },
    {
      title: 'Kelime Bulma ve Seçme Oyunu',
      description:
        'Bu oyun, kullanıcının hızlı okuma ve dikkat becerilerini geliştirmeyi amaçlar.',
      path: '/kelime-secme-ve-bulma-oyunu',
      icon: <FindInPageIcon sx={{ fontSize: 60, color: '#fff' }} />,
    },
    {
      title: 'Kelime Tamamlama Oyunu',
      description:
        'Belirli bir süre içinde bir paragraftaki eksik kelimeleri doğru seçeneklerden seçerek tamamlamanızı gerektiren bir oyundur.',
      path: '/kelime-tamamlama-oyunu',
      icon: <EditIcon sx={{ fontSize: 60, color: '#fff' }} />,
    },
    {
      title: 'Harf Izgara Oyunu',
      description:
        'Bu oyun, belirli bir süre içinde verilen hedef harfi ızgara üzerindeki harfler arasından bulmanızı gerektirir.',
      path: '/harf-izgara-oyunu',
      icon: <GridOnIcon sx={{ fontSize: 60, color: '#fff' }} />,
    },
    {
      title: 'Takistoskopik Okuma',
      description:
        'Ekranda beliren kelime veya kelime gruplarına ayak uydurun. Bu çalışma okumanıza tempo kazandıracaktır.',
      path: '/takistoskopik-okuma-oyunu',
      icon: <VisibilityIcon sx={{ fontSize: 60, color: '#fff' }} />,
    },
  ];

  const handleCardClick = (path) => {
    navigate(path);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header />
      <div className="bg-main">
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            backgroundColor: 'transparent', // Arka planın container tarafından kontrol edilmesini sağlamak için
            py: 5, // Yukarı ve aşağı padding
            px: 2, // Yan padding
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Container
            maxWidth="md"
            className="main-container"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              borderRadius: '12px',
              color: '#333', // Yazı rengini koyu yaptık
              backgroundColor: 'rgba(255, 255, 255, 0.9)', // Arka plan rengini daha opak yaptık
              boxShadow: '0 4px 12px rgba(0,0,0,0.1)', // Hafif gölge
              p: { xs: 2, sm: 4 }, // Responsive padding
              width: '100%',
              maxWidth: '100%', // Container'ın genişliğini kontrol etmek için
            }}
          >
            <Typography
              variant="h4"
              component="h1"
              sx={{
                mb: 4,
                fontWeight: 'bold',
                color: '#1976d2', // Başlık rengini mavi yaptık
              }}
              align="center"
            >
              Hızlı Okuma Uygulamaları
            </Typography>

            {/* Scrollbar eklemek için Box ile sarmalama */}
            <Box
              sx={{
                maxHeight: '60vh', // İhtiyacınıza göre bu değeri ayarlayabilirsiniz
                overflowY: 'auto',
                width: '100%',
                // İsteğe bağlı olarak scrollbar stilini özelleştirebilirsiniz
                '&::-webkit-scrollbar': {
                  width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                  background: '#f1f1f1',
                  borderRadius: '10px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#1976d2',
                  borderRadius: '10px',
                },
              }}
            >
              <Stack
                direction="column"
                spacing={3}
                sx={{
                  width: '100%',
                }}
              >
                {menuItems.map((item, index) => (
                  <Card
                    key={index}
                    sx={{
                      borderRadius: '15px',
                      background: 'rgba(255, 255, 255, 0.8)', // Daha okunaklı arka plan
                      boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
                      transition: 'transform 0.3s, box-shadow 0.3s',
                      '&:hover': {
                        transform: 'translateY(-5px)',
                        boxShadow: '0 8px 20px rgba(0,0,0,0.2)',
                      },
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <CardActionArea
                      onClick={() => handleCardClick(item.path)}
                      sx={{ display: 'flex', alignItems: 'center', width: '100%' }}
                    >
                      <Box
                        sx={{
                          p: 2,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          background: '#1976d2', // İkon arka planını mavi yaptık
                          borderRadius: '15px 0 0 15px',
                          minWidth: '100px',
                        }}
                      >
                        {item.icon}
                      </Box>
                      <CardContent sx={{ flexGrow: 1, padding: '16px' }}>
                        <Typography
                          variant="h6"
                          sx={{
                            fontWeight: '600',
                            color: '#1976d2', // Başlık rengini mavi yaptık
                          }}
                        >
                          {item.title}
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#555', mt: 1 }}>
                          {item.description}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                ))}
              </Stack>
            </Box>
          </Container>
        </Box>
      </div>
      <Footer />
    </Box>
  );
}

export default HizliOku;
