import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Typography, Button, Card, CardContent, Grid, Box, Avatar,} from '@mui/material';
import { ArrowForwardIos, Lock, CheckCircle, ArrowBackIos } from '@mui/icons-material';
import { API_URLS } from '../../../config/config';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';

const StyledCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  overflow: 'visible',
  marginBottom: theme.spacing(4),
  borderRadius: '15px',
  transition: 'transform 0.3s, box-shadow 0.3s',
  boxShadow: theme.shadows[3],
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: theme.shadows[6],
  },
}));
 
const ExerciseList = ({ stage, userData, onBack }) => {
  const [exercises, setExercises] = useState([]);
  const [progressData, setProgressData] = useState(null);
  const navigate = useNavigate();
  const token = localStorage.getItem('userToken');

  useEffect(() => {
    const fetchExercises = async () => {
      try {
        // Fetch stages
        let response = await axios.get(`${API_URLS.TEACHER_URL}akillikurs/progress/stages`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        let stages = response.data.stages || [];
        let currentStage = stages.find((s) => s.stageNumber === stage.number);

        // If the current stage doesn't exist, attempt to initialize it
        if (!currentStage) {
          try {
            // Initialize the stage
            const initResponse = await axios.post(
              `${API_URLS.TEACHER_URL}akillikurs/progress/initialize-stage`,
              {},
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            stages = initResponse.data.progress.stages;
            currentStage = stages.find((s) => s.stageNumber === stage.number);
          } catch (initError) {
            if (initError.response && initError.response.status === 400) {
              // Previous stage not completed
              console.error(initError.response.data.message);
              return;
            } else {
              throw initError;
            }
          }
        }

        // Set exercises
        if (currentStage) {
          setExercises(
            currentStage.games.map((game) => ({
              ...game,
              studentId: userData.id,
            }))
          );
        } else {
          console.error(`Aşama numarası ${stage.number} bulunamadı.`);
        }

        // Fetch progress data
        setProgressData({ stages }); 
      } catch (error) {
        console.error('Egzersizler alınırken hata oluştu:', error);
      }
    };

    fetchExercises();
  }, [stage, token, userData.id]);

  const stageProgress = progressData?.stages.find((s) => s.stageNumber === stage.number);

  let prevExerciseCompleted = true;

  // Tüm egzersizler tamamlanmış mı kontrol et
  const allExercisesCompleted =
    exercises.length > 0 &&
    exercises.every((exercise) => {
      const gameProgress = stageProgress?.games.find((g) => g.gameName === exercise.gameName);
      return gameProgress?.completed;
    });

  // "Aşamayı Tamamla" butonuna tıklandığında çalışacak fonksiyon
  const handleCompleteStage = () => {
    navigate('/seviye-tespit-sinav', { state: { stage, fromStage: true } });
  };

  return (
    <Box sx={{ padding: '40px', backgroundColor: '#FFF8E1', minHeight: '100vh' }}>
      {/* Geri Butonu */}
      <Button
        variant="contained"
        color="primary"
        startIcon={<ArrowBackIos />}
        onClick={onBack}
        sx={{ mb: 2 }}
      >
        Geri
      </Button>
      <Typography
        variant="h3"
        gutterBottom
        sx={{ fontFamily: 'Comic Sans MS', color: '#4E342E', textAlign: 'center', mb: 6 }}
      >
        {stage.title} Egzersizleri
      </Typography>
      <Box sx={{ position: 'relative', marginLeft: '20px' }}>
        {exercises.map((exercise, index) => {
          const gameProgress = stageProgress?.games.find(
            (g) => g.gameName === exercise.gameName
          );
          const completed = gameProgress?.completed || false;

          const isUnlocked = prevExerciseCompleted;
          prevExerciseCompleted = completed;

          return (
            <Box key={index} sx={{ position: 'relative' }}>
              <StyledCard
                sx={{
                  opacity: isUnlocked ? 1 : 0.6,
                  pointerEvents: isUnlocked ? 'auto' : 'none',
                  backgroundColor: completed
                    ? '#B2FF59'
                    : isUnlocked
                    ? '#FFF176'
                    : '#E0E0E0',
                }}
              >
                {index < exercises.length - 1 && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '30px',
                      left: '-15px',
                      width: '2px',
                      height: '100%',
                      bgcolor: '#e0e0e0',
                    }}
                  ></Box>
                )}
                <CardContent>
                  <Grid container alignItems="center">
                    <Grid item xs={10}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar
                          sx={{
                            bgcolor: completed ? 'success.main' : 'primary.main',
                            mr: 2,
                          }}
                        >
                          {completed ? <CheckCircle /> : <ArrowForwardIos />}
                        </Avatar>
                        <Typography
                          variant="h5"
                          component="h2"
                          sx={{ fontFamily: 'Comic Sans MS', color: '#BF360C' }}
                        >
                          {exercise.gameName}
                        </Typography>
                      </Box>
                      <Typography variant="body2" component="p" sx={{ mt: 1, color: '#6D4C41' }}>
                        {exercise.description}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} sx={{ textAlign: 'right' }}>
                      {isUnlocked ? (
                        <Button
                          variant="contained"
                          color={completed ? 'success' : 'primary'}
                          onClick={() =>
                            navigate(exercise.path, { state: { stage, exercise } })
                          }
                          endIcon={<ArrowForwardIos />}
                          sx={{ fontWeight: 'bold' }}
                        >
                          {completed ? 'Tekrar Oyna' : 'Başla'}
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="secondary"
                          disabled
                          startIcon={<Lock />}
                          sx={{ fontWeight: 'bold' }}
                        >
                          Kilitli
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
                {!isUnlocked && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      bgcolor: 'rgba(255, 255, 255, 0.7)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '15px',
                    }}
                  >
                    <Lock sx={{ fontSize: 40, color: 'grey.500' }} />
                  </Box>
                )}
              </StyledCard>
            </Box>
          );
        })}

        {/* "Aşamayı Tamamla" butonunu ekleyin */}
        {allExercisesCompleted && (
          <Box sx={{ mt: 4 }}>
            <StyledCard
              sx={{
                backgroundColor: '#64B5F6', // Butonun arka plan rengini ayarlayın
              }}
            >
              <CardContent>
                <Grid container alignItems="center">
                  <Grid item xs={10}>
                    <Typography
                      variant="h5"
                      component="h2"
                      sx={{ fontFamily: 'Comic Sans MS', color: '#FFFFFF' }}
                    >
                      Aşamayı Tamamla
                    </Typography>
                    <Typography variant="body2" component="p" sx={{ mt: 1, color: '#FFFFFF' }}>
                      Tüm egzersizleri tamamladınız. Aşamayı tamamlamak için testi geçin.
                    </Typography>
                  </Grid>
                  <Grid item xs={2} sx={{ textAlign: 'right' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCompleteStage}
                      endIcon={<ArrowForwardIos />}
                      sx={{ fontWeight: 'bold' }}
                    >
                      Teste Git
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </StyledCard>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ExerciseList;
