import React from 'react';

const GameBoard = ({
  paragraph,
  selectedWords,
  targetWords,
  handleWordClick,
  correctWords,
}) => {
  return (
    <>
      {/* Hedef Kelimeler Listesi */}
      <div className="row mb-3">
        <div className="col-12">
          <h5>Hedef Kelimeler:</h5>
          <div className="target-words-container">
            {targetWords.map((word, index) => {
              const isFound = correctWords.includes(word);
              return (
                <span
                  key={index}
                  className={`target-word ${isFound ? 'found' : ''}`}
                >
                  {word}
                </span>
              );
            })}
          </div>
        </div>
      </div>

      {/* Paragraf */}
      <div className="kelime-secme-game-container">
        {paragraph ? (
          paragraph.split(' ').map((word, index) => {
            const normalizedWord = word
              .toLowerCase()
              .replace(/[^\p{L}\p{N}_]+/gu, '');
            const isSelected = selectedWords.includes(normalizedWord);
            const isCorrect = targetWords.includes(normalizedWord);
            const className = `word ${
              isSelected ? (isCorrect ? 'correct' : 'incorrect') : ''
            }`;

            return (
              <span
                key={index}
                className={className}
                onClick={() => handleWordClick(word)}
              >
                {word}{' '}
              </span>
            );
          })
        ) : (
          <div
            className="alert alert-warning text-center"
            role="alert"
          >
            Seçili paragrafta içerik bulunamadı. Lütfen farklı bir
            paragraf seçin.
          </div>
        )}
      </div>

      {/* Stil tanımlamaları */}
      <style jsx>{`
        .word {
          cursor: pointer;
          transition: background-color 0.3s;
        }

        .word:hover {
          background-color: #f0f8ff;
        }

        .word.correct {
          background-color: #c8e6c9;
          color: #2e7d32;
          font-weight: bold;
        }

        .word.incorrect {
          background-color: #ffcdd2;
          color: #c62828;
          text-decoration: line-through;
        }

        .target-words-container {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
          justify-content: center;
          margin-bottom: 10px;
        }

        .target-word {
          background-color: #e1f5fe;
          padding: 8px 12px;
          border-radius: 20px;
          font-weight: bold;
          color: #0277bd;
          position: relative;
        }

        .target-word.found {
          background-color: #c8e6c9;
          color: #2e7d32;
        }

        .target-word.found::after {
          content: '✔';
          position: absolute;
          top: -5px;
          right: -5px;
          background-color: #2e7d32;
          color: white;
          border-radius: 50%;
          width: 20px;
          height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
        }
      `}</style>
    </>
  );
};

export default GameBoard;
