// useTachistoscopicGameLogic.js

import { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URLS } from '../../../../config/config';

const useTachistoscopicGameLogic = () => {
  const [difficulty, setDifficulty] = useState('easy');
  const [words, setWords] = useState([]);
  const [currentWord, setCurrentWord] = useState('');
  const [showWord, setShowWord] = useState(false);
  const [input, setInput] = useState('');
  const [displayedWords, setDisplayedWords] = useState([]);
  const [enteredWords, setEnteredWords] = useState([]);
  const [showingTime, setShowingTime] = useState(30);
  const [writingTime, setWritingTime] = useState(60);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [phase, setPhase] = useState('showing');
  const [speed, setSpeed] = useState(3000);
  const token = localStorage.getItem('userToken');

  const fetchWords = async (difficulty) => {
    try {
      const url = `${API_URLS.HIZLIOKUMA_URL}akanyazi/${difficulty}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.data && response.data.length > 0) {
        const wordsArray = response.data
          .flatMap((paragraph) => paragraph.content.split(' '))
          .map((word) =>
            word
              .replace(/[^\p{L}\p{N}\s]/gu, '') // Noktalama işaretlerini kaldır
              .trim()
          )
          .filter((word) => word.length > 0); // Boş kelimeleri filtrele

        setWords(wordsArray);
      } else {
        setWords([]);
      }
    } catch (error) {
      console.error('Kelimeler alınırken hata oluştu:', error);
      setWords([]);
    }
  };


  useEffect(() => {
    fetchWords(difficulty);
    if (difficulty === 'easy') {
      setShowingTime(30);
      setWritingTime(60);
      setSpeed(3000); // Hız (ms)
    } else if (difficulty === 'medium') {
      setShowingTime(20);
      setWritingTime(45);
      setSpeed(2000);
    } else if (difficulty === 'hard') {
      setShowingTime(15);
      setWritingTime(30);
      setSpeed(1000);
    }
  }, [difficulty]);

  useEffect(() => {
    let wordInterval;
    let showingTimer;

    // Kelimenin ekranda ne kadar süre kalacağını ayarla (speed'e bağlı olarak)
    const wordDisplayDuration = speed * 0.6; // Hızın %60'ı kadar süre ekranda kalsın.

    if (isPlaying && words.length > 0 && phase === 'showing') {
      let remainingShowingTime = showingTime;

      const showNextWord = () => {
        const randomWord = words[Math.floor(Math.random() * words.length)];
        setCurrentWord(randomWord);
        setShowWord(true);
        setDisplayedWords(prevWords => [...prevWords, randomWord]);

        setTimeout(() => {
          setShowWord(false);
        }, wordDisplayDuration);

        remainingShowingTime -= speed / 1000;

        if (remainingShowingTime <= 0) {
          // Gösterim süresi doldu
          setPhase('writing');
          setShowWord(false);
          clearInterval(wordInterval);
        }
      };

      // İlk kelimeyi hemen göster
      showNextWord();

      // Belirli bir aralıkla kelimeleri göster
      wordInterval = setInterval(showNextWord, speed);

      // Showing time'ı güncelle
      showingTimer = setInterval(() => {
        setShowingTime(prevTime => {
          if (prevTime <= 1) {
            clearInterval(showingTimer);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    }

    return () => {
      clearInterval(wordInterval);
      clearInterval(showingTimer);
    };
  }, [isPlaying, words, phase, speed]);

  useEffect(() => {
    let writingTimer;
    if (isPlaying && phase === 'writing') {
      writingTimer = setInterval(() => {
        setWritingTime(prevTime => {
          if (prevTime <= 1) {
            clearInterval(writingTimer);
            setIsPlaying(false);
            setIsFinished(true);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    }

    return () => clearInterval(writingTimer);
  }, [isPlaying, phase]);

  const handlePlayPause = () => {
    if (isPlaying) {
      setIsFinished(true);
      setIsPlaying(false);
    } else {
      setIsFinished(false);
      setDisplayedWords([]);
      setEnteredWords([]);
      setPhase('showing');
      setIsPlaying(true);
    }
  };

  const handleReset = () => {
    setIsPlaying(false);
    setIsFinished(false);
    setInput("");
    setCurrentWord("");
    setShowWord(false);
    setPhase('showing');
    setShowingTime(difficulty === 'easy' ? 30 : difficulty === 'medium' ? 20 : 15);
    setWritingTime(difficulty === 'easy' ? 60 : difficulty === 'medium' ? 45 : 30);
    setDisplayedWords([]);
    setEnteredWords([]);
    setSpeed(difficulty === 'easy' ? 3000 : difficulty === 'medium' ? 2000 : 1000);
  };

  const handleSubmit = () => {
    setIsFinished(true);
    setIsPlaying(false); // Oyun bittiğinde durdur
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInput(value);

    if (value.endsWith(' ')) {
      const word = value.trim();
      setEnteredWords(prevWords => [...prevWords, word]);
      setInput('');
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Enter tuşunun varsayılan davranışını engelle
      const word = input.trim();
      if (word.length > 0) {
        setEnteredWords((prevWords) => [...prevWords, word]);
      }
      setInput('');
    }
  };

  const handleDifficultyChange = (e) => {
    const newDifficulty = e.target.value;
    setDifficulty(newDifficulty);
    handleReset();
  };

  const handleSpeedChange = (e) => {
    const newSpeed = Number(e.target.value) * 1000;
    setSpeed(newSpeed);
  };

  const getSpeedInSeconds = (speedMs) => {
    return (speedMs / 1000).toFixed(1);
  };

  return {
    difficulty,
    words,
    currentWord,
    showWord,
    input,
    displayedWords,
    enteredWords,
    showingTime,
    writingTime,
    isPlaying,
    isFinished,
    phase,
    speed,
    handlePlayPause,
    handleReset,
    handleSubmit,
    handleInputChange,
    handleKeyPress,
    handleDifficultyChange,
    handleSpeedChange,
    getSpeedInSeconds,
    setIsFinished,
  };
};

export default useTachistoscopicGameLogic;
