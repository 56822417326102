// components/PaymentFailed.js

import React, { useEffect } from 'react';
import { Typography, Card, CardContent, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ErrorIcon from '@mui/icons-material/Error';

function PaymentFailed() {
  const navigate = useNavigate();

  useEffect(() => {
    // 2 saniye sonra anasayfaya yönlendir
    const timer = setTimeout(() => {
      navigate('/');
    }, 2000);

    // Temizleme fonksiyonu
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <Box 
      display="flex" 
      justifyContent="center" 
      alignItems="center" 
      minHeight="100vh"
      bgcolor="#f5f5f5"
      padding={2}
    >
      <Card sx={{ maxWidth: 400, padding: 3, textAlign: 'center', boxShadow: 3 }}>
        <CardContent>
          <ErrorIcon color="error" sx={{ fontSize: 60, marginBottom: 2 }} />
          <Typography variant="h5" gutterBottom>
            Ödeme Başarısız
          </Typography>
          <Typography variant="body1" gutterBottom>
            Ödeme işlemi sırasında bir hata oluştu veya işlem iptal edildi.
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Anasayfaya yönlendiriliyorsunuz...
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
}

export default PaymentFailed;
