// src/pages/Home.js

import React from 'react';
import Header from '../components/Header';
import HeaderBanner from '../components/ui/Banner';
import Footer from '../components/Footer';
import { Card, CardContent, Typography, Grid, Box, Container } from '@mui/material';
import ComputerIcon from '@mui/icons-material/Computer';
import GroupIcon from '@mui/icons-material/Group';
import DiamondIcon from '@mui/icons-material/Diamond';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PackagePromotion from '../components/paketler/PackagePromotion';

function MainCard({ Icon, title, subtitle, bgColor }) {
  return (
    <Card
      sx={{
        minWidth: 275,
        boxShadow: 10,
        borderRadius: 3,
        background: bgColor,
        color: '#fff',
        transition: '0.3s',
        position: 'relative',
        overflow: 'hidden',
        '&:hover': {
          transform: 'scale(1.05)',
          boxShadow: 15,
        },
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '-20%',
          right: '-20%',
          width: '150%',
          height: '150%',
          background: 'rgba(255,255,255,0.1)',
          transform: 'rotate(45deg)',
        }}
      />
      <CardContent
        sx={{
          position: 'relative',
          zIndex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          py: 6,
        }}
      >
        <Icon sx={{ fontSize: 60, mb: 2 }} />
        <Typography variant="h4" component="div" sx={{ fontWeight: 'bold' }}>
          {title}
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            fontFamily: '"Roboto Condensed", sans-serif',
            fontWeight: 700,
            fontSize: 20,
            color: '#fff',
            textShadow: `
              1px 0 0 #000,
              -1px 0 0 #000,
              0 1px 0 #000,
              0 -1px 0 #000
            `,
          }}
        >
          {subtitle}
        </Typography>
      </CardContent>
    </Card>
  );
}

function Home() {
  return (
    <div>
      <Header />
      <HeaderBanner />

      {/* Ana Kartlar Bölümü */}
      <Box sx={{ flexGrow: 1, py: 8, backgroundColor: '#f0f2f5' }}>
      <Container maxWidth="lg">
      {/* Başlık */}
      <Typography
        variant="h3"
        align="center"
        gutterBottom
        sx={{ fontWeight: 'bold', mb: 2, color: '#333' }}
      >
        Bizimle Neler Başarabilirsiniz?
      </Typography>
      
      {/* Alt Metin - Ortalanmış */}
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Typography
          variant="h6"
          align="center"
          gutterBottom
          sx={{
            mb: 6,
            color: '#555',
            fontWeight: '500',
            fontStyle: 'italic',
            letterSpacing: '0.5px',
            lineHeight: 1.6,
            background: 'linear-gradient(90deg, #ece9e6, #ffffff)',
            display: 'inline-block',
            padding: '8px 16px',
            borderRadius: '8px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          }}
        >
          Seri Okuma, Okuduğunu Anlama, Paragraf Teknikleri ve Hafıza Teknikleri
        </Typography>
      </Box>
      
      {/* Kartlar */}
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={3}>
          <MainCard
            Icon={ComputerIcon}
            title="71k+"
            subtitle="Eğitim Kursu Sunulan"
            bgColor="linear-gradient(135deg, #7b1fa2, #9c27b0)"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <MainCard
            Icon={GroupIcon}
            title="50k+"
            subtitle="Öğrenci Memnuniyeti"
            bgColor="linear-gradient(135deg, #00796b, #009688)"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <MainCard
            Icon={DiamondIcon}
            title="200+"
            subtitle="Deneyimli Öğretmen"
            bgColor="linear-gradient(135deg, #c2185b, #e91e63)"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <MainCard
            Icon={CheckCircleOutlineIcon}
            title="95%"
            subtitle="Başarı Oranı"
            bgColor="linear-gradient(135deg, #fbc02d, #ffc107)"
          />
        </Grid>
      </Grid>
    </Container>
      </Box>

      {/* Paket Tanıtımı Bölümü */}
      <PackagePromotion />

      <Footer />
    </div>
  );
}

export default Home;
